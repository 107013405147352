

























import { Component, Vue } from "vue-property-decorator";
import { getStatus } from "@/common/document_statuses";
import { CountEmployeeDocumentApprovalRequest } from "@/api";

@Component
export default class DocumentMenu extends Vue {
  get documentStatus() {
    const count = this.$store.getters[
      "employeeDocuments/employeeDocumentCount"
    ];
    const listWithCount = getStatus.map(item => {
      const foundItem = count?.find(
        (doc: CountEmployeeDocumentApprovalRequest) => doc.id === item.id,
      );
      return {
        ...item,
        count: foundItem?.count || 0,
      };
    });
    return listWithCount;
  }

  goTo(path: string) {
    const to = path.toLowerCase();
    const menu = "load";
    this.$router.push({ path: to, query: { menu } });
  }

  isActive(id: number) {
    return this.$route.params.status === id.toString();
  }
}
