
























import { checkSelfRole } from "@/common/role_utils";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ReportLayout extends Vue {
  isOwner = checkSelfRole("owner");

  get isCategory() {
    return this.$route.params.departmentId;
  }

  get isCompany() {
    return this.isOwner && this.$route.path.endsWith("owner");
  }
}
