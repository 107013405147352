/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UpdateEmployeeProfileRequest = {
    properties: {
        firstName: {
    type: 'string',
},
        lastName: {
    type: 'string',
},
        titleId: {
    type: 'number',
},
        birthday: {
    type: 'string',
    format: 'date',
},
        hireDate: {
    type: 'string',
    format: 'date',
},
        phone: {
    type: 'string',
},
        about: {
    type: 'string',
},
        hobbies: {
    type: 'string',
},
        shift: {
    type: 'number',
},
        birthdayVisible: {
    type: 'number',
},
    },
};