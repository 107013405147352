


























import { Component, Vue } from "vue-property-decorator";

@Component
export default class DocumentRoot extends Vue {
  get isList() {
    return this.$route.params.status;
  }

  get isDetails() {
    return this.$route.params.id;
  }
}
