




































































import { Vue, Component } from "vue-property-decorator";
import EditCustomEvent from "@/views/admin/event_metrics/EditCustomEvent.vue";
import RestoreCustomEvent from "@/views/admin/event_metrics/RestoreCustomEvent.vue";
import AddCustomEvent from "@/views/admin/event_metrics/AddCustomEvent.vue";
import _ from "lodash";
import loading from "@/components/loader/loading.vue";
import ConfirmModal from "@/views/company_directory/ConfirmModal.vue";

@Component({
  components: {
    EditCustomEvent,
    AddCustomEvent,
    loading,
    ConfirmModal,
    RestoreCustomEvent,
  },
})
export default class AllEventList extends Vue {
  employeeRestoreConfirmModal = false;
  addEvent = false;

  created() {
    this.$store.dispatch("EventMetricAdmin/loadAvailableEvents");
    this.$store.dispatch("EventMetricAdmin/loadArchivedCustomEvents");
  }

  get archiveEvents() {
    const archiveEvents = this.$store.getters[
      "EventMetricAdmin/archievedEventList"
    ];
    return _.sortBy(archiveEvents, archiveEvents =>
      archiveEvents.name.toUpperCase(),
    );
  }

  get standardEvents() {
    const standardEvents = this.$store.getters[
      "EventMetricAdmin/standardEventList"
    ];
    return _.sortBy(standardEvents, standardEvents =>
      standardEvents.name.toUpperCase(),
    );
  }

  get customEvents() {
    const customEvents = this.$store.getters[
      "EventMetricAdmin/customEventList"
    ];
    return _.sortBy(customEvents, customEvents =>
      customEvents.name.toUpperCase(),
    );
  }

  back() {
    this.$router.back();
  }
}
