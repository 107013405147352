




































import { Company, Department } from "@/api";
import _ from "lodash";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class EventDepartmentList extends Vue {
  search = "";
  created() {
    this.$store.dispatch("company/loadDepartments");
    this.$store.dispatch("departmentAdmin/loadArchiveDepartment");
  }
  get activeMenu() {
    if (this.$route.params.id) return this.$route.params.id;
    else return "not_select";
  }
  get company(): Company {
    return this.$store.getters["getSelectedCompany"];
  }

  get departments(): Department[] {
    const search = !this.search ? "" : this.search;
    const departments = this.$store.getters["departmentAdmin/departments"];
    const list = _.sortBy(
      _.filter(departments, department =>
        _.includes(`${department.name.toLowerCase()}`, search.toLowerCase()),
      ),
      [(department: Department) => `${department.name.trim().toUpperCase()}`],
    );
    return Object.values(list);
  }
  clearSearch() {
    this.search = "";
  }
  selectDepartment(department: Department) {
    this.$router.push("/admin/event/log/logevent/" + department.id);
  }

  get departmentId(): number {
    const departmentIdParam = this.$route.query.departmentId as string;
    if (departmentIdParam) {
      try {
        return parseInt(departmentIdParam);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  isDepartmentSelected(department: Department): boolean {
    const departmentId = this.departmentId;
    return department.id === departmentId;
  }
}
