





























import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import * as validation from "@/tool/validation";
import { MessageGroup, MessageGroupDetails } from "@/api";
import { selfData } from "@/common/role_utils";

@Component
export default class UpdateGroupName extends Vue {
  @Ref("form") form!: VForm;
  @Prop() readonly groupDetails!: MessageGroupDetails;

  name = this.groupDetails?.name || "";
  rules = [validation.noSpace, validation.requiredString];
  loading = false;

  close() {
    this.form.resetValidation();
    this.$emit("close");
  }

  async save() {
    const isValid = this.form.validate();
    if (isValid) {
      this.loading = true;
      const requestBody = {
        id: this.groupDetails.id,
        name: this.name,
        companyId: selfData().companyId,
      } as MessageGroup;
      this.$store.dispatch("chat/updateGroup", requestBody);
      this.close();
      this.loading = false;
    }
  }
}
