import { RouteConfig } from "vue-router";
import EmployeeList from "@/views/departments/EmployeeList.vue";
import TitleList from "@/views/departments/TitleList.vue";
import DepartmentList from "@/views/departments/DepartmentList.vue";
import EmployeeProfile from "@/views/company_directory/EmployeeProfile.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "Department List",
    components: {
      default: DepartmentList,
      departmentList: DepartmentList,
      titleList: TitleList,
      employeeList: EmployeeList,
      employeeDetail: EmployeeProfile,
    },
    meta: {
      title: "Departments",
      requiresAuth: true,
    },
  },
  {
    path: ":deptId/title",
    name: "Title List",
    components: {
      default: TitleList,
      departmentList: DepartmentList,
      titleList: TitleList,
      employeeList: EmployeeList,
      employeeDetail: EmployeeProfile,
    },
    meta: {
      title: "Title List",
      requiresAuth: true,
    },
  },
  {
    path: ":deptId/title/:titleName/:titleId/employees",
    name: "Employee List",
    components: {
      default: EmployeeList,
      departmentList: DepartmentList,
      titleList: TitleList,
      employeeList: EmployeeList,
      employeeDetail: EmployeeProfile,
    },
    meta: {
      title: "Employee List",
      requiresAuth: true,
    },
  },
  {
    path: ":deptId/title/:titleName/:titleId/employees/:id",
    name: "Department Employee Detail",
    components: {
      default: EmployeeProfile,
      departmentList: DepartmentList,
      titleList: TitleList,
      employeeList: EmployeeList,
      employeeDetail: EmployeeProfile,
    },
    meta: {
      title: "Employee Detail",
      requiresAuth: true,
    },
  },
];

export default routes;
