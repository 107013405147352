




















import { Component, Vue } from "vue-property-decorator";

@Component
export default class CompanyRoot extends Vue {
  get hasDetail() {
    return this.$route.params.companyid;
  }

  get add() {
    return this.$route.path.endsWith("create");
  }
}
