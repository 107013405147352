















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Snackbar extends Vue {
  get alertState() {
    return this.$store.getters["alert/alertState"];
  }

  close() {
    this.$store.dispatch("alert/close");
  }
}
