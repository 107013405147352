






































import { Company } from "@/api/models/Company";
import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";

@Component
export default class CompanyList extends Vue {
  titleId = 0;

  async created() {
    await this.$store.dispatch("superAdmin/fetchCompanyList");
    if (this.selectedCompany && !this.$vuetify.breakpoint.smAndDown) {
      this.selectTitle(this.selectedCompany);
    }
    if (
      this.companies &&
      !this.selectedCompany &&
      !this.$vuetify.breakpoint.smAndDown
    ) {
      if (!this.$vuetify.breakpoint.smAndDown) {
        this.selectTitle(this.companies[0]);
      }
    }
  }

  get companies() {
    const list = _.sortBy(
      this.$store.getters["superAdmin/companyList"],
      company => company.name.toUpperCase(),
    );
    return list;
  }

  // Below function will be used for the create button to show form for the create company
  goTo(route: string) {
    this.titleId = 0;
    this.$router.push({ path: `/company-list/${route}` });
  }

  // Below functoin will be used to show the details of the company
  async selectTitle(company: Company) {
    this.titleId = company.id;
    await this.$store.dispatch("selectCompanyById", company.id);
    await this.$store.dispatch("superAdmin/selectCompany", company.id);
    this.$store.dispatch("company/loadDepartments", true);
    this.$router.push({
      path: `/company-list/${this.titleId}`,
    });
  }

  get selectedCompany() {
    return this.$store.getters["getSelectedCompany"];
  }
}
