import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";

export type SnackbarNotice = {
  message: {
    title: string;
    body: string;
  };
  color: string;
  isOpen: boolean;
  timeout: number;
};

export type Notification = {
  notice: [];
  fcmToken: string | null;
  tokenId: number | null;
  notifyMessage: SnackbarNotice;
};

const state: Notification = {
  notice: [],
  fcmToken: null,
  tokenId: null,
  notifyMessage: {
    message: {
      title: "",
      body: "",
    },
    color: "info",
    isOpen: false,
    timeout: 5000,
  },
};

const getters: GetterTree<Notification, RootState> = {
  notice: state => state.notice,
  fcmToken: state => state.fcmToken,
  tokenId: state => state.tokenId,
  notifyMessage: state => state.notifyMessage,
};

const mutations: MutationTree<Notification> = {
  // SET_NOTICE: (state, notice) => {
  //   state.notice?.push(notice);
  // },
  SET_FCM_TOKEN: (state, token) => {
    state.fcmToken = token;
  },
  SET_TOKEN_ID: (state, tokenId) => {
    state.tokenId = tokenId;
  },
  SET_NOTIFY_MESSAGE: (state, message: { title: string; body: string }) => {
    state.notifyMessage.isOpen = false;
    state.notifyMessage.message = message;
    state.notifyMessage.isOpen = true;
  },
  CLOSE_NOTIFY_MESSAGE: state => {
    state.notifyMessage.isOpen = false;
  },
};

const actions: ActionTree<Notification, RootState> = {
  setTokenLocal: ({ commit }, token: string) => {
    commit("SET_FCM_TOKEN", token);
  },
  setFCMToken: async ({ rootState, getters }, force: boolean) => {
    const companyId = rootState.appContext.company?.id;
    const token = getters["fcmToken"];
    const localToken = localStorage.getItem("fcm");
    if (!companyId || !token) return;
    if (!force && token === localToken) return;
    // const res = await FcmTokenService.saveFcmToken({
    //   companyId,
    //   requestBody: {
    //     token,
    //   },
    // });
    // localStorage.setItem("fcmId", `${res.token_id}`);
    // commit("SET_TOKEN_ID", res.token_id);
  },
  destroyFCMToken: async ({ rootState }) => {
    const companyId = rootState.appContext.company?.id;
    // const tokenId = getters["tokenId"] || Number(localStorage.getItem("fcmId"));
    if (companyId) {
      // await FcmTokenService.destroyFcmToken({
      //   companyId,
      //   tokenId,
      // });
    }
  },
  pushNotice: ({ commit }, notice) => {
    commit("SET_NOTICE", notice);
  },
  setNotifyMessage: ({ commit }, message: { title: string; body: string }) => {
    commit("SET_NOTIFY_MESSAGE", message);
  },
  close: ({ commit }) => {
    commit("CLOSE_NOTIFY_MESSAGE");
  },
};

export const notifications: Module<Notification, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
