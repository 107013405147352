import { render, staticRenderFns } from "./LargeLogo.vue?vue&type=template&id=d06dd3ba&scoped=true"
import script from "./LargeLogo.vue?vue&type=script&lang=ts"
export * from "./LargeLogo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d06dd3ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
