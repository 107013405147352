












































import { Vue, Component } from "vue-property-decorator";

@Component
export default class EventMetrics extends Vue {
  get activeMenu() {
    if (this.$route.path.includes("all")) return "all";
    else if (this.$route.path.includes("track")) return "track";
    else if (this.$route.path.includes("log")) return "log";
    else return "not_select";
  }
  selectEventMenu(eventMenu: string) {
    this.$router.push({ path: `/admin/event/${eventMenu}` });
  }
  get isSuperAdmin(): boolean {
    return this.$store.getters["isSuperAdmin"];
  }
}
