


























































































































import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
import moment from "moment";
import { TrackRecords } from "@/api";
import Loading from "@/components/loader/loading.vue";

type GuardianList = {
  id: number;
  name: string;
};

@Component({
  components: {
    Loading,
  },
})
export default class Tracking extends Vue {
  loading = false;
  datePicker = false;
  range: string[] = [];
  headings: string[] = [];
  guardianId = 0;
  guardianList: Array<GuardianList> = [];
  radioValue = 1;
  minDate = moment()
    .local()
    .format("YYYY-MM-DD");

  async created() {
    this.loading = true;
    this.defaultDates();
    this.setHeadings();
    await this.fetchTrackingRecords(true);
    this.loading = false;
  }

  get formattedDates() {
    const [date1, date2] = this.range;
    let beginDate = "";
    let endDate = "";
    if (date1 < date2) {
      beginDate = date1;
      endDate = date2;
    } else {
      beginDate = date2;
      endDate = date1;
    }
    const formattedDate = this.range
      ? `${moment(beginDate).format("MMM DD")}  -  ${moment(endDate).format(
          "MMM DD",
        )}`
      : "";
    return formattedDate;
  }

  defaultDates() {
    this.range = [
      moment()
        .subtract(35, "days")
        .startOf("week")
        .format("YYYY-MM-DD"),
      moment()
        .endOf("week")
        .format("YYYY-MM-DD"),
    ];
    this.guardianId = 0;
  }

  setHeadings() {
    const beginAt = this.range[0];
    const endAt = moment(this.range[1]).endOf("week");
    this.headings = [];
    let i = 0;

    /* eslint-disable no-constant-condition */
    while (true) {
      const date = moment(beginAt).add(7 * i, "days");
      const end = date.endOf("week");
      const check = end.isBefore(endAt) || end.isSame(endAt);
      if (check) {
        this.headings.push(
          `${date.startOf("week").format("MMM DD")} - ${date
            .endOf("week")
            .format("MMM DD")}`,
        );
        i++;
      } else {
        break;
      }
    }
  }

  get GuardianAngels() {
    let lists =
      (this.$store.getters["inspection/tracking"] as TrackRecords) || [];
    this.guardianList = [];
    _.forEach(lists, item => {
      if (item)
        this.guardianList.push({
          id: item.id,
          name: item.name,
        });
    });

    if (this.guardianId) {
      lists = _.filter(lists, item => item.id == this.guardianId);
    }
    return _.map(lists, item => {
      const ga = JSON.parse(JSON.stringify(item));
      ga.record = _.sortBy(item.record, rec => rec.from);
      return ga;
    });
  }

  async fetchTrackingRecords(rateStart = false) {
    this.setHeadings();
    await this.$store.dispatch("inspection/fetchTracking", {
      beginAt: this.range[0],
      endAt: this.range[1],
      rateStart,
    });
  }
}
