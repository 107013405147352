/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Employee = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        firstName: {
    type: 'string',
},
        lastName: {
    type: 'string',
},
        externalEmployeeId: {
    type: 'string',
},
        hireDate: {
    type: 'string',
    format: 'date',
},
        birthday: {
    type: 'string',
    format: 'date',
},
        title: {
    type: 'string',
},
        titleId: {
    type: 'number',
},
        primaryDepartmentId: {
    type: 'number',
    isRequired: true,
},
        email: {
    type: 'string',
},
        phone: {
    type: 'string',
},
        about: {
    type: 'string',
},
        hobbies: {
    type: 'string',
},
        subRole: {
    type: 'number',
    isRequired: true,
},
        shift: {
    type: 'number',
    isRequired: true,
},
        roles: {
    type: 'array',
    contains: {
    type: 'string',
},
    isRequired: true,
},
        createdAt: {
    type: 'string',
    isRequired: true,
},
        profilePicture: {
    type: 'string',
},
        phoneVisible: {
    type: 'number',
},
        birthdayVisible: {
    type: 'number',
},
        lead: {
    type: 'number',
},
        guardianAngel: {
    type: 'boolean',
},
        departmentAssignments: {
    type: 'array',
    contains: {
        type: 'DepartmentEmployeeAssignment',
    },
    isRequired: true,
},
    },
};