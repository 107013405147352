


















































































import { Component, Vue, Watch } from "vue-property-decorator";
import loading from "@/components/loader/loading.vue";
import { EmployeeDocumentResponse } from "@/api";
import { postStatus } from "@/common/document_statuses";
import VuePdfApp from "vue-pdf-app";
import ConfirmModal from "@/components/EmployeeDocument/ConfirmModal.vue";
import { dateFormat } from "@/common/date_format";
import { fetchDocumentURL } from "@/common/document_url";

@Component({
  components: {
    loading,
    VuePdfApp,
    ConfirmModal,
  },
})
export default class DocumentApproval extends Vue {
  @Watch("$route")
  async routeChanged() {
    this.loading = true;
    await this.$store.dispatch("employeeDocuments/getSelectedDocument", {
      employeeId: this.$route.query.employeeId,
      employeeDocumentId: this.$route.params.id,
    });
    this.showFile();
    this.loading = false;
  }

  loading = false;
  approving = false;
  denying = false;
  approvalConfirm = false;
  config = {
    toolbar: false,
  };
  text = "";
  preview = true;
  sourceFile = "";
  loadingURL = false;

  async created() {
    this.loading = true;
    await this.$store.dispatch("employeeDocuments/getSelectedDocument", {
      employeeId: this.$route.query.employeeId,
      employeeDocumentId: this.$route.params.id,
    });
    this.showFile();
    this.loading = false;
  }

  async showFile() {
    this.loadingURL = true;
    const res = await fetchDocumentURL(this.document.id);
    this.loadingURL = false;
    if (!res.length) return;
    this.sourceFile = res;
  }

  get document(): EmployeeDocumentResponse {
    return this.$store.getters["employeeDocuments/selectedDocument"];
  }

  checkDoc(path: string) {
    return !path?.includes(".pdf");
  }

  checkPath(path: string) {
    return this.$route.params.status === path;
  }

  async approvedDocument() {
    const approve = postStatus.find(
      stat => stat.status.toLowerCase() === "approved",
    );
    this.approving = true;
    await this.$store.dispatch("employeeDocuments/reviewDocument", {
      employeeId: this.document.employee_id,
      employeeDocumentId: this.document.id,
      requestBody: {
        approval: approve?.id,
      },
    });
    this.approving = false;
    this.text =
      "The document is successfully approved. Employee will be notified.";
    this.approvalConfirm = !this.approvalConfirm;
    this.fetchCount();
  }

  async deniedDocument() {
    const approve = postStatus.find(
      stat => stat.status.toLowerCase() === "denied",
    );
    this.denying = true;
    await this.$store.dispatch("employeeDocuments/reviewDocument", {
      employeeId: this.document.employee_id,
      employeeDocumentId: this.document.id,
      requestBody: {
        approval: approve?.id,
      },
    });
    this.denying = false;
    this.text =
      "The document is successfully denied. Employee will be notified.";
    this.approvalConfirm = !this.approvalConfirm;
    this.fetchCount();
  }

  async fetchCount() {
    await this.$store.dispatch("employeeDocuments/getEmployeeDocumentCount");
  }

  fetchList() {
    this.$store.dispatch("employeeDocuments/getApprovalDocumentList", {
      approval: Number(this.$route.params.status),
    });
    this.$router.push({
      path: `/document-approval/${this.$route.params.status}`,
    });
  }

  close() {
    this.approvalConfirm = !this.approvalConfirm;
    this.fetchList();
  }

  formatDate(date: string) {
    return dateFormat(date);
  }

  srcFailed() {
    this.preview = true;
  }
}
