









































































































import { Vue, Component } from "vue-property-decorator";
import { Floor } from "@/api";
import _ from "lodash";
import Loading from "@/components/loader/loading.vue";
import FloorFormModal from "./FloorFormModal.vue";
import ConfirmationModule from "@/components/confirmationModule/ConfirmationModule.vue";

@Component({
  components: {
    Loading,
    FloorFormModal,
    ConfirmationModule,
  },
})
export default class FloorList extends Vue {
  isDataLoading = false;
  search = "";
  dialog = false;
  dialogEdit = false;
  deleteDialog = false;
  saving = false;
  updating = false;
  deleting = false;
  count = 0;
  editingFloor: Floor | null = null;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("floor/fetchCompanyFloors");
    this.isDataLoading = false;
  }

  get floors() {
    const search = !this.search ? "" : this.search;
    const data = this.$store.getters["floor/floors"];
    const list = _.sortBy(
      _.filter(data, floor =>
        _.includes(`${floor.name.toLowerCase()}`, search.toLowerCase()),
      ),
      [(floor: Floor) => `${floor.name.trim().toUpperCase()}`],
    );
    return Object.values(list);
  }

  get floorId() {
    const floorId = this.$route.params.floorId as string;
    if (floorId) {
      try {
        return parseInt(floorId);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  selectFloor(floor: Floor) {
    this.$router.push({
      path: `/admin/room-setup/floor/${floor.id}`,
    });
  }

  async addFloor(requestBody: Floor) {
    this.saving = true;
    await this.$store.dispatch("floor/addCompanyFloor", requestBody);
    this.saving = false;
    this.dialog = !this.dialog;
    this.count++;
  }

  editFloor(floor: Floor) {
    this.count++;
    this.editingFloor = floor;
    this.dialogEdit = !this.dialogEdit;
  }

  async updateFloor(requestBody: Floor) {
    this.updating = true;
    await this.$store.dispatch("floor/updateCompanyFloor", requestBody);
    this.updating = false;
    this.dialogEdit = !this.dialogEdit;
    this.count++;
  }

  deleteConfirm(floor: Floor) {
    this.editingFloor = floor;
    this.deleteDialog = !this.deleteDialog;
  }

  async deleteFloor() {
    this.deleting = true;
    await this.$store.dispatch(
      "floor/removeCompanyFloor",
      this.editingFloor as Floor,
    );
    this.deleting = false;
    this.deleteDialog = !this.deleteDialog;
    this.$router.push({
      path: `/admin/room-setup/`,
    });
  }

  clearSearch() {
    this.search = "";
  }

  back() {
    this.$router.back();
  }
}
