






























import { Component, Ref, Prop, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { noSpace, requiredString } from "@/tool/validation";
import { Floor } from "@/api";

@Component
export default class FloorFormModal extends Vue {
  @Ref("form") form!: VForm;

  @Prop(String) title!: string;
  @Prop(String) action!: string;
  @Prop(Object) floor!: Floor;
  @Prop(Boolean) loader!: boolean;

  name = this.floor?.name ? this.floor.name : "";
  errorMessage = "";
  requiredTextInput = [requiredString, noSpace];

  close() {
    this.form.resetValidation();
    this.errorMessage = "";
    this.$emit("close");
  }

  isSame() {
    let same = false;
    if (this.name?.trim().length == 0) {
      this.errorMessage = "Required";
      same = true;
    }
    if (this.name?.trim() === this.floor?.name.trim()) {
      this.errorMessage = "Title is same as previous";
      same = true;
    }
    return same;
  }

  async save() {
    this.errorMessage = "";
    const isValid = this.form.validate();
    if (isValid && !this.isSame()) {
      try {
        const payload: Floor = {
          id: this.title === "Add" ? undefined : this.floor.id,
          name: this.name,
        };
        this.$emit("save", payload);
      } catch (e) {
        console.log(e);
      }
    }
  }
}
