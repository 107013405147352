/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScoreboardBreakdown } from '../models/ScoreboardBreakdown';
import type { ScoreboardEventBreakdown } from '../models/ScoreboardEventBreakdown';
import type { ScoreboardSummary } from '../models/ScoreboardSummary';
import type { ScoreboardSummaryList } from '../models/ScoreboardSummaryList';
import { request as __request } from '../core/request';

export class ScoreboardService {

    /**
     * @returns ScoreboardSummaryList All summary scoreboards for the company, broken down by department
     * @throws ApiError
     */
    public static async listScoreboards({
companyId,
beginAt,
endAt,
}: {
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<ScoreboardSummaryList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/scoreboards`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * @returns ScoreboardEventBreakdown breakdown of the logged events of the given EventMetric grouped by employee recipients for the whole company
     * @throws ApiError
     */
    public static async getEventCompanyBreakdown({
companyId,
eventMetricId,
groupByPoster,
beginAt,
endAt,
}: {
companyId: number,
eventMetricId: number,
groupByPoster?: boolean,
beginAt?: string,
endAt?: string,
}): Promise<ScoreboardEventBreakdown> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/scoreboards/events/${eventMetricId}/company-breakdown`,
            query: {
                'groupByPoster': groupByPoster,
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * @returns ScoreboardEventBreakdown breakdown of the logged events of the given EventMetric grouped by employee recipients for the whole company
     * @throws ApiError
     */
    public static async getEventDepartmentBreakdown({
companyId,
eventMetricId,
departmentId,
groupByPoster,
beginAt,
endAt,
}: {
companyId: number,
eventMetricId: number,
departmentId: number,
groupByPoster?: boolean,
beginAt?: string,
endAt?: string,
}): Promise<ScoreboardEventBreakdown> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/scoreboards/events/${eventMetricId}/department-breakdown/${departmentId}`,
            query: {
                'groupByPoster': groupByPoster,
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * @returns ScoreboardSummary All summary scoreboards for the company, broken down by department
     * @throws ApiError
     */
    public static async getDepartmentScoreboardSummary({
companyId,
departmentId,
beginAt,
endAt,
}: {
companyId: number,
departmentId: number,
beginAt?: string,
endAt?: string,
}): Promise<ScoreboardSummary> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/scoreboards/departments/${departmentId}`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * @returns ScoreboardBreakdown Detailed Scoreboard breakdown for a department
     * @throws ApiError
     */
    public static async getDepartmentScoreboardBreakdown({
companyId,
departmentId,
beginAt,
endAt,
}: {
companyId: number,
departmentId: number,
beginAt?: string,
endAt?: string,
}): Promise<ScoreboardBreakdown> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/scoreboards/departments/${departmentId}/breakdown`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * @returns ScoreboardBreakdown Employee event count breakdown
     * @throws ApiError
     */
    public static async getCompanyScoreboardBreakdown({
companyId,
beginAt,
departmentId,
endAt,
}: {
companyId: number,
beginAt: string,
departmentId?: number,
endAt?: string,
}): Promise<ScoreboardBreakdown> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/scoreboard/breakdown`,
            query: {
                'beginAt': beginAt,
                'departmentId': departmentId,
                'endAt': endAt,
            },
        });
        return result.body;
    }

}