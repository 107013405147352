
































































import { Component, Vue } from "vue-property-decorator";
import { checkSelfRole } from "@/common/role_utils";

@Component
export default class AdminLayout extends Vue {
  toShow = "";

  created() {
    if (!this.$store.getters["isSuperAdmin"]) {
      if (checkSelfRole("employee")) {
        this.$router.replace("/");
      }
    }
  }

  isInPath(check: string) {
    return this.$route.path.includes(check);
  }

  isEndsWith(check: string) {
    return this.$route.path.endsWith(check);
  }

  get departmentDetail() {
    if (this.$route.params.id) return this.$route.path.includes("department");
    return false;
  }
}
