/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Record = {
    properties: {
        completed: {
    type: 'boolean',
    isRequired: true,
},
        from: {
    type: 'string',
    isRequired: true,
},
        to: {
    type: 'string',
    isRequired: true,
},
    },
};