var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[_c('v-subheader',[_c('h1',{staticClass:"headline black--text text-decoration-underline"},[_vm._v(" Event Metrics ")])]),_c('v-list-item',{class:{
      active: _vm.activeMenu === 'all',
      'bg-gray': _vm.activeMenu === 'all',
    },on:{"click":function($event){return _vm.selectEventMenu('all')}}},[_c('v-list-item-content',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Custom Events")])])],1),_c('v-list-item',{class:{
      active: _vm.activeMenu === 'track',
      'bg-gray': _vm.activeMenu === 'track',
    },on:{"click":function($event){return _vm.selectEventMenu('track')}}},[_c('v-list-item-content',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Tracking Setup")])])],1),(!_vm.isSuperAdmin)?_c('v-list-item',{class:{
      active: _vm.activeMenu === 'log',
      'bg-gray': _vm.activeMenu === 'log',
    },on:{"click":function($event){return _vm.selectEventMenu('log')}}},[_c('v-list-item-content',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Log New")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }