/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RegisterAccountRequestBody = {
    properties: {
        registrationCode: {
    type: 'string',
    isRequired: true,
},
        email: {
    type: 'string',
},
        externalEmployeeId: {
    type: 'string',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        password: {
    type: 'string',
    isRequired: true,
},
    },
};