/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DepartmentDocumentListResponse } from '../models/DepartmentDocumentListResponse';
import type { DepartmentDocumentResponse } from '../models/DepartmentDocumentResponse';
import { request as __request } from '../core/request';

export class DepartmentDocumentService {

    /**
     * create links between document and department
     * @returns DepartmentDocumentResponse created department-document
     * @throws ApiError
     */
    public static async createDepartmentDocument({
companyId,
departmentId,
documentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** The documentId **/
documentId: number,
}): Promise<DepartmentDocumentResponse> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/department-document/document/${documentId}`,
        });
        return result.body;
    }

    /**
     * update links between document and department
     * @returns DepartmentDocumentResponse updated department-document
     * @throws ApiError
     */
    public static async updateDepartmentDocument({
companyId,
departmentId,
departmentDocumentId,
documentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** The departmentDocumentId **/
departmentDocumentId: number,
/** The documentId **/
documentId: number,
}): Promise<DepartmentDocumentResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}/department-document/${departmentDocumentId}/document/${documentId}`,
        });
        return result.body;
    }

    /**
     * get links between document and department
     * @returns DepartmentDocumentResponse get department-document
     * @throws ApiError
     */
    public static async getDepartmentDocumentById({
companyId,
departmentId,
departmentDocumentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** The departmentDocumentId **/
departmentDocumentId: number,
}): Promise<DepartmentDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/department-document/${departmentDocumentId}`,
        });
        return result.body;
    }

    /**
     * get links list between document and department by departmentId
     * @returns DepartmentDocumentListResponse get department-document lists
     * @throws ApiError
     */
    public static async getDepartmentDocumentByDepartmentId({
companyId,
departmentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
}): Promise<DepartmentDocumentListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/department-document`,
        });
        return result.body;
    }

}