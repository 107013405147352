/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DocumentResponse = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        created_at: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
    },
};