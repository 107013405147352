/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyExpense = {
    properties: {
        departmentId: {
    type: 'number',
},
        department: {
    type: 'string',
},
        categoryId: {
    type: 'number',
},
        category: {
    type: 'string',
},
        budgetId: {
    type: 'number',
},
        budget: {
    type: 'number',
    format: 'float',
},
        balance: {
    type: 'number',
    format: 'float',
},
        glCode: {
    type: 'string',
},
        ppd: {
    type: 'number',
    format: 'float',
},
    },
};