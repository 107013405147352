/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CreateEmployeeRequestBody = {
    properties: {
        firstName: {
    type: 'string',
    isRequired: true,
},
        lastName: {
    type: 'string',
    isRequired: true,
},
        hireDate: {
    type: 'string',
    format: 'date',
},
        birthday: {
    type: 'string',
    format: 'date',
},
        titleId: {
    type: 'number',
},
        companyRole: {
    type: 'Enum',
},
        primaryDepartmentId: {
    type: 'number',
    isRequired: true,
},
        primaryDepartmentRole: {
    type: 'Enum',
},
        externalEmployeeId: {
    type: 'string',
},
        email: {
    type: 'string',
},
        phone: {
    type: 'string',
},
        authUID: {
    type: 'string',
},
        subRole: {
    type: 'number',
},
        shift: {
    type: 'number',
},
    },
};