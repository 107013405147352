














































import { Component, Vue } from "vue-property-decorator";

@Component
export default class SupervisorLayout extends Vue {
  get isList() {
    return this.$route.path.includes("list");
  }
  get isNew() {
    return this.$route.path.includes("new") && this.$route.params?.id;
  }
  get isExcused() {
    return this.$route.params.status === "excused" && this.$route.params?.id;
  }
  get isUnexcused() {
    return this.$route.params.status === "unexcused" && this.$route.params?.id;
  }
  get isProcessed() {
    return this.$route.path.includes("processed") && this.$route.params?.id;
  }
  get isDenied() {
    return this.$route.path.includes("denied") && this.$route.params?.id;
  }
  get isResubmit() {
    return this.$route.path.includes("resubmit") && this.$route.params?.id;
  }
  get isForm() {
    return this.$route.path.includes("supervisor/form");
  }
}
