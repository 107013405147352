import { render, staticRenderFns } from "./RoomSetupLayout.vue?vue&type=template&id=32e36c65&scoped=true"
import script from "./RoomSetupLayout.vue?vue&type=script&lang=ts"
export * from "./RoomSetupLayout.vue?vue&type=script&lang=ts"
import style0 from "./RoomSetupLayout.vue?vue&type=style&index=0&id=32e36c65&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e36c65",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
