var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-fill fill-height py-6 px-4"},[(_vm.loading)?_c('Loading',{attrs:{"loading":_vm.loading,"size":70,"width":7}}):_c('div',{staticClass:"mb-16",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"display-1 font-weight-bold text-blue mb-2"},[_vm._v("Tracking")]),_c('div',{staticClass:"d-flex flex-fill flex-wrap my-4"},[_c('div',{staticClass:"title",class:{ dateInput: _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" Filters: ")]),_c('div',{staticClass:"w-175"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"px-3",attrs:{"value":_vm.formattedDates,"readonly":"","outlined":"","label":"Start Date","dense":""}},on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","max":_vm.minDate},on:{"change":function($event){_vm.datePicker = false;
              _vm.fetchTrackingRecords();}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1),_c('div',{staticClass:"w-175"},[_c('v-select',{attrs:{"items":_vm.guardianList,"item-text":"name","item-value":"id","label":"Guardian Angels","outlined":"","dense":""},model:{value:(_vm.guardianId),callback:function ($$v) {_vm.guardianId=$$v},expression:"guardianId"}})],1),_c('v-btn',{staticClass:"mx-2",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '65%' : '80px',"color":"#55abfa","rounded":""},on:{"click":function($event){_vm.defaultDates();
          _vm.fetchTrackingRecords(true);}}},[_c('span',{staticClass:"text-white caption font-weight-bold"},[_vm._v("Clear")])])],1),_c('v-simple-table',{staticClass:"scroll-x",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left sticky-1",staticStyle:{"min-width":"180px"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center sticky-2",staticStyle:{"min-width":"100px"}},[_vm._v(" Completion Rate (%) ")]),_vm._l((_vm.headings),function(title){return _c('th',{key:title,staticClass:"text-center",staticStyle:{"min-width":"80px"}},[_vm._v(" "+_vm._s(title)+" ")])})],2)]),_c('tbody',_vm._l((_vm.GuardianAngels),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"sticky-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-center sticky-2",class:{
                'text-green': Math.floor(item.completionRate) >= 90,
                'text-red': Math.floor(item.completionRate) < 90,
              }},[_vm._v(" "+_vm._s(Math.floor(item.completionRate))+"% ")]),_vm._l((item.record),function(rec){return _c('td',{key:rec.from,staticClass:"text-center"},[(rec.completed)?_c('v-icon',{attrs:{"color":"primary","size":"20px"}},[_vm._v(" mdi-circle-slice-8 ")]):_c('v-icon',{attrs:{"color":"primary","size":"20px"}},[_vm._v(" mdi-circle-outline ")])],1)})],2)}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }