/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SurveyRequest = {
    properties: {
        survey: {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        formId: {
    type: 'number',
    isRequired: true,
},
        roomId: {
    type: 'number',
    isRequired: true,
},
        resident: {
    type: 'string',
    isRequired: true,
},
        duration: {
    type: 'number',
},
        dischargeDate: {
    type: 'string',
},
        admissionDate: {
    type: 'string',
},
        phone: {
    type: 'string',
    isRequired: true,
},
        physicianId: {
    type: 'number',
},
        completedBy: {
    type: 'string',
    isRequired: true,
},
        Status: {
    type: 'string',
},
    },
    isRequired: true,
},
        physician: {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        employeeId: {
    type: 'number',
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
    isRequired: true,
},
        data: {
    type: 'array',
    contains: {
        type: 'SurveyData',
    },
    isRequired: true,
},
    },
};