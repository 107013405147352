























































import { EmployeeDocumentByDocumentIdResponse } from "@/api";
import VuePdfApp from "vue-pdf-app";
import { Component, Prop, Vue } from "vue-property-decorator";
import { fetchDocumentURL } from "@/common/document_url";
import loading from "@/components/loader/loading.vue";
import { checkSelf, checkSelfRole } from "@/common/role_utils";

@Component({
  components: {
    VuePdfApp,
    loading,
  },
})
export default class PreviewDocument extends Vue {
  @Prop() readonly document!: EmployeeDocumentByDocumentIdResponse;
  @Prop() readonly documentName!: string;

  dialog = false;
  config = {
    toolbar: false,
  };
  sourceFile = "";
  preview = true;
  loadingURL = false;

  async showFile(id: number, employeeId: number) {
    if (checkSelfRole("owner") || checkSelf(employeeId)) {
      this.dialog = !this.dialog;
      this.loadingURL = true;
      const res = await fetchDocumentURL(id);
      this.loadingURL = false;
      if (!res.length) return;
      this.sourceFile = res;
    } else {
      this.$store.dispatch(
        "alert/warning",
        "You are not authorized to preview or download the selected file.",
      );
    }
  }

  checkDoc(path: string) {
    return !path?.includes(".pdf");
  }

  checkPath(path: string) {
    return this.$route.params?.status === path;
  }

  previewFailed() {
    this.preview = false;
  }
}
