
























































































































































































































import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";
import loading from "@/components/loader/loading.vue";
import { addDayOnDate, dateFormat } from "@/common/date_format";
import moment from "moment";
import { payloadInitializer, payload } from "@/tool/scoreboardParameter";
import { checkSelfRole } from "@/common/role_utils";

@Component({
  components: {
    loading,
  },
})
export default class EmployeeBreakDown extends Vue {
  isDataLoading = false;
  eventName = this.$route.query.eventName;
  title = this.$route.query.title;
  startDate: string | null = null;
  endDate: string | null = null;
  formErrors = "";
  showStartDate = false;
  showEndDate = false;
  formattedStartDate = "";
  formattedEndDate = "";
  initialStartDate: string | null = null;
  initialEndDate: string | null = null;
  totalCount = 0;
  activeCount = 0;
  payload!: payload;

  async created() {
    this.payload = payloadInitializer(this.$route);

    if (this.stateStartDate) {
      this.startDate = this.stateStartDate;
      this.initialStartDate = this.stateStartDate;
    }
    if (this.stateEndDate) {
      this.endDate = this.stateEndDate;
      this.initialEndDate = this.stateEndDate;
    }

    this.payload.beginDate = this.startDate;
    this.payload.endDate = addDayOnDate(this.endDate || "");
    this.isDataLoading = true;

    await this.$store.dispatch(
      "scoreboard/loadEmployeeBreakdown",
      this.payload,
    );

    this.isDataLoading = false;
  }

  get stateStartDate() {
    return this.$store.getters["scoreboard/startDate"];
  }

  get stateEndDate() {
    return this.$store.getters["scoreboard/endDate"];
  }

  async archiveEvent(eventId: number, employeeId: number, toggle: boolean) {
    try {
      await this.$store.dispatch("scoreboard/toggleEvent", {
        eventId,
        requestBody: {
          employeeId,
          toggle,
        },
      });
      this.$store.dispatch("scoreboard/fetchCompanyScoreboards");
    } catch (error) {
      this.$store.dispatch("alert/warning", error);
    }
  }
  formatDate(date: string, isStartDate: boolean) {
    if (isStartDate) this.formattedStartDate = dateFormat(date);
    else this.formattedEndDate = dateFormat(date);
  }
  async selectedDate() {
    if (this.startDate && this.endDate) {
      const filteredDate = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.startDate < this.endDate) {
        this.formErrors = "";
        try {
          await this.$store.dispatch(
            "scoreboard/setStartEndDate",
            filteredDate,
          );
          if (
            this.startDate !== this.initialStartDate ||
            this.endDate !== this.initialEndDate
          ) {
            this.$store.dispatch("scoreboard/fetchCompanyScoreboards", {
              startDate: filteredDate.startDate,
              endDate: addDayOnDate(filteredDate.endDate),
            });
          }
          this.isDataLoading = true;
          if (this.$route.query.empId) {
            await this.$store.dispatch("scoreboard/loadEmployeeBreakdown", {
              departmentId: this.$route.query.deptId,
              eventMetricId: this.$route.params.id,
              employeeId: this.$route.query.empId,
              beginDate: filteredDate.startDate,
              endDate: addDayOnDate(filteredDate.endDate),
            });
          } else {
            // The statement for the department or company
            if (this.$route.query.deptId) {
              await this.$store.dispatch("scoreboard/loadEmployeeBreakdown", {
                departmentId: this.$route.query.deptId,
                eventMetricId: this.$route.params.id,
                beginDate: filteredDate.startDate,
                endDate: addDayOnDate(filteredDate.endDate),
              });
            } else {
              await this.$store.dispatch("scoreboard/loadEmployeeBreakdown", {
                eventMetricId: this.$route.params.id,
                beginDate: filteredDate.startDate,
                endDate: addDayOnDate(filteredDate.endDate),
              });
            }
          }
          this.isDataLoading = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.formErrors = "start date should not be greater than end date";
      }
    } else {
      this.formErrors = "require both dates";
    }
  }
  get list() {
    const list = this.$store.getters["scoreboard/employeeBreakdown"];
    let ret = [];
    if (list && list?.loggedEvents) {
      const filter = _.concat(list.loggedEvents);
      if (this.$route.query.isPoster === "true") {
        if (this.$route.query.empId) {
          if (this.$route.query.deptId) {
            // This is for the single employee of the department
            ret = filter.filter(log => {
              if (
                (log.eventMetricId.toString() === this.$route.params.id &&
                  log.postedByEmployeeId.toString() ===
                    this.$route.query.empId) ||
                Number(this.$route.query.empId) < 0
              ) {
                if (log.postedByEmployeeDepartmentId) {
                  if (
                    log.postedByEmployeeDepartmentId.toString() ===
                    this.$route.query.deptId
                  ) {
                    return log;
                  }
                }
              }
            });
          } else {
            // This is for the single employee breakdown of the company
            ret = filter.filter(log => {
              if (
                (log.eventMetricId.toString() === this.$route.params.id &&
                  log.postedByEmployeeId.toString() ===
                    this.$route.query.empId) ||
                Number(this.$route.query.empId) < 0
              ) {
                return log;
              }
            });
          }
        } else {
          if (this.$route.query.deptId) {
            // This is for the department employees
            ret = filter.filter(log => {
              if (
                log.eventMetricId.toString() === this.$route.params.id &&
                log.postedByEmployeeDepartmentId == this.$route.query.deptId
              ) {
                return log;
              }
            });
          } else {
            // This is for the company employees
            ret = filter.filter(log => {
              if (log.eventMetricId.toString() === this.$route.params.id) {
                return log;
              }
            });
          }
        }
      } else {
        if (this.$route.query.empId) {
          if (this.$route.query.deptId) {
            // This is for the single employee of the department
            this.totalCount = 0;
            this.activeCount = 0;
            filter.filter(log => {
              if (log.eventMetricId.toString() === this.$route.params.id) {
                const res = _.filter(log.employeeRecipients, employee => {
                  if (employee.departmentId) {
                    if (
                      employee.employeeId.toString() ===
                        this.$route.query.empId &&
                      employee.departmentId.toString() ===
                        this.$route.query.deptId
                    ) {
                      this.totalCount++;
                      if (employee.toggle) this.activeCount++;
                      return employee;
                    }
                  }
                });
                if (res) {
                  ret.push(
                    ...res.map(employee => {
                      return {
                        employee,
                        createdAt: log.createdAt,
                        note: log.note,
                        isPostedBy: log.postedByEmployeeName,
                        id: log.id,
                        giver: log.giver,
                        document: log.document,
                      };
                    }),
                  );
                }
              }
            });
          } else {
            // This is for the single employee breakdown of the company
            this.totalCount = 0;
            this.activeCount = 0;
            filter.filter(log => {
              if (log.eventMetricId.toString() === this.$route.params.id) {
                const res = _.filter(log.employeeRecipients, employee => {
                  if (
                    employee.employeeId.toString() === this.$route.query.empId
                  ) {
                    this.totalCount++;
                    if (employee.toggle) this.activeCount++;
                    return employee;
                  }
                });
                if (res) {
                  ret.push(
                    ...res.map(employee => {
                      return {
                        employee,
                        createdAt: log.createdAt,
                        note: log.note,
                        isPostedBy: log.postedByEmployeeName,
                        id: log.id,
                        giver: log.giver,
                        document: log.document,
                      };
                    }),
                  );
                }
              }
            });
          }
        } else {
          if (this.$route.query.deptId) {
            // This is for the department employees
            this.totalCount = 0;
            this.activeCount = 0;
            filter.filter(log => {
              if (log.eventMetricId.toString() === this.$route.params.id) {
                const res = _.filter(log.employeeRecipients, employee => {
                  if (
                    employee.departmentId &&
                    employee.departmentId.toString() ===
                      this.$route.query.deptId
                  ) {
                    this.totalCount++;
                    if (employee.toggle) this.activeCount++;
                    return employee;
                  }
                });
                if (res) {
                  ret.push(
                    ...res.map(employee => {
                      return {
                        employee,
                        createdAt: log.createdAt,
                        note: log.note,
                        isPostedBy: log.postedByEmployeeName,
                        id: log.id,
                        giver: log.giver,
                        document: log.document,
                      };
                    }),
                  );
                }
              }
            });
          } else {
            // This is for the company employees
            this.totalCount = 0;
            this.activeCount = 0;
            filter.filter(log => {
              if (log.eventMetricId.toString() === this.$route.params.id) {
                const res = _.filter(log.employeeRecipients, employee => {
                  this.totalCount++;
                  if (employee.toggle) this.activeCount++;
                  return employee;
                });
                if (res) {
                  ret.push(
                    ...res.map(employee => {
                      return {
                        employee,
                        createdAt: log.createdAt,
                        note: log.note,
                        isPostedBy: log.postedByEmployeeName,
                        id: log.id,
                        document: log.document,
                        giver: log.giver,
                      };
                    }),
                  );
                }
              }
            });
          }
        }
      }
    }
    return _.orderBy(ret, res => res.createdAt, "desc").map(list => {
      const ISOdate = moment(list.createdAt).format("ll, LT");
      return {
        ...list,
        createdAt: ISOdate,
      };
    });
  }

  back() {
    this.$router.back();
  }

  get isEmployee() {
    return checkSelfRole("employee");
  }

  destroyed() {
    if (!this.$route.path.includes("scoreboard"))
      this.$store.dispatch("scoreboard/setStartEndDate", {
        startDate: null,
        endDate: null,
      });
  }
}
