

















































































import { Vue, Component, Prop } from "vue-property-decorator";
import _, { isNumber } from "lodash";
import { TrackList } from "@/store/types";

@Component
export default class EditTrackList extends Vue {
  @Prop() readonly deptId!: string;
  @Prop() readonly isComapny!: boolean;
  @Prop() readonly EventProp!: [];
  @Prop() readonly DepartmentEventProps!: number[];

  eventList: TrackList[] = [];
  eventDepartmentList: (number | null)[] = [];
  updating = false;

  async created() {
    await this.$store.dispatch(
      "EventMetricAdmin/loadEventTrackedAtDepartment",
      this.deptId,
    );
    this.eventList = [...this.EventProp];
    this.eventDepartmentList = [...this.DepartmentEventProps];
    this.setEventList(this.DepartmentEventProps);
  }

  setEventList(departmentEvents: (number | null)[]) {
    this.eventList = _.map(
      _.sortBy(this.eventList, events => events.name.toUpperCase()),
      event => {
        if (departmentEvents.includes(event.id)) {
          return {
            ...event,
            value: event.id,
          };
        } else {
          return {
            ...event,
            value: null,
          };
        }
      },
    );
    this.eventDepartmentList = _.filter(
      _.map(this.eventList, eve => eve.value),
      eve => isNumber(eve),
    );
  }

  close() {
    this.setEventList(this.eventDepartmentList);
    this.$emit("done");
  }

  async done() {
    this.updating = true;
    const filteredTracked = _.map(this.eventList, event => event.value);
    const allTracked = _.filter(filteredTracked, eve => isNumber(eve));
    const addDiff = _.difference(allTracked, this.eventDepartmentList);
    const remDiff = _.difference(this.eventDepartmentList, allTracked);
    const filteredList = _.difference(
      [...this.eventDepartmentList, ...addDiff],
      remDiff,
    );
    for (let i = 0; i < addDiff.length; i++) {
      if (this.isComapny) {
        await this.$store
          .dispatch("EventMetricAdmin/addNewTrackedEventAtCompany", {
            eventMetricId: addDiff[i],
          })
          .catch(error => {
            console.log(error);
            this.updating = false;
          });
      } else {
        await this.$store
          .dispatch("EventMetricAdmin/addNewTrackedDepartmentEvent", {
            eventMetricId: addDiff[i],
            departmentId: this.deptId,
          })
          .catch(error => {
            console.log(error);
            this.updating = false;
          });
      }
    }
    for (let i = 0; i < remDiff.length; i++) {
      if (this.isComapny) {
        await this.$store
          .dispatch("EventMetricAdmin/stopTrackedEventAtCompany", {
            eventMetricId: remDiff[i],
          })
          .catch(error => {
            console.log(error);
            this.updating = false;
          });
      } else {
        await this.$store
          .dispatch("EventMetricAdmin/stopTrackedDepartmentEvent", {
            eventMetricId: remDiff[i],
            departmentId: this.deptId,
          })
          .catch(error => {
            console.log(error);
            this.updating = false;
          });
      }
    }
    this.updating = false;
    this.setEventList(filteredList);
    this.$emit("done");
  }
}
