




















import { checkSelfRole } from "@/common/role_utils";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class AddBudgetConfirmation extends Vue {
  isOwner = checkSelfRole("owner");

  goTo() {
    this.$emit("close");
    this.$router.push({ path: "/admin/company-budget" });
  }
}
