













































































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import Loading from "@/components/loader/loading.vue";
import { Rooms, SurveySummary } from "@/api";
import _ from "lodash";

@Component({
  components: {
    Loading,
  },
})
export default class Sumamry extends Vue {
  datePicker = false;
  range: string[] = [];
  loading = false;
  floorId = 0;
  physicianId = 0;
  roomId = 0;
  expanded = [];
  isAdmission = true;
  headers = [
    {
      text: "Room No.",
      align: "start",
      sortable: false,
      value: "room.name",
    },
    { text: "Resident", value: "resident" },
    { text: "Physician", value: "physician.name" },
    { text: "Date and Time Completed", value: "created_at" },
    { text: "", value: "data-table-expand" },
  ];

  async created() {
    this.loading = true;
    this.defaultDates();
    const floors = this.$store.dispatch("floor/fetchCompanyFloors");
    const summary = this.fetchSummary();
    const companyRooms = this.$store.dispatch("room/fetchCompanyRooms");
    const companyPhysician = this.$store.dispatch("physician/fetchPhysicians");

    await Promise.all([floors, summary, companyRooms, companyPhysician]);
    this.loading = false;
  }

  defaultDates() {
    this.range = [
      moment()
        .startOf("week")
        .format("YYYY-MM-DD"),
      moment()
        .endOf("week")
        .format("YYYY-MM-DD"),
    ];
    this.floorId = 0;
    this.physicianId = 0;
    this.roomId = 0;
  }

  get summary() {
    const stateSummary = this.isAdmission
      ? this.$store.getters["survey/admissionSummary"]
      : this.$store.getters["survey/dischargeSummary"];
    const list =
      (JSON.parse(JSON.stringify(stateSummary)) as SurveySummary[]) || [];
    const res: SurveySummary[] = [];
    _.forEach(list, item => {
      const idatas = _.sortBy(item.data || [], ["order"], ["asc"]);
      const data = item;
      data.data = idatas;
      res.push(data);
    });

    return res;
  }

  get floors() {
    return this.$store.getters["floor/floors"];
  }

  get physicians() {
    return this.$store.getters["physician/physicians"] || [];
  }

  get companyRooms() {
    const list =
      (JSON.parse(
        JSON.stringify(this.$store.getters["room/companyRooms"]),
      ) as Rooms) || [];
    if (!this.floorId) return list;
    return _.filter(list, item => item.floorId == this.floorId);
  }

  get formattedDates() {
    const [date1, date2] = this.range;
    let beginDate = "";
    let endDate = "";
    if (date1 < date2) {
      beginDate = date1;
      endDate = date2;
    } else {
      beginDate = date2;
      endDate = date1;
    }
    const formattedDate = this.range
      ? `${moment(beginDate).format("MMM DD")}  -  ${moment(endDate).format(
          "MMM DD",
        )}`
      : "";
    return formattedDate;
  }

  dateFilterChanged(type: string) {
    switch (type) {
      case "dates":
        this.floorId = 0;
        this.roomId = 0;
        this.physicianId = 0;
        break;
      case "floor":
        this.roomId = 0;
        this.physicianId = 0;
        break;
      case "room":
        this.physicianId = 0;
        break;
      default:
        this.defaultDates();
        break;
    }
    this.fetchSummary();
  }

  async fetchSummary() {
    await this.$store.dispatch("survey/fetchSummary", {
      beginAt: this.range[0],
      endAt: this.range[1],
      physicianId: this.physicianId ? this.physicianId : undefined,
      floorId: this.floorId ? this.floorId : undefined,
      roomId: this.roomId ? this.roomId : undefined,
      dispatchAdmission: this.isAdmission,
    });
  }

  formatTableDate(date: string) {
    return moment(date)
      .local()
      .format("MMM D, YYYY | hh:mma");
  }
}
