const statusId = {
  new: 0,
  excused: 1,
  unexcused: 2,
  deny: 4,
  resubmit: 5,
  processed: 1,
};

export default statusId;
