










import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Pagination extends Vue {
  @Prop() readonly page: number = 1;
  @Watch("page", { immediate: true, deep: true })
  onPropChange(newVal: number) {
    this.currentPage = newVal;
  }

  @Prop() length!: number;

  currentPage = this.page;
  @Watch("currentPage", { immediate: true, deep: true })
  onPageChange(newVal: number) {
    this.$emit("onPageChange", newVal);
  }
}
