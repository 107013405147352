/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LoggedEvent = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        departmentId: {
    type: 'number',
    isRequired: true,
},
        eventMetricId: {
    type: 'number',
    isRequired: true,
},
        eventMetricName: {
    type: 'string',
    isRequired: true,
},
        employeeRecipients: {
    type: 'array',
    contains: {
        type: 'LoggedEventRecipient',
    },
    isRequired: true,
},
        postedByEmployeeId: {
    type: 'number',
    isRequired: true,
},
        postedByEmployeeName: {
    type: 'string',
    isRequired: true,
},
        postedByEmployeeDepartmentId: {
    type: 'number',
    isRequired: true,
},
        note: {
    type: 'string',
},
        document: {
    type: 'string',
},
        giver: {
    type: 'string',
},
        createdAt: {
    type: 'string',
    isRequired: true,
},
        approved: {
    type: 'string',
},
    },
};