






























































import { Department, DepartmentPhoneNumber } from "@/api";
import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DepartmentList extends Vue {
  search = "";
  created() {
    this.$store.dispatch("company/loadDepartments");
  }
  get departments(): Department[] {
    const search = !this.search ? "" : this.search;
    const departments = this.$store.getters["departmentAdmin/departments"];
    const list = _.sortBy(
      _.filter(departments, department =>
        _.includes(
          `${department.name.toLowerCase()} ${department.id}`,
          search.toLowerCase(),
        ),
      ),
      department => department.name.trim().toUpperCase(),
    );
    const extPhone = (phonenumbers: DepartmentPhoneNumber[]) => {
      if (phonenumbers) {
        const ext = phonenumbers.filter(extension => {
          if (extension.type === 2) {
            return extension;
          }
        });
        const phone = phonenumbers.filter(extension => {
          if (extension.type === 1) {
            return extension;
          }
        });
        if (phone && phone.length !== 0) {
          return [...phone, ...ext];
        } else if (ext && ext.length !== 0) {
          return [...ext];
        } else {
          return [];
        }
      } else return [];
    };
    return list.map(item => {
      return {
        ...item,
        phonenumbers: extPhone(item.phonenumbers),
      };
    });
  }

  get departmentId() {
    return parseInt(this.$route.params.deptId);
  }

  clearSearch() {
    this.search = "";
  }
  selectDepartment(id: number) {
    this.$router.push({ path: `/departments/${id}/title` });
  }

  formatPhone(phone: string) {
    const format = phone.split("");
    const formattedPhone = [];
    let count = 0;
    for (let i = 0; i <= format.length; i++) {
      if (count % 3 === 0 && i < 9 && count !== 0) {
        formattedPhone.push("-");
        count = 0;
      }
      formattedPhone.push(format[i]);
      count++;
    }
    return formattedPhone.join("");
  }
}
