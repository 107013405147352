



























import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Croppper from "cropperjs";
import { Employee } from "@/api";
import { checkSelf } from "@/common/role_utils";

@Component
export default class ImageCropper extends Vue {
  @Prop(String) readonly file!: string;
  @Prop(String) readonly fileName!: string;
  @Prop(Object) readonly employee!: Employee;
  @Ref("cropperImage") readonly cropImage!: HTMLImageElement;

  cropper!: Croppper;
  uploading = false;

  mounted() {
    if (this.file !== "" && this.file) {
      this.cropper = new Croppper(this.cropImage, {
        aspectRatio: 1,
        minCropBoxHeight: 256,
        minCropBoxWidth: 256,
        viewMode: 3,
        dragMode: "move",
        background: false,
      });
    }
  }

  get isSelf() {
    return checkSelf(this.employee.id);
  }

  updateProfile() {
    this.cropper.getCroppedCanvas().toBlob(async blob => {
      let formData = null;
      if (blob) {
        formData = new FormData();
        formData.append("profileImage", blob, this.fileName);
      }
      if (formData) {
        this.uploading = true;
        const res = await this.$store.dispatch("directory/uploadProfileImage", {
          employeeId: this.employee.id,
          requestBody: formData,
        });
        this.uploading = false;
        this.$emit("close");
        if (res?.accepted) {
          if (this.isSelf) this.$store.dispatch("updateSelfProfile");
        } else {
          console.log(`Could not update`);
        }
      }
    }, "image/jpeg");
    if (this.uploading) this.$emit("close");
    this.cropper.destroy();
  }

  close() {
    this.$emit("close");
  }
}
