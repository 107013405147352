

























import { Vue, Component } from "vue-property-decorator";
import LogNewEvent from "@/views/admin/event_metrics/log_event/LogNewEvent.vue";
import ListLogEvents from "@/views/admin/event_metrics/log_event/ListLogEvents.vue";
import loading from "@/components/loader/loading.vue";
import _ from "lodash";
import { EventMetric } from "@/api";
@Component({
  watch: {
    $route() {
      this.$store.dispatch(
        "departmentAdmin/selectDepartment",
        this.$route.params.id,
      );
      this.$store.dispatch(
        "EventMetricAdmin/loadEventTrackedAtDepartment",
        this.$route.params.id,
      );
    },
  },
  components: {
    LogNewEvent,
    ListLogEvents,
    loading,
  },
})
export default class LogEvents extends Vue {
  created() {
    this.$store.dispatch(
      "departmentAdmin/selectDepartment",
      this.$route.params.id,
    );
    this.$store.dispatch(
      "EventMetricAdmin/loadEventTrackedAtDepartment",
      this.$route.params.id,
    );
  }

  get department() {
    return this.$store.getters["departmentAdmin/selectedDepartment"];
  }

  get eventMetrics() {
    const list = this.$store.getters["EventMetricAdmin/departmentEventList"];
    if (list) {
      return _.uniqBy(list, (item: EventMetric) => item.name);
    } else {
      return [];
    }
  }
}
