/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDepartmentCategoryRequestBody } from '../models/AddDepartmentCategoryRequestBody';
import type { AddDepartmentTitleRequestBody } from '../models/AddDepartmentTitleRequestBody';
import type { Budget } from '../models/Budget';
import type { DeleteDepartmentCategoryRequestBody } from '../models/DeleteDepartmentCategoryRequestBody';
import type { DepartmentCategory } from '../models/DepartmentCategory';
import type { DepartmentTitle } from '../models/DepartmentTitle';
import type { GetDepartmentCategoriesRequestBody } from '../models/GetDepartmentCategoriesRequestBody';
import type { GetDepartmentTitlesRequestBody } from '../models/GetDepartmentTitlesRequestBody';
import type { SortDepartmentTitlesRequestBody } from '../models/SortDepartmentTitlesRequestBody';
import { request as __request } from '../core/request';

export class DepartmentService {

    /**
     * Get a list of titles for a department
     * @returns GetDepartmentTitlesRequestBody The list of titles in the given department
     * @throws ApiError
     */
    public static async getDepartmentTitles({
companyId,
departmentId,
}: {
companyId: number,
departmentId: number,
}): Promise<GetDepartmentTitlesRequestBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/titles`,
        });
        return result.body;
    }

    /**
     * Create a title for a department
     * @returns DepartmentTitle The department that has been created
     * @throws ApiError
     */
    public static async addDepartmentTitle({
companyId,
departmentId,
requestBody,
}: {
companyId: number,
departmentId: number,
requestBody: AddDepartmentTitleRequestBody,
}): Promise<DepartmentTitle> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/titles`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Sort the title rank in a department
     * @returns any The departments have been sorted successfully
     * @throws ApiError
     */
    public static async sortDepartmentTitles({
companyId,
departmentId,
requestBody,
}: {
companyId: number,
departmentId: number,
requestBody: SortDepartmentTitlesRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/titles/sort`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Update a title for a department
     * @returns any The department has been updated successfully
     * @throws ApiError
     */
    public static async updateDepartmentTitle({
companyId,
departmentId,
titleId,
requestBody,
}: {
companyId: number,
departmentId: number,
titleId: number,
requestBody: AddDepartmentTitleRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}/titles/${titleId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete a department title
     * @returns any The department has been updated successfully
     * @throws ApiError
     */
    public static async deleteDepartmentTitle({
companyId,
departmentId,
titleId,
requestBody,
}: {
companyId: number,
departmentId: number,
titleId: number,
requestBody: AddDepartmentTitleRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/${departmentId}/titles/${titleId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a list of categories of a department
     * @returns GetDepartmentCategoriesRequestBody The list of categories in the given department
     * @throws ApiError
     */
    public static async getDepartmentCategories({
companyId,
departmentId,
}: {
companyId: number,
departmentId: number,
}): Promise<GetDepartmentCategoriesRequestBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/category`,
        });
        return result.body;
    }

    /**
     * Create a category for a department
     * @returns DepartmentCategory Department Category is created
     * @throws ApiError
     */
    public static async addDepartmentCategory({
companyId,
departmentId,
requestBody,
}: {
companyId: number,
departmentId: number,
requestBody: AddDepartmentCategoryRequestBody,
}): Promise<DepartmentCategory> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/category`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Update category name
     * @returns DepartmentCategory Department category is updated
     * @throws ApiError
     */
    public static async updateDepartmentCategory({
companyId,
departmentId,
requestBody,
}: {
companyId: number,
departmentId: number,
requestBody: DepartmentCategory,
}): Promise<DepartmentCategory> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}/category`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete category of a department
     * @returns any The category is deleted successfully
     * @throws ApiError
     */
    public static async deleteDepartmentCategory({
companyId,
departmentId,
requestBody,
}: {
companyId: number,
departmentId: number,
requestBody: DeleteDepartmentCategoryRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/${departmentId}/category`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a budget of a department category
     * @returns Budget The budget of the given category
     * @throws ApiError
     */
    public static async getDepartmentCategoryBudget({
companyId,
departmentId,
categoryId,
beginAt,
endAt,
}: {
companyId: number,
departmentId: number,
categoryId?: number,
beginAt?: string,
endAt?: string,
}): Promise<Budget> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/category/get-budget`,
            query: {
                'categoryId': categoryId,
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Add a budget of a department category
     * @returns Budget The budget of the given category
     * @throws ApiError
     */
    public static async addDepartmentCategoryBudget({
companyId,
departmentId,
categoryId,
requestBody,
}: {
companyId: number,
departmentId: number,
categoryId: number,
requestBody: Budget,
}): Promise<Budget> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/category/${categoryId}/budget`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Update a budget of a department category
     * @returns Budget The budget of the given category
     * @throws ApiError
     */
    public static async updateDepartmentCategoryBudget({
companyId,
departmentId,
categoryId,
requestBody,
}: {
companyId: number,
departmentId: number,
categoryId: number,
requestBody: Budget,
}): Promise<Budget> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}/category/${categoryId}/budget`,
            body: requestBody,
        });
        return result.body;
    }

}