import { GetterTree, ActionTree, MutationTree, Module } from "vuex";
import { RootState } from "@/store/types";
import {
  EmployeeDocumentResponse,
  EmployeeDocumentService,
  EmployeeDocumentNotificationService,
  getEmployeeDocumentByAprovalRequest,
  ActiveAndArchiveListOfEmployeeDocumentResponse,
  OwnerViewDocumentResponse,
  OpenAPI,
  ExpirationNotification,
  NotifyOnEmployeeDocumentResponse,
  approvDenyEmployeeDocumentRequest,
  CountCompanyDocumentResponse,
  CountDocumentService,
  CountEmployeeDocumentApprovalRequest,
} from "@/api";
import resolve from "@/common/resolver";

export type EmployeeDocumentState = {
  documentList: ActiveAndArchiveListOfEmployeeDocumentResponse | null;
  approvalDocumentList: [] | null;
  selectedDocument: EmployeeDocumentResponse | null;
  companyDocumentCount: CountCompanyDocumentResponse[] | null;
  employeeDocumentCount: CountEmployeeDocumentApprovalRequest[] | null;
  documentNotification: ExpirationNotification | null;
  documentSelfNotification: ExpirationNotification | null;
  documentNotificationByDepartmentDocument: NotifyOnEmployeeDocumentResponse | null;
  ownerDocumentList: OwnerViewDocumentResponse | null;
};

const state: EmployeeDocumentState = {
  documentList: null,
  approvalDocumentList: null,
  selectedDocument: null,
  companyDocumentCount: null,
  employeeDocumentCount: null,
  documentNotification: null,
  documentSelfNotification: null,
  documentNotificationByDepartmentDocument: null,
  ownerDocumentList: null,
};

const getters: GetterTree<EmployeeDocumentState, RootState> = {
  documentList: state => state.documentList,
  approvalDocumentList: state => state.approvalDocumentList,
  selectedDocument: state => state.selectedDocument,
  companyDocumentCount: state => state.companyDocumentCount,
  employeeDocumentCount: state => state.employeeDocumentCount,
  documentNotification: state => state.documentNotification,
  documentNotificationByDepartmentDocument: state =>
    state.documentNotificationByDepartmentDocument,
  ownerDocumentList: state => state.ownerDocumentList,
  documentSelfNotification: state => state.documentSelfNotification,
};

const mutations: MutationTree<EmployeeDocumentState> = {
  SET_DOCUMENT_LIST: (state, documentList) => {
    state.documentList = documentList;
  },
  ADD_DOCUMENT_LIST: (state, document) => {
    // if (!state.documentList) state.documentList = [document];
    // else state.documentList.push(document);
    console.log(`Add one on ${state.documentList} with ${document}`);
  },
  SET_APPROVAL_DOCUMENT_LIST: (state, documentList) => {
    state.approvalDocumentList = documentList;
  },
  SET_SELECTED_DOCUMENT: (state, document) => {
    state.selectedDocument = document;
  },
  SET_COMPANY_DOCUMENT_COUNT: (state, count) => {
    state.companyDocumentCount = count;
  },
  SET_DOCUMENT_NOTIFICATION: (state, documentNotification) => {
    state.documentNotification = documentNotification;
  },
  SET_OWNER_DOCUMENT_LIST: (state, ownerDocumentList) => {
    state.ownerDocumentList = ownerDocumentList;
  },
  SET_EMPLOYEE_DOCUMENT_COUNT: (state, count) => {
    state.employeeDocumentCount = count;
  },
  SET_DOCUMENT_NOTIFICATION_BY_DEPARTMENT_DOCUMENT: (state, count) => {
    state.documentNotificationByDepartmentDocument = count;
  },
  SET_DOCUMENT_SELF_NOTIFICATION: (state, notifications) => {
    state.documentSelfNotification = notifications;
  },
};

const actions: ActionTree<EmployeeDocumentState, RootState> = {
  getDocumentList: async (
    { commit, rootState },
    {
      departmentDocumentId,
    }: {
      departmentDocumentId: number;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    const employeeId = rootState.appContext.self?.id;
    if (companyId && employeeId) {
      const documentList = await EmployeeDocumentService.getActiveArchiveEmployeeDocumentList(
        {
          companyId,
          employeeId,
          departmentDocumentId,
        },
      );
      commit("SET_DOCUMENT_LIST", documentList);
    }
  },
  getOwnerDocumentList: async (
    { commit, rootState },
    payload: {
      documentId: number;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    const role = rootState.appContext.self?.roles[0];
    if (companyId) {
      const ownerDocumentList = await EmployeeDocumentService.getEmployeeDocumentByDocumentId(
        {
          companyId,
          documentId: payload.documentId,
          role,
        },
      );
      commit("SET_OWNER_DOCUMENT_LIST", ownerDocumentList);
    }
  },

  getApprovalDocumentList: async (
    { commit, rootState },
    requestBody: getEmployeeDocumentByAprovalRequest,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const res = await EmployeeDocumentService.getEmployeeDocumentByApproval(
          {
            companyId,
            requestBody,
          },
        );
        commit("SET_APPROVAL_DOCUMENT_LIST", res);
      } catch (error) {
        console.log(error);
      }
    }
  },

  getDocumentNotification: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    const employeeId = rootState.appContext.self?.id;
    const departmentId = rootState.appContext.self?.primaryDepartmentId;
    const role = rootState.appContext.self?.roles[0];
    const isEmployee = rootState.appContext.self?.roles.includes("employee");
    if (companyId && employeeId && departmentId) {
      const selfNotification = await EmployeeDocumentNotificationService.getExpirationNotificationOnDashboard(
        {
          companyId,
          employeeId,
        },
      );
      commit("SET_DOCUMENT_SELF_NOTIFICATION", selfNotification);
      if (!isEmployee) {
        const notification = await EmployeeDocumentNotificationService.expirationNotificationForAdminAndOwnerOnDashboard(
          {
            companyId,
            departmentId,
            role,
          },
        );
        commit("SET_DOCUMENT_NOTIFICATION", notification);
      }
    }
  },

  uploadDocument: async (
    { commit, rootState },
    payload: {
      employeeId: number;
      departmentDocumentId: number;
      expirationDate?: string;
      license?: string;
      // eslint-disable-next-line
      requestBody?: FormData;
      role: string;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/employees/${payload.employeeId}/employee-document/department-document/${payload.departmentDocumentId}?expiration_date=${payload.expirationDate}&license=${payload.license}&role=${payload.role}`,
          {
            method: "POST",
            body: payload.requestBody,
            headers: {
              authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
            },
          },
        );
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error, { root: true });
        }
      }
    }
  },

  async getSelectedDocument(
    { commit, rootState },
    payload: {
      employeeId: number;
      employeeDocumentId: number;
      expirationDate?: string;
      license?: string;
    },
  ) {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const res = await EmployeeDocumentService.getEmployeeDocumentById({
          companyId,
          employeeId: payload.employeeId,
          employeeDocumentId: payload.employeeDocumentId,
          expirationDate: payload.expirationDate,
          license: payload.license,
        });
        commit("SET_SELECTED_DOCUMENT", res);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error, { root: true });
        }
      }
    }
  },
  reviewDocument: async (
    { rootState },
    payload: {
      employeeId: number;
      employeeDocumentId: number;
      requestBody: approvDenyEmployeeDocumentRequest;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) return;
    await EmployeeDocumentService.approvDenyEmployeeDocument({
      companyId,
      employeeId: payload.employeeId,
      employeeDocumentId: payload.employeeDocumentId,
      requestBody: payload.requestBody,
    });
  },
  getCompanyDocumentCount: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    const departmentId = rootState.appContext.self?.primaryDepartmentId;
    const role = rootState.appContext.self?.roles[0];
    if (companyId && departmentId && role) {
      const res = await CountDocumentService.getCountCompanyDocument({
        companyId,
        departmentId,
        role,
      });
      commit("SET_COMPANY_DOCUMENT_COUNT", res);
    }
  },
  getSelfDocumentCount: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    const departmentId = rootState.appContext.self?.primaryDepartmentId;
    if (companyId && departmentId) {
      const res = await CountDocumentService.getCountOwnDocument({
        companyId,
        departmentId,
      });
      // same document count as the company document panel has
      commit("SET_COMPANY_DOCUMENT_COUNT", res);
    }
  },
  getEmployeeDocumentCount: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      const res = await CountDocumentService.countEmployeeDocumentByApproval({
        companyId,
      });
      commit("SET_EMPLOYEE_DOCUMENT_COUNT", res);
    }
  },

  getDocumentNotificationByDepartmentDocument: async (
    { commit, rootState },
    isSelf: boolean,
  ) => {
    let res!: NotifyOnEmployeeDocumentResponse;
    const companyId = rootState.appContext.company?.id;
    const departmentId = rootState.appContext.self?.primaryDepartmentId;
    const role = rootState.appContext.self?.roles[0];
    if (companyId && departmentId) {
      if (isSelf) {
        res = await EmployeeDocumentNotificationService.getEmployeeDocumentExpirationNotificationByDepartmentDocument(
          {
            companyId,
            departmentId,
          },
        );
      } else {
        res = await EmployeeDocumentNotificationService.expirationNotificationForAdminAndOwner(
          {
            companyId,
            departmentId,
            role,
          },
        );
      }
      commit("SET_DOCUMENT_NOTIFICATION_BY_DEPARTMENT_DOCUMENT", res);
    }
  },
  fetchDocumentUrl: async ({ rootState }, employeeDocumentId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) return undefined;
    try {
      const res = await EmployeeDocumentService.getEmployeeDocumentUrl({
        companyId,
        employeeDocumentId,
      });
      return res;
    } catch (error) {
      throw new Error("Failed to load");
    }
  },
};

export const employeeDocuments: Module<EmployeeDocumentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
