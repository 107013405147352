import io from "socket.io-client";
import store from "@/store";
import { checkSelf, checkSelfRole } from "@/common/role_utils";
import { Employee } from "@/api";
import moment from "moment";

const serverUrl = process.env.VUE_APP_BASE_API_URL?.replace("/v1", "");

type MessageReq = {
  id: number;
  companyId: number;
  from: number;
  to?: number;
  group?: number;
  message: string;
  media?: string;
};

type NotificationType = {
  to: number;
  message: string;
  type: string;
  submitter: Employee;
};

let socket: SocketIOClient.Socket;

const connect = (id: number, username: string, companyId: number) => {
  if (!serverUrl) return false;
  socket = io(serverUrl, {
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    socket.emit("/auth", {
      author: id,
      message: "Hello, Server",
      username,
      companyId,
    });
  });
  socket.on("connect_error", (err: Error) => {
    console.log("Failed to connect: ", err);
  });
  return true;
};

const updateType = (
  type: string,
  routeTarfStatus: number,
  documentId: number,
  status: number,
  submitter: Employee,
) => {
  switch (type.toLowerCase()) {
    case "tarf":
      store.dispatch("tarf/fetchTarfStatuses", {
        force: false,
        toFetch: routeTarfStatus,
      });
      break;

    case "document":
      if (checkSelfRole("owner")) {
        store.dispatch("employeeDocuments/getEmployeeDocumentCount");
        if (status >= 0) {
          store.dispatch("employeeDocuments/getApprovalDocumentList", {
            approval: status,
          });
        }
      }
      if (checkSelfRole("owner") || checkSelfRole("admin")) {
        store.dispatch("employeeDocuments/getCompanyDocumentCount");
        if (documentId >= 0) {
          store.dispatch("employeeDocuments/getOwnerDocumentList", {
            documentId: documentId,
          });
        }
      }
      if (checkSelfRole("employee") || checkSelf(submitter?.id)) {
        store.dispatch("employeeDocuments/getSelfDocumentCount");
        if (documentId >= 0) {
          store.dispatch("employeeDocuments/getDocumentList", {
            departmentDocumentId: documentId,
          });
        }
      }

      break;

    case "event":
      store.dispatch("EventMetricAdmin/fetchEventCount", {
        eventId: -1,
        date: moment()
          .local()
          .format("YYYY-MM-DD"),
      });
      break;

    case "unread message":
      store.dispatch("chat/fetchUnreadMessageCount");
      store.dispatch("chat/fetchChatList");
      store.dispatch("chat/fetchGroupList");
      break;

    default:
      break;
  }
};

export { connect, socket, updateType, MessageReq, NotificationType };
