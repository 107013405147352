/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TarfDetail = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        createdAt: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
        reviewedAt: {
    type: 'string',
    format: 'date-time',
},
        reviewedBy: {
    type: 'ListedEmployee',
},
        requestedBy: {
    type: 'ListedEmployee',
},
        status: {
    type: 'number',
    isRequired: true,
},
        processed: {
    type: 'number',
},
        reason: {
    type: 'TarfReason',
    isRequired: true,
},
        reasons: {
    type: 'array',
    contains: {
        type: 'TarfReason',
    },
    isRequired: true,
},
        adjustmentDateTime: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
        timeIn: {
    type: 'string',
    isRequired: true,
    format: 'time',
},
        timeOut: {
    type: 'string',
    isRequired: true,
    format: 'time',
},
        shiftType: {
    type: 'number',
    isRequired: true,
},
        requesterComment: {
    type: 'string',
    isRequired: true,
},
        supervisorComment: {
    type: 'string',
},
        deniedComment: {
    type: 'string',
},
        hoursWorked: {
    type: 'string',
    isRequired: true,
    format: 'time',
},
        mealTimeIn: {
    type: 'string',
    format: 'time',
},
        mealTimeOut: {
    type: 'string',
    format: 'time',
},
        editedBy: {
    type: 'ListedEmployee',
},
        editedFields: {
    type: 'string',
},
        editedOn: {
    type: 'string',
    format: 'time',
},
    },
};