import { RouteConfig } from "vue-router";
import EventFeed from "@/views/activity/EventFeed.vue";
import EventDetail from "@/views/activity/EventDetail.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    components: {
      default: EventFeed,
      eventFeed: EventFeed,
      eventDetail: EventDetail,
    },
    meta: {
      title: "Activity Feed",
      requiresAuth: true,
    },
  },
  {
    path: ":eventId",
    components: {
      default: EventDetail,
      eventFeed: EventFeed,
      eventDetail: EventDetail,
    },
    meta: {
      title: "Activity Detail",
      requiresAuth: true,
    },
  },
];

export default routes;
