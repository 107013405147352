/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyExpenses } from '../models/CompanyExpenses';
import type { Expense } from '../models/Expense';
import type { ExpenseList } from '../models/ExpenseList';
import { request as __request } from '../core/request';

export class ExpenseService {

    /**
     * Get a list of expenses of a category
     * @returns ExpenseList The list of expense of the given category
     * @throws ApiError
     */
    public static async getCategoryExpense({
companyId,
departmentId,
categoryId,
beginAt,
endAt,
}: {
companyId: any,
departmentId: any,
categoryId?: number,
beginAt: string,
endAt: string,
}): Promise<ExpenseList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/category/get-expenses`,
            query: {
                'categoryId': categoryId,
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Add an expense to a category
     * @returns Expense Expense added successfully
     * @throws ApiError
     */
    public static async addCategoryExpense({
companyId,
departmentId,
categoryId,
requestBody,
}: {
companyId: any,
departmentId: any,
categoryId: any,
requestBody: any,
}): Promise<Expense> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/category/${categoryId}/expenses`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update an expense to a category
     * @returns Expense Expense updated successfully
     * @throws ApiError
     */
    public static async updateCategoryExpense({
companyId,
departmentId,
categoryId,
requestBody,
}: {
companyId: any,
departmentId: any,
categoryId: any,
requestBody: any,
}): Promise<Expense> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}/category/${categoryId}/expenses`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a budget of a Company category
     * @returns CompanyExpenses The list of expense made by department and it's categories within the given company
     * @throws ApiError
     */
    public static async getCompanyCategoryExpenses({
companyId,
beginAt,
endAt,
}: {
companyId: number,
beginAt: string,
endAt: string,
}): Promise<CompanyExpenses> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/expenses`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

}