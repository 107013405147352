import { RouteConfig } from "vue-router";
import AdminToolMenu from "@/views/admin/AdminToolMenu.vue";
import DepartmentList from "@/views/admin/departments/DepartmentList.vue";
import DepartmentDetail from "@/views/admin/departments/DepartmentDetail.vue";
import AllEventList from "@/views/admin/event_metrics/AllEventList.vue";
import EventTrackList from "@/views/admin/event_metrics/EventTrackList.vue";
import LogLayout from "@/views/admin/event_metrics/log_event/LogLayout.vue";
import EventDepartmentList from "@/views/admin/event_metrics/log_event/EventDepartmentList.vue";
import LogEventList from "@/views/admin/event_metrics/log_event/LogEvents.vue";
import ArchiveEmployeeList from "@/views/admin/archive_list/ArchiveEmployeeList.vue";
import TarfHistoryLayout from "@/views/admin/tarf_history/TarfHistoryLayout.vue";
import TarfHistoryEmployeeList from "@/views/admin/tarf_history/TarfHistoryEmployeeList.vue";
import TarfHistoryStatuses from "@/views/admin/tarf_history/TarfHistoryStatuses.vue";
import TarfHistoryList from "@/views/admin/tarf_history/TarfHistoryList.vue";
import CompanyBudgetLayout from "@/views/admin/budget/CompanyBudget.vue";
import RoomSetupLayout from "@/views/admin/room_setup/RoomSetupLayout.vue";
import FloorList from "@/views/admin/room_setup/FloorList.vue";
import RoomList from "@/views/admin/room_setup/RoomList.vue";
import RoomDetails from "@/views/admin/room_setup/RoomDetails.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    components: {
      default: AdminToolMenu,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Admin",
      requiresAuth: true,
    },
  },
  {
    path: "department",
    name: "Departments",
    components: {
      default: DepartmentList,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Company Departments",
      requiresAuth: true,
    },
  },
  {
    path: "department/:id",
    name: "Department Detail",
    components: {
      default: DepartmentDetail,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Department Detail",
      requiresAuth: true,
    },
  },
  // Event Matrics router paths
  {
    path: "event/all",
    name: "All Event List",
    components: {
      default: AllEventList,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "All Event",
      requiresAuth: true,
    },
  },
  {
    path: "event/track",
    name: "Event Track List",
    components: {
      default: EventTrackList,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Track Event",
      requiresAuth: true,
    },
  },
  {
    path: "event/log",
    components: {
      default: LogLayout,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Event",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        components: {
          default: EventDepartmentList,
          departmentList: EventDepartmentList,
          logevent: LogEventList,
        },
        meta: {
          title: "Department List",
          requiresAuth: true,
        },
      },
      {
        path: "logevent/:id",
        components: {
          default: LogEventList,
          departmentList: EventDepartmentList,
          logevent: LogEventList,
        },
        meta: {
          title: "Log Event",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "archive_list",
    name: "Employee Archive List",
    components: {
      default: ArchiveEmployeeList,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      archiveList: ArchiveEmployeeList,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Archive List",
      requiresAuth: true,
    },
  },
  {
    path: "tarf-history",
    components: {
      default: TarfHistoryLayout,
      menu: AdminToolMenu,
      tarfHistory: TarfHistoryLayout,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Tarf History",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        components: {
          default: TarfHistoryEmployeeList,
          menu: AdminToolMenu,
          employees: TarfHistoryEmployeeList,
          statuses: TarfHistoryStatuses,
          history: TarfHistoryList,
        },
        meta: {
          title: "Employee List",
          requiresAuth: true,
        },
      },
      {
        path: "employee/:id",
        components: {
          default: TarfHistoryStatuses,
          menu: AdminToolMenu,
          employees: TarfHistoryEmployeeList,
          statuses: TarfHistoryStatuses,
          history: TarfHistoryList,
        },
        meta: {
          title: "Tarf Statuses",
          requiresAuth: true,
        },
      },
      {
        path: "employee/:id/tarfs/:status",
        components: {
          default: TarfHistoryList,
          menu: AdminToolMenu,
          employees: TarfHistoryEmployeeList,
          statuses: TarfHistoryStatuses,
          history: TarfHistoryList,
        },
        meta: {
          title: "Tarf list",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "company-budget",
    components: {
      default: CompanyBudgetLayout,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      archiveList: ArchiveEmployeeList,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Budget",
      requiresAuth: true,
    },
  },
  {
    path: "room-setup",
    components: {
      default: RoomSetupLayout,
      menu: AdminToolMenu,
      departments: DepartmentList,
      departmentDetail: DepartmentDetail,
      events: AllEventList,
      trackevents: EventTrackList,
      logevents: LogLayout,
      archiveList: ArchiveEmployeeList,
      tarfHistory: TarfHistoryLayout,
      budget: CompanyBudgetLayout,
      roomSetup: RoomSetupLayout,
    },
    meta: {
      title: "Room Setup",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        components: {
          default: FloorList,
          menu: AdminToolMenu,
          floor: FloorList,
          room: RoomList,
          details: RoomDetails,
        },
        meta: {
          title: "Floor List",
          requiresAuth: true,
        },
      },
      {
        path: "floor/:floorId",
        components: {
          default: RoomList,
          menu: AdminToolMenu,
          floor: FloorList,
          room: RoomList,
          details: RoomDetails,
        },
        meta: {
          title: "Room list",
          requiresAuth: true,
        },
      },
      {
        path: "floor/:floorId/room/:roomId",
        components: {
          default: RoomDetails,
          menu: AdminToolMenu,
          floor: FloorList,
          room: RoomList,
          details: RoomDetails,
        },
        meta: {
          title: "Room details",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
