

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OverviewCard extends Vue {
  @Prop(String) readonly title!: string;

  goTo() {
    this.$emit("goTo");
  }
}
