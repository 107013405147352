
































































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import {
  requiredString,
  noSpace,
  requireAutoSelectNumber,
} from "@/tool/validation";
import { Department, Employee, MessageGroupRequest } from "@/api";
import _ from "lodash";
import { selfData } from "@/common/role_utils";

@Component
export default class AddGroupForm extends Vue {
  @Ref("form") readonly form!: VForm;
  @Prop() readonly title!: string;

  valid = false;
  creating = false;
  selectAllValue = false;
  selectedEmployees: number[] = [];
  name = "";
  stringRules = [requiredString, noSpace];
  employeesRules = [requireAutoSelectNumber];
  departmentId = 0;
  empTitle = "";

  get employees() {
    const employees = this.$store.state.directory.employees || [];
    return _.orderBy(
      _.map(employees, (employee: Employee) => {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          profile: employee.profilePicture,
          primaryDepartment: employee.primaryDepartmentId,
          titleId: employee.titleId,
        };
      }),
      ["name"],
      ["asc"],
    );
  }

  get employeesSelection() {
    const checkFilter = this.departmentId !== 0 || this.empTitle.length !== 0;

    const empls = checkFilter
      ? (_.orderBy(
          _.filter(
            this.$store.state.directory.employees ?? [],
            (employee: Employee) => {
              if (this.departmentId && !this.empTitle.length)
                return employee.primaryDepartmentId === this.departmentId;
              if (this.empTitle.length)
                return (
                  employee.primaryDepartmentId === this.departmentId &&
                  employee.title?.includes(this.empTitle)
                );
            },
          ),
          employee => employee.firstName.toLowerCase(),
        ) as Employee[])
      : (_.orderBy(this.$store.state.directory.employees ?? [], employee =>
          employee.firstName.toLowerCase(),
        ) as Employee[]);
    return _.orderBy(
      _.map(empls, (employee: Employee) => {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          profile: employee.profilePicture,
          primaryDepartment: employee.primaryDepartmentId,
          titleId: employee.titleId,
        };
      }),
      ["name"],
      ["asc"],
    );
  }

  get departments(): Department[] {
    const departments = this.$store.state.company.departments;
    if (departments) {
      return [
        { id: 0, name: "All Department", companyId: 0, isArchived: false },
        ...departments,
      ];
    } else {
      return [];
    }
  }

  get titleList() {
    const list = this.$store.getters["departmentAdmin/departmentTitle"];
    if (list) {
      return list;
    } else {
      return [];
    }
  }

  async getDepartmentTitle() {
    this.empTitle = "";
    if (this.departmentId)
      await this.$store.dispatch("departmentAdmin/getTitle", this.departmentId);
  }

  close() {
    this.form.reset();
    this.$emit("close");
  }

  async createGroup() {
    if (this.form.validate()) {
      this.creating = true;
      const check = this.selectedEmployees.filter(
        (id: number) => id == selfData().id,
      );
      if (check.length == 0) this.selectedEmployees.push(selfData().id);
      const requestBody = {
        companyId: selfData().companyId,
        name: this.name,
        employees: this.selectedEmployees,
      } as MessageGroupRequest;

      const group = await this.$store.dispatch("chat/createGroup", requestBody);
      this.creating = false;
      this.close();

      this.$store.dispatch("chat/setSelectedGroup", { group, force: true });
      this.$router.push({
        path: `/chat/${group.id}`,
        query: {
          isGroup: group.name,
        },
      });
    }
  }

  getEmployeeLetter(name: string) {
    if (name) {
      const [fname, lname] = name.split(" ");
      return `${fname[0]}${lname[0]}`;
    }
    return "";
  }

  selectAll() {
    this.selectedEmployees = [];
    if (this.selectAllValue) {
      _.forEach(this.employeesSelection, emp => {
        this.selectedEmployees.push(emp.id);
      });
    }
  }
}
