var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-fill fill-height py-6 px-4"},[(_vm.loading)?_c('Loading',{attrs:{"loading":_vm.loading,"size":70,"width":7}}):_c('div',{staticClass:"mb-16",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"display-1 font-weight-bold text-blue"},[_vm._v("Report Summary")]),_c('div',{staticClass:"d-flex flex-fill flex-wrap my-2"},[_c('div',{staticClass:"title",class:{ dateInput: _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" Filters: ")]),_c('div',{staticClass:"w-175"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"px-3",attrs:{"value":_vm.formattedDates,"readonly":"","outlined":"","label":"Start Date","dense":""}},on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"range":"","no-title":""},on:{"change":function($event){_vm.datePicker = false;
              _vm.dateFilterChanged('dates');}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1),_c('div',{staticClass:"w-150"},[_c('v-select',{attrs:{"items":_vm.floors,"item-text":"name","item-value":"id","label":"Floor #","outlined":"","dense":""},on:{"change":function($event){return _vm.dateFilterChanged('floor')}},model:{value:(_vm.floorId),callback:function ($$v) {_vm.floorId=$$v},expression:"floorId"}})],1),_c('div',{staticClass:"w-175"},[_c('v-select',{attrs:{"items":_vm.employees,"item-text":"text","item-value":"id","label":"Inspected By","outlined":"","dense":""},on:{"change":_vm.fetchSummary},model:{value:(_vm.inspectedBy),callback:function ($$v) {_vm.inspectedBy=$$v},expression:"inspectedBy"}})],1),_c('div',{staticClass:"w-150"},[_c('v-select',{attrs:{"items":_vm.companyRooms,"item-text":"name","item-value":"id","label":"Room #","outlined":"","dense":""},on:{"change":function($event){return _vm.dateFilterChanged('room')}},model:{value:(_vm.roomId),callback:function ($$v) {_vm.roomId=$$v},expression:"roomId"}})],1),_c('div',{staticClass:"w-150"},[_c('v-select',{attrs:{"items":_vm.beds,"item-text":"name","item-value":"id","label":"Bed #","outlined":"","dense":""},on:{"change":_vm.fetchSummary},model:{value:(_vm.bedId),callback:function ($$v) {_vm.bedId=$$v},expression:"bedId"}})],1),_c('v-btn',{staticClass:"mx-2",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '65%' : '80px',"color":"#55abfa","rounded":""},on:{"click":function($event){return _vm.dateFilterChanged('default')}}},[_c('span',{staticClass:"text-white caption font-weight-bold"},[_vm._v("Clear")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.summary,"single-expand":"","expanded":_vm.expanded,"item-key":"id","no-data-text":"No data found","show-expand":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTableDate(item.created_at))+" ")]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers && headers.length}},[(item.idata)?_c('v-list',_vm._l((item.idata),function(item,index){return _c('v-list-item',{key:item.id,staticClass:"mb-2"},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"1","md":"1"}},[_vm._v(" Q"+_vm._s(index + 1)+" ")]),_c('v-col',{attrs:{"cols":"11","md":"5"}},[_vm._v(_vm._s(item.question))]),(item.allow_rating)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"1","md":"1"}},[_vm._v(" "+_vm._s(item.rating)+" / 5 ")]):_c('v-col',{staticClass:"text-center",class:{ 'text-green': item.meet, 'text-red': !item.meet },attrs:{"cols":"1","md":"1"}},[_vm._v(" "+_vm._s(item.meet ? "MET" : "UNMET")+" ")]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('div',[_vm._v("Comment: "+_vm._s(item.comment))]),_c('v-divider'),_c('div',[_vm._v("Feedback: "+_vm._s(item.feedback))]),_c('v-divider')],1)],1)],1)}),1):_vm._e()],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }