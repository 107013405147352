/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventCount } from '../models/EventCount';
import type { LoggedEvent } from '../models/LoggedEvent';
import type { LoggedEventList } from '../models/LoggedEventList';
import type { ToggleLoggedEventRequestBody } from '../models/ToggleLoggedEventRequestBody';
import { request as __request } from '../core/request';

export class EventLogService {

    /**
     * List events that have occurred in this department
     * @returns LoggedEventList Events that have occurred in this department
     * @throws ApiError
     */
    public static async listLoggedEvents({
companyId,
departmentId,
eventMetricId,
employeeId,
postedByEmployeeId,
postedByEmployeeDepartmentId,
beginAt,
endAt,
limit,
offset,
}: {
companyId: number,
/** Filter events by this department ID **/
departmentId?: number,
/** Filter events by this event metric ID **/
eventMetricId?: number,
/** Filter events by the employee for which the event was logged (ex: the employee who earned he 5 Star Moment) **/
employeeId?: number,
/** Filter events by the giver employee which logged the event (ex: who posted the 5 Star Moment) **/
postedByEmployeeId?: number,
/** Filter events by the giver employee department which logged the event (ex: who posted the 5 Star Moment) **/
postedByEmployeeDepartmentId?: number,
beginAt?: string,
endAt?: string,
limit?: number,
offset?: number,
}): Promise<LoggedEventList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/event-log`,
            query: {
                'departmentId': departmentId,
                'eventMetricId': eventMetricId,
                'employeeId': employeeId,
                'postedByEmployeeId': postedByEmployeeId,
                'postedByEmployeeDepartmentId': postedByEmployeeDepartmentId,
                'beginAt': beginAt,
                'endAt': endAt,
                'limit': limit,
                'offset': offset,
            },
        });
        return result.body;
    }

    /**
     * Log an event
     * @returns LoggedEvent The logged event
     * @throws ApiError
     */
    public static async logEvent({
companyId,
requestBody,
count,
}: {
companyId: number,
requestBody: any,
/** Number of events to be logged **/
count?: number,
}): Promise<LoggedEvent> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/event-log`,
            query: {
                'count': count,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Toggle LoggedEvent of an Employee
     * @returns any The Event has been saved
     * @throws ApiError
     */
    public static async toggleEmployeeEvent({
companyId,
eventId,
requestBody,
}: {
companyId: number,
eventId: number,
requestBody: ToggleLoggedEventRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/event-log/${eventId}/toggle-event`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get logged event count
     * @returns EventCount The Event has been saved
     * @throws ApiError
     */
    public static async getEventCount({
companyId,
eventId,
date,
}: {
companyId: number,
eventId: number,
date?: string,
}): Promise<EventCount> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/event-log/${eventId}/count`,
        });
        return result.body;
    }

    /**
     * Get a logged event
     * @returns LoggedEvent The logged event
     * @throws ApiError
     */
    public static async getEvent({
companyId,
eventId,
}: {
companyId: number,
eventId: number,
}): Promise<LoggedEvent> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/event-log/${eventId}`,
        });
        return result.body;
    }

    /**
     * Delete a logged event. Only 5 star event is allowed to be deleted.
     * @returns any Delete the logged event
     * @throws ApiError
     */
    public static async deleteEvent({
companyId,
eventId,
}: {
companyId: number,
eventId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/event-log/${eventId}`,
        });
        return result.body;
    }

}