













































































































































import {
  Department,
  Employee,
  MessageGroupDetails,
  MessageGroupRequest,
} from "@/api";
import { selfData } from "@/common/role_utils";
import { VForm } from "@/common/vuetify_types";
import { requireAutoSelectNumber } from "@/tool/validation";
import _ from "lodash";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component
export default class AddGroupParticipants extends Vue {
  @Prop() readonly selectedIds!: number[];
  @Prop() readonly groupDetails!: MessageGroupDetails;
  @Ref("form") form!: VForm;

  loading = false;
  searchInput = null;
  selectedEmployees: number[] = [];
  employeesRules = [requireAutoSelectNumber];
  departmentId = 0;
  empTitle = "";

  close() {
    this.form.reset();
    this.departmentId = 0;
    this.empTitle = "";
    this.$emit("close");
  }

  async save() {
    if (this.form.validate()) {
      this.loading = true;
      const payload = {
        companyId: selfData().companyId,
        id: this.groupDetails.id,
        name: this.groupDetails.name,
        employees: this.selectedEmployees,
      } as MessageGroupRequest;
      await this.$store.dispatch("chat/addGroupParticipants", payload);
      this.loading = false;
      this.close();
    }
  }

  get employees() {
    const checkFilter = this.departmentId !== 0 || this.empTitle;

    const empls = checkFilter
      ? (_.orderBy(
          _.filter(
            this.$store.state.directory.employees ?? [],
            (employee: Employee) => {
              if (this.departmentId && !this.empTitle.length)
                return employee.primaryDepartmentId === this.departmentId;
              if (this.empTitle.length)
                return (
                  employee.primaryDepartmentId === this.departmentId &&
                  employee.title?.includes(this.empTitle)
                );

              !this.selectedIds.includes(employee.id);
            },
          ),
          employee => employee.firstName.toLowerCase(),
        ) as Employee[])
      : (_.orderBy(
          _.filter(
            this.$store.state.directory.employees ?? [],
            emp => !this.selectedIds.includes(emp.id),
          ),
          employee => employee.firstName.toLowerCase(),
        ) as Employee[]);
    return _.orderBy(
      _.map(empls, (employee: Employee) => {
        return {
          ...employee,
          list: `${employee.firstName} ${employee.lastName} - ${employee.title} - ${employee.departmentAssignments[0].departmentName}`,
        };
      }),
      ["name"],
      ["asc"],
    );
  }

  get finalEmployees() {
    let employees = this.$store.state.directory.employees;
    employees = employees
      ? employees.filter((employee: Employee) => {
          if (this.selectedEmployees.includes(employee.id)) {
            return employee;
          }
        })
      : [];
    return employees.map((employee: Employee) => {
      return {
        ...employee,
        list: `${employee.firstName} ${employee.lastName} - ${employee.title} - ${employee.departmentAssignments[0].departmentName}`,
      };
    });
  }

  set finalEmployees(value) {
    if (!this.selectedEmployees.includes(value?.id)) {
      if (value?.id) {
        this.selectedEmployees.push(value.id);
      }
    }
  }

  remove(employee: number) {
    for (let i = 0; i < this.selectedEmployees.length; i++) {
      const selectedEmployee = this.selectedEmployees[i];
      if (selectedEmployee === employee) {
        this.selectedEmployees.splice(i, 1);
      }
    }
  }

  get departments(): Department[] {
    const departments = this.$store.state.company.departments;
    if (departments) {
      return [
        { id: 0, name: "All Department", companyId: 0, isArchived: false },
        ...departments,
      ];
    } else {
      return [];
    }
  }

  get titleList() {
    const list = this.$store.getters["departmentAdmin/departmentTitle"];
    if (list) {
      return list;
    } else {
      return [];
    }
  }

  async getDepartmentTitle() {
    this.empTitle = "";
    if (this.departmentId)
      await this.$store.dispatch("departmentAdmin/getTitle", this.departmentId);
  }
}
