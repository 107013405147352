import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  CompanyGuardianAngels,
  GuardianAngel,
  GuardianAngelService,
  GuardianAngels,
} from "@/api";

type GuardianState = {
  guardians: GuardianAngels | null;
  companyGuardianAngels: CompanyGuardianAngels | null;
};

const state: GuardianState = {
  guardians: null,
  companyGuardianAngels: null,
};

const getters: GetterTree<GuardianState, RootState> = {
  guardians: state => state.guardians,
  companyGuardianAngels: state => state.companyGuardianAngels,
};

const mutations: MutationTree<GuardianState> = {
  SET_GUARDIANS: (state, guardians) => {
    state.guardians = guardians;
  },
  REMOVE_GUARDIAN: (state, guardian) => {
    let index = state.guardians?.findIndex(item => item.id == guardian.id);
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.guardians?.splice(index, 1);
    }
  },
  SET_COMPANY_GUARDIAN_ANGELS: (state, companyGuardianAngels) => {
    state.companyGuardianAngels = companyGuardianAngels;
  },
};

const actions: ActionTree<GuardianState, RootState> = {
  fetchRoomGuardians: async (
    { commit, rootState },
    { floorId, roomId }: { floorId: number; roomId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const guardians = await GuardianAngelService.getRoomGuardianAngels({
        companyId,
        floorId,
        roomId,
      });
      commit("SET_GUARDIANS", guardians);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addRoomGuardian: async (
    { commit, rootState, dispatch },
    {
      floorId,
      roomId,
      requestBody,
    }: { floorId: number; roomId: number; requestBody: GuardianAngel },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      await GuardianAngelService.addRoomGuardianAngel({
        companyId,
        floorId,
        roomId,
        requestBody,
      });
      await dispatch("fetchRoomGuardians", { floorId, roomId });
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateRoomGuardian: async (
    { commit, rootState, dispatch },
    {
      floorId,
      roomId,
      requestBody,
    }: { floorId: number; roomId: number; requestBody: GuardianAngel },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      await GuardianAngelService.updateRoomGuardianAngel({
        companyId,
        floorId,
        roomId,
        requestBody,
      });
      await dispatch("fetchRoomGuardians", { floorId, roomId });
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeRoomGuardian: async (
    { commit, rootState },
    {
      floorId,
      roomId,
      requestBody,
    }: { floorId: number; roomId: number; requestBody: GuardianAngel },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      await GuardianAngelService.deleteRoomGuardianAngel({
        companyId,
        floorId,
        roomId,
        requestBody,
      });
      commit("REMOVE_GUARDIAN", requestBody);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchCompanyGuardianAngels: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const guardians = await GuardianAngelService.getCompanyGuardianAngels({
        companyId,
      });
      commit("SET_COMPANY_GUARDIAN_ANGELS", guardians);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const guardian: Module<GuardianState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
