/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveAndArchiveListOfEmployeeDocumentResponse } from '../models/ActiveAndArchiveListOfEmployeeDocumentResponse';
import type { approvDenyEmployeeDocumentRequest } from '../models/approvDenyEmployeeDocumentRequest';
import type { DocumentPathResponse } from '../models/DocumentPathResponse';
import type { EmployeeDocumentResponse } from '../models/EmployeeDocumentResponse';
import type { getEmployeeDocumentByAprovalRequest } from '../models/getEmployeeDocumentByAprovalRequest';
import type { OwnerViewDocumentResponse } from '../models/OwnerViewDocumentResponse';
import { request as __request } from '../core/request';

export class EmployeeDocumentService {

    /**
     * create an employee Document
     * @returns EmployeeDocumentResponse The created employee document
     * @throws ApiError
     */
    public static async createEmployeeDocument({
companyId,
employeeId,
departmentDocumentId,
expirationDate,
license,
role,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The departmentDocumentId **/
departmentDocumentId: number,
/** The expiration date of the document **/
expirationDate?: string,
/** The license of the document **/
license?: string,
/** role of user **/
role?: string,
requestBody?: any,
}): Promise<EmployeeDocumentResponse> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document/department-document/${departmentDocumentId}`,
            query: {
                'expiration_date': expirationDate,
                'license': license,
                'role': role,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get active and archive employee document list
     * @returns ActiveAndArchiveListOfEmployeeDocumentResponse The fetch  employee document list
     * @throws ApiError
     */
    public static async getActiveArchiveEmployeeDocumentList({
companyId,
employeeId,
departmentDocumentId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The departmentDocumentId **/
departmentDocumentId: number,
}): Promise<ActiveAndArchiveListOfEmployeeDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document/department-document/${departmentDocumentId}/document-list`,
        });
        return result.body;
    }

    /**
     * update an employee Document image
     * @returns EmployeeDocumentResponse The updated employee document image
     * @throws ApiError
     */
    public static async updateEmployeeDocumentImage({
companyId,
employeeId,
employeeDocumentId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The employeeDocumentId **/
employeeDocumentId: number,
requestBody?: any,
}): Promise<EmployeeDocumentResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document/${employeeDocumentId}/image`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get an employee Document by id
     * @returns EmployeeDocumentResponse The get employee document
     * @throws ApiError
     */
    public static async getEmployeeDocumentById({
companyId,
employeeId,
employeeDocumentId,
expirationDate,
license,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The employeeDocumentId **/
employeeDocumentId: number,
/** The expiration date of the document **/
expirationDate?: string,
/** The license of the document **/
license?: string,
}): Promise<EmployeeDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document/${employeeDocumentId}`,
            query: {
                'expiration_date': expirationDate,
                'license': license,
            },
        });
        return result.body;
    }

    /**
     * update an employee Document data
     * @returns EmployeeDocumentResponse The updated employee document data
     * @throws ApiError
     */
    public static async updateEmployeeDocumentData({
companyId,
employeeId,
employeeDocumentId,
expirationDate,
license,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The employeeDocumentId **/
employeeDocumentId: number,
/** The expiration date of the document **/
expirationDate?: string,
/** The license of the document **/
license?: string,
}): Promise<EmployeeDocumentResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document/${employeeDocumentId}`,
            query: {
                'expiration_date': expirationDate,
                'license': license,
            },
        });
        return result.body;
    }

    /**
     * approve or deny employee document
     * @returns EmployeeDocumentResponse The approve or deny employee document
     * @throws ApiError
     */
    public static async approvDenyEmployeeDocument({
companyId,
employeeId,
employeeDocumentId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The employeeDocumentId **/
employeeDocumentId: number,
requestBody: approvDenyEmployeeDocumentRequest,
}): Promise<EmployeeDocumentResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document/${employeeDocumentId}/approve-deny`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get employee document on the basis of approval
     * @returns EmployeeDocumentResponse The list of employee document on the basis  of approval
     * @throws ApiError
     */
    public static async getEmployeeDocumentByApproval({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: getEmployeeDocumentByAprovalRequest,
}): Promise<EmployeeDocumentResponse> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/employee-document`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get an employee Document by employee id
     * @returns EmployeeDocumentResponse The employee document list
     * @throws ApiError
     */
    public static async getEmployeeDocumentByEmployeeId({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<EmployeeDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document`,
        });
        return result.body;
    }

    /**
     * get an employee Document by document id only owner and admin can hit this api
     * @returns OwnerViewDocumentResponse The employee document list
     * @throws ApiError
     */
    public static async getEmployeeDocumentByDocumentId({
companyId,
documentId,
role,
}: {
/** The companyId **/
companyId: number,
/** The documentId **/
documentId: number,
/** role of user **/
role?: string,
}): Promise<OwnerViewDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/document/${documentId}/employee-document`,
            query: {
                'role': role,
            },
        });
        return result.body;
    }

    /**
     * get particular document url
     * @returns DocumentPathResponse url in response
     * @throws ApiError
     */
    public static async getEmployeeDocumentUrl({
companyId,
employeeDocumentId,
}: {
/** The companyId **/
companyId: number,
/** The employeeDocumentId **/
employeeDocumentId: number,
}): Promise<DocumentPathResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employee-document/${employeeDocumentId}/url`,
        });
        return result.body;
    }

}