/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Task = {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        departmentId: {
    type: 'number',
    isRequired: true,
},
        roomId: {
    type: 'number',
},
        bedId: {
    type: 'number',
},
        inspectionDataId: {
    type: 'number',
},
        details: {
    type: 'string',
    isRequired: true,
},
        location: {
    type: 'string',
},
        type: {
    type: 'string',
    isRequired: true,
},
        assignee: {
    type: 'number',
    isRequired: true,
},
        status: {
    type: 'number',
},
        deadline: {
    type: 'string',
    isRequired: true,
},
        createdAt: {
    type: 'string',
},
    },
};