



































































import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
import { Room } from "@/api";
import Loading from "@/components/loader/loading.vue";
import RoomFormModal from "./RoomFormModal.vue";

@Component({
  components: {
    Loading,
    RoomFormModal,
  },
  watch: {
    async $route() {
      const floorId = this.$route.params.floorId;
      const roomId = this.$route.params.roomId;
      if (floorId && !roomId) {
        this.$data.isDataLoading = true;
        await this.$store.dispatch("room/fetchFloorRooms", floorId);
        this.$data.isDataLoading = false;
      }
    },
  },
})
export default class RoomList extends Vue {
  isDataLoading = false;
  search = "";
  dialog = false;
  saving = false;
  count = 0;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("room/fetchFloorRooms", this.floorId);
    this.isDataLoading = false;
  }

  get rooms() {
    const search = !this.search ? "" : this.search;
    const data = this.$store.getters["room/rooms"];
    const list = _.sortBy(
      _.filter(data, room =>
        _.includes(`${room.name.toLowerCase()}`, search.toLowerCase()),
      ),
      [(room: Room) => `${room.name.trim().toUpperCase()}`],
    );
    return Object.values(list);
  }

  get floorId() {
    const floorId = this.$route.params.floorId;
    if (floorId) {
      try {
        return parseInt(floorId);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  get roomId() {
    const roomId = this.$route.params.roomId;
    if (roomId) {
      try {
        return parseInt(roomId);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  selectRoom(room: Room) {
    this.$router.push({
      path: `/admin/room-setup/floor/${room.floorId}/room/${room.id}`,
    });
  }

  async addRoom(requestBody: Room) {
    this.saving = true;
    await this.$store.dispatch("room/addFloorRoom", {
      floorId: this.floorId,
      requestBody,
    });
    this.saving = false;
    this.dialog = !this.dialog;
    this.count++;
  }

  clearSearch() {
    this.search = "";
  }

  back() {
    this.$router.back();
  }
}
