










































import { Component, Vue } from "vue-property-decorator";
import { ScoreboardSummary } from "@/api";
import ScoreboardSummaryCard from "@/components/scoreboard/ScoreboardSummaryCard.vue";
import loading from "@/components/loader/loading.vue";

@Component({
  components: { ScoreboardSummaryCard, loading },
})
export default class ScoreboardOverview extends Vue {
  expValue!: number;

  get activeValue() {
    return this.$store.getters["scoreboard/activeValue"];
  }

  get scoreboards(): ScoreboardSummary[] {
    return this.$store.getters["scoreboard/scoreboards"];
  }

  get companyScoreboard(): ScoreboardSummary {
    const [filterCompany] = this.scoreboards.filter(
      scoreboard => !scoreboard.departmentId,
    );
    return filterCompany;
  }

  get departmentScoreboards(): ScoreboardSummary[] {
    const filterDepartment = this.scoreboards.filter(
      scoreboard => scoreboard.departmentId,
    );
    return filterDepartment;
  }

  valueHandler(index: number) {
    this.$store.dispatch("scoreboard/setValue", index);
  }
}
