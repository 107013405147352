import moment from "moment";

export const monthStamp = () => {
  const beginAt = moment()
    .startOf("month")
    .format("YYYY-MM-DD");

  const endAt = moment()
    .add(1, "M")
    .startOf("month")
    .format("YYYY-MM-DD");

  return {
    beginAt,
    endAt,
  };
};
