































import { Department } from "@/api";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class LogLayoutMobile extends Vue {
  created() {
    this.$store.dispatch("company/loadDepartments");
  }
  isOpen = false;
  header = "Department Select";
  get departments(): Department[] {
    return this.$store.getters["departmentAdmin/departments"];
  }

  selectDepartment(department: Department) {
    this.header = department.name;
    this.$router.push("/admin/event/log/logevent/" + department.id);
    this.isOpen = false;
  }

  get isLogEvent() {
    return this.$route.params.id && this.$route.path.includes("logevent");
  }

  back() {
    this.$router.push({ path: "/admin" });
  }
}
