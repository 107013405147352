import { RouteConfig } from "vue-router";
import DocumentMenu from "@/views/ed_approval/DocumentMenu.vue";
import DocumentList from "@/views/ed_approval/DocumentList.vue";
import DocumentApproval from "@/views/ed_approval/DocumentApproval.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    components: {
      default: DocumentMenu,
      documentMenu: DocumentMenu,
      documentList: DocumentList,
      documentApproval: DocumentApproval,
    },
    meta: {
      title: "Employee Document",
      requiresAuth: true,
    },
  },
  {
    path: ":status",
    components: {
      default: DocumentList,
      documentMenu: DocumentMenu,
      documentList: DocumentList,
      documentApproval: DocumentApproval,
    },
    meta: {
      title: "Employee Document",
      requiresAuth: true,
    },
  },
  {
    path: ":status/:id",
    components: {
      default: DocumentApproval,
      documentMenu: DocumentMenu,
      documentList: DocumentList,
      documentApproval: DocumentApproval,
    },
    meta: {
      title: "Employee Document",
      requiresAuth: true,
    },
  },
];

export default routes;
