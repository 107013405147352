import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import {
  EventLogService,
  LogEventRequestBody,
  // LogEventRequestBody,
  LoggedEvent,
  OpenAPI,
} from "@/api";
import Vue from "vue";
import resolve from "@/common/resolver";

export type ActivityState = {
  activityFeed: LoggedEvent[] | null;
  selectedEvent: LoggedEvent | null;
  latestFeed: LoggedEvent | null;
  loading: boolean;
};

const state: ActivityState = {
  activityFeed: null,
  selectedEvent: null,
  latestFeed: null,
  loading: false,
};

const getters: GetterTree<ActivityState, RootState> = {
  activityFeed: store => {
    return store.activityFeed;
  },
  selectedEvent: store => {
    return store.selectedEvent;
  },
  latestFeed: store => {
    return store.latestFeed;
  },
  loading: store => store.loading,
};

const mutations: MutationTree<ActivityState> = {
  SET_ACTIVITY_FEED: (state, loggedEvents: LoggedEvent[]) => {
    if (loggedEvents) {
      state.activityFeed = loggedEvents
        .sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        })
        .filter(activity => activity.eventMetricId === -1);
    } else {
      state.activityFeed = [];
    }
  },
  EVENT_ADDED: (state, event: LoggedEvent) => {
    if (state.activityFeed) {
      Vue.set(state.activityFeed, state.activityFeed?.length ?? 0, event);
    }
  },
  SELECT_EVENT: (state, event: LoggedEvent) => {
    state.selectedEvent = event;
  },
  SET_LATEST_FEED: (state, event: LoggedEvent) => {
    state.latestFeed = event;
  },
  DESTROY_SELECTED_EVENT: state => {
    state.selectedEvent = null;
  },
  SET_LOADING: (state, status) => {
    state.loading = status;
  },
};

const actions: ActionTree<ActivityState, RootState> = {
  loadActivityFeed: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      const loggedEventList = await EventLogService.listLoggedEvents({
        companyId,
        eventMetricId: -1,
      });
      commit("SET_ACTIVITY_FEED", loggedEventList.loggedEvents);
    }
  },
  selectEvent: async ({ commit, state, rootState }, eventId) => {
    if (!eventId) {
      return;
    }
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      return;
    }
    let selectedEvent: LoggedEvent | undefined;
    const activityFeed = state.activityFeed;
    if (activityFeed) {
      const existingEvent = activityFeed.filter(event => event.id == eventId);
      if (existingEvent.length > 0) {
        selectedEvent = existingEvent[0];
      }
    }
    if (!selectedEvent) {
      // look it up
      try {
        const loggedEvent = await EventLogService.getEvent({
          companyId,
          eventId,
        });
        commit("SELECT_EVENT", loggedEvent);
      } catch (e) {
        console.log(e);
      }
    } else {
      commit("SELECT_EVENT", selectedEvent);
    }
  },
  submitFiveStarEvent: async (
    { commit, rootState },
    requestBody: LogEventRequestBody,
  ) => {
    const companyId = rootState.appContext.company?.id;
    let formData = null;
    formData = new FormData();
    formData.append("eventMetricId", requestBody.eventMetricId.toString());
    formData.append("employeeIds", JSON.stringify(requestBody.employeeIds));
    formData.append("note", requestBody.note ?? "");
    formData.append("timestamp", requestBody.timestamp);
    formData.append("giver", requestBody.giver ?? "self");
    if (requestBody.document) {
      formData.append(
        "document",
        requestBody.document,
        requestBody.document?.name,
      );
    }
    if (companyId) {
      try {
        // commit("EVENT_ADDED", loggedEvent);
        await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/event-log?count=1`,
          {
            method: "POST",
            body: formData,
            headers: {
              authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
            },
          },
        );

        commit("alert/SET_SHOW_SUCCESS", "Successfully added", {
          root: true,
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  },
  latestEvent: ({ commit }, event: LoggedEvent) => {
    commit("SET_LATEST_FEED", event);
  },
  destroySelectedEvent: ({ commit }) => {
    commit("DESTROY_SELECTED_EVENT");
  },
  destroyActivityFeed: ({ commit }) => {
    commit("SET_ACTIVITY_FEED", null);
  },
  updateLoading: ({ commit }, value) => {
    commit("SET_LOADING", value);
  },
};

export type SubmitFiveStarMomentRequest = {
  employeeIds: number[];
  note: string;
};

export const activity: Module<ActivityState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
