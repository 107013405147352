/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ActiveAndArchiveListOfEmployeeDocumentResponse = {
    properties: {
        active: {
    type: 'array',
    contains: {
        type: 'LeastEmployeeDocument',
    },
    isRequired: true,
},
        archive: {
    type: 'array',
    contains: {
        type: 'LeastEmployeeDocument',
    },
    isRequired: true,
},
    },
};