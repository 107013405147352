































































































import { EventMetric } from "@/api";
import { VForm } from "@/common/vuetify_types";
import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import ConfirmModal from "@/views/company_directory/ConfirmModal.vue";
import * as validation from "@/tool/validation";

@Component({
  components: {
    ConfirmModal,
  },
})
export default class EditCustomEvent extends Vue {
  isArchiving = false;
  eventDeleteConfirmModal = false;
  editEvent = false;
  @Ref("form") from!: VForm;
  @Prop(Object) event!: EventMetric;
  name = this.event.name;
  description = this.event.description;
  isLeaderOrderDescending = this.event.isLeaderOrderDescending;
  loading = false;
  rules = {
    required: validation.requiredSelect,
    space: validation.noSpace,
  };

  async acrheiveEvent(id: string) {
    this.isArchiving = true;
    await this.$store.dispatch("EventMetricAdmin/archiveCustomEvent", id);
    this.isArchiving = false;
    this.eventDeleteConfirmModal = false;
  }

  close() {
    this.name = this.event.name;
    this.description = this.event.description;
    this.isLeaderOrderDescending = this.event.isLeaderOrderDescending;
    this.editEvent = !this.editEvent;
  }

  save() {
    const isValid = this.from.validate();
    if (isValid) {
      if (
        this.name === this.event.name &&
        this.description === this.event.description &&
        this.isLeaderOrderDescending === this.event.isLeaderOrderDescending
      ) {
        this.$store.dispatch("alert/warning", "No changes made to the event.");
      } else {
        this.loading = true;
        this.$store.dispatch("EventMetricAdmin/updateCustomEvent", {
          id: this.event.id,
          name: this.name.trim(),
          description: this.description?.trim(),
          companyId: this.event.companyId,
          isLeaderOrderDescending: this.isLeaderOrderDescending,
          isArchived: this.event.isArchived,
          createdAt: this.event.createdAt,
        });
        this.editEvent = !this.editEvent;
        this.loading = false;
      }
    } else {
      this.$store.dispatch("alert/warning", "Please fulfill the requirements.");
    }
  }
}
