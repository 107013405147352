






























import { Component, Ref, Prop, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { noSpace, requiredString } from "@/tool/validation";
import { AddRoom, Room } from "@/api";

@Component
export default class RoomFormModal extends Vue {
  @Ref("form") form!: VForm;

  @Prop(String) title!: string;
  @Prop(String) action!: string;
  @Prop(Object) room!: Room;
  @Prop(Boolean) loader!: boolean;
  name = this.room?.name ? this.room.name : "";
  errorMessage = "";
  requiredTextInput = [requiredString, noSpace];

  close() {
    this.form.resetValidation();
    this.errorMessage = "";
    this.$emit("close");
  }

  validate() {
    this.errorMessage = "";
    let same = false;
    const isValid = this.form.validate();
    if (!isValid) same = true;
    if (this.name?.trim().length == 0) {
      this.errorMessage = "Required";
      same = true;
    }
    if (this.name?.trim() === this.room?.name.trim()) {
      this.errorMessage = "Title is same as previous";
      same = true;
    }
    return same;
  }

  async save() {
    if (!this.validate()) {
      let requestBody;
      try {
        if (this.title === "Add") {
          requestBody = {
            name: this.name,
          } as AddRoom;
        } else {
          requestBody = {
            id: this.room.id,
            name: this.name,
            floorId: this.room.floorId,
          } as Room;
        }
        this.$emit("save", requestBody);
      } catch (e) {
        console.log(e);
      }
    }
  }
}
