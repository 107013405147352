/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SurveyDataDetail = {
    properties: {
        survey_id: {
    type: 'number',
    isRequired: true,
},
        resident: {
    type: 'string',
    isRequired: true,
},
        question_id: {
    type: 'number',
    isRequired: true,
},
        question: {
    type: 'string',
    isRequired: true,
},
        data_id: {
    type: 'number',
    isRequired: true,
},
        comment: {
    type: 'string',
    isRequired: true,
},
        feedback: {
    type: 'string',
    isRequired: true,
},
        rating: {
    type: 'number',
    isRequired: true,
},
        created_at: {
    type: 'string',
    isRequired: true,
},
    },
};