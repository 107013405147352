
















import { Component, Vue } from "vue-property-decorator";
import LargeLogo from "@/components/LargeLogo.vue";

@Component({
  components: { LargeLogo },
})
export default class LoginRoot extends Vue {
  get isLogin() {
    return this.$route.path.includes("login");
  }
  back() {
    this.$router.back();
  }
}
