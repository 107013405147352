import moment from "moment";

function calculateActualPPD(
  spend: number,
  census: number,
  date: string,
): number {
  // Actual PPD - MTD Spend/ (days in month x census) = actual PPD
  const days = parseInt(
    moment(date)
      .endOf("month")
      .format("DD"),
  );
  return parseFloat((spend / (days * census)).toFixed(2));
}

export { calculateActualPPD };
