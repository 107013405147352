

































































































































































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { noSpace, requiredString } from "@/tool/validation";
import moment from "moment";
import { customHourMinAdder, dateFormat } from "@/common/date_format";
import { CalendarEvent } from "@/api";
import { selfData } from "@/common/role_utils";
import { CalEvent } from "@/common/CalendarEvent";

@Component
export default class EventForm extends Vue {
  @Ref("form") form!: VForm;
  @Prop() readonly propEvent!: CalEvent;
  @Prop() readonly isupdate!: boolean;

  event: CalEvent = JSON.parse(JSON.stringify(this.propEvent));
  startDatePicker = false;
  formattedStartDate = "";
  endDatePicker = false;
  formattedEndDate = "";
  minDate = moment()
    .local()
    .format("YYYY-MM-DD");
  startTimePicker = false;
  endTimePicker = false;
  commentError = "";
  textRules = [requiredString, noSpace];
  saving = false;
  colors = [
    {
      value: "green",
      text: "Green",
    },
    {
      value: "red",
      text: "Red",
    },
    {
      value: "blue",
      text: "Blue",
    },
    {
      value: "purple darken-3",
      text: "purple",
    },
    {
      value: "indigo darken-3",
      text: "Indigo",
    },
    {
      value: "cyan darken-3",
      text: "Cyan",
    },
    {
      value: "teal darken-3",
      text: "Teal",
    },
    {
      value: "amber darken-3",
      text: "Amber",
    },
    {
      value: "pink",
      text: "Pink",
    },
  ];

  created() {
    this.event.start = this.event.start.substring(0, 10);
    this.event.end = this.event.end.substring(0, 10);
    this.formatDate(this.event.start, true);
    this.formatDate(this.event.end, false);
  }

  formatDate(date: string, isStart: boolean) {
    if (!date) return;
    if (isStart) {
      this.formattedStartDate = dateFormat(date);
    } else {
      this.formattedEndDate = dateFormat(date);
    }
  }

  udpateTime() {
    this.event.startTime = "";
    this.event.endTime = "";
  }

  onEventSubmit() {
    const isValid = this.form.validate();
    if (isValid) {
      const startDate = customHourMinAdder(
        this.event.start,
        this.event.startTime || "",
      );
      const endDate = customHourMinAdder(
        this.event.end,
        this.event.endTime || "",
      );
      const payload: CalendarEvent = {
        name: this.event.name,
        color: this.event.color,
        description: this.event.description,
        startDate: startDate,
        endDate: endDate,
        startTime: this.event.startTime ? this.event.startTime : undefined,
        endTime: this.event.endTime ? this.event.startTime : undefined,
        timed: this.event.timed,
        companyId: selfData().companyId,
      };
      if (this.isupdate) {
        this.eventUpdate(payload);
      } else {
        this.eventSubmit(payload);
      }
    }
  }

  async eventUpdate(payload: CalendarEvent) {
    this.saving = true;
    if (this.propEvent.id) payload.id = this.propEvent.id;
    await this.$store.dispatch("calendar/updateEvent", payload);
    this.saving = false;
    this.close();
  }

  async eventSubmit(payload: CalendarEvent) {
    this.saving = true;
    await this.$store.dispatch("calendar/addEvent", payload);
    this.saving = false;
    this.close();
  }

  close() {
    this.form.reset();
    this.$emit("close");
  }
}
