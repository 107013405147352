/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ScoreboardEventBreakdown = {
    properties: {
        eventMetricId: {
    type: 'number',
    isRequired: true,
},
        eventMetricName: {
    type: 'string',
    isRequired: true,
},
        employeeScores: {
    type: 'array',
    contains: {
        type: 'ScoreboardEventBreakdownEmployeeScore',
    },
    isRequired: true,
},
        groupTotal: {
    type: 'number',
    isRequired: true,
},
        beginAt: {
    type: 'string',
    isRequired: true,
},
        endAt: {
    type: 'string',
    isRequired: true,
},
    },
};