export const getStatus = [
  {
    status: "New",
    id: 0,
  },
  {
    status: "Approved",
    id: 1,
  },
  {
    status: "Denied",
    id: 2,
  },
];

export const approvalStatus = [
  {
    status: "Approval Inprogress",
    id: 0,
  },
  {
    status: "Approve",
    id: 1,
  },
  {
    status: "Deny",
    id: 2,
  },
];

export const expiredStatus = [
  {
    status: "expired",
    id: 0,
    icon: "mdi-alert-circle",
  },
  {
    status: "not-expired",
    id: 1,
    icon: "mdi-alert-outline",
  },
  {
    status: "expired-after-1-week",
    id: 2,
    icon: "mdi-alert-outline",
  },
  {
    status: "expired-after-2-week",
    id: 3,
    icon: "mdi-alert-outline",
  },
];
export const postStatus = [
  {
    status: "Approved",
    id: 0,
  },
  {
    status: "Denied",
    id: 1,
  },
];
