/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TarfEditRequestBody = {
    properties: {
        adjustmentDateTime: {
    type: 'string',
    format: 'date-time',
},
        timeIn: {
    type: 'string',
    format: 'time',
},
        timeOut: {
    type: 'string',
    format: 'time',
},
        hoursWorked: {
    type: 'string',
    format: 'time',
},
        mealTimeIn: {
    type: 'string',
    format: 'time',
},
        mealTimeOut: {
    type: 'string',
    format: 'time',
},
    },
};