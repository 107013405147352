































































































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import Loading from "@/components/loader/loading.vue";
import { Beds, InspectionSummaries, Rooms } from "@/api";
import _ from "lodash";

@Component({
  components: {
    Loading,
  },
})
export default class Sumamry extends Vue {
  datePicker = false;
  range: string[] = [];
  loading = false;
  floorId = 0;
  inspectedBy = 0;
  roomId = 0;
  bedId = 0;
  expanded = [];
  headers = [
    {
      text: "Roon No.",
      align: "start",
      sortable: false,
      value: "room.name",
    },
    { text: "Bed No.", value: "bed.name" },
    { text: "Patient Name", value: "patient.name" },
    { text: "Inspected By", value: "inspected_by.name" },
    { text: "Date and Time Completed", value: "created_at" },
    { text: "", value: "data-table-expand" },
  ];

  async created() {
    this.loading = true;
    this.defaultDates();
    const floors = this.$store.dispatch("floor/fetchCompanyFloors");
    const summary = this.fetchSummary();
    const beds = this.$store.dispatch("bed/fetchCompanyBeds");
    const companyRooms = this.$store.dispatch("room/fetchCompanyRooms");
    const employees = this.$store.dispatch("directory/loadAllEmployees");

    await Promise.all([floors, summary, beds, companyRooms, employees]);
    this.loading = false;
  }

  defaultDates() {
    this.range = [
      moment()
        .startOf("week")
        .format("YYYY-MM-DD"),
      moment()
        .endOf("week")
        .format("YYYY-MM-DD"),
    ];
    this.floorId = 0;
    this.inspectedBy = 0;
    this.roomId = 0;
    this.bedId = 0;
  }

  get summary() {
    const list =
      (JSON.parse(
        JSON.stringify(this.$store.getters["inspection/summary"]),
      ) as InspectionSummaries) || [];
    const res: InspectionSummaries = [];
    _.forEach(list, item => {
      const idatas = _.sortBy(item.idata || [], ["order"], ["asc"]);
      const data = item;
      data.idata = idatas;
      res.push(data);
    });

    return res;
  }

  get floors() {
    return this.$store.getters["floor/floors"];
  }

  get beds() {
    const list =
      (JSON.parse(
        JSON.stringify(this.$store.getters["bed/companyBeds"]),
      ) as Beds) || [];
    if (!this.roomId) return list;
    return _.filter(list, item => item.roomId == this.roomId);
  }

  get employees() {
    const employeesList = this.$store.state.directory.employees ?? [];
    const list = _.map(
      _.orderBy(employeesList, [
        employee => `${employee.firstName} ${employee.lastName}`.toLowerCase(),
      ]),
      employee => ({
        ...employee,
        text: `${employee.firstName} ${employee.lastName}`,
      }),
    );

    return list;
  }

  get companyRooms() {
    const list =
      (JSON.parse(
        JSON.stringify(this.$store.getters["room/companyRooms"]),
      ) as Rooms) || [];
    if (!this.floorId) return list;
    return _.filter(list, item => item.floorId == this.floorId);
  }

  get formattedDates() {
    const [date1, date2] = this.range;
    let beginDate = "";
    let endDate = "";
    if (date1 < date2) {
      beginDate = date1;
      endDate = date2;
    } else {
      beginDate = date2;
      endDate = date1;
    }
    const formattedDate = this.range
      ? `${moment(beginDate).format("MMM DD")}  -  ${moment(endDate).format(
          "MMM DD",
        )}`
      : "";
    return formattedDate;
  }

  dateFilterChanged(type: string) {
    switch (type) {
      case "dates":
        this.floorId = 0;
        this.roomId = 0;
        this.bedId = 0;
        break;
      case "floor":
        this.roomId = 0;
        this.bedId = 0;
        break;
      case "room":
        this.bedId = 0;
        break;
      default:
        this.defaultDates();
        break;
    }
    this.fetchSummary();
  }

  async fetchSummary() {
    await this.$store.dispatch("inspection/fetchSummary", {
      beginAt: this.range[0],
      endAt: this.range[1],
      floorId: this.floorId ? this.floorId : undefined,
      inspectedBy: this.inspectedBy ? this.inspectedBy : undefined,
      roomId: this.roomId ? this.roomId : undefined,
      bedId: this.bedId ? this.bedId : undefined,
    });
  }

  formatTableDate(date: string) {
    return moment(date)
      .local()
      .format("MMM D, YYYY | hh:mma");
  }
}
