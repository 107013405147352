














































































import { Component, Vue } from "vue-property-decorator";
import ConfirmModal from "@/views/company_directory/ConfirmModal.vue";
import EditCompany from "@/views/super_admin/company/EditCompany.vue";

@Component({
  watch: {
    $route() {
      this.$store.dispatch(
        "superAdmin/selectCompany",
        this.$route.params.companyid,
      );
    },
  },
  components: {
    ConfirmModal,
    EditCompany,
  },
})
export default class CompanyDetail extends Vue {
  count = 0;
  companyId = "";
  delteConfirmation = false;
  updateCompany = false;
  deleting = false;

  get selectedCompany() {
    return this.$store.getters["superAdmin/selectedCompany"];
  }

  async deleteCompany() {
    this.delteConfirmation = !this.delteConfirmation;
    this.deleting = true;
    await this.$store.dispatch(
      "superAdmin/deleteCompany",
      this.selectedCompany.id,
    );
    this.deleting = false;
    this.$router.push({ path: "/company-list" });
  }

  backToList() {
    this.$router.push({ path: "/company-list" });
  }
}
