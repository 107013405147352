


















import { Component, Vue } from "vue-property-decorator";
import { isAdmin } from "@/common/role_utils";

@Component
export default class TarfLayout extends Vue {
  created() {
    if (this.$store.getters["isSuperAdmin"])
      this.$router.push({ path: "/company-list" });
  }
  get currentUser() {
    if (isAdmin()) {
      return false;
    } else {
      return true;
    }
  }
}
