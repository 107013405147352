/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InspectionData = {
    properties: {
        id: {
    type: 'number',
},
        inspectionId: {
    type: 'number',
},
        questionId: {
    type: 'number',
    isRequired: true,
},
        comment: {
    type: 'string',
},
        feedback: {
    type: 'string',
},
        ratingScore: {
    type: 'number',
},
        meet: {
    type: 'boolean',
},
    },
};