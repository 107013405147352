










































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Department, DepartmentCategory } from "@/api";
import { TableRow as ExpenseTableRow } from "@/common/ExpenseTableRow";
import { dateFormat } from "@/common/date_format";
import ExpenseForm from "../ExpenseForm.vue";
import FilterMobile from "../FilterMobile.vue";
import { formatCurrency } from "@/tool/formatCurrency";
import moment from "moment";

@Component({
  components: {
    ExpenseForm,
    FilterMobile,
  },
})
export default class CategoryReportMobile extends Vue {
  @Prop() census?: number;
  @Prop() budget?: number;
  @Prop() ppd?: number;
  @Prop() actualPPD?: number;
  @Prop() totalRemaining?: number;
  @Prop() departmentId?: number;
  @Prop() categoryId?: number;
  @Prop() expenses?: ExpenseTableRow[];

  dateFormat = dateFormat;
  key = 0;
  popDialog = false;
  filterPop = false;
  editData: ExpenseTableRow | null = null;
  formatCurrency = formatCurrency;
  currentMonth = moment().format("MMMM");

  editExpense(item: ExpenseTableRow) {
    this.key++;
    this.editData = item;
    this.popDialog = true;
  }

  addExpense() {
    this.key++;
    this.editData = null;
    this.popDialog = true;
  }

  fetchExpenses() {
    this.popDialog = false;
    this.$emit("fetch");
  }

  filterExpense({
    departmentId,
    categoryId,
    date,
  }: {
    departmentId: number;
    categoryId: number;
    date: string;
  }) {
    this.filterPop = false;
    this.currentMonth = moment(date).format("MMMM");
    this.$emit("filter", {
      departmentId,
      categoryId,
      date,
    });
  }

  get department() {
    let name = "";
    this.$store.getters["departmentAdmin/departments"]?.map(
      (department: Department) => {
        if (department.id === this.departmentId) {
          name = department.name;
        }
        return department;
      },
    );
    return name;
  }

  get category() {
    let name = "";
    const categoryList =
      this.$store.getters["departmentAdmin/categories"] || [];
    categoryList.map((category: DepartmentCategory) => {
      if (category.id == this.categoryId) {
        name = category.category;
      }
      return category;
    });
    return name;
  }
}
