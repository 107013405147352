/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $approvDenyEmployeeDocumentRequest = {
    properties: {
        approval: {
    type: 'number',
    isRequired: true,
},
    },
};