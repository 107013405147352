














import { Component, Vue } from "vue-property-decorator";
import TARFform from "@/components/tarf_form/TARFform.vue";
import loading from "@/components/loader/loading.vue";
import AdminTARFform from "@/components/tarf_form/AdminTARFfrom.vue";
import { isAdmin } from "@/common/role_utils";

@Component({
  components: {
    TARFform,
    loading,
    AdminTARFform,
  },
  watch: {
    async $route() {
      if (this.$route.params.id) {
        this.$data.isDataLoading = true;
        await this.$store.dispatch(
          "tarf/fetchSelectedTarf",
          this.$route.params.id,
        );
        this.$data.isDataLoading = false;
      }
    },
  },
})
export default class ProcessedRequest extends Vue {
  isDataLoading = false;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("tarf/getReasonList");
    await this.$store.dispatch("tarf/fetchSelectedTarf", this.$route.params.id);
    this.isDataLoading = false;
  }

  get selectedTarf() {
    return this.$store.getters["tarf/selectedTarf"];
  }

  get currentUser() {
    const superAdmin = this.$store.getters["isSuperAdmin"];
    if (isAdmin() || superAdmin) {
      return false;
    } else {
      return true;
    }
  }
}
