import { RouteConfig } from "vue-router";
import ChatList from "@/views/chat/ChatList.vue";
import Chat from "@/views/chat/Chat.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "Chat",
    components: {
      default: ChatList,
      chatList: ChatList,
      chat: Chat,
    },
    meta: {
      title: "Company Directory",
      requiresAuth: true,
    },
  },
  {
    path: ":id",
    name: "Chat",
    components: {
      default: Chat,
      chatList: ChatList,
      chat: Chat,
    },
    meta: {
      title: "Employee Detail",
      requiresAuth: true,
    },
  },
];

export default routes;
