type Resolver<T> = () => Promise<T>;

async function resolve<T>(resolver?: T | Resolver<T>): Promise<T | undefined> {
  if (typeof resolver === "function") {
    return (resolver as Resolver<T>)();
  }
  return resolver;
}

export default resolve;
