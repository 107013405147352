import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import _ from "lodash";
import { RootState } from "@/store/types";
import {
  EventLogService,
  LoggedEventList,
  ScoreboardBreakdown,
  ScoreboardEventBreakdown,
  ScoreboardService,
  ScoreboardSummary,
  ScoreboardSummaryScore,
  ToggleLoggedEventRequestBody,
} from "@/api";
import Vue from "vue";
import { monthStamp } from "@/tool/beginAtEndAt";
// eslint-disable-next-line
import { payload } from "@/tool/scoreboardParameter";

export interface ScoreboardState {
  scoreboardSummaries: ScoreboardSummary[] | null;
  isLoading: boolean;
  loadingError: string | null;
  departmentScoreboardBreakdowns: {
    [departmentId: number]: ScoreboardBreakdown;
  };
  companyScoreboardBreakdown: ScoreboardEventBreakdown | null;
  employeeScoreboardBreakdown: LoggedEventList | null;
  companyDepartmentList: ScoreboardSummary[] | null;
  trackedEvents: ScoreboardSummaryScore[] | null;
  scoreboardFiltered: ScoreboardSummary[] | null;
  activeValue: number | null;
  startDate: string | null;
  endDate: string | null;
}

const state: ScoreboardState = {
  scoreboardSummaries: null,
  isLoading: false,
  loadingError: null,
  departmentScoreboardBreakdowns: {},
  companyScoreboardBreakdown: null,
  employeeScoreboardBreakdown: null,
  companyDepartmentList: null,
  trackedEvents: null,
  scoreboardFiltered: null,
  activeValue: -1,
  startDate: null,
  endDate: null,
};

const getters: GetterTree<ScoreboardState, RootState> = {
  scoreboards: state => {
    return state.scoreboardSummaries ?? [];
  },
  departmentBreakdown: state => {
    return state.departmentScoreboardBreakdowns;
  },
  companyBreakdown: state => state.companyScoreboardBreakdown,
  employeeBreakdown: state => state.employeeScoreboardBreakdown,
  companyDepartmentList: state => state.companyDepartmentList,
  trackedEvents: state => state.trackedEvents,
  activeValue: state => state.activeValue,
  startDate: state => state.startDate,
  endDate: state => state.endDate,
};

const mutations: MutationTree<ScoreboardState> = {
  FETCHING_SCOREBOARDS: (state, isFetching: boolean) => {
    state.isLoading = isFetching;
  },
  SCOREBOARDS_UPDATED: (state, scoreboards: ScoreboardSummary[]) => {
    const departmentScoreboard = _.sortBy(
      scoreboards.filter(scoreboard => {
        if (scoreboard.departmentId !== null) {
          return scoreboard;
        }
      }),
      [scoreboard => scoreboard.title.toLowerCase()],
      ["asc"],
    );
    const companyScoreboard = scoreboards.filter(s => {
      if (s.departmentId === null) {
        return s;
      }
    });
    const orderedScoreboard = [...companyScoreboard, ...departmentScoreboard];
    const orderSummary = (summary: Array<ScoreboardSummaryScore>) => {
      const fiveStar = summary.filter(summary => {
        if (summary.eventMetricId === -1) {
          return summary;
        }
      });
      const standardEvent = _.orderBy(
        summary.filter(summary => {
          if (summary.eventMetricId < 0 && summary.eventMetricId !== -1) {
            return summary;
          }
        }),
        event => event.eventName.toLowerCase(),
        ["asc"],
      );
      const otherEvent = _.orderBy(
        summary.filter(summary => {
          if (summary.eventMetricId > 0) {
            return summary;
          }
        }),
        event => event.eventName.toLowerCase(),
        ["asc"],
      );
      return _.map([...fiveStar, ...standardEvent, ...otherEvent], event => {
        if (event.eventMetricId === -1 && event.isPoster) {
          event.eventMetricId = -111;
        }
        return event;
      }) as Array<ScoreboardSummaryScore>;
    };
    state.scoreboardSummaries = orderedScoreboard.map(score => {
      return {
        ...score,
        summaryScores: orderSummary(score.summaryScores),
      };
    });
    state.scoreboardFiltered = [...state.scoreboardSummaries];
  },
  UPDATE_TRACKED_EVENTS: (state, scoreboards) => {
    if (scoreboards) {
      const [companyEvent] = _.filter(
        scoreboards,
        scoreboard =>
          scoreboard.title.toLowerCase() === "company".toLowerCase(),
      );
      state.trackedEvents = _.concat(
        _.map(companyEvent.summaryScores, event => {
          if (event.isPoster) {
            event.eventMetricId = -111;
            event.eventName = "5 Star Moment Giver";
          }
          if (event.eventMetricId === -1 && !event.isPoster) {
            event.eventName = "5 Star Moment Reciever";
          }
          return event;
        }),
      );
    }
  },
  UPDATE_SCOREBOARDS_FAILED: (state, error: string) => {
    state.isLoading = false;
    state.loadingError = error;
  },
  DEPARTMENT_SCOREBOARD_BREAKDOWN_UPDATED: (
    state,
    update: DepartmentScoreboardBreakdownUpdate,
  ) => {
    Vue.set(
      state.departmentScoreboardBreakdowns,
      update.departmentId,
      update.breakdown,
    );
  },
  COMPANY_SCOREBOARD_BREAKDOWN_UPDATE: (state, companyBreakdown) => {
    state.companyScoreboardBreakdown = companyBreakdown;
  },
  EMPLOYEE_SCOREBOARD_BREAKDOWN_UPDATE: (state, logs) => {
    state.employeeScoreboardBreakdown = logs;
  },
  FILTER_SCOREBOARDS: (state, findex) => {
    state.scoreboardSummaries?.splice(findex, 1);
  },
  FILTER_ADD_SCOREBOARDS: (state, findex) => {
    state.scoreboardSummaries?.unshift(...findex);
  },
  CLEAR_ALL_BREAKDOWN: state => {
    state.companyScoreboardBreakdown = null;
    state.departmentScoreboardBreakdowns = {};
    state.employeeScoreboardBreakdown = null;
  },
  FILTER_REMOVE_EVENT: (state, eventId) => {
    const filteredEvents = _.map(state.scoreboardSummaries, score => {
      return {
        ...score,
        summaryScores: score.summaryScores.filter(event => {
          if (event.eventMetricId !== eventId && event.eventMetricId) {
            return event;
          }
        }),
      };
    });
    state.scoreboardSummaries = [...filteredEvents];
  },
  FILTER_ADD_EVENT: (state, eventId) => {
    const scores = (departmentId: number) => {
      const [department] = _.filter(
        state.scoreboardFiltered,
        scoreFilter => scoreFilter.departmentId === departmentId,
      );
      const [event] = department.summaryScores.filter(eventFilter => {
        if (eventFilter.eventMetricId === eventId && eventId) {
          return eventFilter;
        }
      });
      return event;
    };
    state.scoreboardSummaries = _.map(state.scoreboardSummaries, score => {
      return {
        ...score,
        summaryScores: [
          ...score.summaryScores,
          scores(score.departmentId),
        ].filter(event => {
          if (event) {
            return event;
          }
        }),
      };
    });
  },
  SET_ACTIVE_VALUE: (state, value) => {
    state.activeValue = value;
  },
  SET_START_DATE: (state, startDate) => {
    state.startDate = startDate;
  },
  SET_END_DATE: (state, endDate) => {
    state.endDate = endDate;
  },
};

const actions: ActionTree<ScoreboardState, RootState> = {
  fetchCompanyScoreboards: async (
    { commit, rootState },
    filteredDate = null,
  ) => {
    const companyId = rootState.appContext.company?.id;
    let beginAt;
    let endAt;

    if (!filteredDate) {
      const dateStamp = monthStamp();
      beginAt = dateStamp.beginAt;
      endAt = dateStamp.endAt;
    } else {
      beginAt = new Date(filteredDate.startDate).toISOString().substr(0, 10);
      endAt = new Date(filteredDate.endDate).toISOString().substr(0, 10);
    }

    if (companyId) {
      try {
        commit("FETCHING_SCOREBOARDS", true);
        const scoreboards = await ScoreboardService.listScoreboards({
          companyId: companyId,
          beginAt,
          endAt,
        });
        commit("FETCHING_SCOREBOARDS", false);
        commit("SCOREBOARDS_UPDATED", scoreboards.scoreboards);
        commit("UPDATE_TRACKED_EVENTS", scoreboards.scoreboards);
      } catch (e) {
        console.log(`got error: ${e}`);
        commit("UPDATE_SCOREBOARDS_FAILED", e);
      }
    }
  },
  loadDepartmentBreakdown: async (
    { commit, rootState },
    {
      departmentId,
      eventMetricId,
      groupByPoster,
      beginDate,
      endDate,
    }: {
      departmentId: number;
      eventMetricId: number;
      groupByPoster: boolean | undefined;
      beginDate?: string | number | Date;
      endDate?: string;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    let beginAt;
    let endAt;
    if (!beginDate && !endDate) {
      const dateStamp = monthStamp();
      beginAt = dateStamp.beginAt;
      endAt = dateStamp.endAt;
    } else {
      if (beginDate && endDate) {
        beginAt = new Date(beginDate).toISOString().substr(0, 10);
        endAt = new Date(endDate).toISOString().substr(0, 10);
      }
    }
    if (companyId) {
      try {
        const scoreboardBreakdown = await ScoreboardService.getEventDepartmentBreakdown(
          {
            companyId,
            eventMetricId,
            departmentId,
            groupByPoster,
            beginAt,
            endAt,
          },
        );
        commit("DEPARTMENT_SCOREBOARD_BREAKDOWN_UPDATED", {
          departmentId,
          breakdown: scoreboardBreakdown,
        } as DepartmentScoreboardBreakdownUpdate);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  loadCompanyBreakdown: async (
    { commit, rootState },
    {
      eventMetricId,
      groupByPoster,
      beginDate,
      endDate,
    }: {
      eventMetricId: number;
      groupByPoster: boolean | undefined;
      beginDate?: string | number | Date;
      endDate?: string;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    let beginAt;
    let endAt;
    if (!beginDate && !endDate) {
      const dateStamp = monthStamp();
      beginAt = dateStamp.beginAt;
      endAt = dateStamp.endAt;
    } else {
      if (beginDate && endDate) {
        beginAt = new Date(beginDate).toISOString().substr(0, 10);
        endAt = new Date(endDate).toISOString().substr(0, 10);
      }
    }
    if (companyId) {
      try {
        const companyBreakdown = await ScoreboardService.getEventCompanyBreakdown(
          {
            companyId,
            eventMetricId,
            groupByPoster,
            beginAt,
            endAt,
          },
        );
        commit("COMPANY_SCOREBOARD_BREAKDOWN_UPDATE", companyBreakdown);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  loadEmployeeBreakdown: async ({ commit, rootState }, payload: payload) => {
    const companyId = rootState.appContext.company?.id;

    if (payload.beginDate && payload.endDate) {
      payload.beginDate = new Date(payload.beginDate)
        .toISOString()
        .substring(0, 10);
      payload.endDate = new Date(payload.endDate)
        .toISOString()
        .substring(0, 10);
    } else {
      const dateStamp = monthStamp();
      payload.beginDate = dateStamp.beginAt;
      payload.endDate = dateStamp.endAt;
    }
    if (companyId) {
      try {
        const logs = await EventLogService.listLoggedEvents({
          companyId,
          employeeId: payload.employeeId,
          departmentId: payload.departmentId,
          postedByEmployeeDepartmentId: payload.postedByEmployeeDepartmentId,
          postedByEmployeeId: payload.postedByEmployeeId,
          eventMetricId: payload.eventMetricId,
          beginAt: payload.beginDate,
          endAt: payload.endDate,
        });
        commit("EMPLOYEE_SCOREBOARD_BREAKDOWN_UPDATE", logs);
      } catch (e) {
        console.log(e);
      }
    }
  },
  filterScoreboards: ({ commit }, findex: number) => {
    try {
      commit("FILTER_SCOREBOARDS", findex);
    } catch (e) {
      console.log(e);
    }
  },
  addFilterScoreboards: ({ commit }, findex: ScoreboardSummary) => {
    try {
      commit("FILTER_ADD_SCOREBOARDS", findex);
    } catch (e) {
      console.log(e);
    }
  },
  clearBreakdown: ({ commit }) => {
    try {
      commit("CLEAR_ALL_BREAKDOWN");
    } catch (e) {
      console.log(e);
    }
  },
  removeEvent: ({ commit }, eventId) => {
    if (eventId) {
      commit("FILTER_REMOVE_EVENT", eventId);
    }
  },
  addEvent: ({ commit }, eventId) => {
    if (eventId) {
      commit("FILTER_ADD_EVENT", eventId);
    }
  },
  setValue: ({ commit }, value) => {
    commit("SET_ACTIVE_VALUE", value);
  },
  setStartEndDate: (
    { commit },
    dates: { startDate: string; endDate: string },
  ) => {
    if (!dates.startDate || !dates.endDate) {
      commit("SET_START_DATE", dates.startDate);
      commit("SET_END_DATE", dates.endDate);
    }
    commit("SET_START_DATE", dates.startDate);
    commit("SET_END_DATE", dates.endDate);
  },
  toggleEvent: async (
    { rootState },
    body: { eventId: number; requestBody: ToggleLoggedEventRequestBody },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) throw new Error("CompanyId is not found.");
    try {
      await EventLogService.toggleEmployeeEvent({
        companyId,
        eventId: body.eventId,
        requestBody: body.requestBody,
      });
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
    }
  },
};

interface DepartmentScoreboardBreakdownUpdate {
  departmentId: number;
  breakdown: ScoreboardEventBreakdown;
}

export const scoreboard: Module<ScoreboardState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
