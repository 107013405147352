/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LoggedEventList = {
    properties: {
        loggedEvents: {
    type: 'array',
    contains: {
        type: 'LoggedEvent',
    },
    isRequired: true,
},
        total: {
    type: 'number',
},
    },
};