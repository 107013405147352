/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TarfRequestsGroupedYear = {
    properties: {
        year: {
    type: 'number',
    isRequired: true,
},
        months: {
    type: 'array',
    contains: {
        type: 'TarfRequestsGroupedMonth',
    },
    isRequired: true,
},
    },
};