















































































































































































































































































































































import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { ActiveSurveyForm, SurveyRequest } from "@/api";
import { VForm } from "@/common/vuetify_types";
import { SurveyFormCollection } from "@/common/InspectionDataCollection";
import { dateFormat } from "@/common/date_format";
import _ from "lodash";
import { selfData } from "@/common/role_utils";
import {
  maxLenInt,
  noSpace,
  numberOnly,
  requiredSelectWithZero,
  requiredString,
} from "@/tool/validation";
import ConfirmModule from "@/components/confirmationModule/ConfirmationModule.vue";
import moment from "moment";

@Component({
  components: {
    ConfirmModule,
  },
})
export default class SurveyForm extends Vue {
  @Prop() readonly isAdmission!: boolean;
  @Prop() readonly title!: string;

  @Ref("initialForm") initialForm!: VForm;
  @Ref("form") form!: VForm;

  step = 0;
  self = selfData();
  submitting = false;
  confirmClose = false;
  dischargeDatePicker = false;
  admissionDatePicker = false;
  admissionDate = "";
  dischargeDate = "";
  textRules = [requiredString, noSpace];
  selectRules = [requiredSelectWithZero];
  numberRules = [numberOnly];
  phoneRules = [
    requiredString,
    numberOnly,
    noSpace,
    (v: string) => maxLenInt(v, 10),
  ];

  minDate = moment()
    .local()
    .format("YYYY-MM-DD");
  surveyData: SurveyFormCollection[] = [];
  payload: SurveyRequest = {
    survey: {
      companyId: 0,
      formId: 0,
      roomId: 0,
      resident: "",
      phone: "",
      completedBy: "",
    },
    physician: {
      companyId: 0,
      name: "",
    },
    data: [],
  };
  commentError = "";
  feedbackError = "";
  meetError = "";
  ratingError = "";
  completedByOption = ["Family", "Friends", "Patient"];
  completedBy = "";

  async created() {
    await this.$store.dispatch("room/fetchCompanyRooms");
  }

  get companyRooms() {
    return this.$store.getters["room/companyRooms"] || [];
  }

  get questionLists() {
    const form = this.isAdmission
      ? (this.$store.getters["survey/activeAdmissionForm"] as ActiveSurveyForm)
      : (this.$store.getters["survey/activeDischargeForm"] as ActiveSurveyForm);
    this.payload.survey.formId = form.id;
    let index = 1;
    this.surveyData = [];
    _.sortBy(form?.questions || [], ["order"], ["desc"]);
    _.forEach(form?.questions || [], question => {
      const data = {
        index: index,
        question: question,
        data: {
          questionId: question.id,
          comment: undefined,
          feedback: undefined,
          ratingScore: 0,
        },
      };
      this.surveyData.push(JSON.parse(JSON.stringify(data)));
      index++;
    });
    return this.surveyData;
  }

  formatDate(date: string) {
    if (this.isAdmission) {
      this.payload.survey.admissionDate = dateFormat(date);
    } else {
      this.payload.survey.dischargeDate = dateFormat(date);
    }
  }

  nextInspection() {
    const valid = this.initialForm.validate();
    if (valid) {
      this.payload.survey.companyId = this.self.companyId;
      this.payload.physician.companyId = this.self.companyId;
      this.payload.survey.completedBy = this.completedBy;
      this.payload.survey.duration = Number(this.payload.survey.duration);
      this.step++;
    }
  }

  clearError() {
    this.commentError = "";
    this.feedbackError = "";
    this.meetError = "";
    this.ratingError = "";
  }

  validate(item: SurveyFormCollection): boolean {
    this.clearError();
    let valid = true;
    if (!item.data.skipRating && !item.data.ratingScore) {
      this.meetError = "Give 1-5 rating or select checkbox for skip.";
      valid = false;
    }
    return valid;
  }

  next(item: SurveyFormCollection) {
    const valid = this.validate(item);
    if (valid) this.step++;
  }

  async submit(item: SurveyFormCollection) {
    const valid = this.validate(item);
    if (valid) {
      this.submitting = !this.submitting;
      this.payload.data = [];
      _.forEach(this.surveyData, item => {
        if (item?.data.skipRating) item.data.ratingScore = 0;
        this.payload.data.push({
          questionId: item.data.questionId,
          comment: item.data.comment,
          feedback: item.data.feedback,
          ratingScore: item.data.ratingScore,
        });
      });
      await this.$store.dispatch("survey/submitSurvey", {
        requestBody: this.payload,
        dispatchAdmission: this.isAdmission,
      });
      this.submitting = !this.submitting;
      this.step++;
    }
  }

  close() {
    this.$emit("close");
  }

  complete() {
    this.$emit("complete");
  }

  recognize() {
    this.$emit("recognize");
  }
}
