






















































































import { Component, Ref, Vue } from "vue-property-decorator";
import LargeLogo from "../../components/LargeLogo.vue";
import auth from "@/auth";
import { VForm } from "@/common/vuetify_types";
import { Company } from "@/api";
import * as validation from "@/tool/validation";

@Component({
  components: { LargeLogo },
})
export default class EmailLogin extends Vue {
  @Ref("form") form!: VForm;
  emailOrId = "";
  password = "";
  snackbar = false;
  errorText = "";
  loading = false;
  companyCode = "";
  isPasswordVisible = false;
  rules = {
    word: validation.stringOnly,
    required: validation.requiredString,
    idValid: validation.emailOrIdValid,
  };

  get companyInfo(): Company {
    return this.$store.getters["getSelectedCompany"];
  }

  async signIn() {
    const isValid = this.form.validate();
    if (isValid) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "selectCompanyByCode",
          this.companyCode.trim(),
        );
        if (this.companyInfo) {
          if (/^[0-9]+$/.test(this.emailOrId.toString().trim())) {
            await auth.loginWithEmployeeID(
              this.companyInfo.id,
              this.emailOrId.trim(),
              this.password,
            );
          } else {
            await auth.loginWithEmailAndPassword(
              this.emailOrId.trim(),
              this.password,
            );
          }
          const employee = await this.$store.dispatch("getMe");
          const isEmployee = this.$store.getters.isSuperAdmin;
          if (employee?.companyId === this.companyInfo.id) {
            if (!isEmployee) {
              await this.$router.replace({ path: "/" });
            } else {
              this.$store.dispatch("clearState");
              await auth.logout();
              await this.$router.replace({ path: "/login" });
              this.$store.dispatch(
                "alert/error",
                "User cannot login as Employee",
              );
            }
          } else {
            this.$store.dispatch("clearState");
            await auth.logout();
            this.showError(
              "Entered Company code does not match with employee company code.",
            );
            this.loading = false;
            return;
          }
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        if (e instanceof Error) {
          if (e.message.includes("user record") || e.message.includes("Found"))
            this.showError("Invalid user ID/Email");
          else if (e.message.includes("password"))
            this.showError("Password didn't match.");
          else this.showError(e.message);
        }
      }
    }
  }

  showError(text: string) {
    this.errorText = text;
    this.snackbar = true;
  }

  goTo(path: string) {
    this.$router.push({ path });
  }
}
