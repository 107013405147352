






























































































































import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import { ScoreboardEventBreakdownEmployeeScore } from "@/api/models/ScoreboardEventBreakdownEmployeeScore";
import loading from "@/components/loader/loading.vue";
import { addDayOnDate, dateFormat } from "@/common/date_format";
@Component({
  components: {
    loading,
  },
})
export default class ScoreboardSummaryCard extends Vue {
  title = this.$route.query.title;
  sortList = true;
  startDate: string | null = null;
  endDate: string | null = null;
  formErrors = "";
  showStartDate = false;
  showEndDate = false;
  formattedStartDate = "";
  formattedEndDate = "";
  initialStartDate: string | null = null;
  initialEndDate: string | null = null;
  poster = false;

  created() {
    if (this.stateStartDate) {
      this.startDate = this.stateStartDate;
      this.initialStartDate = this.stateStartDate;
    }
    if (this.stateEndDate) {
      this.endDate = this.stateEndDate;
      this.initialEndDate = this.stateEndDate;
    }
    if (this.$route.query.isPoster === "true") {
      this.poster = true;
    }
    if (this.$route.query.title === "Company") {
      this.$store.dispatch("scoreboard/loadCompanyBreakdown", {
        eventMetricId: this.$route.params.eventId,
        groupByPoster: this.poster,
        beginDate: this.startDate,
        endDate: addDayOnDate(this.endDate || ""),
      });
    } else {
      this.$store.dispatch("scoreboard/loadDepartmentBreakdown", {
        departmentId: this.$route.query.deptId,
        eventMetricId: this.$route.params.eventId,
        groupByPoster: this.poster,
        beginDate: this.startDate,
        endDate: addDayOnDate(this.endDate || ""),
      });
    }
  }

  get stateStartDate() {
    return this.$store.getters["scoreboard/startDate"];
  }

  get stateEndDate() {
    return this.$store.getters["scoreboard/endDate"];
  }

  formatDate(date: string, isStartDate: boolean) {
    if (isStartDate) this.formattedStartDate = dateFormat(date);
    else this.formattedEndDate = dateFormat(date);
  }
  get breakDownList() {
    let list;
    if (this.$route.query.title === "Company") {
      list = this.$store.getters["scoreboard/companyBreakdown"];
    } else {
      const deptlist = this.$store.getters["scoreboard/departmentBreakdown"];
      list = deptlist[this.$route.query.deptId?.toString()];
    }
    return list;
  }

  get employeeScores() {
    const list = this.breakDownList.employeeScores;
    return this.sortList ? list : _.reverse(list);
  }

  get employeeTotal() {
    const totals = this.breakDownList.employeeScores.map(
      (employee: ScoreboardEventBreakdownEmployeeScore) => employee.total,
    );
    const total = totals.reduce((a: number, b: number) => {
      return a + b;
    }, 0);
    return total;
  }
  backToDetail() {
    this.$router.back();
  }
  employeeBreakDown(i: number, firstName: string) {
    this.$router.push({
      path: `/scoreboard/breakdown/${this.$route.params.eventId}`,
      query: {
        title: firstName,
        eventName: this.breakDownList.eventMetricName,
        empId: i.toString(),
        deptId: this.$route.query.deptId,
        isPoster: this.$route.query.isPoster,
      },
    });
  }
  giverOrReceiver() {
    if (this.breakDownList?.eventMetricName === "5 Star Moment") {
      if (this.$route.fullPath === "/scoreboard/-1?title=Company&isPoster=true")
        return "Giver";
      else return "Receiver";
    }
  }
  companyOrDepartmentBreakdown() {
    this.$router.push({
      path: `/scoreboard/breakdown/${this.$route.params.eventId}`,
      query: {
        title: this.title,
        eventName: this.breakDownList.eventMetricName,
        deptId: this.$route.query.deptId,
        isPoster: this.$route.query.isPoster,
      },
    });
  }
  async selectedDate() {
    if (this.startDate && this.endDate) {
      const filteredDate = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.startDate < this.endDate) {
        this.formErrors = "";
        try {
          await this.$store.dispatch(
            "scoreboard/setStartEndDate",
            filteredDate,
          );
          if (
            this.startDate !== this.initialStartDate ||
            this.endDate !== this.initialEndDate
          ) {
            this.$store.dispatch("scoreboard/fetchCompanyScoreboards", {
              startDate: filteredDate.startDate,
              endDate: addDayOnDate(filteredDate.endDate),
            });
          }
          if (this.$route.query.deptId) {
            this.$store.dispatch("scoreboard/loadDepartmentBreakdown", {
              departmentId: this.$route.query.deptId,
              eventMetricId: this.$route.params.id,
              groupByPoster: this.$route.query.isPoster,
              beginDate: filteredDate.startDate,
              endDate: addDayOnDate(filteredDate.endDate),
            });
          } else {
            this.$store.dispatch("scoreboard/loadCompanyBreakdown", {
              eventMetricId: this.$route.params.eventId,
              groupByPoster: this.$route.query.isPoster,
              beginDate: filteredDate.startDate,
              endDate: addDayOnDate(filteredDate.endDate),
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.formErrors = "start date should not be greater than end date";
      }
    } else {
      this.formErrors = "require both dates";
    }
  }

  getEmployeeName(employee: ScoreboardEventBreakdownEmployeeScore): string {
    const anynomous = ["Family", "Visitor", "Patient"];
    const name = anynomous.find(name => name == employee.nonEmployeeName);
    if (this.poster) {
      if (name) return name;
      return `${employee.nonEmployeeName} - ${employee.title} - ${employee.primaryDepartmentName}`;
    }
    return `${employee.employeeFirstName} ${employee.employeeLastName} - ${employee.title} - ${employee.primaryDepartmentName}`;
  }

  destroyed() {
    this.$store.dispatch("scoreboard/clearBreakdown");
    if (!this.$route.path.includes("scoreboard"))
      this.$store.dispatch("scoreboard/setStartEndDate", {
        startDate: null,
        endDate: null,
      });
  }
}
