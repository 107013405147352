import { RouteConfig } from "vue-router";
import FormMenu from "@/views/forms/employee/FormMenu.vue";
import EmployeeLayout from "@/views/forms/employee/EmployeeLayout.vue";
import SupervisorLayout from "@/views/forms/supervisor/SupervisorLayout.vue";
import NewForm from "@/views/forms/employee/NewForm.vue";
import SupervisorMenu from "@/views/forms/supervisor/SupervisorMenu.vue";
import RequestsList from "@/views/forms/supervisor/RequestsList.vue";
import NewRequest from "@/views/forms/supervisor/NewRequest.vue";
import ApprovedRequest from "@/views/forms/supervisor/ExcusedRequest.vue";
import DeniedRequest from "@/views/forms/supervisor/UnexcusedRequest.vue";
import ProcessedRequest from "@/views/forms/supervisor/ProcessedRequest.vue";
import DenyRequest from "@/views/forms/supervisor/DenyRequest.vue";
import ResubmitRequest from "@/views/forms/supervisor/ResubmitRequest.vue";
import TARFform from "@/views/forms/supervisor/TARFform.vue";

const routes: Array<RouteConfig> = [
  {
    path: "employee",
    components: {
      default: EmployeeLayout,
      employeeLayout: EmployeeLayout,
      supervisorLayout: SupervisorLayout,
    },
    meta: {
      title: "TARF Form",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "employeeTarf",
        components: {
          default: FormMenu,
          tool: FormMenu,
        },
        meta: {
          title: "TARF Form",
          requiresAuth: true,
        },
      },
      {
        path: "request",
        components: {
          default: NewForm,
          tool: FormMenu,
          newrequest: NewForm,
        },
        meta: {
          title: "TARF Request",
          requiresAuth: true,
        },
      },
      {
        path: "new/:id",
        components: {
          default: NewRequest,
          tool: FormMenu,
          newrequest: NewRequest,
        },
        meta: {
          title: "New TARF",
          requiresAuth: true,
        },
      },
      {
        path: "excused/:id",
        components: {
          default: ApprovedRequest,
          tool: FormMenu,
          approvedrequest: ApprovedRequest,
        },
        meta: {
          title: "Excused TARF",
          requiresAuth: true,
        },
      },
      {
        path: "unexcused/:id",
        components: {
          default: DeniedRequest,
          tool: FormMenu,
          deniedrequest: DeniedRequest,
        },
        meta: {
          title: "Unexcused TARF",
          requiresAuth: true,
        },
      },
      {
        path: "denied/:id",
        components: {
          default: DenyRequest,
          tool: FormMenu,
          denyrequest: DenyRequest,
        },
        meta: {
          title: "Denied TARF",
          requiresAuth: true,
        },
      },
      {
        path: "resubmit/:id",
        components: {
          default: ResubmitRequest,
          tool: FormMenu,
          resubmitrequest: ResubmitRequest,
        },
        meta: {
          title: "Resubmit TARF",
          requiresAuth: true,
        },
      },
      {
        path: "processed/:id",
        components: {
          default: NewRequest,
          tool: FormMenu,
          newrequest: NewRequest,
          approvedrequest: ApprovedRequest,
          deniedrequest: DeniedRequest,
          processedrequest: ProcessedRequest,
          denyrequest: DenyRequest,
          resubmitrequest: ResubmitRequest,
        },
        meta: {
          title: "Processed TARF",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "supervisor",
    components: {
      default: SupervisorLayout,
      employeeLayout: EmployeeLayout,
      supervisorLayout: SupervisorLayout,
    },
    meta: {
      title: "TARF Form",
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "supervisorTarf",
        components: {
          default: SupervisorMenu,
          supervisorMenu: SupervisorMenu,
        },
        meta: {
          title: "TARF Form",
          requiresAuth: true,
        },
      },
      {
        path: "form",
        components: {
          default: TARFform,
          supervisorMenu: SupervisorMenu,

          TARFform: TARFform,
        },
        meta: {
          title: "TARF Request",
          requiresAuth: true,
        },
      },
      {
        path: "list/:status",
        components: {
          default: RequestsList,
          supervisorMenu: SupervisorMenu,
          requestList: RequestsList,
        },
        meta: {
          title: "TARF list",
          requiresAuth: true,
        },
      },
      {
        path: "list/:status/:id",
        components: {
          default: NewRequest,
          supervisorMenu: SupervisorMenu,
          requestList: RequestsList,
          newRequest: NewRequest,
          approvedRequest: ApprovedRequest,
          deniedRequest: DeniedRequest,
          processedRequest: ProcessedRequest,
          denyRequest: DenyRequest,
          resubmitRequest: ResubmitRequest,
          TARFform: TARFform,
        },
        meta: {
          title: "TARF Form",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
