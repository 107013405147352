
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { ScoreboardSummary, ScoreboardSummaryScore } from "@/api";

@Component
export default class ScoreboardSummaryCard extends Vue {
  @Prop(Object) summary!: ScoreboardSummary;

  viewBreakDown(score: ScoreboardSummaryScore, isPoster: boolean | null) {
    let poster = "false";
    if (isPoster) {
      poster = "true";
    }
    const eventMetricId = () => {
      if (score.eventMetricId === -111) {
        return "-1";
      } else {
        return score.eventMetricId.toString();
      }
    };
    this.$router.push({
      path: `/scoreboard/${eventMetricId()}`,
      query: {
        title: this.summary.title,
        deptId: this.summary.departmentId?.toString(),
        isPoster: poster,
      },
    });
  }

  who(isPoster: boolean) {
    if (isPoster) {
      return "Giver";
    } else {
      return "Receiver";
    }
  }
}
