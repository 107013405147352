import Vue from "vue";
import Vuetify from "vuetify";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

Vue.component("font-awesome-icon", FontAwesomeIcon); // Register component globally
library.add(fas, far); // Include needed icons
Vue.config.productionTip = false;

Vue.use(Vuetify);

const CUSTOM_ICONS = {
  addressBook: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "address-book"],
    },
  },
  tick: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "check-double"],
    },
  },
  thList: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "th-list"],
    },
  },
  star: {
    component: FontAwesomeIcon,
    props: {
      icon: ["far", "star"],
    },
  },
  cog: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "cog"],
    },
  },
  plus: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "plus"],
    },
  },
  cake: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "birthday-cake"],
    },
  },
  share: {
    component: FontAwesomeIcon,
    props: {
      icon: ["far", "share-square"],
    },
  },
  search: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "search"],
    },
  },
  doc: {
    // used for the nav-icon by vuetify
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "file-alt"],
    },
  },
  pdfDownload: {
    component: FontAwesomeIcon,
    props: {
      icon: ["fas", "file-download"],
    },
  },
};

export default new Vuetify({
  icons: {
    iconfont: "faSvg",
    values: CUSTOM_ICONS,
  },
});
