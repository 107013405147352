var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-8 py-8",staticStyle:{"height":"100%"}},[(_vm.loading)?[_c('Loading',{attrs:{"loading":_vm.loading,"size":70,"width":7}})]:[_c('div',[_c('v-btn',{attrs:{"fab":"","text":""},on:{"~click":function($event){return _vm.back($event)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-keyboard-backspace ")])],1)],1),_c('div',{staticClass:"d-flex flex-fill align-center flex-wrap",class:{
        'justify-center mb-2': _vm.$vuetify.breakpoint.smAndDown,
        'justify-space-between': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"font-weight-bold text-grey title"},[_vm._v("Survey")]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('StartSurvey',{attrs:{"isAdmission":_vm.isAdmission},on:{"complete":_vm.complete}})],1)]),_c('div',{staticClass:"mb-12 title",class:{ 'text-center': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.details)?_vm._l((_vm.details),function(room){return _c('div',{key:room.id,staticClass:"mb-12"},[_c('div',[_vm._v("Room #"+_vm._s(room.name))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":room.details,"items-per-page":10,"item-class":"my-1 pa-2"},scopedSlots:_vm._u([{key:"item.reported",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.comment",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.comment ? item.comment : "N/A")+" ")])]}}],null,true)})],1)}):[_vm._m(0)]]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"text-grey font-weight-bold"},[_vm._v("No Data Available")])])}]

export { render, staticRenderFns }