/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetRecentlyJoinedEmployeesResponseBody = {
    properties: {
        employees: {
    type: 'array',
    contains: {
        type: 'AnniversaryEmployeeInfo',
    },
    isRequired: true,
},
    },
};