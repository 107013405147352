
























































import { Component, Vue } from "vue-property-decorator";
import { isString } from "lodash";
import Loading from "@/components/loader/loading.vue";
import StartInspection from "./StartInspection.vue";

@Component({
  components: {
    Loading,
    StartInspection,
  },
})
export default class OverviewDetails extends Vue {
  loading = false;
  title = this.$route.query.title;
  isRating =
    this.title == "Department Unmet Items" ||
    this.title == "Unmet Items" ||
    this.title == "Unresolved Items";
  headers = this.isRating
    ? [
        {
          text: "Question",
          value: "question",
          width: "25%",
        },
        {
          text: "Patient",
          align: "center",
          sortable: false,
          value: "patient",
        },
        {
          text: "Reported",
          sortable: false,
          value: "reported",
          align: "center",
        },
        {
          text: "Comment",
          sortable: false,
          value: "comment",
          width: "20%",
        },
      ]
    : [
        {
          text: "Question",
          value: "question",
          width: "25%",
        },
        {
          text: "Patient",
          align: "center",
          sortable: false,
          value: "patient",
        },
        {
          text: "Rating",
          align: "center",
          sortable: false,
          value: "rating",
        },
        {
          text: "Reported",
          sortable: false,
          value: "reported",
          align: "center",
        },
        {
          text: "Comment",
          sortable: false,
          value: "comment",
          width: "20%",
        },
      ];

  async created() {
    const toFetch = this.$route.query.summary;
    const beginAt = this.$route.query.beginAt;
    const endAt = this.$route.query.endAt;
    const departmentId = this.$route.query.departmentId;
    this.loading = true;
    if (isString(toFetch)) {
      if (departmentId) {
        await this.$store.dispatch(`inspection/${toFetch}`, {
          beginAt,
          endAt,
          departmentId: Number(departmentId),
        });
      } else {
        await this.$store.dispatch(`inspection/${toFetch}`, { beginAt, endAt });
      }
      this.loading = false;
    }
  }

  get details() {
    return this.$store.getters["inspection/summaryDetails"];
  }

  destroyed() {
    this.$store.dispatch("inspection/clearSummaryDetails");
  }

  back() {
    this.$router.back();
  }
}
