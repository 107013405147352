import { RouteConfig } from "vue-router";
import OwnerExpenseReport from "@/views/expense_report/owner/Report.vue";
import AdminExpenseReport from "@/views/expense_report/admin/Report.vue";
import CategoryReport from "@/views/expense_report/owner/CategoryReport.vue";

const routes: Array<RouteConfig> = [
  {
    path: "owner",
    name: "Owner Report",
    components: {
      default: OwnerExpenseReport,
      ownerReport: OwnerExpenseReport,
      adminReport: AdminExpenseReport,
      category: CategoryReport,
    },
    meta: {
      title: "Owner Report",
      requiresAuth: true,
    },
  },
  {
    path: "owner/department/:departmentId/category/:categoryId",
    name: "Category Report",
    components: {
      default: CategoryReport,
      ownerReport: OwnerExpenseReport,
      adminReport: AdminExpenseReport,
      category: CategoryReport,
    },
    meta: {
      title: "Category Expenses",
      requiresAuth: true,
    },
  },
  {
    path: "admin",
    name: "Admin Report",
    components: {
      default: AdminExpenseReport,
      ownerReport: OwnerExpenseReport,
      adminReport: AdminExpenseReport,
    },
    meta: {
      title: "Admin Report",
      requiresAuth: true,
    },
  },
];

export default routes;
