

































































































































import { Employee } from "@/api";
import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
import shifts from "@/common/shift_type";
import { dateFormat } from "@/common/date_format";
import { checkOtherRole, checkSelf, checkSelfRole } from "@/common/role_utils";
import * as validation from "@/tool/validation";

@Component
export default class EditEmployee extends Vue {
  @Prop(Object) readonly employee!: Employee;
  @Prop() readonly id!: number;

  valid = true;
  firstName = this.employee.firstName;
  lastName = this.employee.lastName;
  title = this.employee.titleId;
  showDate = false;
  birthPicker = false;
  hireDate = this.employee?.hireDate?.substr(0, 10) || null;
  birthday = this.employee?.birthday?.substr(0, 10) || null;
  formattedBirthday = "";
  formattedhireDate = "";
  phone = this.employee.phone || null;
  about = this.employee.about;
  hobbies = this.employee.hobbies;
  shift = this.employee?.shift || 0;
  shifts = [...shifts];
  newDate = "";
  formErrors = "";
  isEmployee = checkSelfRole("employee");
  showBirthday = this.employee.birthdayVisible ? false : true;
  saving = false;

  nameRules = [
    validation.requiredString,
    (v: string) => validation.maxLen(v, 20),
    validation.noSpace,
  ];
  lNameRules = [(v: string) => validation.maxLen(v, 20), validation.noSpace];
  phoneRules = [
    validation.requiredString,
    validation.noSpace,
    validation.numberOnly,
    (v: string) => validation.maxLenInt(v, 10),
  ];

  required_select = validation.requiredSelect;
  maxDate = new Date().toISOString().substr(0, 10);
  date_required = validation.requiredSelect;
  requiredShift = [validation.requriedNumber];

  get isSelectedOwner() {
    return checkOtherRole(this.employee, "owner");
  }

  get isSelf() {
    return checkSelf(this.employee.id);
  }

  async created() {
    await this.$store.dispatch(
      "departmentAdmin/getTitle",
      this.employee.primaryDepartmentId,
    );
  }

  isSame() {
    let same = false;
    if (
      this.firstName?.trim().toLowerCase() !==
      this.employee?.firstName?.trim().toLowerCase()
    )
      same = true;
    if (
      this.lastName?.trim().toLowerCase() !==
      this.employee?.lastName?.trim().toLowerCase()
    )
      same = true;
    if (this.phone !== this.employee?.phone) same = true;
    if (
      this.about?.trim().toLowerCase() !==
      this.employee?.about?.trim().toLowerCase()
    )
      same = true;
    if (
      this.hobbies?.trim().toLowerCase() !==
      this.employee?.hobbies?.trim().toLowerCase()
    )
      same = true;
    if (
      this.hireDate?.trim().toLowerCase() !==
      this.employee?.hireDate
        ?.trim()
        .toLowerCase()
        .substr(0, 10)
    )
      same = true;

    if (this.title !== this.employee?.titleId) same = true;
    if (
      this.birthday?.trim().toLowerCase() !==
      this.employee?.birthday
        ?.trim()
        .toLowerCase()
        .substr(0, 10)
    )
      same = true;

    if (this.shift !== this.employee.shift) same = true;
    const bvisible = this.showBirthday ? 0 : 1;
    if (bvisible !== this.employee.birthdayVisible) same = true;
    return same;
  }

  async save() {
    if (!this.isSame()) {
      this.$store.dispatch("alert/warning", "Nothing is Changed");
    } else {
      this.saving = true;
      await this.$store.dispatch("directory/editEmployeeProfile", {
        requestBody: {
          firstName: this.firstName?.trim(),
          lastName: this.lastName?.trim(),
          hireDate: !this.hireDate
            ? null
            : new Date(this.hireDate).toISOString(),
          birthday: !this.birthday
            ? null
            : new Date(this.birthday).toISOString(),
          titleId: this.title,
          phone: !this.phone ? null : this.phone,
          about: this.about?.trim(),
          hobbies: this.hobbies?.trim(),
          shift: Number(this.shift),
          birthdayVisible: this.isSelf
            ? this.showBirthday
              ? 0
              : 1
            : this.employee.birthdayVisible,
        },
        employeeId: this.employee.id,
      });
      this.$store.dispatch(
        "directory/getTitledEmployee",
        this.$route.params.titleId,
      );
      this.saving = false;
      this.close();
    }
  }
  close() {
    this.$emit("save");
  }
  formatDate(date: string, isBirthday: boolean) {
    if (isBirthday) this.formattedBirthday = dateFormat(date);
    else this.formattedhireDate = dateFormat(date);
  }
  selectedDate() {
    if (this.birthday && this.hireDate) {
      if (this.birthday < this.hireDate) {
        const indate = this.birthday.substr(0, 10);
        const hired = this.hireDate.substr(0, 10);
        const birthday = moment(indate);
        const currentdate = moment(hired);
        const duration = moment.duration(currentdate.diff(birthday));
        if (duration.years() > 13) {
          this.formErrors = "";
        } else {
          this.formErrors = "Must be 14 years old at least";
        }
      } else {
        this.formErrors = "birthday should not be greater than hire date";
      }
    }
  }
  get titleList() {
    const list = this.$store.getters["departmentAdmin/departmentTitle"];
    if (list) {
      return list;
    } else {
      return [];
    }
  }
}
