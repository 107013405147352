



























































































import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import loading from "@/components/loader/loading.vue";
import {
  CountCompanyDocumentResponse,
  DepartmentDocumentResponse,
  DocumentResponse,
  NotifyOnEmployeeDocument,
} from "@/api";
import UploadDoc from "@/components/EmployeeDocument/UploadDoc.vue";
import MobileUploadDoc from "@/components/EmployeeDocument/MobileUploadDoc.vue";
import CamAccess from "@/components/camAccess/CamAccess.vue";
import ConfirmModal from "@/components/EmployeeDocument/ConfirmModal.vue";
import { checkSelfRole, selfData } from "@/common/role_utils";

@Component({
  components: {
    loading,
    UploadDoc,
    CamAccess,
    MobileUploadDoc,
    ConfirmModal,
  },
})
export default class DocumentMenu extends Vue {
  isDataLoading = false;
  uploadDialog = false;
  uploadKey = 0;
  adDocumentId = -1;
  adDocumentName = "";
  camDialog = false;
  camKey = 0;
  uploadConfirm = false;
  text =
    "Your document is successfully uploaded. Please wait for the verification from the supervisor.";

  async created() {
    this.isDataLoading = true;
    if (checkSelfRole("owner")) {
      await this.$store.dispatch("departmentAdmin/getDocuments");
      await this.$store.dispatch(
        "departmentAdmin/getDepartmentDocuments",
        selfData().primaryDepartmentId,
      );
    } else {
      await this.$store.dispatch(
        "departmentAdmin/getDepartmentDocuments",
        selfData().primaryDepartmentId,
      );
    }
    await this.$store.dispatch(
      "employeeDocuments/getDocumentNotificationByDepartmentDocument",
      false,
    );
    this.isDataLoading = false;
  }

  isActive(id: number, documentId: number) {
    const docId = documentId ? (checkSelfRole("admin") ? documentId : id) : id;
    return docId.toString() === this.$route.params?.documentId;
  }

  isRole(role: string) {
    return checkSelfRole(role.toLowerCase());
  }

  get documents() {
    let documentList;
    const countList = this.$store.getters[
      "employeeDocuments/companyDocumentCount"
    ];
    const notifications = this.$store.getters[
      "employeeDocuments/documentNotificationByDepartmentDocument"
    ];
    if (checkSelfRole("owner")) {
      documentList = this.$store.getters["departmentAdmin/documents"];
      const listWithCount = documentList?.map((document: DocumentResponse) => {
        const foundItem = countList?.find(
          (count: CountCompanyDocumentResponse) =>
            count.document_id === document.id,
        );
        const countNotification = notifications?.find(
          (notification: NotifyOnEmployeeDocument) =>
            notification.document_id === document.id,
        );
        return {
          ...document,
          count: foundItem?.count || 0,
          notification: countNotification?.notify_flag || 0,
        };
      });
      documentList = listWithCount;
    } else {
      documentList = this.$store.getters["departmentAdmin/departmentDocuments"];
      const listWithCount = documentList?.map(
        (document: DepartmentDocumentResponse) => {
          const foundItem = countList?.find(
            (count: CountCompanyDocumentResponse) =>
              count.document_id === document.document_id,
          );
          const countNotification = notifications?.find(
            (notification: NotifyOnEmployeeDocument) =>
              notification.document_id === document.document_id,
          );
          return {
            ...document,
            count: foundItem?.count || 0,
            uploadFlag: foundItem?.upload_flag || 0,
            notification: countNotification?.notify_flag || 0,
          };
        },
      );
      documentList = listWithCount;
    }

    return _.orderBy(
      documentList,
      document => document.name || document.document_name,
    );
  }

  selectDocument(id: number, documentId: number, title: string) {
    const docId = documentId ? (checkSelfRole("admin") ? documentId : id) : id;
    this.$router.push({ path: `/company-document/${docId}`, query: { title } });
  }

  addDocument(documentId: number, documentName: string) {
    this.uploadKey++;
    this.adDocumentId = documentId;
    this.adDocumentName = documentName;
    this.uploadDialog = !this.uploadDialog;
  }

  takePic(documentId: number, documentName: string) {
    this.adDocumentId = documentId;
    this.adDocumentName = documentName;
    this.camKey++;
    this.camDialog = !this.camDialog;
  }

  closeUpload(upload: boolean) {
    this.uploadKey++;
    this.uploadDialog = false;
    this.uploadConfirm = upload;
  }

  closeConfirm() {
    this.uploadConfirm = false;
    if (this.$vuetify.breakpoint.smAndDown)
      this.$router.push("/company-document/" + this.adDocumentId);
  }

  getIcon(flag: number) {
    if (flag == 0) return "";
    else if (flag == 1 || flag == 2) return "mdi-alert-outline";
    else return "mdi-alert-circle";
  }

  getColor(flag: number) {
    if (flag == 0) return "";
    else if (flag == 1 || flag == 2) return "yellow";
    else return "red";
  }
}
