































































import { Vue, Component } from "vue-property-decorator";
import { AnniversaryListing } from "@/api";
import loading from "@/components/loader/loading.vue";
import moment from "moment";

@Component({
  components: {
    loading,
  },
})
export default class BirthdayList extends Vue {
  isDataLoading = false;

  get newHires() {
    return this.$store.getters["dashboard/newHireList"] as AnniversaryListing;
  }

  monthName(month: string) {
    return moment(month, "M").format("MMMM");
  }

  getDay(hireDate: string) {
    return moment(hireDate).format("DD");
  }

  goTo(id: number) {
    this.$router.push(`/company-directory/profile/${id}`);
  }
}
