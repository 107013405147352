/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EmployeeEventScore = {
    properties: {
        eventMetricId: {
    type: 'number',
    isRequired: true,
},
        eventMetricName: {
    type: 'string',
    isRequired: true,
},
        score: {
    type: 'number',
    isRequired: true,
},
        isPoster: {
    type: 'boolean',
    isRequired: true,
},
    },
};