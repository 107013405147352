
























































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import Loading from "@/components/loader/loading.vue";
import StartSurvey from "@/views/survey/overview/StartSurvey.vue";

@Component({
  components: {
    Loading,
    StartSurvey,
  },
})
export default class OverviewDetails extends Vue {
  loading = false;
  title = this.$route.query.title;
  isAdmission = false;
  headers = [
    {
      text: "Question",
      value: "question",
      width: "25%",
    },
    {
      text: "Resident",
      align: "center",
      sortable: false,
      value: "resident",
    },
    {
      text: "Rating",
      align: "center",
      sortable: false,
      value: "rating",
    },
    {
      text: "Reported",
      sortable: false,
      value: "reported",
      align: "center",
    },
    {
      text: "Comment",
      sortable: false,
      value: "comment",
      width: "20%",
    },
  ];

  async created() {
    const dispatchAdmission = this.$route.query.dispatchAdmission;
    this.isAdmission = dispatchAdmission ? true : false;
    const beginAt = this.$route.query.beginAt;
    const endAt = this.$route.query.endAt;
    const overviewId = this.$route.query.overviewId;
    const payload = {
      beginAt,
      endAt,
      dispatchAdmission: this.isAdmission,
      overviewId,
    };
    this.loading = true;
    await this.$store.dispatch("survey/fetchOverviewDetails", payload);
    this.loading = false;
  }

  get details() {
    return this.isAdmission
      ? this.$store.getters["survey/admissionOverviewDetails"]
      : this.$store.getters["survey/dischargeOverviewDetails"];
  }

  destroyed() {
    this.$store.dispatch("survey/clearSummaryDetails");
  }

  back() {
    this.$router.push({
      path: `/survey/overview`,
      query: {
        dispatchAdmission: this.isAdmission ? "true" : "false",
      },
    });
  }

  complete(isAdmission: boolean) {
    this.$router.push({
      path: `/survey/overview`,
      query: {
        dispatchAdmission: isAdmission ? "true" : "false",
      },
    });
  }

  formatDate(date: string): string {
    return moment(date).format("MM/DD/YYYY @ hh:mm");
  }
}
