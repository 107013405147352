





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmationForList extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly text!: string;
  @Prop() readonly list!: string[];
  @Prop(Boolean) loader!: boolean;

  close() {
    this.$emit("close");
  }

  ok() {
    this.$emit("ok");
  }
}
