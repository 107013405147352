







































import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
import { EventMetric } from "@/api";

@Component
export default class EventByDepartment extends Vue {
  created() {
    this.$store.dispatch("EventMetricAdmin/trackEventByDepartment");
  }

  get departmentTrackEvents() {
    const list = this.$store.getters["EventMetricAdmin/departmentTrackEvents"];
    const departmentOrder = _.orderBy(list, department =>
      department.department.name.trim().toUpperCase(),
    );
    const eventOrder = _.map(departmentOrder, department => {
      return {
        ...department,
        eventMetrics: _.orderBy(department.eventMetrics, event =>
          event.name.toUpperCase(),
        ),
      };
    });
    return eventOrder;
  }

  sortByAlphabet(eventMetrics: EventMetric[]) {
    const uniqueEvents = _.uniqBy(eventMetrics, function(e) {
      return e.name;
    });
    return _.sortBy(uniqueEvents, uniqueEvents =>
      uniqueEvents.name.toUpperCase(),
    );
  }

  edit(id: number) {
    this.$emit("edit", id);
  }
}
