


















































































































import { Component, Vue } from "vue-property-decorator";
import { Employee, Department } from "@/api";
import _ from "lodash";
import * as roleUtils from "@/common/role_utils";
import AddEmployee from "@/views/company_directory/AddEmployee.vue";
import loading from "@/components/loader/loading.vue";
@Component({
  components: {
    AddEmployee,
    loading,
  },
  watch: {
    async $route() {
      const hasTitleId = this.$route.params.titleId;
      const hasQueryLoad = this.$route.query.load;
      const employeeId = this.$route.params.id;
      if (
        hasTitleId &&
        hasTitleId !== "0" &&
        (hasQueryLoad || (this.$vuetify.breakpoint.lgAndUp && !employeeId))
      ) {
        this.$data.isDataLoading = true;
        await this.$store.dispatch(
          "directory/getTitledEmployee",
          this.$route.params.titleId,
        );
        this.$data.isDataLoading = false;
      }
    },
  },
})
export default class EmployeeList extends Vue {
  search = "";
  showAdd = false;
  width = this.$vuetify.breakpoint.smAndDown ? screen.width + "px" : 350 + "px";
  employeeId = 0;
  isDataLoading = false;
  counterDrawer = 0;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch(
      "directory/getTitledEmployee",
      this.$route.params.titleId,
    );
    this.isDataLoading = false;
  }

  get crumbs() {
    const department = this.$store.getters[
      "departmentAdmin/selectedDepartment"
    ];
    const crumb = [];
    const titleName = this.$route.params.titleName.includes("-")
      ? this.$route.params.titleName.split("-").join("/")
      : this.$route.params.titleName;
    if (department) {
      crumb.push({
        text: department.name,
        disabled: false,
        to: "/departments",
      });
    }
    if (this.$route.params.deptId) {
      crumb.push({
        text: titleName,
        disabled: false,
        to: `/departments/${this.$route.params.deptId}/title`,
      });
    }

    return crumb;
  }

  get departments(): Department[] {
    return _.concat(
      [{ id: 0, name: "All Department", companyId: 0, isArchived: false }],
      this.$store.state.company.departments ?? [],
    );
  }
  get employees(): { alphabet: string; records: Employee[] }[] {
    const search = !this.search ? "" : this.search;
    const employees = this.$store.state.directory.titleEmployees ?? [];
    const list = _.reduce(
      _.orderBy(
        _.filter(employees.employees ?? [], (employee: Employee) =>
          _.includes(
            `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
            search.toLowerCase(),
          ),
        ),
        [
          (employee: Employee) =>
            `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
        ],
        ["asc"],
      ),
      (acc: { alphabet: string; records: Employee[] }[], cur) => {
        const alphabet = cur.firstName[0].toUpperCase();
        if (!acc[alphabet]) acc[alphabet] = { alphabet, records: [cur] };
        else acc[alphabet].records.push(cur);
        return acc;
      },
      [],
    );
    return Object.values(list);
  }

  get isAdmin() {
    return roleUtils.isAdmin();
  }

  clearSearch() {
    this.search = "";
  }

  selectEmployee(employeeId: number) {
    this.employeeId = employeeId;
    this.$router.push({
      path: `/departments/${this.$route.params.deptId}/title/${this.$route.params.titleName}/${this.$route.params.titleId}/employees/${employeeId}`,
    });
  }

  createEmployee() {
    this.counterDrawer++;
    this.showAdd = true;
  }

  close() {
    this.clearSearch();
    this.showAdd = false;
    this.counterDrawer++;
  }
}
