
























































































































































































import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
import {
  AddBed,
  Bed,
  Beds,
  GuardianAngel,
  GuardianAngelResponse,
  GuardianAngels,
  Room,
} from "@/api";
import Loading from "@/components/loader/loading.vue";
import RoomFormModal from "./RoomFormModal.vue";
import BedFormModal from "./BedFormModal.vue";
import GuardianFormModal from "./GuardianFomrModal.vue";
import ConfirmationModule from "@/components/confirmationModule/ConfirmationModule.vue";

@Component({
  components: {
    Loading,
    RoomFormModal,
    BedFormModal,
    GuardianFormModal,
    ConfirmationModule,
  },
  watch: {
    async $route() {
      const floorId = this.$route.params.floorId;
      const roomId = this.$route.params.roomId;
      if (floorId && roomId) {
        this.$data.isDataLoading = true;
        await this.$store.dispatch("bed/fetchRoomBeds", {
          floorId: floorId,
          roomId: roomId,
        });

        await this.$store.dispatch("guardian/fetchRoomGuardians", {
          floorId: floorId,
          roomId: roomId,
        });

        await this.$store.dispatch("room/setSelectedRoom", roomId);
        this.$data.isDataLoading = false;
      }
    },
  },
})
export default class RoomDetails extends Vue {
  isDataLoading = false;
  updatingRoom = false;
  deletingRoom = false;
  updatingBed = false;
  deletingBed = false;
  updatingGuardian = false;
  deletingGuardian = false;
  savingBed = false;
  savingGuardian = false;
  count = 0;
  dialog = false;
  bedDialog = false;
  bedEditDialog = false;
  deleteRoomDialog = false;
  deleteRoomBedDialog = false;
  deleteRoomGuardianDialog = false;
  guardianEditDialog = false;
  addGuardianDialog = false;
  selectedBedToUpdate: Bed | null = null;
  selectedGuardianToUpdate: GuardianAngelResponse | null = null;
  guardianIds: number[] = [];

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("bed/fetchRoomBeds", {
      floorId: this.floorId,
      roomId: this.roomId,
    });

    await this.$store.dispatch("guardian/fetchRoomGuardians", {
      floorId: this.floorId,
      roomId: this.roomId,
    });

    await this.$store.dispatch("room/setSelectedRoom", this.roomId);
    this.isDataLoading = false;
  }

  get floorId() {
    const floorId = this.$route.params.floorId;
    if (floorId) {
      try {
        return parseInt(floorId);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  get roomId() {
    const roomId = this.$route.params.roomId;
    if (roomId) {
      try {
        return parseInt(roomId);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  get room() {
    const room = this.$store.getters["room/selectedRoom"];
    return room;
  }

  get beds() {
    const beds = this.$store.getters["bed/beds"] as Beds;
    return _.sortBy(beds || [], (bed: Bed) => bed.name);
  }

  get guardians() {
    const guardians = this.$store.getters[
      "guardian/guardians"
    ] as GuardianAngels;
    this.guardianIds = [];
    guardians?.map(guardian => {
      this.guardianIds.push(guardian.employeeId);
      return guardian;
    });
    return _.orderBy(
      guardians || [],
      [
        (guardian: GuardianAngelResponse) => guardian.primaryGuardian,
        (guardian: GuardianAngelResponse) => guardian.employeeName,
      ],
      ["desc", "asc"],
    );
  }

  async updateRoom(requestBody: Room) {
    this.updatingRoom = true;
    await this.$store.dispatch("room/updateFloorRoom", {
      floorId: this.floorId,
      requestBody,
    });
    await this.$store.dispatch("room/setSelectedRoom", requestBody.id);
    this.updatingRoom = false;
    this.dialog = !this.dialog;
    this.count++;
  }

  async removeRoom() {
    this.deletingRoom = true;
    await this.$store.dispatch("room/removeFloorRoom", {
      floorId: this.floorId,
      requestBody: this.room,
    });
    this.deletingRoom = false;
    this.deleteRoomDialog = !this.deleteRoomDialog;
    this.$router.push({
      path: `/admin/room-setup/floor/${this.floorId}/`,
    });
  }

  async addBed(bed: AddBed) {
    this.savingBed = true;
    await this.$store.dispatch("bed/addRoomBed", {
      floorId: this.floorId,
      roomId: this.roomId,
      requestBody: bed,
    });
    this.savingBed = false;
    this.bedDialog = !this.bedDialog;
    this.count++;
  }

  updateBedDialog(bed: Bed) {
    this.count++;
    this.selectedBedToUpdate = bed;
    this.bedEditDialog = !this.bedEditDialog;
  }

  async updateBed(bed: Bed) {
    this.updatingBed = true;
    await this.$store.dispatch("bed/updateRoomBed", {
      floorId: this.floorId,
      roomId: this.roomId,
      requestBody: bed,
    });
    this.updatingBed = false;
    this.bedEditDialog = !this.bedEditDialog;
    this.count++;
  }

  removeBedConfirmation(bed: Bed) {
    this.selectedBedToUpdate = bed;
    this.deleteRoomBedDialog = !this.deleteRoomBedDialog;
  }

  removeBed() {
    this.deletingBed = true;
    this.$store.dispatch("bed/removeRoomBed", {
      floorId: this.floorId,
      roomId: this.roomId,
      requestBody: this.selectedBedToUpdate,
    });
    this.deletingBed = false;
    this.deleteRoomBedDialog = !this.deleteRoomBedDialog;
  }

  async AddGuardian(guardian: GuardianAngel) {
    this.savingGuardian = true;
    await this.$store.dispatch("guardian/addRoomGuardian", {
      floorId: this.floorId,
      roomId: this.roomId,
      requestBody: guardian,
    });
    this.savingGuardian = false;
    this.addGuardianDialog = !this.addGuardianDialog;
    this.count++;
  }

  updateGuardianDialog(guardian: GuardianAngelResponse) {
    this.count++;
    this.selectedGuardianToUpdate = guardian;
    this.guardianEditDialog = !this.guardianEditDialog;
  }

  async updateGuardian(guardian: GuardianAngelResponse) {
    this.updatingGuardian = true;
    await this.$store.dispatch("guardian/updateRoomGuardian", {
      floorId: this.floorId,
      roomId: this.roomId,
      requestBody: guardian,
    });
    this.updatingGuardian = false;
    this.guardianEditDialog = !this.guardianEditDialog;
    this.count++;
  }

  removeGuardianConfirmation(guardian: GuardianAngelResponse) {
    this.selectedGuardianToUpdate = guardian;
    this.deleteRoomGuardianDialog = !this.deleteRoomGuardianDialog;
  }

  removeGuardian() {
    this.deletingGuardian = true;
    this.$store.dispatch("guardian/removeRoomGuardian", {
      floorId: this.floorId,
      roomId: this.roomId,
      requestBody: this.selectedGuardianToUpdate,
    });

    this.deletingGuardian = false;
    this.deleteRoomGuardianDialog = !this.deleteRoomGuardianDialog;
  }
}
