/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AddRoom = {
    properties: {
        name: {
    type: 'string',
    isRequired: true,
},
    },
};