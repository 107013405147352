





















































import { Component, Vue } from "vue-property-decorator";
import FiveStarForm from "@/components/five_star/FiveStarForm.vue";

@Component({
  components: { FiveStarForm },
})
export default class FiveStarMomentButton extends Vue {
  counter = 0;
  dialog = false;
  get isOpen() {
    const navigationRailIsOpen = this.$store.state.navigationRailIsOpen;
    return navigationRailIsOpen;
  }
}
