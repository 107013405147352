var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-fill flex-column px-11 py-2"},[_c('v-container',[_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"fab":"","text":""},on:{"~click":function($event){return _vm.back($event)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-keyboard-backspace ")])],1),_c('div',{staticClass:"title py-2 px-2"},[_vm._v(_vm._s(_vm.eventName))])],1),_c('div',{staticClass:"px-4 d-flex flex-wrap justify-space-between align-center"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.title)+" Events")]),_c('div',{staticClass:"dateInput"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"px-3",attrs:{"outlined":"","error-messages":_vm.formErrors,"label":"Start Date"},model:{value:(_vm.formattedStartDate),callback:function ($$v) {_vm.formattedStartDate=$$v},expression:"formattedStartDate"}},on),[_vm._v(_vm._s(_vm.formatDate(_vm.startDate, true))+" ")])]}}]),model:{value:(_vm.showStartDate),callback:function ($$v) {_vm.showStartDate=$$v},expression:"showStartDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showStartDate = false},"change":_vm.selectedDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('div',{staticClass:"dateInput"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"px-3",attrs:{"outlined":"","error-messages":_vm.formErrors,"label":"End Date","readonly":""},model:{value:(_vm.formattedEndDate),callback:function ($$v) {_vm.formattedEndDate=$$v},expression:"formattedEndDate"}},on),[_vm._v(_vm._s(_vm.formatDate(_vm.endDate, false)))])]}}]),model:{value:(_vm.showEndDate),callback:function ($$v) {_vm.showEndDate=$$v},expression:"showEndDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showEndDate = false},"change":_vm.selectedDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)]),(_vm.$route.query.isPoster !== 'true')?_c('div',[_c('p',[_vm._v("Active: "+_vm._s((_vm.activeCount + "/" + _vm.totalCount)))])]):_vm._e()]),(!_vm.isDataLoading && _vm.list && _vm.list.length !== 0)?_c('v-card',{staticClass:"py-2",attrs:{"flat":""}},[(_vm.isEmployee)?_c('div',[_vm._l((_vm.list),function(employee){return [_c('div',{key:employee.id},[_c('v-list-item',[_c('v-list-item-title',{attrs:{"width":""}},[_c('p',{staticClass:"subtitle-2 ma-0"},[_vm._v(" "+_vm._s(employee.createdAt)+" ")]),(_vm.$route.query.isPoster !== 'true')?_c('p',{staticClass:"subtitle-1 mb-2"},[_vm._v(" "+_vm._s(employee.employee.employeeName)+" ")]):_c('p',{staticClass:"subtitle-1 mb-2"},[_vm._v(" "+_vm._s(employee.giver || employee.postedByEmployeeName)+" ")])])],1),_c('v-divider')],1)]})],2):_c('v-expansion-panels',{attrs:{"focusable":""}},_vm._l((_vm.list),function(employee,i){return _c('v-expansion-panel',{key:i},[(_vm.$route.query.isPoster !== 'true')?_c('v-expansion-panel-header',[_c('div',[_c('p',{staticClass:"caption ma-0"},[_vm._v(" "+_vm._s(employee.createdAt)+" ")]),_c('p',{staticClass:"subtitle-2 ma-0"},[_vm._v(" "+_vm._s(employee.employee.employeeName)+" ")])]),_c('div',{staticClass:"d-flex justify-end mr-4"},[_c('v-switch',{attrs:{"inset":""},on:{"click":function($event){$event.stopPropagation();return _vm.archiveEvent(
                    employee.id,
                    employee.employee.employeeId,
                    employee.employee.toggle
                  )}},model:{value:(employee.employee.toggle),callback:function ($$v) {_vm.$set(employee.employee, "toggle", $$v)},expression:"employee.employee.toggle"}})],1)]):_c('v-expansion-panel-header',[_c('div',[_c('p',{staticClass:"caption ma-0"},[_vm._v(" "+_vm._s(employee.createdAt)+" ")]),_c('p',{staticClass:"subtitle-1 ma-0"},[_vm._v(" "+_vm._s(employee.giver || employee.postedByEmployeeName)+" ")])])]),_c('v-expansion-panel-content',[_c('p',{staticClass:"ma-2 subtitle-1 font-weight-bold"},[_vm._v(" Note: ")]),_c('p',{staticClass:"subtitle-1 ma-2"},[_vm._v(" "+_vm._s(employee.note)+" ")]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{class:{
                  'image-container': _vm.$vuetify.breakpoint.smAndDown,
                  'image-container-lg': !_vm.$vuetify.breakpoint.smAndDown,
                }},[(employee.document)?_c('v-img',{attrs:{"src":employee.document,"alt":"Failed to preview","max-height":"100%","max-width":"100%","contain":""}}):_vm._e()],1)]),(_vm.$route.query.isPoster !== 'true')?[_c('p',{staticClass:"ma-2 subtitle-1 font-weight-bold"},[_vm._v(" Giver: "),_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(employee.giver || employee.isPostedBy))])])]:[_c('p',{staticClass:"ma-2 subtitle-1 font-weight-bold"},[_vm._v(" Recipients: ")]),_c('p',{staticClass:"ma-2"},[_vm._l((employee.employeeRecipients),function(recipient,index){return [_c('span',{key:recipient.employeeId + index,staticClass:"subtitle-2"},[_vm._v(_vm._s(recipient.employeeName))]),(employee.employeeRecipients[index + 1])?_c('span',{key:recipient.employeeId + recipient.employeeName,staticClass:"mr-1 headline"},[_vm._v(", ")]):_vm._e()]})],2)]],2)],1)}),1)],1):_vm._e()],1),(_vm.list.length === 0 || _vm.isDataLoading)?_c('v-card',{staticClass:"py-2 d-flex flex-fill fill-height justify-center align-center",attrs:{"flat":""}},[(!_vm.isDataLoading)?_c('div',{staticClass:"text-center title font-weight-bold"},[_vm._v(" No data found. ")]):_vm._e(),(_vm.isDataLoading)?_c('loading',{attrs:{"loading":_vm.isDataLoading,"size":70,"width":7}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }