/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CountCompanyDocumentResponse = {
    properties: {
        document_id: {
    type: 'number',
    isRequired: true,
},
        count: {
    type: 'number',
    isRequired: true,
},
        upload_flag: {
    type: 'number',
    isRequired: true,
},
    },
};