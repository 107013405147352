/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SendPasswordResetLinkResponse = {
    properties: {
        employeeId: {
    type: 'number',
    isRequired: true,
},
        employeeFirstName: {
    type: 'string',
    isRequired: true,
},
        employeeLastName: {
    type: 'string',
    isRequired: true,
},
        contactMethod: {
    type: 'Enum',
    isRequired: true,
},
        contactRef: {
    type: 'string',
    isRequired: true,
},
    },
};