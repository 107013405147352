































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { DepartmentDocumentResponse, DocumentResponse } from "@/api";
import { noSpace, requiredComboBox } from "@/tool/validation";

@Component
export default class AddEmploymentDocumentModal extends Vue {
  @Ref("form") form!: VForm;
  @Prop(Number) departmentId!: number;

  name = "";
  search = "";
  loading = false;
  requiredTextInput = [requiredComboBox, noSpace];
  close() {
    this.form.reset();
    this.$emit("close");
  }

  get suggesstions() {
    const documents = this.$store.getters["departmentAdmin/documents"];
    const departmentDocuments = this.$store.getters[
      "departmentAdmin/departmentDocuments"
    ];
    const departmentDocIds = departmentDocuments?.map(
      (departDoc: DepartmentDocumentResponse) => departDoc.document_id,
    );
    const suggests = documents?.filter(
      (document: DocumentResponse) => !departmentDocIds?.includes(document.id),
    );
    return suggests?.map((suggest: DocumentResponse) => suggest.name);
  }

  changeText(event: string) {
    this.search = event;
  }

  async save() {
    const isValid = this.form.validate();
    const documents = this.$store.getters["departmentAdmin/documents"];
    const name = this.search;
    const isNew = documents?.filter(
      (document: DocumentResponse) =>
        document.name?.toLowerCase() === name?.toLowerCase(),
    );
    if (isValid) {
      try {
        this.loading = true;
        if (!isNew || !isNew.length) {
          await this.$store.dispatch("departmentAdmin/addDocument", {
            departmentId: this.departmentId,
            requestBody: {
              name: name.trim(),
            },
          });
        } else {
          await this.$store.dispatch("departmentAdmin/addDepartmentDocument", {
            departmentId: this.departmentId,
            documentId: isNew[0].id,
          });
        }
        this.loading = false;
        this.form.reset();
        this.$emit("save");
      } catch (e) {
        console.log(e);
      }
    }
  }
}
