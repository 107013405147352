











































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { Employee, GuardianAngel, GuardianAngelResponse } from "@/api";
import _ from "lodash";

@Component
export default class GuardianFormModal extends Vue {
  @Ref("form") form!: VForm;
  @Prop(String) title!: string;
  @Prop(String) action!: string;
  @Prop(Object) guardianAngel!: GuardianAngelResponse;
  @Prop(Number) roomId!: number;
  @Prop(Boolean) loader!: boolean;
  @Prop(Boolean) isEdit!: boolean;
  @Prop(Array) guardians!: number[];

  selectedEmployee = this.guardianAngel?.employeeId
    ? this.guardianAngel.employeeId
    : 0;
  primaryGuardian = this.guardianAngel?.primaryGuardian === 1 ? true : false;
  errorMessage = "";
  employeesRules = (v: number) => {
    if (!v || v == 0) {
      return false;
    }
    return true;
  };

  created() {
    if (this.title !== "Lead") {
      this.$store.dispatch("directory/loadAllEmployees");
    }
  }

  get employees(): Employee[] {
    const companyEmployees = this.$store.state.directory.employees || [];
    let employeesList = [...companyEmployees];
    if (this.guardians?.length) {
      employeesList = employeesList.filter(compEmployee => {
        if (!this.guardians.includes(compEmployee.id)) return compEmployee;
      });
    }
    return _.orderBy(employeesList, ["firstName", "lastname"], ["asc", "asc"]);
  }

  checkInput() {
    const isValid = this.employeesRules(this.selectedEmployee);
    if (isValid) {
      this.errorMessage = "";
      return true;
    } else {
      this.errorMessage = "You must specify one employees";
      return false;
    }
  }

  async submit() {
    const isValid = this.checkInput();
    if (isValid) {
      try {
        const payload = {
          id: this.guardianAngel?.id,
          roomId: this.roomId,
          employeeId: this.selectedEmployee,
          primaryGuardian: this.primaryGuardian ? 1 : 0,
        } as GuardianAngel;
        this.$emit("save", payload);
      } catch (e) {
        this.$store.dispatch("alert/error", e);
      }
    }
  }

  close() {
    this.form.reset();
    this.errorMessage = "";
    this.$emit("close");
  }
}
