import { render, staticRenderFns } from "./GroupChat.vue?vue&type=template&id=7551ce04&scoped=true"
import script from "./GroupChat.vue?vue&type=script&lang=ts"
export * from "./GroupChat.vue?vue&type=script&lang=ts"
import style0 from "./GroupChat.vue?vue&type=style&index=0&id=7551ce04&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7551ce04",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
