var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-8 py-12",staticStyle:{"height":"100%"}},[(_vm.loading)?[_c('Loading',{attrs:{"loading":_vm.loading,"size":70,"width":7}})]:[(_vm.activeForm)?[_c('div',{staticClass:"d-flex flex-fill mb-8 align-center flex-wrap",class:{
          'justify-center': _vm.$vuetify.breakpoint.smAndDown,
          'justify-space-between': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('div',{staticClass:"font-weight-bold text-grey title"},[_vm._v("Guardian Angel")]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('StartInspection')],1)]),_c('div',{staticClass:"d-flex justify-start align-start flex-wrap",class:{ 'justify-center': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"dateInput"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"px-3",attrs:{"value":_vm.formattedStartDate,"readonly":"","outlined":"","error-messages":_vm.formErrors,"label":"Start Date","dense":""}},on))]}}],null,false,1311240547),model:{value:(_vm.showStartDate),callback:function ($$v) {_vm.showStartDate=$$v},expression:"showStartDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showStartDate = false},"change":_vm.selectedDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('div',{staticClass:"dateInput"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"px-3",attrs:{"value":_vm.formattedEndDate,"outlined":"","error-messages":_vm.formErrors,"label":"End Date","readonly":"","dense":""}},on))]}}],null,false,3928258307),model:{value:(_vm.showEndDate),callback:function ($$v) {_vm.showEndDate=$$v},expression:"showEndDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showEndDate = false},"change":_vm.selectedDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-btn',{staticClass:"mx-1",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '65%' : '80px',"color":"#55abfa","rounded":""},on:{"click":_vm.clearFilter}},[_c('span',{staticClass:"text-white caption font-weight-bold"},[_vm._v("Clear")])])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('OverviewCard',{attrs:{"title":"Average Customer Experience Rating"},on:{"goTo":function($event){return _vm.goTo('getAverageRatingDetails', 'Customer Experience Rating')}}},[_c('v-card-title',{staticClass:"text-green justify-center font-weight-bold display-1 "},[_vm._v(" "+_vm._s(_vm.summaryReport ? _vm.summaryReport.averageRating.toFixed(1) : 0)+" / 5 ")]),_c('v-card-text',{staticClass:"font-weight-bold text-center ma-0 pa-0"},[_vm._v(" Based on "+_vm._s(_vm.summaryReport.totalInspection == 1 ? ((_vm.summaryReport.totalInspection) + " submission") : ((_vm.summaryReport.totalInspection) + " submissions"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('OverviewCard',{attrs:{"title":"Unmet Items"},on:{"goTo":function($event){return _vm.goTo('getItemsMissed', 'Unmet Items')}}},[_c('v-card-title',{staticClass:"justify-center font-weight-bold display-1",class:{
                  'text-red': Math.floor(_vm.summaryReport.itemMissed) > 0,
                  'text-green': Math.floor(_vm.summaryReport.itemMissed) == 0,
                }},[_vm._v(" "+_vm._s(_vm.summaryReport ? Math.floor(_vm.summaryReport.itemMissed) : 0)+"% ")]),_c('v-card-text',{staticClass:"font-weight-bold text-center ma-0 pa-0 title"},[_vm._v(" "+_vm._s(((_vm.summaryReport.totalItemsMissed) + "/" + (_vm.summaryReport.totalQuestionInspected)))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('OverviewCard',{attrs:{"title":"Customer Experience Rating 3 or Less"},on:{"goTo":function($event){return _vm.goTo('getLessThanRatingDetails', 'Customer Experience Rating')}}},[_c('v-card-title',{staticClass:"justify-center font-weight-bold display-1 ",class:{
                  'text-red': Math.floor(_vm.summaryReport.lessThen3Rating) > 0,
                  'text-green':
                    Math.floor(_vm.summaryReport.lessThen3Rating) == 0,
                }},[_vm._v(" "+_vm._s(_vm.summaryReport ? Math.floor(_vm.summaryReport.lessThen3Rating) : 0)+"% ")]),_c('v-card-text',{staticClass:"font-weight-bold text-center ma-0 pa-0 title"},[_vm._v(" "+_vm._s(((_vm.summaryReport.totalLessThenRating) + "/" + (_vm.summaryReport.totalInspection)))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('OverviewCard',{attrs:{"title":"Customer Experience Rating 4 or 5"},on:{"goTo":function($event){return _vm.goTo('getMoreThanRatingDetails', 'Customer Experience Rating')}}},[_c('v-card-title',{staticClass:"text-green justify-center font-weight-bold display-1 "},[_vm._v(" "+_vm._s(_vm.summaryReport ? Math.floor(_vm.summaryReport.moreThen3Rating) : 0)+"% ")]),_c('v-card-text',{staticClass:"font-weight-bold text-center ma-0 pa-0 title"},[_vm._v(" "+_vm._s(((_vm.summaryReport.totalMoreThenRating) + "/" + (_vm.summaryReport.totalInspection)))+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('OverviewCard',{attrs:{"title":"Unresolved Items"},on:{"goTo":function($event){return _vm.goTo('getUnresolvedItems', 'Unresolved Items')}}},[_c('v-card-title',{staticClass:"justify-center font-weight-bold display-1",class:{
                  'text-red': Math.floor(_vm.summaryReport.unresolvedItems) > 0,
                  'text-green':
                    Math.floor(_vm.summaryReport.unresolvedItems) == 0,
                }},[_vm._v(" "+_vm._s(_vm.summaryReport ? Math.floor(_vm.summaryReport.unresolvedItems) : 0)+"% ")]),_c('v-card-text',{staticClass:"font-weight-bold text-center ma-0 pa-0 title"},[_vm._v(" "+_vm._s(((_vm.summaryReport.totalUnresolvedItems) + "/" + (_vm.summaryReport.totalItemsMissed)))+" ")])],1)],1),_c('v-col',{class:{ 'order-1': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"body-1 font-weight-bold pa-0"},[_vm._v(" Unattended Rooms ")]),_c('v-card-title',{staticClass:"justify-center font-weight-bold display-1 ",class:{
                  'text-red': Math.floor(_vm.totalUnattendedRooms) > 0,
                  'text-green': Math.floor(_vm.totalUnattendedRooms) == 0,
                }},[_vm._v(" "+_vm._s(_vm.summaryReport ? _vm.totalUnattendedRooms : 0)+" ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-simple-table',{staticClass:"list",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left text-grey font-weight-bold",staticStyle:{"width":"30%"}},[_vm._v(" ROOM ")]),_c('th',{staticClass:"text-right text-grey font-weight-bold",staticStyle:{"width":"70%"}},[_vm._v(" Guardian Angel ")])])]),_c('tbody',[(_vm.summaryReport.unattendedRooms.unattendedRoom)?_vm._l((_vm.summaryReport
                            .unattendedRooms.unattendedRoom),function(item,index){return _c('tr',{key:item.id + index},[_c('td',[_vm._v(_vm._s(((item.name) + " / " + (item.BedName))))]),_c('td',{staticClass:"text-right left"},[_vm._v(" "+_vm._s(_vm.getGuardianName(item.guardianAngel))+" ")])])}):[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(" No data available ")])])]],2)]},proxy:true}],null,false,2460131160)})],1)],1)],1),_c('v-col',{class:{ 'order-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"body-1 font-weight-bold pa-0"},[_vm._v(" Unmet Items By Department ")]),_c('v-card-text',[_c('Pie',{key:_vm.pieCounter,attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData,"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"width":_vm.width,"height":_vm.height}})],1)],1)],1)],1)],1)]:[_c('div',{staticClass:"d-flex flex-column flex-fill fill-height justify-center align-center"},[_c('p',{staticClass:"text-blue button font-weight-bold"},[_vm._v(" No Guardian Angel Form ")]),_c('v-btn',{staticClass:"px-12",attrs:{"color":"#a8c8ff","rounded":""},on:{"click":function($event){_vm.createDialog = !_vm.createDialog}}},[_c('span',{staticClass:"text-blue overline font-weight-bold"},[_vm._v("Create a new GA Form")])])],1)]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }