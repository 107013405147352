/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Form = {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        status: {
    type: 'number',
},
        isStandard: {
    type: 'boolean',
},
    },
};