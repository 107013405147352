/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LoggedEventRecipient = {
    properties: {
        departmentId: {
    type: 'number',
    isRequired: true,
},
        departmentName: {
    type: 'string',
    isRequired: true,
},
        employeeId: {
    type: 'number',
    isRequired: true,
},
        employeeName: {
    type: 'string',
    isRequired: true,
},
        employeeProfilePicture: {
    type: 'string',
},
    },
};