

























































import { Budget, CategoryBudget, Census } from "@/api";
import ConfirmationModule from "@/components/confirmationModule/ConfirmationModule.vue";
import { VForm } from "@/common/vuetify_types";
import { calculateBudget } from "@/tool/budgetCalculator";
import {
  floatOnly,
  noSpace,
  requiredString,
  requriedNumber,
} from "@/tool/validation";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    ConfirmationModule,
  },
})
export default class BudgetForm extends Vue {
  @Ref("form") form!: VForm;
  @Prop() data!: CategoryBudget;
  @Prop() dindex!: number;
  @Prop() departmentId!: number;
  @Prop() cindex!: number;
  @Prop() census!: number;
  @Prop() validate!: boolean;

  @Watch("data", { immediate: true, deep: true })
  onUpdateData(newVal: CategoryBudget) {
    this.category = newVal;
    this.budget = newVal.budget;
  }

  @Watch("validate", { immediate: true, deep: true })
  onUpdatValidate(nvewVal: boolean) {
    if (nvewVal) {
      this.form.validate();
    }
  }

  @Watch("census", { immediate: true, deep: true })
  onCensusUpdate(newVal: number, oldVal: number) {
    if (newVal > 0 && newVal != oldVal && newVal != this.companyCensus.census) {
      this.updateAllBudgets(false, false, true);
    }
  }

  category = this.data;
  prevCategory = JSON.parse(JSON.stringify(this.data));
  reqString = [requiredString, noSpace];
  reqFloat = [requriedNumber, floatOnly];
  saving = false;
  confirm = false;
  budget = this.data.budget;

  checkZeroPpd() {
    if (this.form.validate()) {
      const ppd = this.category.ppd;
      if (ppd <= 0) {
        this.confirm = true;
        return;
      }
      this.saveBudget();
    }
  }

  finalBudget(ppdUpdate = false, glUpdate = false, censusUdpate = false) {
    if (glUpdate || (censusUdpate && this.category.ppd == 0)) {
      return this.category.budget;
    }
    let budget = parseFloat(this.budget.toString());
    let calcBudget = calculateBudget(this.category.ppd, this.census);

    if (isNaN(calcBudget)) calcBudget = 0;

    if (ppdUpdate || censusUdpate) {
      return parseFloat(calcBudget.toFixed(2));
    }

    this.category.ppd = 0;
    if (isNaN(budget)) budget = 0;
    return parseFloat(budget.toFixed(2));
  }

  get companyCensus() {
    const companyCensus = this.$store.getters[
      "categoryBudget/census"
    ] as Census;
    return companyCensus;
  }

  async saveBudget() {
    this.confirm = false;
    if (this.form.validate()) {
      const census = parseInt(this.census.toString());
      const payload: Budget = {
        catId: this.category.category_id,
        budget: this.category.budget,
        ppd: this.category.ppd,
        balance: this.category.budget,
        glCode: this.category.glCode,
      };

      if (!census || census === 0) {
        this.$emit("error");
        return;
      }
      this.saving = true;
      await this.$store.dispatch("categoryBudget/addBudgetToCategroy", {
        departmentId: this.departmentId,
        categoryId: this.category.category_id,
        requestBody: payload,
      });
      this.saving = false;
    }
  }

  updateAllBudgets(ppdUpdate = false, glUpdate = false, censusUpdate = false) {
    this.category.budget = this.finalBudget(ppdUpdate, glUpdate, censusUpdate);
    this.category.balance = this.category.budget;
    this.budget = this.category.balance;
    const ppd = parseFloat(this.category.ppd.toString());
    if (typeof this.category.ppd == "string") this.category.ppd = ppd;
    if (isNaN(this.category.ppd)) this.category.ppd = 0;
    this.$emit("updateAllBudgets", {
      cat: this.category,
      dindex: this.dindex,
      cindex: this.cindex,
    });
  }
}
