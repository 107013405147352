









































































import { Component, Vue } from "vue-property-decorator";
import EventMetrics from "@/views/admin/event_metrics//EventMetrics.vue";
import { checkSelfRole } from "@/common/role_utils";

@Component({
  components: {
    EventMetrics,
  },
})
export default class AdminToolMenu extends Vue {
  isOwner = checkSelfRole("owner");

  get activeMenu() {
    if (this.$route.path.includes("department")) return "department";
    else if (this.$route.path.includes("archive_list")) return "archive";
    else if (this.$route.path.includes("tarf-history")) return "tarf";
    else if (this.$route.path.includes("company-budget")) return "budget";
    else if (this.$route.path.includes("room-setup")) return "room";
    else return "not_select";
  }
  selectToolMenu(toolMenu: string) {
    this.$router.push({ path: `/admin/${toolMenu}` });
  }
}
