var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('p',{staticClass:"text-h5"},[_vm._v("Welcome to CareCens")]),_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"id":"companyCodeInput","outlined":"","label":"Company Code","rules":[_vm.rules.required]},model:{value:(_vm.companyCode),callback:function ($$v) {_vm.companyCode=$$v},expression:"companyCode"}}),_c('v-text-field',{attrs:{"id":"emailInput","outlined":"","label":"Email/EmployeeID","rules":[_vm.rules.required, _vm.rules.idValid]},model:{value:(_vm.emailOrId),callback:function ($$v) {_vm.emailOrId=$$v},expression:"emailOrId"}}),_c('v-text-field',{attrs:{"id":"emailPasswordInput","outlined":"","label":"Password","rules":[_vm.rules.required],"type":_vm.isPasswordVisible ? 'text' : 'password',"append-icon":_vm.isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"my-4",attrs:{"block":"","loading":_vm.loading},on:{"click":_vm.signIn}},[_vm._v("Sign In")]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"my-4",class:{ 'text-decoration-underline': hover },attrs:{"color":"primary","x-small":"","text":"","ripple":false},on:{"click":function($event){return _vm.goTo('/reset-code')}}},[_vm._v("Forget/Reset Password")])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"my-4",class:{ 'text-decoration-underline': hover },attrs:{"color":"primary","x-small":"","text":"","ripple":false},on:{"click":function($event){return _vm.goTo('/activate-account')}}},[_vm._v("Activate Account")])]}}])})],1),_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"my-4",class:{ 'text-decoration-underline': hover },attrs:{"color":"primary","x-small":"","text":"","ripple":false},on:{"click":function($event){return _vm.goTo('/forgot-companycode')}}},[_vm._v("Forgot Company Code")])]}}])})],1)])],1),_c('v-snackbar',{attrs:{"color":"red"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }