import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";

const defaultTimeout = 4000;
const errorTimeout = 6000;

export type AlertState = {
  message: string;
  color: string;
  isOpen: boolean;
  timeout: number;
};

const state: AlertState = {
  message: "",
  color: "info",
  isOpen: false,
  timeout: defaultTimeout,
};

const getters: GetterTree<AlertState, RootState> = {
  alertState(state) {
    return state;
  },
};

const mutations: MutationTree<AlertState> = {
  SET_CLOSE: state => {
    state.isOpen = false;
  },
  SET_SHOW_ERROR: (state, message: string) => {
    state.isOpen = false;
    state.color = "error";
    state.message = message;
    state.timeout = errorTimeout;
    state.isOpen = true;
  },
  SET_SHOW_WARNING: (state, message: string) => {
    state.isOpen = false;
    state.color = "warning";
    state.message = message;
    state.timeout = defaultTimeout;
    state.isOpen = true;
  },
  SET_SHOW_SUCCESS: (state, message: string) => {
    state.isOpen = false;
    state.color = "success";
    state.message = message;
    state.timeout = defaultTimeout;
    state.isOpen = true;
  },
  SET_SHOW_INFO: (state, message: string) => {
    state.isOpen = false;
    state.color = "info";
    state.message = message;
    state.timeout = defaultTimeout;
    state.isOpen = true;
  },
};

const actions: ActionTree<AlertState, RootState> = {
  close: ({ commit }) => {
    commit("SET_CLOSE");
  },
  error: ({ commit }, message: string) => {
    commit("SET_SHOW_ERROR", message);
  },
  warning: ({ commit }, message: string) => {
    commit("SET_SHOW_WARNING", message);
  },
  success: ({ commit }, message: string) => {
    commit("SET_SHOW_SUCCESS", message);
  },
  info: ({ commit }, message: string) => {
    commit("SET_SHOW_INFO", message);
  },
};

export const alert: Module<AlertState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
