/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AddDepartmentTitleRequestBody = {
    properties: {
        title: {
    type: 'string',
    isRequired: true,
},
    },
};