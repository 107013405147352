



























































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import BirthdayList from "@/views/dashboard/BirthdayList.vue";
import AnniversaryList from "@/views/dashboard/AnniversaryList.vue";
import { EmployeeEventScore } from "@/api/models/EmployeeEventScore";
import { Employee } from "@/api/models/Employee";
import loading from "@/components/loader/loading.vue";
import NewHires from "@/views/dashboard/NewHires.vue";
import {
  AnniversaryEmployeeInfo,
  AnniversaryListing,
  ResponseTask,
  Tasks,
} from "@/api";
import moment from "moment";
import _ from "lodash";
import { checkSelfRole, selfData, isAdmin } from "@/common/role_utils";
import { taskStatus, getTaskStatusById } from "@/common/task_status";
import TaskForm from "./TaskForm.vue";
import { dateFormat } from "@/common/date_format";

@Component({
  components: {
    BirthdayList,
    AnniversaryList,
    loading,
    NewHires,
    TaskForm,
  },
})
export default class ProfileBrief extends Vue {
  self = selfData();
  isDataLoading = false;
  birthdayDialog = false;
  anniversaryDialog = false;
  hireDialog = false;
  createTaskDialog = false;
  deletingTask = false;
  updatingTask = false;
  status = taskStatus;
  isAdminOrOwner = isAdmin();
  getTaskText = getTaskStatusById;
  selectedTask: Tasks = [];
  dateFormat = dateFormat;
  itemStatus = 0;
  employee = 0;
  counter = 0;
  headers = [
    {
      text: "DETAILS",
      align: "start",
      sortable: false,
      value: "details",
      width: "30%",
    },
    {
      text: "TYPE",
      sortable: false,
      value: "type",
      width: "15%",
      align: "center",
    },
    {
      text: "ASSIGNED USERS",
      sortable: false,
      value: "assignee",
      width: this.isAdminOrOwner ? "25%" : "20%",
      align: "start",
    },
    {
      text: "ROOM #",
      sortable: false,
      value: "room",
      align: "center",
      width: "12%",
    },
    {
      text: "STATUS",
      sortable: false,
      value: "status",
      width: this.isAdminOrOwner ? "22%" : "28%",
      align: "center",
    },
    {
      text: "DEADLINE",
      sortable: false,
      value: "deadline",
      align: "end",
    },
  ];

  async created() {
    this.isDataLoading = true;
    if (this.$store.getters["isSuperAdmin"])
      this.$router.push({ path: "/company-list" });
    const score = this.$store.dispatch("dashboard/fetchSelfScore", {});
    const events = this.$store.dispatch("dashboard/fetchSelfEvents", {});
    const hires = this.$store.dispatch("dashboard/fetchNewHires");
    const anniversary = this.$store.dispatch("dashboard/fetchAnniversary");
    const birthday = this.$store.dispatch("dashboard/fetchBirthday");
    const notifications = this.$store.dispatch(
      "employeeDocuments/getDocumentNotification",
    );
    const tasks = this.$store.dispatch("task/fetchTasks");
    const employees = this.$store.dispatch("directory/loadAllEmployees");
    const companyRooms = this.$store.dispatch("room/fetchCompanyRooms");
    await Promise.all([
      score,
      events,
      hires,
      anniversary,
      birthday,
      notifications,
      tasks,
      employees,
      companyRooms,
    ]);
    this.isDataLoading = false;
  }

  isRole(role: string) {
    return checkSelfRole(role);
  }

  getCount(allList: AnniversaryEmployeeInfo[], type: string): number {
    if (type === "hireDate" || type === "birthday") {
      return allList.reduce(function(acc, currentValue) {
        if (currentValue[type]) {
          const day = moment().format("DD");
          const month = moment().format("MM");
          const [, hireMonth, hireDay] = currentValue[type]?.split("-") || [];
          if (day === hireDay && month === hireMonth) acc++;
        }
        return acc;
      }, 0);
    }
    return 0;
  }

  get todayAnniversaries() {
    const anniversaries = this.$store.getters[
      "dashboard/anniversaryList"
    ] as AnniversaryListing;
    if (!anniversaries?.anniversaries) return 0;
    const allList: AnniversaryEmployeeInfo[] = [];
    anniversaries.anniversaries?.map(item => {
      allList.push(...item.employees);
    });
    const count = this.getCount(allList, "hireDate");
    return count || 0;
  }
  get todayBirthdays() {
    const birthdays = this.$store.getters[
      "dashboard/birthdayList"
    ] as AnniversaryListing;
    if (!birthdays?.anniversaries) return 0;
    const allList: AnniversaryEmployeeInfo[] = [];
    birthdays.anniversaries?.map(item => {
      allList.push(...item.employees);
    });
    const count = this.getCount(allList, "birthday");
    return count || 0;
  }
  get NewHires() {
    const employees = [];
    const hires = this.$store.getters["dashboard/newHireList"];
    if (!hires) return 0;
    if (!hires.months) return 0;
    _.map(hires.months, month => {
      _.map(month.employees, employee => {
        employees.push(employee);
      });
    });
    return employees.length;
  }
  get scoreAndRank() {
    const rank = this.$store.getters["dashboard/scoreAndRank"];
    return rank;
  }
  get eventScore() {
    const score = this.$store.getters["dashboard/eventScore"];
    return score;
  }

  get selfInfo(): Employee {
    return this.$store.getters["getCurrentEmployeeSelf"];
  }

  get teamDocumentNotification() {
    const notification = this.$store.getters[
      "employeeDocuments/documentNotification"
    ];
    const documentProp: {
      icon: string | undefined;
      color: string | undefined;
    } = {
      icon: undefined,
      color: undefined,
    };
    if (notification?.expired?.length) {
      documentProp.icon = "mdi-alert-circle";
      documentProp.color = "red";
    } else if (notification?.warning?.length) {
      documentProp.icon = "mdi-alert";
      documentProp.color = "yellow";
    }
    return documentProp;
  }

  get selfDocumentNotification() {
    const selfDocumentNtficatn = this.$store.getters[
      "employeeDocuments/documentSelfNotification"
    ];
    const documentProp: {
      icon: string | undefined;
      color: string | undefined;
    } = {
      icon: undefined,
      color: undefined,
    };
    if (selfDocumentNtficatn?.expired?.length) {
      documentProp.icon = "mdi-alert-circle";
      documentProp.color = "red";
    } else if (selfDocumentNtficatn?.warning?.length) {
      documentProp.icon = "mdi-alert";
      documentProp.color = "yellow";
    }
    return documentProp;
  }

  get tasks() {
    const list = this.$store.getters["task/tasks"] || ([] as Tasks);

    return _.cloneDeep(_.orderBy(list, ["status", "deadline"], ["asc", "asc"]));
  }

  get employees() {
    const employeesList = this.$store.state.directory.employees ?? [];
    const list = _.map(
      _.orderBy(employeesList, [
        employee => `${employee.firstName} ${employee.lastName}`.toLowerCase(),
      ]),
      employee => ({
        ...employee,
        text: `${employee.firstName} ${employee.lastName}`,
      }),
    );

    return list;
  }

  employeeScore(event: EmployeeEventScore) {
    this.$router.push({
      path: `/scoreboard/breakdown/${event.eventMetricId}`,
      query: {
        title: this.selfInfo.firstName,
        eventName: event.eventMetricName,
        empId: this.selfInfo.id.toString(),
        isPoster: event.isPoster ? "true" : null,
      },
    });
  }

  routeTo(path: string) {
    this.$router.push(path);
  }

  deleteTask() {
    if (this.selectedTask.length) {
      this.deletingTask = true;
      this.selectedTask.forEach(async (task: ResponseTask) => {
        const requestBody = {
          id: task.id,
          companyId: task.companyId,
          departmentId: task.departmentId,
          roomId: task.room.roomId,
          details: task.details,
          assignee: task.assignee.employeeId,
          type: task.type,
          status: task.status,
          deadline: task.deadline,
        };

        await this.$store.dispatch("task/deleteTask", requestBody);
      });

      this.deletingTask = false;
      this.selectedTask = [];
    }
  }

  updateTask(item: ResponseTask) {
    const requestBody = {
      id: item.id,
      companyId: item.companyId,
      departmentId: item.departmentId,
      roomId: item.room.roomId,
      bedId: item.bed.bedId,
      inspectionDataId: item.inspectionDataId,
      location: item.location,
      details: item.details,
      assignee: item.assignee.employeeId,
      type:
        item.type.toLowerCase() == "guardian angel"
          ? item.type
          : this.self.id == item.assignee.employeeId
          ? "Self Assigned"
          : "Assigned",
      status: item.status,
      deadline: item.deadline,
    };
    this.$store.dispatch("task/updateTask", requestBody);
  }

  updateBulk() {
    if (this.selectedTask.length && this.employee != 0) {
      this.updatingTask = true;
      this.selectedTask.forEach(async (task: ResponseTask) => {
        const requestBody = {
          id: task.id,
          companyId: task.companyId,
          departmentId: task.departmentId,
          roomId: task.room.roomId,
          bedId: task.bed.bedId,
          inspectionDataId: task.inspectionDataId,
          location: task.location,
          details: task.details,
          assignee: this.employee,
          type:
            task.type.toLowerCase() == "guardian angel"
              ? task.type
              : this.self.id == this.employee
              ? "Self Assigned"
              : "Assigned",
          status: task.status,
          deadline: task.deadline,
        };

        await this.$store.dispatch("task/updateTask", requestBody);
      });

      this.updatingTask = false;
      this.selectedTask = [];
      this.employee = 0;
    }
  }
}
