import { RouteConfig } from "vue-router";
import Overview from "@/views/survey/overview/Overveiw.vue";
import DischargeFormList from "@/views/survey/form_generator/discharge/FormList.vue";
import DischargeQuestion from "@/views/survey/form_generator/discharge/Question.vue";
import AdmissionFormList from "@/views/survey/form_generator/admission/FormList.vue";
import AdmissionQuestion from "@/views/survey/form_generator/admission/Question.vue";
import Summary from "@/views/survey/summary/Summary.vue";
import OverviewDetails from "@/views/survey/overview/OverviewDetails.vue";

const routes: Array<RouteConfig> = [
  {
    path: "overview",
    components: {
      default: Overview,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      admissionForm: AdmissionFormList,
      admissionQuestion: AdmissionQuestion,
      dischargeForm: DischargeFormList,
      dischargeQuestion: DischargeQuestion,
    },
    meta: {
      title: "Overview",
      requiresAuth: true,
    },
  },
  {
    path: "overview/details",
    components: {
      default: OverviewDetails,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      admissionForm: AdmissionFormList,
      admissionQuestion: AdmissionQuestion,
      dischargeForm: DischargeFormList,
      dischargeQuestion: DischargeQuestion,
    },
    meta: {
      title: "Overview Details",
      requiresAuth: true,
    },
  },
  {
    path: "summary",
    components: {
      default: Summary,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      admissionForm: AdmissionFormList,
      admissionQuestion: AdmissionQuestion,
      dischargeForm: DischargeFormList,
      dischargeQuestion: DischargeQuestion,
    },
    meta: {
      title: "Summary",
      requiresAuth: true,
    },
  },
  {
    path: "discharge/form",
    components: {
      default: DischargeFormList,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      admissionForm: AdmissionFormList,
      admissionQuestion: AdmissionQuestion,
      dischargeForm: DischargeFormList,
      dischargeQuestion: DischargeQuestion,
    },
    meta: {
      title: "Discharge Form List",
      requiresAuth: true,
    },
  },
  {
    path: "discharge/form/:formId/question",
    components: {
      default: DischargeQuestion,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      admissionForm: AdmissionFormList,
      admissionQuestion: AdmissionQuestion,
      dischargeForm: DischargeFormList,
      dischargeQuestion: DischargeQuestion,
    },
    meta: {
      title: "Discharge Questions",
      requiresAuth: true,
    },
  },
  {
    path: "admission/form",
    components: {
      default: AdmissionFormList,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      admissionForm: AdmissionFormList,
      admissionQuestion: AdmissionQuestion,
      dischargeForm: DischargeFormList,
      dischargeQuestion: DischargeQuestion,
    },
    meta: {
      title: "Admission Form List",
      requiresAuth: true,
    },
  },
  {
    path: "admission/form/:formId/question",
    components: {
      default: AdmissionQuestion,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      admissionForm: AdmissionFormList,
      admissionQuestion: AdmissionQuestion,
      dischargeForm: DischargeFormList,
      dischargeQuestion: DischargeQuestion,
    },
    meta: {
      title: "Admission Questions",
      requiresAuth: true,
    },
  },
];

export default routes;
