

































import { Component, Vue } from "vue-property-decorator";

@Component
export default class CompanyDirectoryRoot extends Vue {
  get hasDetail() {
    return this.$route.params.id;
  }
  get hasTitles() {
    return this.$route.path.includes("titles") || this.$route.params.deptId;
  }
  get hasEmployee() {
    return this.$route.path.includes("employees") || this.$route.params.id;
  }
}
