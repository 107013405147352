/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FCMTokenResponse = {
    properties: {
        token_id: {
    type: 'number',
    isRequired: true,
},
    },
};