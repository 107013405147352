
























































































































































import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { dateFormat } from "@/common/date_format";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import { selfData } from "@/common/role_utils";
import { maxFileSize, requiredString } from "@/tool/validation";
import moment from "moment";

@Component({
  components: {
    VuePdfApp,
  },
})
export default class MobileUploadDoc extends Vue {
  @Ref("form") readonly form!: VForm;
  @Prop() readonly multipleProp!: boolean;
  @Prop() readonly url!: string;
  @Prop() readonly name!: string;
  @Prop() readonly id!: number;
  @Prop() readonly capture!: File;

  fileURL: string | ArrayBuffer | null | undefined = this.url || "";
  formModel = false;
  datePicker = false;
  documentName = this.name;
  expirationDate = "";
  formattedExpirationDate = "";
  license = "";
  rules = {
    required: requiredString,
    dateRequired: (v: string) => v !== "" && v !== undefined,
  };
  isPDF = false;
  config = {
    toolbar: false,
  };
  dragover = false;
  uploadedFiles: File[] = this.capture ? [this.capture] : [];
  multiple = this.multipleProp ? true : false;
  fileData: FormData | null = null;
  uploading = false;
  fileError = "";
  clr = 0;
  isExpiration = false;
  dateError: null | string = null;
  minDate = moment()
    .local()
    .format("YYYY-MM-DD");

  formatDate(date: string) {
    this.formattedExpirationDate = dateFormat(date);
  }

  removeFile(fileName: string) {
    const index = this.uploadedFiles.findIndex(
      (file: File) => file.name === fileName,
    );
    // If file is in uploaded files remove it
    if (index > -1) this.uploadedFiles.splice(index, 1);
    this.fileURL = "";
  }

  closeDialog(upload: boolean) {
    this.$emit("close", upload);
  }

  submitDoc() {
    this.closeDialog(true);
  }

  onChangeFile(event: File) {
    this.clr++;
    if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
    const validSize = maxFileSize(event, 5);
    if (validSize !== true) {
      this.fileError = validSize;
      return true;
    }
    this.uploadedFiles.push(event);
    this.formDataURL();
  }

  formDataURL() {
    const reader = new FileReader();
    if (this.uploadedFiles.length) {
      this.isPDF =
        this.uploadedFiles[0].type === "application/pdf" ? true : false;
      reader.onload = (event: ProgressEvent<FileReader>) => {
        this.fileURL = event.target?.result;
      };
      reader.readAsDataURL(this.uploadedFiles[0]);
    }
  }

  async submit() {
    this.dateError = null;
    const uploadLen = () => {
      if (this.uploadedFiles.length === 0) {
        this.fileError = "File required";
        return true;
      }
      return false;
    };
    const checkExpirationDate = () => {
      if (this.isExpiration) {
        this.expirationDate = "";
        return false;
      }
      if (!this.rules.dateRequired(this.expirationDate)) {
        this.dateError = "Required";
        return true;
      }
      return false;
    };
    if (!this.form.validate() || checkExpirationDate()) {
      uploadLen();
      return;
    }
    if (uploadLen()) return;
    const formData = new FormData();
    formData.append(
      "document",
      this.uploadedFiles[0],
      this.uploadedFiles[0]?.name,
    );
    try {
      this.uploading = true;
      const payload: {
        employeeId: number;
        departmentDocumentId: number;
        expirationDate?: string;
        license?: string;
        // eslint-disable-next-line
        requestBody: FormData;
        role: string;
      } = {
        employeeId: selfData().id,
        departmentDocumentId: this.id,
        expirationDate: this.expirationDate,
        license: this.license,
        requestBody: formData,
        role: selfData().roles[0],
      };
      await this.$store.dispatch("employeeDocuments/uploadDocument", payload);
      this.uploading = false;
      await this.$store.dispatch("employeeDocuments/getSelfDocumentCount");
      this.submitDoc();
    } catch (error) {
      this.uploading = false;
      this.$store.dispatch("alert/error", error);
    }
  }
}
