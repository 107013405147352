








import { Component, Vue } from "vue-property-decorator";
import Snackbar from "./components/Snackbar/Snackbar.vue";
import Notification from "./components/notification/NotificationSnackbar.vue";
import "cropperjs/dist/cropper.css";

@Component({
  components: {
    Snackbar,
    Notification,
  },
})
export default class App extends Vue {
  created() {
    if (this.$store.getters["isSuperAdmin"])
      this.$router.push({ path: "/company-list" });
  }
}
