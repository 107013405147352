import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  Form,
  Forms,
  Question,
  QuestionService,
  Questions,
  ResponseForm,
  ResponseQuestion,
} from "@/api";

type QuestionState = {
  questions: Questions | null;
  forms: Forms | null;
};

const state: QuestionState = {
  questions: null,
  forms: null,
};

const getters: GetterTree<QuestionState, RootState> = {
  questions: state => state.questions,
  forms: state => state.forms,
};

const mutations: MutationTree<QuestionState> = {
  SET_QUESTIONS: (state, questions) => {
    state.questions = questions;
  },
  ADD_QUESTION: (state, question) => {
    if (state.questions == null) {
      state.questions = [];
    }
    state.questions?.push(question);
  },
  REMOVE_QUESTION: (state, question) => {
    let index = state.questions?.findIndex(
      (item: ResponseQuestion) => item.id == question.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.questions?.splice(index, 1);
    }
  },
  SET_FORMS: (state, forms) => {
    state.forms = forms;
  },
  ADD_FORM: (state, form) => {
    if (state.forms == null) {
      state.forms = [];
    }
    state.forms.push(form);
  },
  REMOVE_FORM: (state, form) => {
    let index = state.forms?.findIndex(
      (item: ResponseForm) => item.id == form.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.forms?.splice(index, 1);
    }
  },
  UPDATE_FORM: (state, form) => {
    let index = state.forms?.findIndex(
      (item: ResponseForm) => item.id == form.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.forms?.splice(index, 1);
      state.forms?.push(form);
    }
  },
};

const actions: ActionTree<QuestionState, RootState> = {
  fetchQuestions: async ({ commit, rootState }, formId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const questions = await QuestionService.getQuestions({
        companyId,
        formId,
      });
      commit("SET_QUESTIONS", questions);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addQuestion: async (
    { commit, rootState },
    { requestBody, formId }: { requestBody: Question; formId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const question = await QuestionService.addQuestion({
        companyId,
        formId,
        requestBody,
      });
      commit("ADD_QUESTION", question);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeQuestion: async (
    { commit, rootState },
    { requestBody, formId }: { requestBody: ResponseQuestion; formId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const question = await QuestionService.deleteQuestion({
        companyId,
        formId,
        requestBody,
      });
      commit("REMOVE_QUESTION", question);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  sortQuestion: async (
    { commit, rootState },
    { requestBody, formId }: { requestBody: Questions; formId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      commit("SET_QUESTIONS", requestBody);
      await QuestionService.sortQuestion({
        companyId,
        requestBody,
        formId,
      });
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchForms: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const forms = await QuestionService.getForms({ companyId });
      commit("SET_FORMS", forms);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addForm: async ({ commit, rootState }, name: string) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const requestBody = {
        companyId,
        name,
        status: 1,
        isStandard: false,
      } as Form;
      const form = await QuestionService.addForm({ companyId, requestBody });
      commit("ADD_FORM", form);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeForm: async ({ commit, rootState }, requestBody: ResponseForm) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const form = await QuestionService.deleteForm({ companyId, requestBody });
      commit("REMOVE_FORM", form);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateForm: async ({ commit, rootState }, requestBody: ResponseForm) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const form = await QuestionService.updateForm({ companyId, requestBody });
      commit("UPDATE_FORM", form);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  copyCreateForm: async (
    { commit, rootState },
    { requestBody, formId }: { requestBody: Form; formId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const form = await QuestionService.copyForm({
        companyId,
        formId,
        requestBody,
      });
      commit("ADD_FORM", form);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const question: Module<QuestionState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
