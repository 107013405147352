var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-fill fill-height",attrs:{"id":"admin-tool-menu"}},[_c('v-container',[_c('v-list',[_c('v-list-item',{class:{
          active: _vm.activeMenu === 'department',
          'bg-gray': _vm.activeMenu === 'department',
        },on:{"click":function($event){return _vm.selectToolMenu('department')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Department")])],1)],1),_c('v-list-item',{class:{
          active: _vm.activeMenu === 'archive',
          'bg-gray': _vm.activeMenu === 'archive',
        },on:{"click":function($event){return _vm.selectToolMenu('archive_list')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Archive Employees")])],1)],1),_c('v-list-item',{class:{
          active: _vm.activeMenu === 'tarf',
          'bg-gray': _vm.activeMenu === 'tarf',
        },on:{"click":function($event){return _vm.selectToolMenu('tarf-history')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("TARF History")])],1)],1),(_vm.isOwner)?_c('v-list-item',{class:{
          active: _vm.activeMenu === 'budget',
          'bg-gray': _vm.activeMenu === 'budget',
        },on:{"click":function($event){return _vm.selectToolMenu('company-budget')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Add Budget")])],1)],1):_vm._e()],1),_c('EventMetrics'),_c('v-list',[_c('v-subheader',[_c('h1',{staticClass:"headline black--text text-decoration-underline"},[_vm._v(" Room Setup ")])]),_c('v-list-item',{class:{
          active: _vm.activeMenu === 'room',
          'bg-gray': _vm.activeMenu === 'room',
        },on:{"click":function($event){return _vm.selectToolMenu('room-setup')}}},[_c('v-list-item-content',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Facility Information")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }