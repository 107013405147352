/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class HelloService {

    /**
     * Just to make sure things are working
     * @returns string Hello
     * @throws ApiError
     */
    public static async sayHello(): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/hello`,
        });
        return result.body;
    }

}