import { RouteConfig } from "vue-router";
import ScoreboardDetail from "@/views/scoreboard/ScoreboardDetail.vue";
import ScoreboardBreakdown from "@/views/scoreboard/ScoreboardBreakdown.vue";
import EmployeeBreakdown from "@/views/scoreboard/EmployeeBreakdown.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    component: ScoreboardDetail,
    meta: {
      title: "Scoreboard",
      requiresAuth: true,
    },
  },
  {
    path: ":eventId",
    component: ScoreboardBreakdown,
    meta: {
      title: "Break Down",
      requiresAuth: true,
    },
  },
  {
    path: "breakdown/:id",
    component: EmployeeBreakdown,
    meta: {
      title: "Employee Breakdown",
      requiresAuth: true,
    },
  },
];
export default routes;
