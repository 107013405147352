/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ScoreboardSummary = {
    properties: {
        title: {
    type: 'string',
    isRequired: true,
},
        departmentId: {
    type: 'number',
    isRequired: true,
},
        summaryScores: {
    type: 'array',
    contains: {
        type: 'ScoreboardSummaryScore',
    },
    isRequired: true,
},
        beginAt: {
    type: 'string',
},
        endAt: {
    type: 'string',
},
    },
};