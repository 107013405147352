







































































import { DepartmentTitle } from "@/api";
import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";
import loading from "@/components/loader/loading.vue";

@Component({
  components: {
    loading,
  },
  watch: {
    async $route() {
      const hasDeptId = this.$route.params.deptId;
      const hasQueryLoad = this.$route.query.load;
      const hasTitleId = this.$route.params.titleId;
      if (
        hasDeptId &&
        hasDeptId !== "0" &&
        (hasQueryLoad || (this.$vuetify.breakpoint.lgAndUp && !hasTitleId))
      ) {
        this.$data.isDataLoading = true;
        await this.$store.dispatch(
          "departmentAdmin/selectDepartment",
          this.$route.params.deptId,
        );
        this.$data.isDataLoading = false;
      }
    },
  },
})
export default class TitleList extends Vue {
  isDataLoading = false;
  search = "";

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch(
      "departmentAdmin/getDepartmentTitles",
      this.$route.params.deptId,
    );
    this.isDataLoading = false;
  }

  get titileId() {
    return parseInt(this.$route.params.titleId);
  }

  get crumbs() {
    const department = this.$store.getters[
      "departmentAdmin/selectedDepartment"
    ];

    const crumb = [];
    if (department) {
      crumb.push({
        text: department.name,
        disabled: false,
        to: "/departments",
      });
    }

    return crumb;
  }

  clearSearch() {
    this.search = "";
  }

  selectTitle(title: DepartmentTitle) {
    const titleName = title.title.includes("/")
      ? title.title.split("/").join("-")
      : title.title;
    this.$router.push({
      path: `/departments/${this.$route.params.deptId}/title/${titleName}/${title.id}/employees`,
    });
  }

  get titles(): DepartmentTitle[] {
    const search = !this.search ? "" : this.search;

    const titles = this.$store.getters["departmentAdmin/titleDepartment"];

    const list = _.filter(titles, obj =>
      _.includes(`${obj.title.toLowerCase()} ${obj.id}`, search.toLowerCase()),
    );
    return list;
  }
}
