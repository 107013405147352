import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  ActiveSurveyForm,
  Form,
  Forms,
  ResponseForm,
  ResponseSurveyQuestion,
  SurveyDetail,
  SurveyOverviewDetails,
  SurveyQuestion,
  SurveyQuestions,
  SurveyRequest,
  SurveyService,
  SurveySummary,
} from "@/api";

type SurveyState = {
  beginAt: string | undefined;
  endAt: string | undefined;
  activeDischargeForm: ActiveSurveyForm | null;
  dischargeOverview: SurveyOverviewDetails | null;
  dischargeQuestions: SurveyQuestions | null;
  dischargeForms: Forms | null;
  dischargeSummary: Array<SurveySummary> | null;
  dischargeOverviewDetails: Array<SurveyDetail> | null;
  activeAdmissionForm: ActiveSurveyForm | null;
  admissionOverview: SurveyOverviewDetails | null;
  admissionQuestions: SurveyQuestions | null;
  admissionForms: Forms | null;
  admissionSummary: Array<SurveySummary> | null;
  admissionOverviewDetails: Array<SurveyDetail> | null;
};

const state: SurveyState = {
  beginAt: undefined,
  endAt: undefined,
  activeDischargeForm: null,
  dischargeOverview: null,
  dischargeQuestions: null,
  dischargeForms: null,
  dischargeSummary: null,
  dischargeOverviewDetails: null,
  activeAdmissionForm: null,
  admissionOverview: null,
  admissionQuestions: null,
  admissionForms: null,
  admissionSummary: null,
  admissionOverviewDetails: null,
};

const getters: GetterTree<SurveyState, RootState> = {
  beginAt: state => state.beginAt,
  endAt: state => state.endAt,
  activeDischargeForm: state => state.activeDischargeForm,
  dischargeOverview: state => state.dischargeOverview,
  dischargeQuestions: state => state.dischargeQuestions,
  dischargeForms: state => state.dischargeForms,
  dischargeSummary: state => state.dischargeSummary,
  dischargeOverviewDetails: state => state.dischargeOverviewDetails,
  activeAdmissionForm: state => state.activeAdmissionForm,
  admissionOverview: state => state.admissionOverview,
  admissionQuestions: state => state.admissionQuestions,
  admissionForms: state => state.admissionForms,
  admissionSummary: state => state.admissionSummary,
  admissionOverviewDetails: state => state.admissionOverviewDetails,
};

const mutations: MutationTree<SurveyState> = {
  SET_BEGIN_AT: (state, beginAt) => {
    state.beginAt = beginAt;
  },
  SET_END_AT: (state, endAt) => {
    state.endAt = endAt;
  },
  SET_ACTIVE_DISCHARGE_FORM: (state, form) => {
    state.activeDischargeForm = form;
  },
  SET_DISCHARGE_OVERVIEW: (state, overview) => {
    state.dischargeOverview = overview;
  },
  SET_DISCHARGE_QUESTIONS: (state, questions) => {
    state.dischargeQuestions = questions;
  },
  ADD_DISCHARGE_QUESTION: (state, question) => {
    if (state.dischargeQuestions == null) {
      state.dischargeQuestions = [];
    }
    state.dischargeQuestions?.push(question);
  },
  REMOVE_DISCHARGE_QUESTION: (state, question) => {
    let index = state.dischargeQuestions?.findIndex(
      (item: ResponseSurveyQuestion) => item.id == question.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.dischargeQuestions?.splice(index, 1);
    }
  },
  SET_DISCHARGE_FORMS: (state, forms) => {
    state.dischargeForms = forms;
  },
  ADD_DISCHARGE_FORM: (state, form) => {
    if (state.dischargeForms == null) {
      state.dischargeForms = [];
    }
    state.dischargeForms.push(form);
  },
  REMOVE_DISCHARGE_FORM: (state, form) => {
    let index = state.dischargeForms?.findIndex(
      (item: ResponseForm) => item.id == form.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.dischargeForms?.splice(index, 1);
    }
  },
  UPDATE_DISCHARGE_FORM: (state, form) => {
    let index = state.dischargeForms?.findIndex(
      (item: ResponseForm) => item.id == form.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.dischargeForms?.splice(index, 1);
      state.dischargeForms?.push(form);
    }
  },
  SET_DISCHARGE_SUMMARY: (state, summary) => {
    state.dischargeSummary = summary;
  },
  SET_DISCHARGE_OVERVIEW_DETAILS: (state, overview) => {
    state.dischargeOverviewDetails = overview;
  },
  SET_ACTIVE_ADMISSION_FORM: (state, form) => {
    state.activeAdmissionForm = form;
  },
  SET_ADMISSION_OVERVIEW: (state, overview) => {
    state.admissionOverview = overview;
  },
  SET_ADMISSION_QUESTIONS: (state, questions) => {
    state.admissionQuestions = questions;
  },
  ADD_ADMISSION_QUESTION: (state, question) => {
    if (state.admissionQuestions == null) {
      state.admissionQuestions = [];
    }
    state.admissionQuestions?.push(question);
  },
  REMOVE_ADMISSION_QUESTION: (state, question) => {
    let index = state.admissionQuestions?.findIndex(
      (item: ResponseSurveyQuestion) => item.id == question.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.admissionQuestions?.splice(index, 1);
    }
  },
  SET_ADMISSION_FORMS: (state, forms) => {
    state.admissionForms = forms;
  },
  ADD_ADMISSION_FORM: (state, form) => {
    if (state.admissionForms == null) {
      state.admissionForms = [];
    }
    state.admissionForms.push(form);
  },
  REMOVE_ADMISSION_FORM: (state, form) => {
    let index = state.admissionForms?.findIndex(
      (item: ResponseForm) => item.id == form.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.admissionForms?.splice(index, 1);
    }
  },
  UPDATE_ADMISSION_FORM: (state, form) => {
    let index = state.admissionForms?.findIndex(
      (item: ResponseForm) => item.id == form.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.admissionForms?.splice(index, 1);
      state.admissionForms?.push(form);
    }
  },
  SET_ADMISSION_SUMMARY: (state, summary) => {
    state.admissionSummary = summary;
  },
  SET_ADMISSION_OVERVIEW_DETAILS: (state, overview) => {
    state.admissionOverviewDetails = overview;
  },
};

const actions: ActionTree<SurveyState, RootState> = {
  setBeginAt: ({ commit }, beginAt?: string) => {
    commit("SET_BEGIN_AT", beginAt);
  },
  setEndAt: ({ commit }, endAt?: string) => {
    commit("SET_END_AT", endAt);
  },
  fetchActiveSurveyForm: async (
    { rootState, commit, getters },
    force = false,
  ) => {
    const discharge = getters["activeDischargeForm"];
    const admission = getters["activeAdmissionForm"];
    if (!force) {
      if (discharge.id && admission.id) {
        return;
      }
    }
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const admissionForm = await SurveyService.getActiveAdmissionForm({
        companyId,
      });
      const dischargeForm = await SurveyService.getActiveDischargeForm({
        companyId,
      });
      commit("SET_ACTIVE_ADMISSION_FORM", admissionForm);
      commit("SET_ACTIVE_DISCHARGE_FORM", dischargeForm);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchQuestions: async (
    { commit, rootState },
    {
      formId,
      dispatchAdmission,
    }: { formId: number; dispatchAdmission: boolean },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        const questions = await SurveyService.getAdmissionQuestions({
          companyId,
          formId,
        });
        commit("SET_ADMISSION_QUESTIONS", questions);
      } else {
        const questions = await SurveyService.getDischargeQuestions({
          companyId,
          formId,
        });
        commit("SET_DISCHARGE_QUESTIONS", questions);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addQuestion: async (
    { commit, rootState },
    {
      requestBody,
      formId,
      dispatchAdmission,
    }: {
      requestBody: SurveyQuestion;
      formId: number;
      dispatchAdmission: boolean;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        const question = await SurveyService.addAdmissionQuestion({
          companyId,
          formId,
          requestBody,
        });
        commit("ADD_ADMISSION_QUESTION", question);
      } else {
        const question = await SurveyService.addDischargeQuestion({
          companyId,
          formId,
          requestBody,
        });
        commit("ADD_DISCHARGE_QUESTION", question);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeQuestion: async (
    { commit, rootState },
    {
      requestBody,
      formId,
      dispatchAdmission,
    }: {
      requestBody: ResponseSurveyQuestion;
      formId: number;
      dispatchAdmission: boolean;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        const question = await SurveyService.deleteAdmissionQuestion({
          companyId,
          formId,
          requestBody,
        });
        commit("REMOVE_ADMISSION_QUESTION", question);
      } else {
        const question = await SurveyService.deleteDischargeQuestion({
          companyId,
          formId,
          requestBody,
        });
        commit("REMOVE_DISCHARGE_QUESTION", question);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  sortQuestion: async (
    { commit, rootState },
    {
      requestBody,
      formId,
      dispatchAdmission,
    }: {
      requestBody: SurveyQuestions;
      formId: number;
      dispatchAdmission: boolean;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        commit("SET_ADMISSION_QUESTIONS", requestBody);
        await SurveyService.sortAdmissionQuestion({
          companyId,
          requestBody,
          formId,
        });
      } else {
        commit("SET_DISCHARGE_QUESTIONS", requestBody);
        await SurveyService.sortDischargeQuestion({
          companyId,
          requestBody,
          formId,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchForms: async ({ commit, rootState }, dispatchAdmission: boolean) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        const forms = await SurveyService.getAdmissionForms({ companyId });
        commit("SET_ADMISSION_FORMS", forms);
      } else {
        const forms = await SurveyService.getDischargeForms({ companyId });
        commit("SET_DISCHARGE_FORMS", forms);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addForm: async (
    { commit, rootState },
    { name, dispatchAdmission }: { name: string; dispatchAdmission: boolean },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const requestBody = {
        companyId,
        name,
        status: 1,
        isStandard: false,
      } as Form;
      if (dispatchAdmission) {
        const form = await SurveyService.addAdmissionForm({
          companyId,
          requestBody,
        });
        commit("ADD_ADMISSION_FORM", form);
      } else {
        const form = await SurveyService.addDischargeForm({
          companyId,
          requestBody,
        });
        commit("ADD_DISCHARGE_FORM", form);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeForm: async (
    { commit, rootState },
    {
      requestBody,
      dispatchAdmission,
    }: { requestBody: ResponseForm; dispatchAdmission: boolean },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        const form = await SurveyService.deleteAdmissionForm({
          companyId,
          requestBody,
        });
        commit("REMOVE_ADMISSION_FORM", form);
      } else {
        const form = await SurveyService.deleteDischargeForm({
          companyId,
          requestBody,
        });
        commit("REMOVE_DISCHARGE_FORM", form);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateForm: async (
    { commit, rootState },
    {
      requestBody,
      dispatchAdmission,
    }: { requestBody: ResponseForm; dispatchAdmission: boolean },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        const form = await SurveyService.updateAdmissionForm({
          companyId,
          requestBody,
        });
        commit("UPDATE_ADMISSION_FORM", form);
      } else {
        const form = await SurveyService.updateDischargeForm({
          companyId,
          requestBody,
        });
        commit("UPDATE_DISCHARGE_FORM", form);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  copyCreateForm: async (
    { commit, rootState },
    {
      requestBody,
      formId,
      dispatchAdmission,
    }: { requestBody: Form; formId: number; dispatchAdmission: boolean },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      if (dispatchAdmission) {
        const form = await SurveyService.copyAdmissionForm({
          companyId,
          formId,
          requestBody,
        });
        commit("ADD_ADMISSION_FORM", form);
      } else {
        const form = await SurveyService.copyDischargeForm({
          companyId,
          formId,
          requestBody,
        });
        commit("ADD_DISCHARGE_FORM", form);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  submitSurvey: async (
    { commit, rootState, dispatch },
    {
      requestBody,
      dispatchAdmission,
    }: { requestBody: SurveyRequest; dispatchAdmission: boolean },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      if (dispatchAdmission) {
        await SurveyService.addAdmissionSurvey({ companyId, requestBody });
      } else {
        await SurveyService.addDischargeSurvey({ companyId, requestBody });
      }
      dispatch("fetchOverview", { dispatchAdmission });
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchSummary: async (
    { commit, rootState },
    {
      beginAt,
      endAt,
      floorId,
      roomId,
      physicianId,
      dispatchAdmission,
    }: {
      beginAt?: string;
      endAt?: string;
      floorId?: number;
      roomId?: number;
      physicianId?: number;
      dispatchAdmission: boolean;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const payload = {
        companyId,
        beginAt,
        endAt,
        floorId,
        physicianId,
        roomId,
      };
      if (dispatchAdmission) {
        const summary = await SurveyService.getAdmissionSurveySummary(payload);
        commit("SET_ADMISSION_SUMMARY", summary);
      } else {
        const summary = await SurveyService.getDischargeSurveySummary(payload);
        commit("SET_DISCHARGE_SUMMARY", summary);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchOverview: async (
    { commit, rootState },
    {
      beginAt,
      endAt,
      dispatchAdmission,
    }: {
      beginAt?: string;
      endAt?: string;
      dispatchAdmission: boolean;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const payload = {
        companyId,
        beginAt,
        endAt,
      };
      if (dispatchAdmission) {
        const summary = await SurveyService.getAdmissionOverviewDetails(
          payload,
        );
        commit("SET_ADMISSION_OVERVIEW", summary);
      } else {
        const summary = await SurveyService.getDischargeOverviewDetails(
          payload,
        );
        commit("SET_DISCHARGE_OVERVIEW", summary);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchOverviewDetails: async (
    { commit, rootState },
    {
      beginAt,
      endAt,
      dispatchAdmission,
      overviewId,
    }: {
      beginAt?: string;
      endAt?: string;
      dispatchAdmission: boolean;
      overviewId: number;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const payload = {
        companyId,
        beginAt,
        endAt,
        overviewId,
      };
      if (dispatchAdmission) {
        const overview = await SurveyService.getAdmissionSurveyOverviewDetails(
          payload,
        );
        commit("SET_ADMISSION_OVERVIEW_DETAILS", overview);
      } else {
        const overview = await SurveyService.getDischargeSurveyOverviewDetails(
          payload,
        );
        commit("SET_DISCHARGE_OVERVIEW_DETAILS", overview);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  clearSummaryDetails: ({ commit }) => {
    commit("SET_ADMISSION_OVERVIEW_DETAILS", null);
    commit("SET_DISCHARGE_OVERVIEW_DETAILS", null);
  },
};

export const survey: Module<SurveyState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
