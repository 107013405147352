import { RouteConfig } from "vue-router";
import EmployeeList from "@/views/company_directory/EmployeeList.vue";
import EmployeeProfile from "@/views/company_directory/EmployeeProfile.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "Company Directory",
    components: {
      default: EmployeeList,
      employeeList: EmployeeList,
      employeeDetail: EmployeeProfile,
    },
    meta: {
      title: "Company Directory",
      requiresAuth: true,
    },
  },
  {
    path: "profile/:id",
    name: "Employee Detail",
    components: {
      default: EmployeeProfile,
      employeeList: EmployeeList,
      employeeDetail: EmployeeProfile,
    },
    meta: {
      title: "Employee Detail",
      requiresAuth: true,
    },
  },
];

export default routes;
