/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CronJobResponse } from '../models/CronJobResponse';
import { request as __request } from '../core/request';

export class CronJobService {

    /**
     * cronjob api which update the employee document status at mid night
     * @returns CronJobResponse The success msg
     * @throws ApiError
     */
    public static async getCronJob(): Promise<CronJobResponse> {
        const result = await __request({
            method: 'GET',
            path: `/cron/employee-document`,
        });
        return result.body;
    }

}