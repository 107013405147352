


















































import { Company } from "@/api/models/Company";
import { VForm } from "@/common/vuetify_types";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import EditConfirmModal from "@/views/super_admin/company/EditConfirmModal.vue";

@Component({
  components: {
    EditConfirmModal,
  },
})
export default class EditCompany extends Vue {
  @Ref("editForm") readonly form!: VForm;
  @Prop(Object) readonly company!: Company;

  valid = true;
  loading = false;
  editConfirmModal = false;
  name = this.company.name;
  companyCode = this.company.companyCode;
  formRules = [
    (v: string) => !!v || "Required",
    (v: string) => !v || v.trim() !== "" || "Space only is not accepted",
  ];
  emailValid = (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid";

  close() {
    this.$emit("close");
  }

  async save() {
    const isValid = this.form.validate();
    if (isValid) {
      const requestBody = {
        id: this.company.id,
        name: this.name.trim(),
        companyCode: this.companyCode.trim(),
        createdAt: this.company.createdAt,
      };
      try {
        this.loading = true;
        await this.$store.dispatch("superAdmin/updateCompany", requestBody);
        this.editConfirmModal = false;
        this.$emit("close");
        this.loading = false;
        this.form.reset();
      } catch (error) {
        console.log(error);
      }
    }
  }
}
