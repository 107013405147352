/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddBed } from '../models/AddBed';
import type { Bed } from '../models/Bed';
import type { Beds } from '../models/Beds';
import { request as __request } from '../core/request';

export class BedService {

    /**
     * Get a company floor room beds
     * @returns Beds Company Floor Rooms
     * @throws ApiError
     */
    public static async getRoomBeds({
companyId,
floorId,
roomId,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
}): Promise<Beds> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/bed`,
        });
        return result.body;
    }

    /**
     * Add a company floor room bed
     * @returns Bed added Company Floor Room a Bed
     * @throws ApiError
     */
    public static async addRoomBed({
companyId,
floorId,
roomId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
requestBody: AddBed,
}): Promise<Bed> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/bed`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update a company floor room bed
     * @returns Bed Company Floor Room Bed updated
     * @throws ApiError
     */
    public static async updateRoomBed({
companyId,
floorId,
roomId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
requestBody: Bed,
}): Promise<Bed> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/bed`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete a company floor room bed
     * @returns any Company Floor Room Bed deleted successfully
     * @throws ApiError
     */
    public static async deleteRoomBed({
companyId,
floorId,
roomId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
requestBody: Bed,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/bed`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a company beds
     * @returns Beds Company Beds
     * @throws ApiError
     */
    public static async getCompanyBeds({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Beds> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/bed`,
        });
        return result.body;
    }

}