/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InspectionRequest = {
    properties: {
        inspection: {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        formId: {
    type: 'number',
    isRequired: true,
},
        roomId: {
    type: 'number',
    isRequired: true,
},
        bedId: {
    type: 'number',
},
        inspectedBy: {
    type: 'number',
    isRequired: true,
},
        submittedDate: {
    type: 'string',
    isRequired: true,
},
        submittedTime: {
    type: 'string',
    isRequired: true,
},
    },
    isRequired: true,
},
        patient: {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        roomId: {
    type: 'number',
    isRequired: true,
},
        inspectionId: {
    type: 'number',
},
        name: {
    type: 'string',
},
    },
    isRequired: true,
},
        data: {
    type: 'array',
    contains: {
        type: 'InspectionData',
    },
    isRequired: true,
},
    },
};