



















































































































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { InspectionFormCollection } from "@/common/InspectionDataCollection";
import { VForm } from "@/common/vuetify_types";
import { CurrentActiveForm, InspectionRequest } from "@/api";
import moment from "moment";
import {
  requiredString,
  noSpace,
  requiredSelectWithZero,
} from "@/tool/validation";
import _ from "lodash";
import { dateFormat } from "@/common/date_format";
import { selfData } from "@/common/role_utils";
import ConfirmModule from "@/components/confirmationModule/ConfirmationModule.vue";

@Component({
  components: {
    ConfirmModule,
  },
})
export default class InspectionForm extends Vue {
  @Ref("initialForm") initialForm!: VForm;
  @Ref("form") form!: VForm;

  step = 0;

  self = selfData();
  room = 0;
  formId = 0;
  date = "";
  datePicker = false;
  timePicker = false;
  submitting = false;
  confirmClose = false;
  minDate = moment()
    .local()
    .format("YYYY-MM-DD");
  textRules = [requiredString, noSpace];
  selectRules = [requiredSelectWithZero];
  commentError = "";
  feedbackError = "";
  meetError = "";
  ratingError = "";

  inspectionData: InspectionFormCollection[] = [];
  inspectionReq: InspectionRequest = {
    inspection: {
      companyId: 0,
      formId: 0,
      bedId: 0,
      roomId: 0,
      inspectedBy: 0,
      submittedDate: "",
      submittedTime: "",
    },
    patient: {
      companyId: 0,
      roomId: 0,
      name: "",
    },
    data: [],
  };

  async created() {
    await this.$store.dispatch("room/fetchCompanyRooms");
  }

  get companyRooms() {
    return this.$store.getters["room/companyRooms"] || [];
  }

  get questionLists() {
    const form = this.$store.getters[
      "inspection/activeForm"
    ] as CurrentActiveForm;
    this.formId = form.id;
    let index = 1;
    this.inspectionData = [];
    _.sortBy(form?.questions || [], ["order"], ["desc"]);
    _.forEach(form?.questions || [], question => {
      const data = {
        index: index,
        question: question,
        data: {
          questionId: question.id,
          comment: undefined,
          feedback: undefined,
          ratingScore: 0,
          meet: undefined,
          skipRating: false,
        },
      };
      this.inspectionData.push(JSON.parse(JSON.stringify(data)));
      index++;
    });
    return this.inspectionData;
  }

  selectFloor() {
    const item = _.find(this.companyRooms, room => room.id == this.room);
    const floorId = item.floorId;
    this.$store.dispatch("bed/fetchRoomBeds", {
      floorId: floorId,
      roomId: this.room,
    });
  }

  get roomBeds() {
    return this.$store.getters["bed/beds"] || [];
  }

  formatDate(date: string) {
    this.inspectionReq.inspection.submittedDate = dateFormat(date);
  }

  nextInspection() {
    const valid = this.initialForm.validate();
    if (valid) {
      this.inspectionReq.inspection.companyId = this.self.companyId;
      this.inspectionReq.inspection.formId = this.formId;
      this.inspectionReq.inspection.inspectedBy = this.self.id;
      this.inspectionReq.inspection.roomId = this.room;
      this.inspectionReq.patient.companyId = this.self.companyId;
      this.inspectionReq.patient.roomId = this.room;
      this.step++;
    }
  }

  clearError() {
    this.commentError = "";
    this.feedbackError = "";
    this.meetError = "";
    this.ratingError = "";
  }

  validate(item: InspectionFormCollection): boolean {
    this.clearError();
    let valid = true;
    if (!item.question.ratingScore) {
      if (item.data.meet == undefined) {
        this.meetError = "Required";
        valid = false;
      }
    } else {
      if (!item.data.skipRating && !item.data.ratingScore) {
        this.meetError = "Give 1-5 rating or select checkbox for skip.";
        valid = false;
      }
    }

    return valid;
  }

  next(item: InspectionFormCollection) {
    const valid = this.validate(item);
    if (valid) this.step++;
  }

  async submit(item: InspectionFormCollection) {
    const valid = this.validate(item);
    if (valid) {
      this.submitting = !this.submitting;
      this.inspectionReq.data = [];
      _.forEach(this.inspectionData, item => {
        if (item?.data.skipRating) item.data.ratingScore = 0;
        this.inspectionReq.data.push({
          id: item.data.id,
          inspectionId: item.data.inspectionId,
          questionId: item.data.questionId,
          comment: item.data.comment,
          feedback: item.data.feedback,
          ratingScore: item.data.ratingScore,
          meet: item.data.meet,
        });
      });
      await this.$store.dispatch(
        "inspection/addInspection",
        this.inspectionReq,
      );
      this.submitting = !this.submitting;
      this.step++;
    }
  }

  close() {
    this.$emit("close");
  }

  recognize() {
    this.$emit("recognize");
  }
}
