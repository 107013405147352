/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TrackedEventMetricsByDepartment = {
    properties: {
        trackedMetrics: {
    type: 'array',
    contains: {
        type: 'DepartmentEventMetricsTrackingList',
    },
    isRequired: true,
},
    },
};