

























































































































































import { Component, Vue } from "vue-property-decorator";
import OptionTemplateDialog from "./OptionTemplateDialog.vue";
import NameFormDialog from "./NameFormDialog.vue";
import Loading from "@/components/loader/loading.vue";
import { dateFormat } from "@/common/date_format";
import { Form, Forms, ResponseForm } from "@/api";
import { formStatus, getStatus } from "@/common/form_status";
import _ from "lodash";

@Component({
  components: {
    OptionTemplateDialog,
    NameFormDialog,
    Loading,
  },
})
export default class FormList extends Vue {
  loading = false;
  createDialog = false;
  publishConfirm = false;
  nameConfirm = false;
  creating = false;
  selectedForm = 0;
  formatDate = dateFormat;
  getStatus = getStatus;
  formStatus = formStatus;
  status = -1;
  removing = false;
  copyConfirm = false;
  copying = false;
  deactivating = false;
  saved = false;

  headers = [
    {
      text: "",
      align: "start",
      value: "id",
      width: "5%",
    },
    {
      text: "Name",
      align: "start",
      sortable: false,
      value: "name",
      width: "60%",
    },
    {
      text: "Status",
      sortable: false,
      value: "status",
      width: "15%",
      align: "center",
    },
    {
      text: "Created",
      sortable: false,
      value: "createdAt",
      width: "20%",
      align: "end",
    },
  ];

  async created() {
    this.loading = true;
    await this.$store.dispatch("question/fetchForms");
    this.loading = false;
  }

  get forms() {
    const list = this.$store.getters["question/forms"] as Forms;
    list?.map(form => {
      if (form.status === 3) this.selectedForm = form.id;
      return form;
    });
    if (list === null) return null;
    const forms = _.orderBy(list, ["status", "createdAt"], ["desc", "desc"]);
    if (this.status === -1) return forms;
    else return forms.filter(form => form.status === this.status);
  }

  async createBlank() {
    this.createDialog = !this.createDialog;
    this.nameConfirm = !this.nameConfirm;
  }

  async createTemplate() {
    this.createDialog = !this.createDialog;
  }

  async save(name: string) {
    this.creating = true;
    await this.$store.dispatch("question/addForm", name);
    this.creating = false;
    this.saved = true;
    this.nameConfirm = !this.nameConfirm;
  }

  goTo(form: ResponseForm) {
    this.$router.push(`/guardian-angel/form/${form.id}/question`);
  }

  async remove() {
    if (this.selectedForm > 0 && this.forms) {
      const requestBody = this.forms.filter(
        form => form.id === this.selectedForm,
      )[0];
      this.removing = true;
      await this.$store.dispatch("question/removeForm", requestBody);
      this.removing = false;
    } else {
      this.$store.dispatch("alert/warning", "select a form to take action");
    }
  }

  async createCopy(name: string) {
    if (this.selectedForm > 0 && this.forms) {
      const form = this.forms.filter(form => form.id === this.selectedForm)[0];
      const requestBody = {
        companyId: form.companyId,
        name,
        isStandard: false,
      } as Form;
      this.copying = true;
      await this.$store.dispatch("question/copyCreateForm", {
        formId: this.selectedForm,
        requestBody,
      });
      this.copying = false;
      this.copyConfirm = !this.copyConfirm;
    } else {
      this.$store.dispatch("alert/warning", "select a form to take action");
    }
  }

  async setInactive() {
    if (this.selectedForm > 0 && this.forms) {
      const requestBody = this.forms.filter(
        form => form.id === this.selectedForm,
      )[0];
      requestBody.status = 2;
      this.deactivating = true;
      await this.$store.dispatch("question/updateForm", requestBody);
      this.deactivating = false;
    } else {
      this.$store.dispatch("alert/warning", "select a form to take action");
    }
  }
}
