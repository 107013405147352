

















































































































































































import { dateFormat } from "@/common/date_format";
import { Component, Vue } from "vue-property-decorator";
import { approvalStatus, expiredStatus } from "@/common/document_statuses";
import PreviewDocument from "@/components/EmployeeDocument/PreviewDocument.vue";
import { fetchDocumentURL } from "@/common/document_url";
import { employeeheader } from "@/common/document_table_header";
import { checkSelf, checkSelfRole } from "@/common/role_utils";

@Component({
  components: { PreviewDocument },
  watch: {
    async $route() {
      this.$data.isDataLoading = true;
      await this.$store.dispatch("employeeDocuments/getDocumentList", {
        departmentDocumentId: this.$route.params.documentId,
      });
      this.$data.isDataLoading = false;
    },
  },
})
export default class DocumentList extends Vue {
  isExpired: { status: string; id: number; icon: string } | null = null;
  notExpired: { status: string; id: number; icon: string } | null = null;
  isApproved: { status: string; id: number } | null = null;
  isDenied: { status: string; id: number } | null = null;
  isDataLoading = false;
  docName = "";
  // TODO: remove after socket.io implemented
  intervalSetup: null | ReturnType<typeof setTimeout> = null;

  async created() {
    this.fetchDocumentLists();
    // TODO: remove after socket.io implemented
    this.intervalSetup = setInterval(this.fetchDocumentLists, 180000);

    this.isExpired = expiredStatus.find(item => item.id === 0) ?? null;
    this.notExpired = expiredStatus.find(item => item.id === 1) ?? null;
    this.isApproved = approvalStatus.find(item => item.id === 1) ?? null;
    this.isDenied = approvalStatus.find(item => item.id === 2) ?? null;
  }

  destroyed() {
    if (this.intervalSetup) clearInterval(this.intervalSetup);
  }

  get headers() {
    return employeeheader;
  }

  async fetchDocumentLists() {
    this.isDataLoading = true;
    await this.$store.dispatch("employeeDocuments/getDocumentList", {
      departmentDocumentId: this.$route.params.documentId,
    });
    this.isDataLoading = false;
  }

  get activeDocuments() {
    const documentList = this.$store.getters["employeeDocuments/documentList"];
    return documentList?.active ?? [];
  }

  get archivedDocuments() {
    const archived = this.$store.getters["employeeDocuments/documentList"];
    return archived?.archive ?? [];
  }

  get documentName(): string {
    const docName = this.$route.query.title;
    if (!this.isDataLoading) this.docName = docName?.toString();
    return this.docName;
  }

  formatDate(date: string) {
    const formattedDate = dateFormat(date);
    if (formattedDate === "01/01/0001") return "No Expiration";
    return formattedDate;
  }

  expiredStatus(status: string) {
    const res = this.isExpired?.status === status;
    if (res) return 0;
    else if (this.notExpired?.status === status) return 2;
    else return 1;
  }

  approvalStatus(approval: string) {
    if (this.isApproved?.status === approval) return 1;
    if (this.isDenied?.status === approval) return 2;
    else return 0;
  }

  async downloadItem(id: number, label: string, employeeId: number) {
    if (checkSelfRole("owner") || checkSelf(employeeId)) {
      const res = await fetchDocumentURL(id);
      if (!res.length) {
        this.$store.dispatch("alert/info", "Unable to generate download link.");
        return;
      }
      const url = res;
      const response = await fetch(url);
      const blob = await response.blob();
      const _url = await URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = _url;
      link.download = label || this.documentName;
      link.click();
      URL.revokeObjectURL(link.href);
    } else {
      this.$store.dispatch(
        "alert/warning",
        "You are not authorized to preview or download the selected file.",
      );
    }
  }

  back() {
    this.$router.back();
  }
}
