/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TarfReasonsResponseBody = {
    properties: {
        reasons: {
    type: 'array',
    contains: {
        type: 'TarfReason',
    },
},
    },
};