
































import { Employee } from "@/api";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavigationItem extends Vue {
  @Prop() icon!: string;
  @Prop() label!: string;
  @Prop() rootPath!: string;
  @Prop() notice!: string;

  isSelected() {
    if (this.rootPath == "/") {
      return this.$route.path == "/";
    } else {
      return this.$route.path.startsWith(this.rootPath);
    }
  }

  go() {
    this.$router.push({ path: this.rootPath });
  }

  get self(): Employee {
    return this.$store.getters["getCurrentEmployeeSelf"];
  }

  get isSuperAdmin() {
    return this.$store.getters["isSuperAdmin"];
  }

  get isOpen() {
    return this.$store.state.navigationRailIsOpen;
  }
}
