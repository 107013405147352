






















































import { Component, Vue } from "vue-property-decorator";
import { maxFileSize } from "@/tool/validation";

@Component
export default class ImageInput extends Vue {
  showImage = false;
  showFullImage = false;
  fileName = "";
  selectedFile: string | ArrayBuffer | null | undefined = "";
  imageRules = (v: File) => maxFileSize(v, 2);
  clr = 0;

  selectFile(event: File) {
    this.clr++;
    const isValid = this.imageRules(event);
    if (isValid === true) {
      if (event) {
        this.fileName = event.name;
        this.$emit("updateFile", event);
        if (event.type.includes("image")) {
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>) => {
            this.selectedFile = event.target?.result;
          };
          this.showImage = true;
          reader.readAsDataURL(event);
        } else {
          console.log(event);
        }
      }
    } else {
      this.showImage = false;
      this.fileName = "";
      this.selectedFile = "";
      this.$store.dispatch("alert/warning", isValid);
    }
  }

  removeImage() {
    this.showImage = false;
    this.fileName = "";
    this.selectedFile = "";
    this.$emit("remove");
  }

  showFullSize() {
    this.showFullImage = true;
  }
}
