













































import { Component, Vue } from "vue-property-decorator";
import FormMenu from "@/views/forms/employee/FormMenu.vue";

@Component({
  components: {
    FormMenu,
  },
})
export default class EmployeeLayout extends Vue {
  get isNewRequest() {
    return this.$route.path.includes("new") && this.$route.params.id;
  }
  get isExcusedRequest() {
    return this.$route.path.includes("/excused") && this.$route.params.id;
  }
  get isUnexcusedRequest() {
    return this.$route.path.includes("/unexcused") && this.$route.params.id;
  }
  get isProcessedRequest() {
    return this.$route.path.includes("processed") && this.$route.params.id;
  }
  get isDenyRequest() {
    return this.$route.path.includes("denied") && this.$route.params?.id;
  }
  get isResubmitRequest() {
    return this.$route.path.includes("resubmit") && this.$route.params?.id;
  }
  get isNewForm() {
    return this.$route.path.includes("request");
  }
}
