













































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PublishDialog extends Vue {
  @Prop(Boolean) loading!: boolean;

  ok() {
    this.$emit("publish");
  }

  close() {
    this.$emit("close");
  }
}
