/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DepartmentTitleOrderRequestObject = {
    properties: {
        titleId: {
    type: 'number',
    isRequired: true,
},
        order: {
    type: 'number',
    isRequired: true,
},
    },
};