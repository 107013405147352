


























































import { Vue, Component } from "vue-property-decorator";
import { AnniversaryListing } from "@/api";
import loading from "@/components/loader/loading.vue";
import { list as groupBirthdayList } from "@/common/date_group_order";

@Component({
  components: {
    loading,
  },
})
export default class BirthdayList extends Vue {
  isDataLoading = false;

  selectEmployee(id: number) {
    this.$router.push(`/company-directory/profile/${id}`);
  }
  get birthdays() {
    const list = this.$store.getters[
      "dashboard/birthdayList"
    ] as AnniversaryListing;
    const birthdayList = groupBirthdayList(list, false);
    return birthdayList;
  }
}
