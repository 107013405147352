



































































import { Component, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { AuthService } from "@/api";
import * as validation from "@/tool/validation";

@Component
export default class ActivateAccount extends Vue {
  @Ref("employeeActivationForm") employeeActivationForm!: VForm;

  email = "";
  snackbar = false;
  errorText = "";
  emailError = "";
  messageBox = false;
  loading = false;
  messageText = "";

  rules = {
    required: validation.requiredString,
    validEmail: validation.emailValid,
  };

  showError(text: string) {
    this.errorText = text;
    this.snackbar = true;
  }

  async sendResetCode() {
    this.messageText =
      "Please check your email for the one time verification code. If you have not received the code, enter your valid login email and click Get Reset Code again.";
    if (this.employeeActivationForm.validate()) {
      try {
        this.loading = true;
        await AuthService.sendResetPasswordLinkBySelf({
          requestBody: { email: this.email },
        });
        this.messageBox = !this.messageBox;
        this.loading = false;
      } catch (e) {
        if (e instanceof Error) {
          this.$store.dispatch("alert/warning", e.message);
        }
        this.loading = false;
      }
    }
  }

  get isForgotCompanyCode() {
    return this.$route.path.includes("forgot");
  }

  async sendCompanyCode() {
    this.messageText =
      "Please check your email for the Company code. If you have not received the code, enter your valid login email and click Get Company Code again.";
    if (this.employeeActivationForm.validate()) {
      try {
        this.loading = true;
        await AuthService.sendCompanyCode({ email: this.email });
        this.messageBox = !this.messageBox;
        this.loading = false;
      } catch (e) {
        if (e instanceof Error) {
          this.$store.dispatch("alert/warning", e.message);
        }
        this.loading = false;
      }
    }
  }

  goTo(path: string) {
    this.$router.push({ path });
  }

  close() {
    this.messageBox = !this.messageBox;
  }
}
