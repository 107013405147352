






























import { Vue, Component, Prop } from "vue-property-decorator";
import LogNewEvent from "@/views/admin/event_metrics/log_event/LogNewEvent.vue";
import { Department, EventMetric } from "@/api";

@Component({
  components: {
    LogNewEvent,
  },
})
export default class ListLogEvents extends Vue {
  dialog = false;
  @Prop(Object) event!: EventMetric;
  @Prop(Object) department!: Department;
}
