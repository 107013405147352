var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex fill-height flex-fill flex-column justify-space-between py-4 bg-gray list-container"},[_c('v-container',[_c('div',[(!_vm.$vuetify.breakpoint.xlOnly)?_c('v-breadcrumbs',{staticClass:"pt-0 px-0",attrs:{"items":_vm.crumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"caption",attrs:{"to":item.to,"exact":"","disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,4278594034)}):_vm._e()],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"outlined":"","clearable":"","background-color":"white","hide-details":true,"label":"Search Employee","append-icon":"$search"},on:{"click:clear":function($event){return _vm.clearSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.employees && _vm.employees !== [] && !_vm.isDataLoading)?_c('v-list',{staticClass:"pa-0 my-1"},[_vm._l((_vm.employees),function(employee){return [_c('v-list-item',{key:employee.alphabet,staticClass:"bg-gray"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline",domProps:{"innerHTML":_vm._s(employee.alphabet)}}),_vm._l((employee.records),function(record){return _c('v-list',{key:record.id,staticClass:"py-0"},[_c('v-list-item',{staticClass:"py-0",class:{
                  active: record.id === _vm.employeeId,
                  'bg-gray': record.id !== _vm.employeeId,
                },on:{"click":function($event){return _vm.selectEmployee(record.id)}}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"py-0",domProps:{"innerHTML":_vm._s(((record.firstName) + " " + (record.lastName)))}})],1)],1)],1)})],2)],1)]})],2):_vm._e()],1),(_vm.employees.length === 0 && !_vm.isDataLoading)?_c('div',{staticClass:"d-flex flex-fill justify-center fill-height align-center "},[_c('p',{staticClass:"font-weight-bold body-1 text-center"},[_vm._v(" No employees are available ")])]):_vm._e(),(_vm.isDataLoading)?_c('loading',{staticClass:"bg-gray",attrs:{"loading":_vm.isDataLoading}}):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"pa-2",class:{
      create: _vm.$vuetify.breakpoint.smAndDown,
      'fab-button': !_vm.$vuetify.breakpoint.smAndDown,
    },attrs:{"color":"primary","elevation":"2","rounded":"","large":""},on:{"click":_vm.createEmployee}},[_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("$plus")]),_c('v-card-text',{staticClass:"ml-2 pa-2"},[_vm._v("Employee")])],1):_vm._e(),_c('v-navigation-drawer',{key:_vm.counterDrawer,attrs:{"right":"","app":"","temporary":"","width":_vm.width,"stateless":""},model:{value:(_vm.showAdd),callback:function ($$v) {_vm.showAdd=$$v},expression:"showAdd"}},[_c('AddEmployee',{on:{"close":_vm.close,"save":_vm.close}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }