





























import { EventMetric } from "@/api";
import { Vue, Component, Prop } from "vue-property-decorator";
import ConfirmModal from "@/views/company_directory/ConfirmModal.vue";
@Component({
  components: {
    ConfirmModal,
  },
})
export default class RestoreCustomEvent extends Vue {
  isRestoring = false;
  eventRestoreConfirmModal = false;
  @Prop(Object) event!: EventMetric;

  async restoreEvent(event: EventMetric) {
    this.isRestoring = true;
    await this.$store.dispatch("EventMetricAdmin/removeArchivedCustomEvent", {
      id: event.id,
      name: event.name,
      description: event.description,
      companyId: event.companyId,
      isLeaderOrderDescending: event.isLeaderOrderDescending,
      isArchived: !event.isArchived,
      createdAt: event.createdAt,
    });
    this.isRestoring = true;
    this.eventRestoreConfirmModal = false;
  }
}
