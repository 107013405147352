/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataDetail = {
    properties: {
        inspection_id: {
    type: 'number',
    isRequired: true,
},
        date: {
    type: 'string',
    isRequired: true,
},
        time: {
    type: 'string',
    isRequired: true,
},
        patient: {
    type: 'string',
    isRequired: true,
},
        question_id: {
    type: 'number',
    isRequired: true,
},
        question: {
    type: 'string',
    isRequired: true,
},
        allow_rating: {
    type: 'boolean',
    isRequired: true,
},
        data_id: {
    type: 'number',
    isRequired: true,
},
        meet: {
    type: 'boolean',
    isRequired: true,
},
        comment: {
    type: 'string',
    isRequired: true,
},
        feedback: {
    type: 'string',
    isRequired: true,
},
        rating: {
    type: 'number',
    isRequired: true,
},
    },
};