














































































import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import loading from "@/components/loader/loading.vue";
import { TarfListItem } from "@/api";
import moment from "moment";
import ProgressBar from "@/components/loader/ProgressBar.vue";
import { getStatusAndProcessed } from "@/tool/orderStatuses";

@Component({
  components: {
    loading,
    ProgressBar,
  },
  watch: {
    async $route() {
      if (this.$route.params.status) {
        this.$data.search = "";
        const getStatus = getStatusAndProcessed(
          this.$route.params?.status.toLowerCase(),
        );
        const employeeId = parseInt(this.$route.params.id);
        if (getStatus && employeeId) {
          this.$data.isDataLoading = true;
          await this.$store.dispatch("tarf/fetchEmployeeTarfListAdmin", {
            employeeId,
            status: getStatus.status,
            processed: getStatus.processed,
          });
          this.$data.isDataLoading = false;
        }
      }
    },
  },
})
export default class TarfHistoryList extends Vue {
  search = "";
  isDataLoading = false;

  async created() {
    const statusAndProcessed = getStatusAndProcessed(
      this.$route.params?.status.toLowerCase(),
    );
    const employeeId = parseInt(this.$route.params.id);
    // employeeId required
    if (statusAndProcessed) {
      this.isDataLoading = true;
      await this.$store.dispatch("tarf/fetchEmployeeTarfListAdmin", {
        employeeId,
        status: statusAndProcessed.status,
        processed: statusAndProcessed.processed,
      });
      this.isDataLoading = false;
    }
  }

  clearSearch() {
    this.search = "";
  }

  get requestList() {
    const search = !this.search ? "" : this.search;
    const tarfList = this.$store.getters["tarf/employeeTarfListAdmin"];
    if (!tarfList?.years) return null;
    const allLists: TarfListItem[] = [];
    _.map(tarfList.years, year =>
      _.map(year.months, month =>
        _.map(month.tarfs, tarf => {
          allLists.push(tarf);
        }),
      ),
    );
    const searchList = _.reduce(
      _.orderBy(
        _.filter(allLists ?? [], (tarf: TarfListItem) =>
          _.includes(
            `${tarf.requesterName?.toLowerCase()} ${this.formatDate(
              tarf.createdAt ?? "",
            )?.toLowerCase()}`,
            search?.toLowerCase(),
          ),
        ),
        ["createdAt"],
        ["desc"],
      ),
      (
        acc: {
          year: string;
          months: ({ month?: string; tarfs: TarfListItem[] } | undefined)[];
        }[],
        cur,
      ) => {
        const year = moment(cur.createdAt).format("YYYY");
        const month = moment(cur.createdAt).format("MMMM");
        const months = { month, tarfs: [cur] };
        if (!acc[Number(year)]) acc[Number(year)] = { year, months: [months] };
        else {
          if (_.find(acc[Number(year)].months, mnth => mnth?.month === month)) {
            const mIndex = _.findIndex(
              acc[Number(year)].months,
              mnth => mnth?.month === month,
            );
            acc[Number(year)].months[mIndex]?.tarfs.push(cur);
          } else {
            acc[Number(year)].months.push(months);
          }
        }
        return acc;
      },
      [],
    );
    const orderList = _.orderBy(Object.values(searchList), ["year"], "desc");
    return orderList;
  }

  formatDate(date: string) {
    const utcDate = moment(date).format("MM/DD/YYYY, hh:mm A");
    return utcDate;
  }

  isPath(path: string) {
    return this.$route.path.includes(path);
  }
}
