/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SortDepartmentTitlesRequestBody = {
    properties: {
        orders: {
    type: 'array',
    contains: {
        type: 'DepartmentTitleOrderRequestObject',
    },
    isRequired: true,
},
    },
};