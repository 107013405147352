/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TransferEmployeeToDepartmentRequest = {
    properties: {
        departmentId: {
    type: 'number',
    isRequired: true,
},
        titleId: {
    type: 'number',
    isRequired: true,
},
    },
};