/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CalendarEvent = {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        color: {
    type: 'string',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        description: {
    type: 'string',
    isRequired: true,
},
        startDate: {
    type: 'string',
    isRequired: true,
},
        endDate: {
    type: 'string',
    isRequired: true,
},
        startTime: {
    type: 'string',
},
        endTime: {
    type: 'string',
},
        timed: {
    type: 'boolean',
    isRequired: true,
},
    },
};