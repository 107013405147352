



















































































































































































































































































































































































































































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";
import EditEmployee from "@/views/company_directory/EditEmployee.vue";
import ConfirmModal from "@/views/company_directory/ConfirmModal.vue";
import { Department, Employee, LoggedEvent } from "@/api";
import { VForm } from "@/common/vuetify_types";
import ImageCropper from "@/components/cropper/ImageCropper.vue";
import loading from "@/components/loader/loading.vue";
import moment from "moment";
import { subRoles, getSubRoles } from "@/tool/subRoles";
import shifts from "@/common/shift_type";
import Pagination from "@/components/pagination/paginate.vue";
import { addDayOnDate } from "@/common/date_format";
import EventList from "@/components/pagination/eventList.vue";
import {
  checkOtherRole,
  checkSelf,
  checkSelfRole,
  checkSelfSubrole,
} from "@/common/role_utils";
import DateFilter from "@/components/dateInput/DateFilter.vue";
import MonthPicker from "@/components/dateInput/MonthPicker.vue";
import * as validation from "@/tool/validation";

@Component({
  components: {
    EditEmployee,
    ConfirmModal,
    ImageCropper,
    loading,
    Pagination,
    EventList,
    DateFilter,
    MonthPicker,
  },
  watch: {
    async $route() {
      this.$data.isDataLoading = true;
      await this.$store.dispatch("directory/selectEmployee", {
        employeeId: this.$route.params.id,
      });
      const selectedEmployee = await this.$store.getters[
        "directory/selectedEmployee"
      ];
      if (selectedEmployee)
        await this.$store.dispatch(
          "departmentAdmin/getTitle",
          selectedEmployee?.primaryDepartmentId,
        );
      this.$data.isDataLoading = false;
      this.$data.errorMessage = "";
      this.$data.requiredMessage = "";
      this.$data.roleError = "";
      this.$data.subRoleError = "";
      this.$data.departmentError = "";
      this.$data.titleError = "";
      this.$data.page = 1;
      this.$data.paginationLoading = false;
    },
  },
})
export default class EmployeeProfile extends Vue {
  @Ref("updateEmailForm") readonly emailForm!: VForm;
  @Ref("updateEmployeeIdForm") readonly idForm!: VForm;
  @Ref("updateDepartmentForm") readonly departmentForm!: VForm;
  @Ref("updateRoleForm") readonly roleForm!: VForm;
  @Ref("profileImage") readonly profile!: VForm;
  @Watch("selectedDepartment", { immediate: true, deep: true })
  onSelectedDepartmentChange(newVal: number) {
    if (newVal === this.employee?.primaryDepartmentId)
      this.selectedTitle = this.employee?.titleId;
    else this.selectedTitle = null;
    if (newVal) this.getDepartmentTitle(newVal.toString());
  }

  emailConfirmModal = false;
  isDataReady = false;
  employeeIdConfirmModal = false;
  employeeDeleteConfirmModal = false;
  passwordResetEmailConfirmModal = false;
  passwordResetIdConfirmModal = false;
  resendIdCodeConfirmModal = false;
  resendEmailCodeConfirmModal = false;
  editProfile = false;
  isDeaprtmentSelected = false;
  employeeId = 0;
  selectedRole = "";
  selectedTitle = null;
  roles: string[] = [];
  selectedDepartment = "";
  authId = "";
  authEmail = "";
  dialog = false;
  nameRules = [validation.requiredString];
  width = this.$vuetify.breakpoint.smAndDown ? screen.width + "px" : 350 + "px";
  errorMessage = "";
  requiredMessage = "";
  roleError = "";
  subRoleError = "";
  departmentError = "";
  titleError = "";
  imageRules = [(v: File) => validation.maxFileSize(v, 2)];
  selectedFile: string | ArrayBuffer | null | undefined = "";
  profileUpdate = false;
  employeeProfile: string | ArrayBuffer | null | undefined = null;
  fileName = "";
  clr = 0;
  counter = 0;
  isDataLoading = false;
  subRoles = subRoles;
  subRole: number | undefined = 0;
  employeeIDRules = [
    validation.requiredString,
    validation.noSpace,
    validation.numberOnly,
  ];
  required_select = validation.requiredSelect;
  page = 1;
  limit = 5;
  paginationLoading = false;
  startDate = "";
  endDate = "";
  callOnCreate = false;
  confirmationUpdateVisible = false;
  updateVisibleText = "";
  updateloading = false;
  loading = {
    department: false,
    role: false,
    email: false,
    id: false,
    password: false,
    activation: false,
    archiving: false,
  };

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("directory/selectEmployee", {
      employeeId: this.$route.params.id,
      offset: this.page,
      limit: this.limit,
      beginDate: this.startDate,
      endDate: addDayOnDate(this.endDate),
    });
    this.callOnCreate = true;
    this.isDataLoading = false;
  }

  async updateEventAndScore(startDate: string, endDate: string) {
    this.startDate = startDate;
    this.endDate = endDate;
    if (this.startDate && this.endDate) {
      try {
        this.page = 1;
        this.callEmployeeEvent();
        this.callEmployeeEventScore();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async clearFilter() {
    this.startDate = "";
    this.endDate = "";
    this.page = 1;
    this.callEmployeeEvent();
    this.callEmployeeEventScore();
  }

  async onPageChange(page: number) {
    this.page = page;
    if (this.callOnCreate) this.callOnCreate = false;
    else this.callEmployeeEvent();
  }

  async callEmployeeEvent() {
    this.paginationLoading = true;
    await this.$store.dispatch("directory/getEmployeeEvent", {
      employeeId: this.$route.params.id,
      offset: this.page,
      limit: this.limit,
      beginDate: this.startDate,
      endDate: addDayOnDate(this.endDate),
    });
    this.paginationLoading = false;
  }

  async callEmployeeEventScore() {
    await this.$store.dispatch("directory/getEmployeeScore", {
      employeeId: this.$route.params.id,
      beginDate: this.startDate,
      endDate: addDayOnDate(this.endDate),
    });
  }

  get paginationLength() {
    return Math.ceil(this.totalPages / this.limit);
  }

  async getDepartmentTitle(id: string) {
    this.isDeaprtmentSelected = false;
    await this.$store.dispatch("departmentAdmin/getTitle", id);
    this.isDeaprtmentSelected = true;
  }
  get selectedEmployeeScore() {
    const score = this.$store.getters["directory/selectedEmployeeScore"];
    if (!score) return "";
    else return `${score?.score}/${score.total}`;
  }

  get selectedEmployeeEvent() {
    const events = this.$store.getters["directory/selectedEmployeeEvent"];
    if (!events) return [];
    if (!events.loggedEvents) return [];
    const fiveStarEvents = events.loggedEvents.filter(
      (event: LoggedEvent) => event.eventMetricId === -1,
    );
    return fiveStarEvents ?? [];
  }

  get totalPages() {
    const events = this.$store.getters["directory/selectedEmployeeEvent"];
    return events?.total ?? 0;
  }

  get selectedEmployeeEventScore() {
    return this.$store.getters["directory/selectedEmployeeEventScore"];
  }

  getShift(id: number) {
    const shiftType = shifts.find(shift => shift.id === id);
    return shiftType?.name;
  }

  get titleList() {
    const list = this.$store.getters["departmentAdmin/departmentTitle"];
    if (list) {
      return list;
    } else {
      return [];
    }
  }

  get departments(): Department[] {
    return this.$store.getters["company/departments"] ?? [];
  }

  get isSuperAdmin(): Employee {
    return this.$store.getters["isSuperAdmin"];
  }

  get isSalaryOwner() {
    return (checkSelfRole("owner") && checkSelfSubrole()) || this.isSuperAdmin;
  }

  checkRole(role: string) {
    return checkSelfRole(role);
  }

  get isSelf() {
    return checkSelf(this.employee.id);
  }

  get isSelectedOwner() {
    return (
      !checkOtherRole(this.employee, "owner") ||
      this.isSelf ||
      this.isSuperAdmin
    );
  }

  get roleItems() {
    if (this.checkRole("owner") || this.isSuperAdmin) {
      this.roles = ["employee", "admin", "owner"];
      return this.roles;
    } else {
      this.roles = ["employee", "admin"];
      return this.roles;
    }
  }

  get employee() {
    const employee = this.$store.getters["directory/selectedEmployee"];
    if (employee) {
      this.authId = employee.externalEmployeeId;
      this.authEmail = employee.email;
      this.selectedRole = employee.roles[0];
      this.selectedDepartment = employee.primaryDepartmentId;
      this.selectedTitle = employee.titleId;
      this.subRole = getSubRoles(employee.subRole);
    }
    return employee;
  }

  get avatarName() {
    return `${this.employee?.firstName[0] || ""}${this.employee?.lastName[0] ||
      ""}`;
  }

  get departmentName() {
    const department = _.find(
      this.employee?.departmentAssignments,
      item => item.departmentId === this.employee?.primaryDepartmentId,
    );
    return department?.departmentName;
  }

  edit(id: number) {
    this.employeeId = id;
    this.counter++;
    this.editProfile = true;
  }

  backToList() {
    this.$router.push({ path: "/company-directory" });
  }

  async updateDepartment() {
    this.departmentError = "";
    this.titleError = "";
    const isValid = this.departmentForm.validate();
    if (isValid) {
      if (
        (this.employee.primaryDepartmentId !== this.selectedDepartment ||
          this.employee.titleId !== this.selectedTitle) &&
        !!this.selectedTitle
      ) {
        this.loading.department = true;
        const res = await this.$store.dispatch(
          "directory/updateEmployeeDepartment",
          {
            employeeId: this.employee.id,
            requestBody: {
              departmentId: this.selectedDepartment,
              titleId: this.selectedTitle,
            },
          },
        );
        this.departmentForm.resetValidation();
        this.loading.department = false;
        if (!res) {
          this.selectedDepartment = this.employee.primaryDepartmentId;
          this.selectedTitle = this.employee.titleId;
        } else {
          if (this.$route.params.titleId) {
            const titleName = [...this.titleList]?.find(
              title => title.id == this.selectedTitle,
            );
            this.$router.push({
              path: `/departments/${this.selectedDepartment}/title/${titleName.title}/${this.selectedTitle}/employees/${this.employee.id}`,
              query: {
                load: "Go",
              },
            });
          }
        }
      } else {
        if (!this.selectedTitle) this.titleError = "Required";
        else {
          this.departmentError = "Department is same as previous";
          this.titleError = "Title is same as previous";
        }
      }
    }
  }

  async updateRole() {
    this.roleError = "";
    this.subRoleError = "";
    const isValid = this.roleForm.validate();
    if (isValid) {
      if (
        !this.employee.roles?.includes(this.selectedRole) ||
        this.subRole !== this.employee?.subRole
      ) {
        this.loading.role = true;
        const res = await this.$store.dispatch("directory/updateEmployeeRole", {
          requestBody: { roles: [this.selectedRole], subRole: this.subRole },
          employeeId: this.employee.id,
        });
        if (!res) {
          this.selectedRole = this.employee?.roles[0];
        }
        this.loading.role = false;
        this.roleForm.resetValidation();
      } else {
        if (this.employee.roles?.includes(this.selectedRole))
          this.roleError = "Role is same as previous.";
        if (this.subRole === this.employee?.subRole)
          this.subRoleError = "Sub Role is same as previous.";
      }
    }
  }

  async updateEmail() {
    this.loading.email = true;
    const res = await this.$store.dispatch(
      "directory/updateEmployeeLoginEmail",
      {
        employeeId: this.employee.id,
        email: this.authEmail,
      },
    );
    this.clearError();
    this.loading.email = false;
    this.emailForm.resetValidation();
    this.emailConfirmModal = false;
    if (!res) {
      this.authEmail = this.employee?.email;
    }
  }

  validEmail() {
    this.clearError();
    const isValid = this.emailForm.validate();
    if (isValid) {
      if (/.+@.+\..+/.test(this.authEmail) || !this.authEmail?.trim()) {
        if (
          this.employee?.email?.trim() !== this.authEmail?.trim() &&
          (this.employee?.email?.trim() || this.authEmail?.trim())
        ) {
          this.emailConfirmModal = true;
        } else {
          if (this.authEmail?.trim())
            this.errorMessage = "Email is same as previous";
          else this.errorMessage = "Email is already empty";
        }
      } else {
        this.errorMessage = "E-mail must be valid";
      }
    }
  }

  clearError() {
    this.errorMessage = "";
  }

  async deleteEmployee() {
    this.loading.archiving = true;
    const res = await this.$store.dispatch(
      "directory/deleteEmployeeID",
      this.employee.id,
    );
    this.loading.archiving = false;
    this.employeeDeleteConfirmModal = false;
    if (!res) return;
    if (this.$route.path.includes("/company-directory")) {
      this.$router.push({ path: `/company-directory` });
    } else {
      const path = this.$route.path.replace(`/${this.$route.params.id}`, "");
      this.$store.dispatch(
        "directory/getTitledEmployee",
        this.$route.params.titleId,
      );
      this.$router.push({ path });
    }
  }

  async updateId() {
    this.loading.id = true;
    const res = await this.$store.dispatch("directory/updateEmployeeID", {
      employeeId: this.employee.id,
      externalEmployeeId: parseInt(this.authId.trim()),
    });
    this.requiredMessage = "";
    this.loading.id = false;
    this.idForm.resetValidation();
    this.employeeIdConfirmModal = false;
    if (!res) {
      this.authId = this.employee.externalEmployeeId?.toString();
    }
  }

  async sendPassword() {
    this.loading.password = true;
    const res = await this.$store.dispatch(
      "directory/passwordResetLink",
      this.employee.id,
    );
    this.loading.password = false;
    if (!res) return;
    if (this.employee.email === null) {
      this.passwordResetIdConfirmModal = true;
    } else {
      this.passwordResetEmailConfirmModal = true;
    }
  }

  editProfilePic(event: File) {
    this.clr++;
    const isValid = this.profile.validate();
    if (isValid) {
      if (event) {
        this.fileName = event.name;
        this.profileUpdate = true;
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          this.selectedFile = event.target?.result;
        };
        reader.readAsDataURL(event);
      }
    } else {
      this.profile.reset();
      this.$store.dispatch(
        "alert/warning",
        "Image size must be less then 2MB.",
      );
    }
  }

  close() {
    this.profile.reset();
    this.profileUpdate = false;
  }

  validId() {
    this.requiredMessage = "";
    const isValid = this.idForm.validate();
    if (isValid) {
      if (
        this.authId !== this.employee.externalEmployeeId?.toString() &&
        this.authId
      ) {
        this.employeeIdConfirmModal = true;
      } else {
        if (!this.authId) {
          this.requiredMessage = "Field must not be empty to update";
        } else {
          this.requiredMessage = "Id is same as previous";
        }
      }
    }
  }

  async resendActivation() {
    this.loading.activation = true;
    const res = await this.$store.dispatch(
      "directory/activationCodeResend",
      this.employee.id,
    );
    this.loading.activation = false;
    if (!res) return;
    if (this.employee.email === null) {
      this.resendIdCodeConfirmModal = true;
    } else {
      this.resendEmailCodeConfirmModal = true;
    }
  }

  formatPhone(phone: string) {
    let format = [];
    if (this.employee?.phoneVisible === 0 && this.isSelf)
      format = (phone?.slice(0, 2) + "XXXXXXXX").split("");
    else format = phone.split("");
    const formattedPhone = [];
    let count = 0;
    for (let i = 0; i <= format.length; i++) {
      if (count % 3 === 0 && i < 9 && count !== 0) {
        formattedPhone.push("-");
        count = 0;
      }
      formattedPhone.push(format[i]);
      count++;
    }
    return formattedPhone.join("");
  }

  yearsOfService(hireDate: string) {
    if (hireDate) {
      const indate = hireDate.substr(0, 10);
      const hiredate = moment(indate);
      const currentdate = moment();
      const duration = moment.duration(currentdate.diff(hiredate));
      if (duration.years() > 0) {
        const serviceDurationYear =
          duration.years() > 1
            ? `${duration.years()} Years`
            : `${duration.years()} Year`;
        const serviceDurationMonth =
          duration.months() > 1
            ? `${duration.months()} Months`
            : duration.months() > 0
            ? `${duration.months()} Month`
            : ``;
        return `${serviceDurationYear} ${serviceDurationMonth} of service`;
      } else if (duration.years() === 0) {
        const serviceDurationMonth =
          duration.months() > 1
            ? `${duration.months()} Months of service`
            : duration.months() === 0
            ? `New Team Member`
            : `${duration.months()} Month of service`;
        return serviceDurationMonth;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  updateVisible() {
    this.confirmationUpdateVisible = true;
    this.updateVisibleText =
      this.employee.phoneVisible === 0
        ? "Your phone will be visible to all employees."
        : "Your phone number will be hidden from employees.";
    this.updateVisibleText =
      this.updateVisibleText + " (note: Owner and admin are exception)";
  }

  async updatePhoneVisible() {
    const visible = this.employee.phoneVisible === 0 ? 1 : 0;
    this.updateloading = true;
    await this.$store.dispatch("directory/updatePhoneVisibility", {
      employeeId: this.employee.id,
      visible,
    });
    this.updateloading = false;
    this.confirmationUpdateVisible = false;
  }

  async updateEventScore(beginDate: string, endDate: string) {
    await this.$store.dispatch("directory/getEmployeeEventScores", {
      employeeId: this.$route.params.id,
      beginDate,
      endDate: addDayOnDate(endDate),
    });
  }

  clearEventScoreFilter() {
    this.$store.dispatch("directory/getEmployeeEventScores", {
      employeeId: this.$route.params.id,
    });
  }
}
