/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { CompanyList } from '../models/CompanyList';
import type { CreateStandardEventMetricRequestBody } from '../models/CreateStandardEventMetricRequestBody';
import type { EventMetric } from '../models/EventMetric';
import type { ProvisionCompanyRequestBody } from '../models/ProvisionCompanyRequestBody';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * List all companies
     * @returns CompanyList List of all companies
     * @throws ApiError
     */
    public static async listAllCompanies(): Promise<CompanyList> {
        const result = await __request({
            method: 'GET',
            path: `/super-admin/companies`,
        });
        return result.body;
    }

    /**
     * Provision a company
     * @returns Company The created company
     * @throws ApiError
     */
    public static async provisionCompany({
requestBody,
}: {
requestBody: ProvisionCompanyRequestBody,
}): Promise<Company> {
        const result = await __request({
            method: 'POST',
            path: `/super-admin/companies`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * reprocess user employee accounts and update custom claims in the auth system
     * @returns any successfully synced custom claims for requested employees
     * @throws ApiError
     */
    public static async updateCustomClaims({
companyId,
employeeId,
}: {
/** limit update to employees within a single company **/
companyId?: number,
/** limit update to a single employee **/
employeeId?: number,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/super-admin/update-custom-claims`,
            query: {
                'companyId': companyId,
                'employeeId': employeeId,
            },
        });
        return result.body;
    }

    /**
     * verify a customer email account. This is primarily used as a testing tool for verifying test accounts.
     * @returns any successfully verified the email
     * @throws ApiError
     */
    public static async verifyEmail({
email,
}: {
/** the email to verify **/
email: string,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/super-admin/verify-email`,
            query: {
                'email': email,
            },
        });
        return result.body;
    }

    /**
     * Create a standard event metric available for use at all companies
     * @returns EventMetric The created event metric
     * @throws ApiError
     */
    public static async createStandardEventMetric({
requestBody,
}: {
requestBody: CreateStandardEventMetricRequestBody,
}): Promise<EventMetric> {
        const result = await __request({
            method: 'POST',
            path: `/super-admin/event-metrics`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete this event metric
     * @returns any Successfully deleted this standard event metric
     * @throws ApiError
     */
    public static async deleteStandardEventMetric({
eventMetricId,
}: {
eventMetricId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/super-admin/event-metrics/${eventMetricId}`,
        });
        return result.body;
    }

    /**
     * Update this standard event metric
     * @returns EventMetric The updated standard event metric
     * @throws ApiError
     */
    public static async updateStandardEventMetric({
eventMetricId,
requestBody,
}: {
eventMetricId: number,
requestBody?: EventMetric,
}): Promise<EventMetric> {
        const result = await __request({
            method: 'PUT',
            path: `/super-admin/event-metrics/${eventMetricId}`,
            body: requestBody,
        });
        return result.body;
    }

}