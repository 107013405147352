





























import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import * as validation from "@/tool/validation";

@Component
export default class AddDepartmentModal extends Vue {
  @Ref("form") form!: VForm;
  @Prop(Number) departmentId!: number;

  name = "";
  loading = false;
  requiredTextInput = [validation.requiredString, validation.noSpace];

  close() {
    this.form.reset();
    this.$emit("close");
  }

  async save() {
    const isValid = this.form.validate();
    if (isValid) {
      try {
        this.loading = true;
        await this.$store.dispatch("departmentAdmin/addTitle", {
          departmentId: this.departmentId,
          requestBody: {
            title: this.name.trim(),
          },
        });
        this.loading = false;
        this.form.reset();
        this.$emit("save");
      } catch (e) {
        console.log(e);
      }
    }
  }
}
