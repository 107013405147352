/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EmployeeDocumentByDocumentIdResponse = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        employee_id: {
    type: 'number',
    isRequired: true,
},
        firstName: {
    type: 'string',
    isRequired: true,
},
        lastName: {
    type: 'string',
    isRequired: true,
},
        expiration_date: {
    type: 'string',
    isRequired: true,
    format: 'date',
},
        license: {
    type: 'string',
    isRequired: true,
},
        approval: {
    type: 'string',
    isRequired: true,
},
        primaryDepartmentName: {
    type: 'string',
    isRequired: true,
},
        title: {
    type: 'string',
    isRequired: true,
},
        document_path: {
    type: 'string',
    isRequired: true,
},
        status: {
    type: 'string',
    isRequired: true,
},
        created_at: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
    },
};