

















import { Component, Vue } from "vue-property-decorator";

@Component
export default class NotificationSnackbar extends Vue {
  get alertState() {
    return this.$store.getters["notifications/notifyMessage"];
  }

  close() {
    this.$store.dispatch("notifications/close");
  }
}
