/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSurveyForm } from '../models/ActiveSurveyForm';
import type { Form } from '../models/Form';
import type { Forms } from '../models/Forms';
import type { ResponseForm } from '../models/ResponseForm';
import type { ResponseSurveyQuestion } from '../models/ResponseSurveyQuestion';
import type { SurveyDetail } from '../models/SurveyDetail';
import type { SurveyOverviewDetails } from '../models/SurveyOverviewDetails';
import type { SurveyQuestion } from '../models/SurveyQuestion';
import type { SurveyQuestions } from '../models/SurveyQuestions';
import type { SurveyRequest } from '../models/SurveyRequest';
import type { SurveySummary } from '../models/SurveySummary';
import { request as __request } from '../core/request';

export class SurveyService {

    /**
     * Add discharge survey
     * @returns any Discharge survey added successfully
     * @throws ApiError
     */
    public static async addDischargeSurvey({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: SurveyRequest,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/discharge`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get summary list of discharge survey data
     * @returns SurveySummary get summary details of discharge survey
     * @throws ApiError
     */
    public static async getDischargeSurveySummary({
companyId,
beginAt,
endAt,
floorId,
physicianId,
roomId,
}: {
/** The companyId **/
companyId: number,
beginAt?: string,
endAt?: string,
floorId?: number,
physicianId?: number,
roomId?: number,
}): Promise<Array<SurveySummary>> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/discharge/summary`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
                'floorId': floorId,
                'physicianId': physicianId,
                'roomId': roomId,
            },
        });
        return result.body;
    }

    /**
     * get summary list of discharge survey overview details
     * @returns SurveyDetail get details of discharge survey overview
     * @throws ApiError
     */
    public static async getDischargeSurveyOverviewDetails({
companyId,
overviewId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
overviewId: number,
beginAt?: string,
endAt?: string,
}): Promise<Array<SurveyDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/discharge/details`,
            query: {
                'overviewId': overviewId,
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Get discharge survey form list
     * @returns Forms discharge survey form list of a company
     * @throws ApiError
     */
    public static async getDischargeForms({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Forms> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/discharge/form`,
        });
        return result.body;
    }

    /**
     * Add a discharge survey form
     * @returns ResponseForm added  discharge survey form
     * @throws ApiError
     */
    public static async addDischargeForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Form,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/discharge/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update a discharge survey form
     * @returns ResponseForm updated discharge survey form
     * @throws ApiError
     */
    public static async updateDischargeForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: ResponseForm,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/survey/discharge/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete a discharge survey form
     * @returns ResponseForm deleted discharge survey form
     * @throws ApiError
     */
    public static async deleteDischargeForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: ResponseForm,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/survey/discharge/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Copy other discharge survey forms
     * @returns ResponseForm form copied
     * @throws ApiError
     */
    public static async copyDischargeForm({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The discharge survey formId of the company **/
formId: number,
requestBody: Form,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/discharge/form/${formId}/copy`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get questions list of discharge survey
     * @returns SurveyQuestions question list for discharge survey
     * @throws ApiError
     */
    public static async getDischargeQuestions({
companyId,
formId,
}: {
/** The companyId **/
companyId: number,
/** The discharge formId of the company **/
formId: number,
}): Promise<SurveyQuestions> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/discharge/form/${formId}/question`,
        });
        return result.body;
    }

    /**
     * Add a question for discharge survey
     * @returns ResponseSurveyQuestion added question for the discharge survey
     * @throws ApiError
     */
    public static async addDischargeQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The discharge formId of the company **/
formId: number,
requestBody: SurveyQuestion,
}): Promise<ResponseSurveyQuestion> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/discharge/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update discharge survey question
     * @returns ResponseSurveyQuestion discharge survey question updated
     * @throws ApiError
     */
    public static async updateDischargeQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The discharge formId of the company **/
formId: number,
requestBody: ResponseSurveyQuestion,
}): Promise<ResponseSurveyQuestion> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/survey/discharge/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete discharge survey question
     * @returns ResponseSurveyQuestion discharge survey question deleted
     * @throws ApiError
     */
    public static async deleteDischargeQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The discharge formId of the company **/
formId: number,
requestBody: ResponseSurveyQuestion,
}): Promise<ResponseSurveyQuestion> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/survey/discharge/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Sort questions order ranking
     * @returns SurveyQuestions sorted question for the discharge survey
     * @throws ApiError
     */
    public static async sortDischargeQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The discharge formId of the company **/
formId: number,
requestBody: SurveyQuestions,
}): Promise<SurveyQuestions> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/discharge/form/${formId}/question/sort`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get summary overview of discharge survey data
     * @returns SurveyOverviewDetails get summary overview of discharge survey
     * @throws ApiError
     */
    public static async getDischargeOverviewDetails({
companyId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<Array<SurveyOverviewDetails>> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/discharge/overview`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Get discharge active survey form
     * @returns ActiveSurveyForm active discharge survey form of a company
     * @throws ApiError
     */
    public static async getActiveDischargeForm({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<ActiveSurveyForm> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/discharge/active-form`,
        });
        return result.body;
    }

    /**
     * Add Admission survey
     * @returns any admission survey added successfully
     * @throws ApiError
     */
    public static async addAdmissionSurvey({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: SurveyRequest,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/admission`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get summary list of admission survey data
     * @returns SurveySummary get summary details of admission survey
     * @throws ApiError
     */
    public static async getAdmissionSurveySummary({
companyId,
beginAt,
endAt,
floorId,
physicianId,
roomId,
}: {
/** The companyId **/
companyId: number,
beginAt?: string,
endAt?: string,
floorId?: number,
physicianId?: number,
roomId?: number,
}): Promise<Array<SurveySummary>> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/admission/summary`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
                'floorId': floorId,
                'physicianId': physicianId,
                'roomId': roomId,
            },
        });
        return result.body;
    }

    /**
     * get summary list of admission survey overview details
     * @returns SurveyDetail get details of admission survey overview
     * @throws ApiError
     */
    public static async getAdmissionSurveyOverviewDetails({
companyId,
overviewId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
overviewId: number,
beginAt?: string,
endAt?: string,
}): Promise<Array<SurveyDetail>> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/admission/details`,
            query: {
                'overviewId': overviewId,
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Get survey form list
     * @returns Forms admission survey form list of a company
     * @throws ApiError
     */
    public static async getAdmissionForms({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Forms> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/admission/form`,
        });
        return result.body;
    }

    /**
     * Add an admission survey form
     * @returns ResponseForm added admission survey form
     * @throws ApiError
     */
    public static async addAdmissionForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Form,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/admission/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update an admission survey form
     * @returns ResponseForm updated admission survey form
     * @throws ApiError
     */
    public static async updateAdmissionForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: ResponseForm,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/survey/admission/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete an admission survey form
     * @returns ResponseForm deleted admission survey form
     * @throws ApiError
     */
    public static async deleteAdmissionForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: ResponseForm,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/survey/admission/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get admission active survey form
     * @returns ActiveSurveyForm active admission survey form of a company
     * @throws ApiError
     */
    public static async getActiveAdmissionForm({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<ActiveSurveyForm> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/admission/active-form`,
        });
        return result.body;
    }

    /**
     * Copy other survey forms
     * @returns ResponseForm admission form copied
     * @throws ApiError
     */
    public static async copyAdmissionForm({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The survey formId of the company **/
formId: number,
requestBody: Form,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/admission/form/${formId}/copy`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get questions list
     * @returns SurveyQuestions question list for admission survey
     * @throws ApiError
     */
    public static async getAdmissionQuestions({
companyId,
formId,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
}): Promise<SurveyQuestions> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/admission/form/${formId}/question`,
        });
        return result.body;
    }

    /**
     * Add a question for admission survey
     * @returns ResponseSurveyQuestion added question for the admission survey
     * @throws ApiError
     */
    public static async addAdmissionQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: SurveyQuestion,
}): Promise<ResponseSurveyQuestion> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/admission/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update admission survey question
     * @returns ResponseSurveyQuestion admission survey question updated
     * @throws ApiError
     */
    public static async updateAdmissionQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: ResponseSurveyQuestion,
}): Promise<ResponseSurveyQuestion> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/survey/admission/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete admission survey question
     * @returns ResponseSurveyQuestion admission survey question deleted
     * @throws ApiError
     */
    public static async deleteAdmissionQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: ResponseSurveyQuestion,
}): Promise<ResponseSurveyQuestion> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/survey/admission/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Sort questions order ranking
     * @returns SurveyQuestions sorted question for the survey
     * @throws ApiError
     */
    public static async sortAdmissionQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: SurveyQuestions,
}): Promise<SurveyQuestions> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/survey/admission/form/${formId}/question/sort`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get summary overview of admission survey data
     * @returns SurveyOverviewDetails get summary overview of admission survey
     * @throws ApiError
     */
    public static async getAdmissionOverviewDetails({
companyId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<Array<SurveyOverviewDetails>> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/survey/admission/overview`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

}