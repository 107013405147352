import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  ChatList,
  ChatLists,
  Employee,
  GroupAdmin,
  Message,
  MessageGroup,
  MessageGroupDetails,
  MessageGroupList,
  MessageGroupRequest,
  MessageService,
  OpenAPI,
  UnreadCount,
} from "@/api";
import _ from "lodash";
import resolve from "@/common/resolver";
import moment from "moment";

type MessageState = {
  chatList: ChatLists;
  chat: Message[];
  selectedEmployee: ChatList | null;
  groupList: MessageGroupList;
  selectedGroup: MessageGroup | null;
  groupDetails: MessageGroupDetails | null;
  count: UnreadCount | null;
  chatListSearch: string;
};

const state: MessageState = {
  chatList: [],
  chat: [],
  selectedEmployee: null,
  groupList: [],
  selectedGroup: null,
  groupDetails: null,
  count: null,
  chatListSearch: "",
};

const getters: GetterTree<MessageState, RootState> = {
  chatList: state => state.chatList,
  chats: state => state.chat,
  selectedEmployee: state => state.selectedEmployee,
  groupList: state => state.groupList,
  selectedGroup: state => state.selectedGroup,
  groupDetails: state => state.groupDetails,
  count: state => state.count,
  chatListSearch: state => state.chatListSearch,
};

const mutations: MutationTree<MessageState> = {
  SET_CHAT_LIST: (state, chatList) => {
    state.chatList = chatList;
  },
  SET_CHAT: (state, chat) => {
    state.chat = chat;
  },
  ADD_CHAT: (state, chat) => {
    if (!state.chat) {
      state.chat = [];
    }
    state.chat.push(chat);
    state.chat = _.uniqBy(_.orderBy(state.chat, ["id"], ["desc"]), "id");
  },
  SET_SELECTED_EMPLOYEE: (state, employee) => {
    state.selectedEmployee = employee;
  },
  SET_GROUP_CHAT_LIST: (state, groupList) => {
    state.groupList = groupList;
  },
  SET_SELECTED_GROUP: (state, group) => {
    state.selectedGroup = group;
  },
  ADD_GROUP: (state, group) => {
    if (!state.groupList) {
      state.groupList = [];
    }

    state.groupList.push(group);
    state.groupList = _.sortBy(
      state.groupList,
      [group => group.name.toLowerCase(), "sentAt"],
      ["asc", "desc"],
    );
  },
  SET_GROUP_DETAILS: (state, group) => {
    state.groupDetails = group;
  },
  SET_UNREAD_COUNT: (state, count) => {
    state.count = count;
  },
  SET_CHAT_LIST_SEARCH: (state, search) => {
    state.chatListSearch = search;
  },
};

const actions: ActionTree<MessageState, RootState> = {
  fetchChatList: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const chatList = await MessageService.getMessagesChatList({ companyId });
      commit("SET_CHAT_LIST", chatList);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchChats: async (
    { commit, rootState },
    { from, to, group }: { from: number; to?: number; group?: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const chats = await MessageService.getMessages({
        companyId,
        to,
        group,
        from,
      });
      commit("SET_CHAT", chats);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addChat: ({ commit }, message: string) => {
    commit("ADD_CHAT", message);
  },
  setSelectedEmployee: (
    { commit, getters },
    { employee, force }: { employee: Employee | null; force: boolean },
  ) => {
    if (force) {
      commit("SET_SELECTED_EMPLOYEE", employee);
    } else {
      const emp = getters["selectedEmployee"];
      if (!emp) {
        commit("SET_SELECTED_EMPLOYEE", employee);
      }
    }
  },
  fetchGroupList: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const groupList = await MessageService.getMessagesGroupList({
        companyId,
      });
      commit("SET_GROUP_CHAT_LIST", groupList);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  setSelectedGroup: async (
    { commit, getters },
    { group, force }: { group: MessageGroup; force: boolean },
  ) => {
    if (force) {
      commit("SET_SELECTED_GROUP", group);
    } else {
      const emp = getters["selectedGroup"];
      if (!emp) {
        commit("SET_SELECTED_GROUP", group);
      }
    }
  },
  setChatEmpty: ({ commit }) => {
    commit("SET_CHAT", []);
  },
  fetchGroupDetails: async ({ commit, rootState }, groupId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const groupDetails = await MessageService.getMessagesGroupDetails({
        companyId,
        group: groupId,
      });
      commit("SET_GROUP_DETAILS", groupDetails);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  createGroup: async (
    { commit, rootState },
    requestBody: MessageGroupRequest,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const group = await MessageService.addMessageGroupDetail({
        companyId,
        requestBody,
      });
      group.readAt = moment().toISOString();
      commit("ADD_GROUP", group);
      return group;
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateGroup: async (
    { commit, rootState, dispatch },
    requestBody: MessageGroup,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const group = await MessageService.updateMessageGroupDetail({
        companyId,
        requestBody,
      });
      commit("SET_SELECTED_GROUP", group);
      dispatch("fetchGroupList");
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addGroupParticipants: async (
    { commit, dispatch, rootState },
    requestBody: MessageGroupRequest,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      await MessageService.addMessageGroupParticipants({
        companyId,
        requestBody,
      });
      dispatch("fetchGroupDetails", requestBody.id);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  RemoveGroupParticipants: async (
    { commit, dispatch, rootState },
    requestBody: MessageGroupRequest,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      await MessageService.removeMessageGroupParticipant({
        companyId,
        requestBody,
      });
      dispatch("fetchGroupDetails", requestBody.id);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchUnreadMessageCount: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const count = await MessageService.getUnreadMessagesCount({
        companyId,
      });
      commit("SET_UNREAD_COUNT", count);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addMessageGroupAdmin: async (
    { commit, rootState, dispatch },
    requestBody: GroupAdmin,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      await MessageService.addGroupAdmin({
        companyId,
        requestBody,
      });
      dispatch("fetchGroupDetails", requestBody.groupId);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeMessageGroupAdmin: async (
    { commit, rootState, dispatch },
    requestBody: GroupAdmin,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      await MessageService.removeGroupAdmin({
        companyId,
        requestBody,
      });
      dispatch("fetchGroupDetails", requestBody.groupId);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  messageWithMedia: async (
    { commit },
    {
      id,
      message,
      to,
      group,
      from,
      media,
      companyId,
      file,
    }: {
      id: number;
      message: string;
      to: number;
      group: number;
      from: number;
      media: string;
      companyId: number;
      file: File;
    },
  ) => {
    const formData = new FormData();
    formData.append("id", JSON.stringify(id));
    formData.append("companyId", JSON.stringify(companyId));
    if (to) formData.append("to", JSON.stringify(to));
    formData.append("from", JSON.stringify(from));
    formData.append("message", message);
    formData.append("media", media);
    if (group) formData.append("group", JSON.stringify(group));
    formData.append("file", file, file?.name);
    try {
      await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/message/media`,
        {
          method: "POST",
          body: formData,
          headers: {
            authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
          },
        },
      );
    } catch (e) {
      if (e instanceof Error) {
        commit("alert/SET_SHOW_ERROR", e.message, { root: true });
      }
    }
  },
  setChatListSearch: ({ commit }, search: string) => {
    commit("SET_CHAT_LIST_SEARCH", search);
  },
};

export const chat: Module<MessageState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
