/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDocumentRequest } from '../models/CreateDocumentRequest';
import type { DocumentListResponse } from '../models/DocumentListResponse';
import type { DocumentResponse } from '../models/DocumentResponse';
import { request as __request } from '../core/request';

export class DocumentNameService {

    /**
     * Create an Document Name
     * @returns DocumentResponse The created document name
     * @throws ApiError
     */
    public static async createDocument({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: CreateDocumentRequest,
}): Promise<DocumentResponse> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/document`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get all document name list
     * @returns DocumentListResponse All document name list
     * @throws ApiError
     */
    public static async getDocumentList({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<DocumentListResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/document`,
        });
        return result.body;
    }

    /**
     * Fetch particular document by its id
     * @returns DocumentResponse Single document name
     * @throws ApiError
     */
    public static async getDocumentById({
companyId,
documentId,
}: {
/** The companyId **/
companyId: number,
/** The documentId **/
documentId: number,
}): Promise<DocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/document/${documentId}`,
        });
        return result.body;
    }

    /**
     * update an Document Name
     * @returns DocumentResponse The updated document name
     * @throws ApiError
     */
    public static async updateDocument({
companyId,
documentId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The documentId **/
documentId: number,
requestBody: CreateDocumentRequest,
}): Promise<DocumentResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/document/${documentId}`,
            body: requestBody,
        });
        return result.body;
    }

}