

























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Department } from "@/api";
import _ from "lodash";
import moment from "moment";
import { addDayOnDate, dateCheckifExist } from "@/common/date_format";

@Component
export default class FilterMobile extends Vue {
  @Prop() deptId?: number;
  @Prop() catId?: number;

  departmentId = this.deptId || 0;
  categoryId = this.catId || 0;
  menu = false;
  date =
    (this.$route.query.date as string) ||
    new Date().toISOString().substring(0, 7);
  formattedDate = moment(this.date).format("MMMM");
  maxDate = new Date().toISOString().substring(0, 7);

  async created() {
    const { beginAt, endAt } = dateCheckifExist();
    // eslint-disable-next-line
    const nextMonth = addDayOnDate(endAt!);
    await this.$store.dispatch("categoryBudget/getDepartments", {
      beginAt,
      endAt: nextMonth,
    });
  }

  formatDate() {
    this.formattedDate = moment(this.date).format("MMMM");
  }

  get departments() {
    return this.$store.getters["categoryBudget/departments"]?.map(
      (department: Department) => {
        return {
          ...department,
          header: undefined,
        };
      },
    );
  }

  async getDepartmentCategories() {
    await this.$store.dispatch("departmentAdmin/getCategories", {
      departmentId: this.departmentId,
    });
  }

  get categories() {
    const categoryList =
      this.$store.getters["departmentAdmin/categories"] || [];
    return _.orderBy(categoryList, ["category"], ["asc"]);
  }

  filter() {
    this.$emit("filter", {
      departmentId: this.departmentId,
      categoryId: this.categoryId,
      date: this.date,
    });
  }

  clearFilter() {
    this.departmentId = this.deptId || 0;
    this.categoryId = this.catId || 0;
    this.date = new Date().toISOString().substring(0, 7);
    this.formattedDate = moment(this.date).format("MMMM");
    this.filter();
  }
}
