/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseTask } from '../models/ResponseTask';
import type { Task } from '../models/Task';
import type { Tasks } from '../models/Tasks';
import { request as __request } from '../core/request';

export class TaskService {

    /**
     * Get task list
     * @returns Tasks task list according to user role
     * @throws ApiError
     */
    public static async getTasks({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Tasks> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/task`,
        });
        return result.body;
    }

    /**
     * Add a task
     * @returns ResponseTask task added
     * @throws ApiError
     */
    public static async addTask({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Task,
}): Promise<ResponseTask> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/task`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update task
     * @returns ResponseTask task updated
     * @throws ApiError
     */
    public static async updateTask({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Task,
}): Promise<ResponseTask> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/task`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete task
     * @returns ResponseTask task deleted
     * @throws ApiError
     */
    public static async deleteTask({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Task,
}): Promise<ResponseTask> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/task`,
            body: requestBody,
        });
        return result.body;
    }

}