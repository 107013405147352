import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { TarfService } from "@/api/services/TarfService";
import {
  GetTarfRequestDetailResponse,
  GetTarfRequestsListResponseBody,
  GetTarfRequestStatusesResponse,
  ReviewTarfRequestByOwnerRequestBody,
  ReviewTarfRequestRequestBody,
  TarfEditRequestBody,
  TarfRequestRequestBody,
  TarfResubmitRequestBody,
  TarfStatusDetail,
} from "@/api";

export type TarfState = {
  employeeTarfList: GetTarfRequestsListResponseBody | null;
  supervisorTarfList: GetTarfRequestsListResponseBody | null;
  selectedTarf: GetTarfRequestDetailResponse | null;
  tarfReasonList: TarfRequestRequestBody | null;
  tarfStatus: GetTarfRequestStatusesResponse | null;
  employeeTarfStatusAdmin: GetTarfRequestStatusesResponse | null;
  employeeTarfListAdmin: GetTarfRequestsListResponseBody | null;
};

const state: TarfState = {
  employeeTarfList: null,
  supervisorTarfList: null,
  selectedTarf: null,
  tarfReasonList: null,
  tarfStatus: null,
  employeeTarfStatusAdmin: null,
  employeeTarfListAdmin: null,
};

const getters: GetterTree<TarfState, RootState> = {
  employeeTarfList: state => state.employeeTarfList,
  supervisorTarfList: state => state.supervisorTarfList,
  selectedTarf: state => state.selectedTarf,
  reasonList: state => state.tarfReasonList,
  tarfStatus: state => state.tarfStatus,
  employeeTarfStatusAdmin: state => state.employeeTarfStatusAdmin,
  employeeTarfListAdmin: state => state.employeeTarfListAdmin,
};

const mutations: MutationTree<TarfState> = {
  SET_EMPLOYEE_TARF_LIST: (state, tarfList) => {
    state.employeeTarfList = tarfList;
  },
  SET_SUPERVISOR_TARF_LIST: (state, tarfList) => {
    state.supervisorTarfList = tarfList;
  },
  SET_SELECTED_TARF: (state, tarf) => {
    state.selectedTarf = tarf;
  },
  TARF_REASON_LIST: (state, reasonList) => {
    state.tarfReasonList = reasonList;
  },
  TARF_STATUS: (state, status) => {
    state.tarfStatus = status;
  },
  EMPLOYEE_TARF_STATUS_ADMIN: (state, status) => {
    state.employeeTarfStatusAdmin = status;
  },
  SET_EMPLOYEE_TARF_LIST_ADMIN: (state, tarfList) => {
    state.employeeTarfListAdmin = tarfList;
  },
};

const actions: ActionTree<TarfState, RootState> = {
  fetchEmployeeTarfList: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const res = await TarfService.getTarfRequests({ companyId });
        commit("SET_EMPLOYEE_TARF_LIST", res);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  fetchSupervisorTarfList: async (
    { commit, rootState },
    queries: { status: number; processed?: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const res = await TarfService.getSupervisorTarfRequests({
          companyId,
          status: queries.status,
          processed: queries.processed,
        });
        commit("SET_SUPERVISOR_TARF_LIST", res);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  fetchSelectedTarf: async ({ commit, rootState }, tarfId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const res = await TarfService.getTarfRequestDetail({
          companyId,
          tarfId,
        });
        commit("SET_SELECTED_TARF", res);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  reviewTarfRequest: async (
    { commit, rootState },
    request: {
      requestBody: ReviewTarfRequestRequestBody;
      tarfId: number;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await TarfService.reviewTarfRequest({
          companyId,
          requestBody: request.requestBody,
          tarfId: request.tarfId,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  postEmployeeTimeAdjustmentFormRequest: async (
    { rootState },
    requestBody: TarfRequestRequestBody,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await TarfService.createTarfRequest({
          companyId,
          requestBody,
        });
      } catch (error) {
        if (error instanceof Error) {
          throw error.message;
        }
      }
    }
  },
  getReasonList: async ({ commit, rootState, getters }, force = false) => {
    const companyId = rootState.appContext.company?.id;
    let hasData = getters["reasonList"];
    if (force == true) hasData = false;
    if (companyId && !hasData) {
      try {
        const reasonRequest = await TarfService.getTarfRequestReasons({
          companyId,
        });
        commit("TARF_REASON_LIST", reasonRequest);
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  },

  fetchTarfStatuses: async (
    { commit, rootState, dispatch, getters },
    requestBody: { force: boolean; toFetch: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    const isEmployee = rootState.appContext.self?.roles?.includes("employee");
    if (companyId) {
      try {
        let statuses;
        if (isEmployee) {
          statuses = await TarfService.getEmployeeTarfRequestStatuses({
            companyId,
          });
        } else {
          statuses = await TarfService.getTarfRequestStatuses({
            companyId,
          });
        }
        const stateStatuses = getters["tarfStatus"];
        if (
          statuses &&
          stateStatuses &&
          !requestBody.force &&
          requestBody.toFetch >= 0
        ) {
          let fetchAgain = true;
          statuses.statuses?.map(item => {
            const gett = stateStatuses.statuses.find((stat: TarfStatusDetail) =>
              stat.name.includes(item.name),
            );
            if (gett.count !== item.count && fetchAgain) {
              fetchAgain = false;
              if (isEmployee) dispatch("fetchEmployeeTarfList");
              else {
                if (item.id === requestBody.toFetch)
                  dispatch("fetchSupervisorTarfList", {
                    status: item.id,
                    processed: requestBody.toFetch === 3 ? 1 : 0,
                  });
              }
            }
          });
        }
        commit("TARF_STATUS", statuses);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    } else {
      if (!getters["tarfStatus"]) {
        setTimeout(() => {
          dispatch("fetchTarfStatuses");
        }, 1000);
      }
    }
  },

  processTARFrequest: async (
    { rootState, commit },
    request: {
      tarfId: number;
      reviewRequestBody: ReviewTarfRequestByOwnerRequestBody;
      editRequestBody: TarfEditRequestBody;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        if (request.editRequestBody) {
          await TarfService.saveEditedFieldData({
            companyId,
            tarfId: request.tarfId,
            requestBody: request.editRequestBody,
          });
        }
        await TarfService.reviewTarfRequestOwner({
          companyId,
          tarfId: request.tarfId,
          requestBody: request.reviewRequestBody,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  async ownerDenyTarf(
    { commit, rootState },
    payload: { tarfId: number; requestBody: ReviewTarfRequestRequestBody },
  ) {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await TarfService.denyTarfRequestOwner({
          companyId,
          tarfId: payload.tarfId,
          requestBody: payload.requestBody,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  async resubmitTarrf(
    { commit, rootState },
    payload: {
      tarfId: number;
      requestBody: TarfResubmitRequestBody;
      editRequestBody: TarfEditRequestBody;
    },
  ) {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await TarfService.saveEditedFieldData({
          companyId,
          tarfId: payload.tarfId,
          requestBody: payload.editRequestBody,
        });
        await TarfService.resubmitTarfRequest({
          companyId,
          tarfId: payload.tarfId,
          requestBody: payload.requestBody,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  async fetchEmployeeTarfStatusesAdmin(
    { commit, rootState },
    employeeId: number,
  ) {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const statuses = await TarfService.getEmployeeTarfStatusesAdmin({
          companyId,
          employeeId,
        });
        commit("EMPLOYEE_TARF_STATUS_ADMIN", statuses);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  fetchEmployeeTarfListAdmin: async (
    { commit, rootState },
    {
      employeeId,
      status,
      processed,
    }: { employeeId: number; status: number; processed: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const res = await TarfService.getEmployeeTarfRequestsAdmin({
          companyId,
          employeeId,
          status,
          processed,
        });
        commit("SET_EMPLOYEE_TARF_LIST_ADMIN", res);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
};

export const tarf: Module<TarfState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
