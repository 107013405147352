/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetTarfRequestDetailResponse } from '../models/GetTarfRequestDetailResponse';
import type { GetTarfRequestsListResponseBody } from '../models/GetTarfRequestsListResponseBody';
import type { GetTarfRequestStatusesResponse } from '../models/GetTarfRequestStatusesResponse';
import type { ReviewTarfRequestByOwnerRequestBody } from '../models/ReviewTarfRequestByOwnerRequestBody';
import type { ReviewTarfRequestRequestBody } from '../models/ReviewTarfRequestRequestBody';
import type { TarfEditRequestBody } from '../models/TarfEditRequestBody';
import type { TarfReasonsResponseBody } from '../models/TarfReasonsResponseBody';
import type { TarfRequestRequestBody } from '../models/TarfRequestRequestBody';
import type { TarfResubmitRequestBody } from '../models/TarfResubmitRequestBody';
import { request as __request } from '../core/request';

export class TarfService {

    /**
     * Create a new tarf request
     * @returns any A tarf request has been successfully craeted
     * @throws ApiError
     */
    public static async createTarfRequest({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: TarfRequestRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/tarf-request/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a list of tarf requests
     * @returns GetTarfRequestsListResponseBody A list of tarf requests
     * @throws ApiError
     */
    public static async getTarfRequests({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<GetTarfRequestsListResponseBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/tarf-request/`,
        });
        return result.body;
    }

    /**
     * Get a list of employee tarf requests for admin and owner only
     * @returns GetTarfRequestsListResponseBody A list of tarf requests of employee for admin and owner only
     * @throws ApiError
     */
    public static async getEmployeeTarfStatusesAdmin({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<GetTarfRequestsListResponseBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/tarf-history/${employeeId}/statuses`,
        });
        return result.body;
    }

    /**
     * Get a list of employee tarf requests for admin and owner only
     * @returns GetTarfRequestsListResponseBody A list of tarf requests of employee for admin and owner only
     * @throws ApiError
     */
    public static async getEmployeeTarfRequestsAdmin({
companyId,
employeeId,
status,
processed,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The status **/
status: number,
/** The processed **/
processed: number,
}): Promise<GetTarfRequestsListResponseBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/tarf-history/${employeeId}/tarf-request/${status}-${processed}`,
        });
        return result.body;
    }

    /**
     * Resubmit a tarf request
     * @returns any Tarf request is  Resubmitted
     * @throws ApiError
     */
    public static async resubmitTarfRequest({
companyId,
tarfId,
requestBody,
}: {
/** The company ID **/
companyId: number,
/** The tarf ID **/
tarfId: number,
requestBody: TarfResubmitRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/tarf-request/${tarfId}/resubmit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Save an edited tarf request
     * @returns any Tarf request is saved
     * @throws ApiError
     */
    public static async saveEditedFieldData({
companyId,
tarfId,
requestBody,
}: {
/** The company ID **/
companyId: number,
/** The tarf ID **/
tarfId: number,
requestBody: TarfEditRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/tarf-request/${tarfId}/edit/save`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a list of tarf statuses with its information
     * @returns GetTarfRequestStatusesResponse A list of tarf status
     * @throws ApiError
     */
    public static async getTarfRequestStatuses({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<GetTarfRequestStatusesResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/supervisor/tarf-request/statuses`,
        });
        return result.body;
    }

    /**
     * Get a list of tarf statuses with its information for employee
     * @returns GetTarfRequestStatusesResponse A list of tarf status
     * @throws ApiError
     */
    public static async getEmployeeTarfRequestStatuses({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<GetTarfRequestStatusesResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/tarf-request/statuses`,
        });
        return result.body;
    }

    /**
     * Get a tarf request detail
     * @returns GetTarfRequestDetailResponse A tarf
     * @throws ApiError
     */
    public static async getTarfRequestDetail({
companyId,
tarfId,
}: {
/** The companyId **/
companyId: number,
/** The tarf request Id **/
tarfId: number,
}): Promise<GetTarfRequestDetailResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/tarf-request/${tarfId}`,
        });
        return result.body;
    }

    /**
     * Create a new tarf request
     * @returns GetTarfRequestsListResponseBody A list of tarf requests
     * @throws ApiError
     */
    public static async getSupervisorTarfRequests({
companyId,
status,
processed,
}: {
/** The companyId **/
companyId: number,
/** status to filter. 0 - pending, 1 - approved, 2 - denied **/
status?: number,
/** status of processed. 0 - unProcessed, 1 - Processed **/
processed?: number,
}): Promise<GetTarfRequestsListResponseBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/supervisor/tarf-request/`,
            query: {
                'status': status,
                'processed': processed,
            },
        });
        return result.body;
    }

    /**
     * Review (approve/deny) a tarf request
     * @returns any Tarf request is approved or denied
     * @throws ApiError
     */
    public static async reviewTarfRequest({
companyId,
tarfId,
requestBody,
}: {
/** The company ID **/
companyId: number,
/** The tarf ID **/
tarfId: number,
requestBody: ReviewTarfRequestRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/supervisor/tarf-request/${tarfId}/review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * ..
     * @returns GetTarfRequestsListResponseBody A list of tarf requests
     * @throws ApiError
     */
    public static async getOwnerTarfRequests({
companyId,
status,
processed,
}: {
/** The companyId **/
companyId: number,
/** status to filter. 0 - pending, 1 - approved, 2 - denied **/
status: number,
/** processed request to filter. 0 - Not Processed, 1 - Processed **/
processed?: number,
}): Promise<GetTarfRequestsListResponseBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/owner/tarf-request`,
            query: {
                'status': status,
                'processed': processed,
            },
        });
        return result.body;
    }

    /**
     * Process a tarf request
     * @returns any Tarf request is Processed
     * @throws ApiError
     */
    public static async reviewTarfRequestOwner({
companyId,
tarfId,
requestBody,
}: {
/** The company ID **/
companyId: number,
/** The tarf ID **/
tarfId: number,
requestBody: ReviewTarfRequestByOwnerRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/owner/tarf-request/${tarfId}/review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Deny a tarf request
     * @returns any Tarf request is  denied
     * @throws ApiError
     */
    public static async denyTarfRequestOwner({
companyId,
tarfId,
requestBody,
}: {
/** The company ID **/
companyId: number,
/** The tarf ID **/
tarfId: number,
requestBody: ReviewTarfRequestRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/owner/tarf-request/${tarfId}/deny`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a list of tarf reasons
     * @returns TarfReasonsResponseBody A list of tarf reasons
     * @throws ApiError
     */
    public static async getTarfRequestReasons({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<TarfReasonsResponseBody> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/tarf-request/reasons`,
        });
        return result.body;
    }

}