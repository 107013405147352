import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { AddBed, Bed, BedService, Beds } from "@/api";

type BedState = {
  beds: Beds | null;
  companyBeds?: Beds;
};

const state: BedState = {
  beds: null,
  companyBeds: undefined,
};

const getters: GetterTree<BedState, RootState> = {
  beds: state => state.beds,
  companyBeds: state => state.companyBeds,
};

const mutations: MutationTree<BedState> = {
  SET_ROOM_BEDS: (state, beds) => {
    state.beds = beds;
  },
  ADD_BED: (state, bed) => {
    if (state.beds === null) {
      state.beds = [];
    }
    state.beds.push(bed);
  },
  UPDATE_BED: (state, bed) => {
    let index = state.beds?.findIndex((item: Bed) => item.id == bed.id);
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.beds?.splice(index, 1);
      state.beds?.push(bed);
    }
  },
  REMOVE_BED: (state, bed) => {
    let index = state.beds?.findIndex((item: Bed) => item.id == bed.id);
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.beds?.splice(index, 1);
    }
  },
  SET_COMPANY_BEDS: (state, beds) => {
    state.companyBeds = beds;
  },
};

const actions: ActionTree<BedState, RootState> = {
  fetchRoomBeds: async (
    { commit, rootState },
    { floorId, roomId }: { floorId: number; roomId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const beds = await BedService.getRoomBeds({ companyId, floorId, roomId });
      commit("SET_ROOM_BEDS", beds);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addRoomBed: async (
    { commit, rootState },
    {
      floorId,
      roomId,
      requestBody,
    }: { floorId: number; roomId: number; requestBody: AddBed },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const bed = await BedService.addRoomBed({
        companyId,
        floorId,
        roomId,
        requestBody,
      });
      commit("ADD_BED", bed);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateRoomBed: async (
    { commit, rootState },
    {
      floorId,
      roomId,
      requestBody,
    }: { floorId: number; roomId: number; requestBody: Bed },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const bed = await BedService.updateRoomBed({
        companyId,
        floorId,
        roomId,
        requestBody,
      });
      commit("UPDATE_BED", bed);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeRoomBed: async (
    { commit, rootState },
    {
      floorId,
      roomId,
      requestBody,
    }: { floorId: number; roomId: number; requestBody: Bed },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      await BedService.deleteRoomBed({
        companyId,
        floorId,
        roomId,
        requestBody,
      });
      commit("REMOVE_BED", requestBody);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  clearBedState: ({ commit }) => {
    commit("SET_ROOM_BEDS", null);
  },
  fetchCompanyBeds: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const beds = await BedService.getCompanyBeds({ companyId });
      commit("SET_COMPANY_BEDS", beds);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const bed: Module<BedState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
