/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AnniversaryEmployeeInfo = {
    properties: {
        id: {
    type: 'number',
},
        firstName: {
    type: 'string',
    isRequired: true,
},
        lastName: {
    type: 'string',
    isRequired: true,
},
        hireDate: {
    type: 'string',
},
        birthday: {
    type: 'string',
},
        title: {
    type: 'string',
},
        primaryDepartmentName: {
    type: 'string',
},
    },
};