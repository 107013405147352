/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Floor } from '../models/Floor';
import type { Floors } from '../models/Floors';
import { request as __request } from '../core/request';

export class FloorService {

    /**
     * Get a company floors
     * @returns Floors Company Census for the budget
     * @throws ApiError
     */
    public static async getCompanyFloors({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Floors> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/floor`,
        });
        return result.body;
    }

    /**
     * Add a company floor
     * @returns Floor Company Census for the budget
     * @throws ApiError
     */
    public static async addCompanyFloor({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Floor,
}): Promise<Floor> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/floor`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update a company floor
     * @returns Floor Company Census for the budget
     * @throws ApiError
     */
    public static async updateCompanyFloor({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Floor,
}): Promise<Floor> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/floor`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete a company floor
     * @returns any Company floor deleted successfully
     * @throws ApiError
     */
    public static async deleteCompanyFloor({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Floor,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/floor`,
            body: requestBody,
        });
        return result.body;
    }

}