


































































import { Component, Ref, Prop, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { AddDepartmentPhoneNumberRequestBody, Department } from "@/api";
import { noSpace, numberOnly, requiredString } from "@/tool/validation";

@Component
export default class EditDepartmentModal extends Vue {
  @Ref("form") form!: VForm;

  @Prop(String) inputValue: number = this.$props.inputValue;
  @Prop(String) action: string = this.$props.action;
  @Prop(String) label: string = this.$props.label;
  @Prop(Object) department: Department = this.$props.department;

  ext = [
    {
      value: "",
    },
  ];
  updating = false;

  requiredTextInput = [requiredString, noSpace, numberOnly];

  close() {
    this.$emit("close");
  }

  get lenRules() {
    if (this.label.toLowerCase() === "extension") {
      return (v: string) => v.length <= 4 || "4 digits accepted at most";
    } else {
      return (v: string) => v.length === 10 || "Must contain 10 digits";
    }
  }

  async save() {
    const isValid = this.form.validate();
    if (isValid && this.department) {
      if (this.action.toLowerCase() === "add") {
        try {
          const phonenumbers = this.ext.map((value: { value: string }) => {
            return {
              value: value.value,
              type: this.label.toLowerCase() === "extension" ? 2 : 1,
            };
          });
          this.updating = true;
          await this.$store.dispatch("departmentAdmin/addExtensionOrPhone", {
            departmentId: this.department.id,
            requestBody: {
              phonenumbers: phonenumbers,
            },
          } as {
            departmentId: number;
            requestBody: AddDepartmentPhoneNumberRequestBody;
          });
          this.$store.dispatch("company/loadDepartments", true);
          this.updating = false;
          this.$emit("close");
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("This will be for the Edit action.");
      }
    }
  }

  addExt() {
    this.ext.push({
      value: "",
    });
  }

  removeExt(index: number) {
    this.ext.splice(index, 1);
  }
}
