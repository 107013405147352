/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EmployeeList = {
    properties: {
        isSuperAdmin: {
    type: 'boolean',
    isRequired: true,
},
        employees: {
    type: 'array',
    contains: {
        type: 'Employee',
    },
    isRequired: true,
},
    },
};