import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "@/store/types";
import {
  CompanyBudgets,
  CompaniesService,
  DepartmentService,
  Budget,
  PostBudget,
  CompanyExpense,
  ExpenseService,
  ExpenseList,
  OpenAPI,
  Census,
  DepartmentHasBudget,
} from "@/api";
import resolve from "@/common/resolver";
import moment from "moment";
import { checkSelfRole } from "@/common/role_utils";

export type CategoriesBudget = {
  CompanyCategoriesBudget: CompanyBudgets[] | null;
  companyExpenses: CompanyExpense[] | null;
  departmentExpenses: ExpenseList | null;
  categoryBudget: Budget | null;
  askBudget: boolean;
  census: Census | null;
  departments: DepartmentHasBudget | null;
};

const state: CategoriesBudget = {
  CompanyCategoriesBudget: null,
  companyExpenses: null,
  departmentExpenses: null,
  categoryBudget: null,
  askBudget: false,
  census: null,
  departments: null,
};

const getters: GetterTree<CategoriesBudget, RootState> = {
  companyBudget: state => state.CompanyCategoriesBudget,
  companyExpenses: state => state.companyExpenses,
  departmentExpenses: state => state.departmentExpenses,
  categoryBudget: state => state.categoryBudget,
  askBudget: state => state.askBudget,
  census: state => state.census,
  departments: state => state.departments,
};

const mutations: MutationTree<CategoriesBudget> = {
  SET_COMPANY_BUDGET: (state, budgets) => {
    state.CompanyCategoriesBudget = budgets;
  },
  SET_COMPANY_EXPENSES: (state, expenses) => {
    state.companyExpenses = expenses;
  },
  SET_DEPARTMENT_EXPENSES: (state, expenses) => {
    state.departmentExpenses = expenses;
  },
  SET_DEPARTMENT_BUDGET: (state, budget) => {
    state.categoryBudget = budget;
  },
  SET_ASK_BUDGET: (state, ask) => {
    if (state.askBudget !== ask) state.askBudget = ask;
  },
  SET_CENSUS: (state, census) => {
    state.census = census;
  },
  SET_DEPARTMENT: (state, departments) => {
    state.departments = departments;
  },
};

const actions: ActionTree<CategoriesBudget, RootState> = {
  getCompanyBudget: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const budgets = await CompaniesService.getCompanyCategoryBudget({
          companyId,
        });
        commit("SET_COMPANY_BUDGET", budgets);
      } catch (error) {
        commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },
  addBudgetToCategroy: async (
    { commit, rootState, dispatch },
    {
      departmentId,
      categoryId,
      requestBody,
    }: {
      departmentId: number;
      categoryId: number;
      requestBody: Budget;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentService.addDepartmentCategoryBudget({
          companyId,
          departmentId,
          categoryId,
          requestBody,
        });
        dispatch("getCompanyBudget");
        commit(
          "alert/SET_SHOW_SUCCESS",
          "The category budget added successfully",
          { root: true },
        );
      } catch (error) {
        commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },
  addBudgetToCompanyCategory: async (
    { commit, rootState, dispatch },
    { requestBody, message }: { requestBody: PostBudget; message: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await CompaniesService.addCompanyCategoryBudget({
          companyId,
          requestBody,
        });
        commit("alert/SET_SHOW_SUCCESS", message, { root: true });
        dispatch("getCompanyBudget");
      } catch (error) {
        commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },
  getCompanyExpenses: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId && beginAt && endAt) {
      try {
        const expenses = await ExpenseService.getCompanyCategoryExpenses({
          companyId,
          beginAt,
          endAt,
        });

        const date = moment().format("YYYY-MM");
        const filterDate = beginAt.slice(0, 7);
        if (!expenses && date === filterDate) {
          commit("SET_ASK_BUDGET", true);
        }

        commit("SET_COMPANY_EXPENSES", expenses);
      } catch (error) {
        commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },
  getDepartmentExpenses: async (
    { commit, rootState },
    {
      departmentId,
      categoryId,
      beginAt,
      endAt,
    }: {
      departmentId: number;
      categoryId: number;
      beginAt: string;
      endAt: string;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const expenses = await ExpenseService.getCategoryExpense({
          companyId,
          departmentId,
          categoryId,
          beginAt,
          endAt,
        });

        commit("SET_DEPARTMENT_EXPENSES", expenses);
      } catch (error) {
        commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },

  getCategoryBudget: async (
    { commit, rootState },
    {
      departmentId,
      categoryId,
      beginAt,
      endAt,
    }: {
      departmentId: number;
      categoryId: number;
      beginAt: string;
      endAt: string;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    const isOwner = checkSelfRole("owner");
    if (companyId) {
      try {
        const budget = await DepartmentService.getDepartmentCategoryBudget({
          companyId,
          departmentId,
          categoryId,
          beginAt,
          endAt,
        });

        const date = moment().format("YYYY-MM");
        const filterDate = beginAt.slice(0, 7);
        if (!budget && date === filterDate && !isOwner) {
          console.log("test");
          commit("SET_ASK_BUDGET", true);
        }

        commit("SET_DEPARTMENT_BUDGET", budget);
      } catch (error) {
        commit("SET_DEPARTMENT_BUDGET", null);
        // commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },

  addAnExpense: async (
    { commit, rootState },
    {
      departmentId,
      categoryId,
      requestBody,
    }: { departmentId: number; categoryId: number; requestBody: FormData },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/departments/${departmentId}/category/${categoryId}/expenses`,
        {
          method: "POST",
          body: requestBody,
          headers: {
            authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
          },
        },
      )
        .then(response => {
          // Check if the response is OK
          if (!response.ok) {
            throw new Error("Request failed");
          }

          // Read the response as JSON
          return response.json();
        })
        .catch(error => {
          commit("alert/SET_SHOW_ERROR", error, { root: true });
        });
    } else {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
    }
  },

  updateExpense: async (
    { commit, rootState },
    {
      departmentId,
      categoryId,
      requestBody,
    }: { departmentId: number; categoryId: number; requestBody: FormData },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/departments/${departmentId}/category/${categoryId}/expenses`,
        {
          method: "PUT",
          body: requestBody,
          headers: {
            authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
          },
        },
      )
        .then(response => {
          // Check if the response is OK
          if (!response.ok) {
            throw new Error("Request failed");
          }

          // Read the response as JSON
          return response.json();
        })
        .catch(error => {
          commit("alert/SET_SHOW_ERROR", error, { root: true });
        });
    } else {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
    }
  },
  setAskBudget: ({ commit }, ask) => {
    commit("SET_ASK_BUDGET", ask);
  },

  getCensus: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt: string; endAt: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const census = await CompaniesService.getCompanyCensus({
        companyId,
        beginAt,
        endAt,
      });
      commit("SET_CENSUS", census);
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
  addCensus: async ({ commit, rootState }, requestBody: Census) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const census = await CompaniesService.addCompanyCensus({
        companyId,
        requestBody,
      });
      commit("SET_CENSUS", census);
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
  getDepartments: async (
    { commit, rootState },
    { beginAt, endAt }: { beginAt: string; endAt: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const departments = await CompaniesService.getDepartmentHasBudget({
        companyId,
        beginAt,
        endAt,
      });
      commit("SET_DEPARTMENT", departments);
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
};

export const categoryBudget: Module<CategoriesBudget, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
