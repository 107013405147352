







































































import { VForm } from "@/common/vuetify_types";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component
export default class AddCompany extends Vue {
  @Ref("companyForm") readonly form!: VForm;

  valid = true;
  loading = false;
  name = "";
  companyCode = "";
  administratorEmail = "";
  administratorFirstName = "";
  administratorLastName = "";
  formRules = [
    (v: string) => !!v || "Required",
    (v: string) => !v || v.trim() !== "" || "Space only is not accepted",
  ];
  emailValid = (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid";

  async createCompany() {
    const isValid = this.form.validate();
    if (isValid) {
      const requestBody = {
        name: this.name.trim(),
        companyCode: this.companyCode.trim(),
        administratorEmail: this.administratorEmail.trim(),
        administratorFirstName: this.administratorFirstName.trim(),
        administratorLastName: this.administratorLastName.trim(),
      };
      try {
        this.loading = true;
        console.log(requestBody);
        await this.$store.dispatch("superAdmin/createCompany", requestBody);
        this.loading = false;
        this.form.reset();
      } catch (error) {
        console.log(error);
      }
    }
  }

  backToList() {
    this.$router.push({ path: "/company-list" });
  }
}
