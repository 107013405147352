


















import { Component, Vue } from "vue-property-decorator";

@Component
export default class DepartmentWarningModal extends Vue {
  close() {
    this.$emit("close");
  }
}
