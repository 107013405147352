/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListedEmployee = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        externalEmployeeId: {
    type: 'number',
},
        firstName: {
    type: 'string',
    isRequired: true,
},
        lastName: {
    type: 'string',
    isRequired: true,
},
        primaryDepartmentId: {
    type: 'number',
    isRequired: true,
},
        title: {
    type: 'string',
},
        profilePicture: {
    type: 'string',
},
        shift: {
    type: 'number',
},
        departmentAssignments: {
    type: 'array',
    contains: {
        type: 'DepartmentEmployeeAssignment',
    },
    isRequired: true,
},
        roles: {
    type: 'array',
    contains: {
    type: 'string',
},
},
        lead: {
    type: 'number',
},
        guardianAngel: {
    type: 'boolean',
},
    },
};