/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MeRankingResponse = {
    properties: {
        fiveStarMoment: {
    type: 'EmployeeRanking',
},
        performance: {
    type: 'EmployeeRanking',
},
    },
};