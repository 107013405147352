































































































































import { Component, Vue } from "vue-property-decorator";
import loading from "@/components/loader/loading.vue";
import _ from "lodash";
import moment from "moment";
import { TarfListItem, TarfStatusDetail } from "@/api";
import statusId from "@/tool/tarfStatus";
import { orderStatusList } from "@/tool/orderStatuses";

@Component({
  components: {
    loading,
  },
})
export default class FormMenu extends Vue {
  search = "";
  get activeMenu() {
    if (this.$route.path.includes("request")) return "request";
    else if (this.$route.params.id) return this.$route.params.id;
    else return "not_select";
  }
  isDataLoading = false;
  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("tarf/fetchEmployeeTarfList");
    this.isDataLoading = false;
  }

  get tarfList() {
    const search = !this.search ? "" : this.search;
    const list = this.$store.getters["tarf/employeeTarfList"];
    if (!list?.years) return null;
    const allLists: TarfListItem[] = [];
    _.map(list.years, year =>
      _.map(year.months, month =>
        _.map(month.tarfs, tarf => {
          allLists.push(tarf);
        }),
      ),
    );

    const searchList = _.reduce(
      _.orderBy(
        _.filter(allLists ?? [], (tarf: TarfListItem) =>
          _.includes(
            `${tarf.requesterName?.toLowerCase()} ${this.submittedDateTarfRequest(
              // eslint-disable-next-line
              tarf.createdAt!,
            )}`,
            search.toLowerCase(),
          ),
        ),
        ["createdAt"],
        ["desc"],
      ),
      (
        acc: {
          status: number | undefined;
          record: {
            year: string;
            months: { month?: string; tarfs: TarfListItem[] }[];
          }[];
        }[],
        cur,
      ) => {
        let status = cur.status !== 3 ? cur.status : -1;
        const processed = cur.processed;
        if (processed && status !== 3) status = 3;
        const year = moment(cur.createdAt).format("YYYY");
        const month = moment(cur.createdAt).format("MMMM");
        const months = { month, tarfs: [cur] };
        if (!acc[Number(status)])
          acc[Number(status)] = {
            status,
            record: [{ year, months: [months] }],
          };
        else {
          if (_.find(acc[Number(status)].record, item => item.year === year)) {
            if (
              _.find(acc[Number(status)].record, item =>
                _.find(
                  item.months,
                  dmonth => dmonth.month === month && item.year === year,
                ),
              )
            ) {
              const yIndex = _.findIndex(
                acc[Number(status)].record,
                item => item.year === year,
              );
              const mIndex = _.findIndex(
                acc[Number(status)].record[yIndex].months,
                item => item.month === month,
              );
              acc[Number(status)].record[yIndex].months[mIndex]?.tarfs.push(
                cur,
              );
            } else {
              const yIndex = _.findIndex(
                acc[Number(status)].record,
                item => item.year === year,
              );
              acc[Number(status)].record[yIndex].months.push(months);
            }
          } else {
            acc[Number(status)].record.push({ year, months: [months] });
          }
        }
        return acc;
      },
      [],
    );
    const orderList = _.orderBy(Object.values(searchList), ["year"], "desc");
    const statuses = { ...statusId };
    statuses.processed = 3;
    _.map(statuses, item => {
      if (!_.find(orderList, tarf => tarf.status === item))
        orderList.push({ status: item, record: [] });
    });
    const orderedStatusTarf: {
      status: number | undefined;
      record: {
        year: string;
        months: { month?: string; tarfs: TarfListItem[] }[];
      }[];
    }[] = [];
    _.map(orderStatusList, item => {
      const tarfItem = _.find(orderList, trf => trf.status === item.id);
      if (tarfItem) orderedStatusTarf.push(tarfItem);
    });
    return orderedStatusTarf;
  }

  getReqestTitle(statusId: number) {
    if (statusId === 0) return "New";
    else if (statusId === 1) return "Approved Excused";
    else if (statusId === 3) return "Processed";
    else if (statusId === 4) return "Denied";
    else if (statusId === 5) return "Corrected/Resubmitted";
    else return "Approved Unexcused";
  }

  getReqestRoute(statusId: number) {
    if (statusId === 0) return "new";
    else if (statusId === 1) return "excused";
    else if (statusId === 3) return "processed";
    else if (statusId === 4) return "denied";
    else if (statusId === 5) return "resubmit";
    else return "unexcused";
  }

  getColor(statusId: number) {
    if (statusId === 0) return "primary";
    else if (statusId === 1) return "green";
    else if (statusId === 3) return "grey";
    else if (statusId === 4) return "pink";
    else if (statusId === 5) return "purple ";
    else return "red";
  }

  get tarfStatuses() {
    const statuses = this.$store.getters["tarf/tarfStatus"];
    return statuses?.statuses ?? [];
  }

  getCount(statusId: number) {
    const status = _.find(
      this.tarfStatuses,
      (status: TarfStatusDetail) => status.id == statusId,
    );
    if (status) return status?.count;
    else return 0;
  }

  goTo(path: string) {
    this.$router.push({ path });
  }

  clearSearch() {
    this.search = "";
  }

  selectTarfItem(tarfId: number, status: number) {
    this.$router.push({
      path: `/form/employee/${this.getReqestRoute(
        status,
      ).toLowerCase()}/${tarfId}`,
    });
  }

  submittedDateTarfRequest(date: string) {
    return moment(date).format("MM/DD/YYYY");
  }
}
