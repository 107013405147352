/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InspectionDashboardReport = {
    properties: {
        averageRating: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        itemMissed: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        lessThen3Rating: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        moreThen3Rating: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        unresolvedItems: {
    type: 'number',
    format: 'float',
},
        totalCustomerRating: {
    type: 'number',
    format: 'float',
},
        totalInspection: {
    type: 'number',
    format: 'float',
},
        totalItemsMissed: {
    type: 'number',
    format: 'float',
},
        totalQuestionInspected: {
    type: 'number',
    format: 'float',
},
        totalLessThenRating: {
    type: 'number',
    format: 'float',
},
        totalMoreThenRating: {
    type: 'number',
    format: 'float',
},
        totalUnresolvedItems: {
    type: 'number',
    format: 'float',
},
        chartData: {
    type: 'array',
    contains: {
        type: 'ItemMissed',
    },
    isRequired: true,
},
        unattendedRooms: {
    properties: {
        completionRate: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        unattendedRoom: {
    type: 'array',
    contains: {
        type: 'UnattendedRooms',
    },
    isRequired: true,
},
    },
    isRequired: true,
},
    },
};