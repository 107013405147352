






























import { Component, Ref, Prop, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { noSpace, requiredString } from "@/tool/validation";

@Component
export default class EditDepartmentModal extends Vue {
  @Ref("form") form!: VForm;

  @Prop(String) title!: string;
  @Prop(Number) departmentId!: number;
  @Prop(Number) titleId!: number;
  name = this.title;
  updating = false;
  errorMessage = "";
  requiredTextInput = [requiredString, noSpace];

  close() {
    this.form.resetValidation();
    this.errorMessage = "";
    this.$emit("close");
  }
  isSame() {
    let same = false;
    if (this.name.trim() !== this.title.trim()) same = true;
    return same;
  }
  async save() {
    this.errorMessage = "";
    if (!this.isSame()) {
      this.errorMessage = "Title is same as previous";
    } else {
      const isValid = this.form.validate();
      if (isValid) {
        try {
          this.updating = true;
          await this.$store.dispatch("departmentAdmin/updateTitle", {
            departmentId: this.departmentId,
            titleId: this.titleId,
            requestBody: {
              title: this.name.trim(),
            },
          });
          this.updating = false;
          this.$emit("close");
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
}
