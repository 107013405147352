import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { ResponseTask, Task, TaskService, Tasks } from "@/api";
import { RootState } from "@/store/types";

export type TaskState = {
  tasks: Tasks | null;
};

const state: TaskState = {
  tasks: null,
};

const getters: GetterTree<TaskState, RootState> = {
  tasks: state => state.tasks,
};

const mutations: MutationTree<TaskState> = {
  SET_TASKS: (state, tasks) => {
    state.tasks = tasks;
  },
  ADD_TASK: (state, task) => {
    if (state.tasks == null) {
      state.tasks = [];
    }
    state.tasks.push(task);
  },
  REMOVE_TASK: (state, task) => {
    let index = state.tasks?.findIndex(
      (item: ResponseTask) => item.id == task.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.tasks?.splice(index, 1);
    }
  },
  UPDATE_TASK: (state, task) => {
    let index = state.tasks?.findIndex(
      (item: ResponseTask) => item.id == task.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.tasks?.splice(index, 1);
      state.tasks?.push(task);
    }
  },
};

const actions: ActionTree<TaskState, RootState> = {
  fetchTasks: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const tasks = await TaskService.getTasks({ companyId });
      commit("SET_TASKS", tasks);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addTask: async ({ commit, rootState }, requestBody: Task) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const task = await TaskService.addTask({ companyId, requestBody });
      commit("ADD_TASK", task);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateTask: async ({ commit, rootState }, requestBody: Task) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const task = await TaskService.updateTask({ companyId, requestBody });
      commit("UPDATE_TASK", task);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  deleteTask: async ({ commit, rootState }, requestBody: Task) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const task = await TaskService.deleteTask({ companyId, requestBody });
      commit("REMOVE_TASK", task);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const task: Module<TaskState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
