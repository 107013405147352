





































































































































































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { Department } from "@/api";
import { TableRow as ExpenseTableRow } from "@/common/ExpenseTableRow";
import _ from "lodash";
import { VForm } from "@/common/vuetify_types";
import {
  floatOnly,
  noSpace,
  requiredSelect,
  requiredString,
  requriedNumber,
} from "@/tool/validation";
import { dateFormat, hourMinAdder } from "@/common/date_format";
import moment from "moment";

type URLs = string | ArrayBuffer;

@Component
export default class ExpenseForm extends Vue {
  @Ref("form") form!: VForm;
  @Prop() data!: ExpenseTableRow;
  @Prop() departmentIdProp!: number;
  @Prop() categoryIdProp!: number;

  catId = 0;
  departId = 0;
  vendor = this.data?.vendor || "";
  amount = this.data?.amount || "";
  invoice = this.data?.invoice || "";
  description = this.data?.description || "";
  receipt?: string[] = [];
  fileNameList?: string[] = [];
  orderDate = new Date().toISOString().substring(0, 10);
  receiveDate: string | null = null;
  apVerified = false;
  formatteOrderDate = "";
  formatteReceiveDate = "";
  showOrderDate = false;
  showreceiveDate = false;
  uploadedFiles: File[] = [];
  fileURL: URLs[] = [];
  deleteLinks: string[] = [];
  saving = false;
  maxDate = new Date().toISOString().substring(0, 10);
  selectRules = [(v: number) => !!v || "required"];
  textRules = [requiredString, noSpace];
  dateRules = [requiredSelect];
  amountRules = [requriedNumber, floatOnly];

  created() {
    if (this.data) {
      this.orderDate = this.data?.orderDate.slice(0, 10);
      this.receiveDate =
        this.data?.dateReceived !== "Pending"
          ? moment(this.data?.dateReceived).format("YYYY-MM-DD")
          : null;
      this.apVerified = this.data?.apVerified ? true : false;
    }
    this.catId = this.data?.catId || this.categoryIdProp;
    this.departId = this.departmentIdProp;
    const nameList = JSON.parse(JSON.stringify(this.data?.receipt || []));
    this.receipt = nameList;
    this.fileNameList =
      nameList?.map((name: string) => {
        const splitName = name.split("_");
        const nameOnly = splitName.slice(2).join("_");
        return nameOnly;
      }) || [];
  }

  get departments() {
    return this.$store.getters["departmentAdmin/departments"]?.map(
      (department: Department) => {
        return {
          ...department,
          header: undefined,
        };
      },
    );
  }

  async getDepartmentCategories() {
    await this.$store.dispatch("departmentAdmin/getCategories", {
      departmentId: this.departId,
    });
  }

  get categories() {
    const categoryList =
      this.$store.getters["departmentAdmin/categories"] || [];
    const orderCategories = _.orderBy(categoryList, ["category"], ["asc"]);
    return orderCategories;
  }

  formatDate(isOrder: boolean) {
    if (isOrder)
      this.formatteOrderDate = this.orderDate
        ? dateFormat(this.orderDate)
        : this.formatteOrderDate;
    else {
      if (this.receiveDate) {
        this.formatteReceiveDate = this.receiveDate
          ? dateFormat(this.receiveDate)
          : this.formatteReceiveDate;
      }
    }
  }

  onChangeFile(event: { target: { files: File[] } }) {
    if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
    event.target.files.forEach(element => {
      this.uploadedFiles.push(element);
      this.fileNameList?.push(element.name);
    });
    this.formDataURL();
  }

  formDataURL() {
    if (this.uploadedFiles.length) {
      this.uploadedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) this.fileURL?.push(event.target?.result);
        };
        reader.readAsDataURL(file);
      });
    }
  }

  removeFile(fileName: string) {
    const index = this.uploadedFiles.findIndex(
      (file: File) => file.name === fileName,
    );
    const rindex = this.receipt?.findIndex((name: string) =>
      name.includes(fileName),
    );
    const findex = this.fileNameList?.findIndex((name: string) =>
      name.includes(fileName),
    );

    // If file is in uploaded files remove it
    if (index > -1) {
      this.uploadedFiles.splice(index, 1);
    }
    if (rindex !== undefined && rindex > -1) {
      this.receipt?.splice(rindex, 1);
    }
    if (findex !== undefined && findex > -1) {
      this.fileNameList?.splice(findex, 1);
    }

    this.fileURL = [];
  }

  createRequestBody() {
    const formData = new FormData();
    const amount = parseFloat(this.amount).toFixed(2);
    const preOrderDate = this.data?.orderDate?.slice(0, 10);
    const newOrderDate = this.orderDate;
    const orderDate =
      preOrderDate === newOrderDate
        ? this.data?.orderDate
        : hourMinAdder(this.orderDate);
    formData.append("catId", this.catId?.toString());
    formData.append("vendor", this.vendor);
    formData.append("description", this.description);
    formData.append("amount", amount);
    formData.append("invoice", this.invoice);
    formData.append("orderDate", orderDate);
    this.uploadedFiles.forEach(file =>
      formData.append("receipt[]", file, file.name),
    );
    if (this.data) {
      this.data?.receipt?.forEach((url: string) => {
        formData.append("documents", url);
      });
      formData.append("id", this.data.id?.toString() || "");
      if (this.receiveDate)
        formData.append("dateReceived", hourMinAdder(this.receiveDate));
      formData.append("apVerified", this.apVerified ? "1" : "0");
    }
    return formData;
  }

  async submit() {
    if (this.form.validate()) {
      this.saving = true;
      const formData = this.createRequestBody();
      await this.$store.dispatch("categoryBudget/addAnExpense", {
        departmentId: this.departId,
        categoryId: this.catId,
        requestBody: formData,
      });
      this.saving = false;
      this.form.reset();
      this.uploadedFiles = [];
      this.$emit("save");
    } else {
      this.saving = false;
    }
  }

  async update() {
    if (this.form.validate()) {
      this.saving = true;
      const formData = this.createRequestBody();
      await this.$store.dispatch("categoryBudget/updateExpense", {
        departmentId: this.departId,
        categoryId: this.catId,
        requestBody: formData,
      });
      this.saving = false;
      this.form.reset();
      this.uploadedFiles = [];
      this.$emit("save");
    }
  }

  close() {
    this.form.reset();
    this.$emit("close");
  }
}
