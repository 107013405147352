/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Track = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        completionRate: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        record: {
    type: 'array',
    contains: {
        type: 'Record',
    },
    isRequired: true,
},
    },
};