






























































































































































import { Department, DepartmentEmployeeAssignment, Employee } from "@/api";
import { selfData } from "@/common/role_utils";
import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MessageLayout extends Vue {
  message = "";
  search = "";
  showAll = false;
  employeeId: number[] = [];
  departmentId = 0;
  to: number[] = [2];
  from: number = selfData().id;
  companyId: number = selfData().companyId;

  get messages(): string[] {
    return this.$store.getters["message/messages"];
  }

  get employeeIds() {
    return this.showAll ? this.employeeId : this.employeeId.slice(0, 3);
  }

  get employees(): { alphabet: string; records: Employee[] }[] {
    const search = !this.search ? "" : this.search;
    const employees = this.$store.state.directory.employees;
    const list = _.reduce(
      _.orderBy(
        _.filter(
          _.filter(employees ?? [], (emp: Employee) => {
            if (this.departmentId != 0) {
              return emp.primaryDepartmentId == this.departmentId;
            } else {
              return true;
            }
          }),
          (employee: Employee) =>
            _.includes(
              `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
              search.toLowerCase(),
            ),
        ),
        [
          (employee: Employee) =>
            `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
        ],
        ["asc"],
      ),
      (acc: { alphabet: string; records: Employee[] }[], cur) => {
        const alphabet = cur.firstName[0].toUpperCase();
        if (!acc[alphabet]) acc[alphabet] = { alphabet, records: [cur] };
        else acc[alphabet].records.push(cur);
        return acc;
      },
      [],
    );
    return Object.values(list);
  }

  getEmployeeName(id: number): string {
    const employees = this.$store.state.directory.employees;
    const employee = _.find(
      employees,
      (employee: Employee) => employee.id == id,
    );
    return `${employee.firstName} ${employee.lastName}`;
  }

  primaryDepartment(
    departments: DepartmentEmployeeAssignment[],
    primaryDepartment: number,
  ) {
    const primary = departments.find(
      item => item.departmentId === primaryDepartment,
    );
    return primary?.departmentName;
  }

  selectEmployee(employeeId: number) {
    if (this.employeeId.includes(employeeId)) {
      const index = this.employeeId.indexOf(employeeId);
      this.employeeId.splice(index, 1);
    } else {
      this.employeeId.push(employeeId);
    }
  }

  clearPayload() {
    this.message = "";
    this.to = [];
    this.from = selfData().id;
  }

  submit() {
    this.to = this.employeeId;
    const payload = {
      message: this.message,
      to: this.to,
      from: this.from,
      companyId: this.companyId,
    };
    console.log(payload);
  }

  get departments(): Department[] {
    const departments = this.$store.state.company.departments;
    if (departments) {
      return [
        { id: 0, name: "All Department", companyId: 0, isArchived: false },
        ...departments,
      ];
    } else {
      return [];
    }
  }
}
