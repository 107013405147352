/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DepartmentEmployeeAssignment = {
    properties: {
        departmentId: {
    type: 'number',
    isRequired: true,
},
        departmentName: {
    type: 'string',
    isRequired: true,
},
        departmentRoles: {
    type: 'array',
    contains: {
    type: 'string',
},
    isRequired: true,
},
    },
};