/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AddDepartmentCategoryRequestBody = {
    properties: {
        category: {
    type: 'string',
    isRequired: true,
},
    },
};