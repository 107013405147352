/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Details } from '../models/Details';
import type { Inspection } from '../models/Inspection';
import type { InspectionDashboardReport } from '../models/InspectionDashboardReport';
import type { InspectionRequest } from '../models/InspectionRequest';
import type { InspectionSummaries } from '../models/InspectionSummaries';
import type { TrackRecords } from '../models/TrackRecords';
import { request as __request } from '../core/request';

export class InspectionService {

    /**
     * Add an Inspection
     * @returns InspectionRequest inspection added
     * @throws ApiError
     */
    public static async addInspection({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: InspectionRequest,
}): Promise<InspectionRequest> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/inspection`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete an Inspection
     * @returns Inspection inspection deleted
     * @throws ApiError
     */
    public static async deleteInspection({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Inspection,
}): Promise<Inspection> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/inspection`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get inspection dashboard report
     * @returns InspectionDashboardReport inspection dashboard summary report
     * @throws ApiError
     */
    public static async getInspectionDashboardReport({
companyId,
beginAt,
endAt,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<InspectionDashboardReport> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/dashboard`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get customer average rating score details
     * @returns Details customer average rating score
     * @throws ApiError
     */
    public static async getAverageRatingDetails({
companyId,
beginAt,
endAt,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<Details> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/average`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get details of customer rating score more than or equals to 4
     * @returns Details get details of customer rating score more than or equals to 4
     * @throws ApiError
     */
    public static async getMoreThanDetails({
companyId,
beginAt,
endAt,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<Details> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/moreThan`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get details of customer rating score less than or equals to 3
     * @returns Details get details of customer rating score less than or equals to 3
     * @throws ApiError
     */
    public static async getLessThanDetails({
companyId,
beginAt,
endAt,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<Details> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/lessThan`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get details of items missed
     * @returns Details get details of items missed
     * @throws ApiError
     */
    public static async getItemsMissedDetails({
companyId,
beginAt,
endAt,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<Details> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/itemsMissed`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get details of unresolved unmet items
     * @returns Details get details of unresolved items
     * @throws ApiError
     */
    public static async getUnresolvedItems({
companyId,
beginAt,
endAt,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<Details> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/unresolvedItems`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get details of items missed of the department
     * @returns Details get details of items missed of the department
     * @throws ApiError
     */
    public static async getDepartmentItemsMissedDetails({
companyId,
departmentId,
beginAt,
endAt,
}: {
/** company id **/
companyId: number,
/** department id **/
departmentId: number,
beginAt?: string,
endAt?: string,
}): Promise<Details> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/${departmentId}/departmentItemsMissed`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get summary list of inspection data
     * @returns InspectionSummaries get summary details of inspection data
     * @throws ApiError
     */
    public static async getSummaryDetails({
companyId,
beginAt,
endAt,
floorId,
inspectedBy,
roomId,
bedId,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
floorId?: number,
inspectedBy?: number,
roomId?: number,
bedId?: number,
}): Promise<InspectionSummaries> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/summary`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
                'floorId': floorId,
                'inspectedBy': inspectedBy,
                'roomId': roomId,
                'bedId': bedId,
            },
        });
        return result.body;
    }

    /**
     * get tracking records of inspection
     * @returns TrackRecords get tracking records of inspection
     * @throws ApiError
     */
    public static async getTrackRecords({
companyId,
beginAt,
endAt,
rateStart,
}: {
/** company id **/
companyId: number,
beginAt?: string,
endAt?: string,
rateStart?: boolean,
}): Promise<TrackRecords> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/inspection/tracking`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
                'rateStart': rateStart,
            },
        });
        return result.body;
    }

}