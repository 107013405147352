














































































































































































































import { Department, Forms, Questions, ResponseQuestion } from "@/api";
import { Component, Ref, Vue } from "vue-property-decorator";
import { requiredString, noSpace } from "@/tool/validation";
import { VForm } from "@/common/vuetify_types";
import { selfData } from "@/common/role_utils";
import ConfirmationModule from "@/components/confirmationModule/ConfirmationModule.vue";
import PublishDialog from "../PublishDialog.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    ConfirmationModule,
    draggable,
    PublishDialog,
  },
})
export default class Question extends Vue {
  @Ref("form") readonly form!: VForm;
  formModel = false;
  departmentID = 0;
  question = "";
  allowComments = false;
  allowFeedback = false;
  loading = true;
  companyId = selfData().companyId;
  required_text = [requiredString, noSpace];
  selectEditingQuestion: ResponseQuestion | null = null;
  saving = false;
  deleting = false;
  deleteDialog = false;
  confirmPublish = false;
  publishing = false;
  savingDraft = false;

  required_select = (v: string) =>
    (v !== "" && v !== undefined && v != "0") || "Required";

  async created() {
    const formId = this.$route.params.formId;
    this.loading = true;
    if (formId)
      await this.$store.dispatch("survey/fetchQuestions", {
        formId,
        dispatchAdmission: false,
      });
    this.loading = false;
  }

  get departments(): Department[] {
    return this.$store.getters["company/departments"]?.map(
      (item: Department) => {
        return {
          ...item,
          header: undefined,
        };
      },
    );
  }

  clearForm() {
    this.selectEditingQuestion = null;
    this.departmentID = 0;
    this.question = "";
    this.allowComments = false;
    this.allowFeedback = false;
    this.form.reset();
    this.form.resetValidation();
  }

  get questions(): Questions {
    return this.$store.getters["survey/dischargeQuestions"];
  }

  set questions(values: Questions) {
    const requestBody = values.map((item, index) => {
      return {
        ...item,
        order: index,
      };
    });

    const formId = Number(this.$route.params.formId);
    if (!formId) {
      this.$store.dispatch("alert/warning", "failed to add question");
      return;
    }
    this.$store.dispatch("survey/sortQuestion", {
      requestBody,
      formId,
      dispatchAdmission: false,
    });
  }

  async submit() {
    const formId = Number(this.$route.params.formId);
    if (!formId) {
      this.$store.dispatch("alert/warning", "failed to add question");
      return;
    }
    if (this.form.validate()) {
      this.saving = true;
      const requestBody = {
        departmentID: this.departmentID,
        question: this.question,
        allowComment: this.allowComments,
        allowFeedback: this.allowFeedback,
      };

      await this.$store.dispatch("survey/addQuestion", {
        requestBody,
        formId,
        dispatchAdmission: false,
      });
      this.saving = false;
      this.clearForm();
    }
  }

  async removeQuestion(question: ResponseQuestion) {
    this.selectEditingQuestion = question;
    this.deleteDialog = !this.deleteDialog;
  }

  async confirmRemove() {
    const formId = Number(this.$route.params.formId);
    if (!formId) {
      this.$store.dispatch("alert/warning", "failed to add question");
      return;
    }
    this.deleting = true;
    await this.$store.dispatch("survey/removeQuestion", {
      requestBody: this.selectEditingQuestion,
      formId,
      dispatchAdmission: false,
    });
    this.clearForm();
    this.deleting = false;
    this.deleteDialog = !this.deleteDialog;
  }

  back() {
    this.$router.back();
  }

  async publish() {
    const formId = Number(this.$route.params.formId);
    const forms = JSON.parse(
      JSON.stringify(this.$store.getters["survey/dischargeForms"] || []),
    ) as Forms;
    const requestBody = forms.filter(form => form.id === formId);
    if (!formId || !forms || requestBody.length == 0) {
      this.$store.dispatch("alert/warning", "failed to publish form");
      return;
    }

    const form = requestBody[0];
    form.status = 3;
    this.publishing = true;
    await this.$store.dispatch("survey/updateForm", {
      requestBody: form,
      dispatchAdmission: false,
    });
    this.publishing = false;
    this.confirmPublish = !this.confirmPublish;
  }
}
