













































import { Component, Vue } from "vue-property-decorator";
import * as roleUtils from "@/common/role_utils";
import { TarfStatusDetail } from "@/api";
import _ from "lodash";
import { orderStatusList, OrderStatus } from "@/tool/orderStatuses";
import statusId from "@/tool/tarfStatus";

@Component
export default class SupervisorMenu extends Vue {
  orderTarfStatus!: OrderStatus[];

  created() {
    this.orderTarfStatus = _.concat(orderStatusList);
  }

  get tarfStatuses() {
    const statuses = this.$store.getters["tarf/tarfStatus"];
    return statuses?.statuses ?? [];
  }

  getStatus(id: number) {
    const status = _.find(
      this.tarfStatuses,
      (status: TarfStatusDetail) => status.id == id,
    );
    return status?.count || 0;
  }

  getColor(id: number) {
    if (id === statusId.new) return "primary";
    else if (id === statusId.excused) return "green";
    else if (id === statusId.unexcused) return "red";
    else if (id === statusId.deny) return "pink";
    else if (id === statusId.resubmit) return "purple ";
    else return "grey";
  }

  goTo(path: string) {
    path = path.replaceAll("Corrected/Submitted", "resubmit");
    path = path.toLowerCase();
    this.$router.push({ path });
  }

  get activeMenu() {
    if (this.$route.path.includes("new")) return statusId.new;
    else if (this.$route.params.status === "excused") return statusId.excused;
    else if (this.$route.params.status === "unexcused")
      return statusId.unexcused;
    else if (this.$route.path.includes("processed")) return 3;
    else if (this.$route.path.includes("resubmit")) return statusId.resubmit;
    else if (this.$route.path.includes("denied")) return statusId.deny;
    else if (this.$route.path.includes("supervisor/form")) return "tarf";
    else return "";
  }

  get isSalaryPost() {
    return roleUtils.checkSelfSubrole();
  }

  get isAdmin() {
    return roleUtils.isAdmin();
  }

  get isSuperAdmin() {
    return this.$store.getters["isSuperAdmin"];
  }

  get isOpen() {
    return this.$store.state.navigationRailIsOpen;
  }
}
