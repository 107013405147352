















import { Component, Vue, Watch } from "vue-property-decorator";
import { ScoreboardBreakdown, ScoreboardSummary } from "@/api";
import ScoreboardSummaryCard from "@/components/scoreboard/ScoreboardSummaryCard.vue";
import { DataTableHeader } from "vuetify";

@Component({
  components: { ScoreboardSummaryCard },
})
export default class DepartmentScoreboard extends Vue {
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    this.loadBreakdown();
  }

  get departmentId() {
    const departmentIdParam =
      (this.$route.query?.departmentId as string) ?? null;
    if (departmentIdParam) {
      return parseInt(departmentIdParam);
    }
    return 0;
  }

  loadBreakdown() {
    this.$store.dispatch(
      "scoreboard/loadDepartmentBreakdown",
      this.departmentId,
    );
  }

  get scoreboards(): ScoreboardSummary[] {
    return this.$store.getters["scoreboard/scoreboards"];
  }

  get breakdown(): ScoreboardBreakdown | null {
    if (this.departmentId) {
      const breakdowns = this.$store.getters[
        "scoreboard/departmentBreakdown"
      ] as { [departmentId: number]: ScoreboardBreakdown };
      return breakdowns[this.departmentId];
    }
    return null;
  }

  get scoreboard(): ScoreboardSummary | undefined {
    const id = this.departmentId;
    if (id) {
      for (const s of this.scoreboards) {
        if (s.departmentId === id) {
          return s;
        }
      }
    }
    return undefined;
  }

  get breakdownHeaders() {
    const breakdown = this.breakdown;
    if (breakdown) {
      return [
        {
          text: "Name",
          align: "start",
          value: "name",
        } as DataTableHeader,
        ...breakdown.events?.map(eventType => {
          return {
            text: eventType.name,
            value: `eventType-${eventType.id}`,
          } as DataTableHeader;
        }),
      ];
    }
    return [];
  }

  get breakdownRows() {
    const breakdown = this.breakdown;
    if (breakdown) {
      return breakdown.employeeScores.map(scores => {
        const row = {} as { [row: string]: number | string };
        row["name"] = scores.employeeName;
        const employeeScoreCounts = scores.scores;
        for (let i = 0; i < employeeScoreCounts.length; i++) {
          const eventType = breakdown.events[i];
          row[`eventType-${eventType.id}`] = employeeScoreCounts[i];
        }
        return row;
      });
    }
    return [];
  }
}
