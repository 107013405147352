































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ActiveSurveyForm } from "@/api";
import FiveStarForm from "@/components/five_star/FiveStarForm.vue";
import SurveyForm from "@/views/survey/form/Form.vue";
import OptionsSurvey from "@/views/survey/form/OptionsSurvey.vue";

@Component({
  components: {
    SurveyForm,
    FiveStarForm,
    OptionsSurvey,
  },
})
export default class StartSurvey extends Vue {
  @Prop() readonly dispatchAdmission!: boolean;
  dialog = false;
  isAdmission = this.dispatchAdmission ? true : false;
  surveyDialog = false;
  surveyOptionDialog = false;
  counter = 0;
  dischargeForm!: ActiveSurveyForm;
  admissionForm!: ActiveSurveyForm;

  async created() {
    this.isAdmission = this.dispatchAdmission ? true : false;
    await this.$store.dispatch("survey/fetchActiveSurveyForm");
  }

  recognize() {
    this.surveyDialog = !this.surveyDialog;
    this.dialog = !this.dialog;
  }

  startAdmissionSurvey() {
    this.counter++;
    this.isAdmission = true;
    this.surveyOptionDialog = !this.surveyOptionDialog;
    this.surveyDialog = !this.surveyDialog;
  }

  startDischargeSurvey() {
    this.counter++;
    this.isAdmission = false;
    this.surveyOptionDialog = !this.surveyOptionDialog;
    this.surveyDialog = !this.surveyDialog;
  }

  complete() {
    this.$emit("complete", this.isAdmission);
  }
}
