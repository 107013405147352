


























































































































































































































































































































































































































































































































































































import {
  GetTarfRequestDetailResponse,
  TarfReason,
  TarfRequestRequestBody,
} from "@/api";
import { VForm } from "@/common/vuetify_types";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import moment from "moment";
import shifts from "@/common/shift_type";
import statusId from "@/tool/tarfStatus";
import TarfConfirmModal from "@/components/tarf_form/TarfConfirmModal.vue";
import { dateFormat, hourMinAdder } from "@/common/date_format";
import { checkSelfRole } from "@/common/role_utils";
import * as validation from "@/tool/validation";

@Component({
  components: {
    TarfConfirmModal,
  },
})
export default class TARFform extends Vue {
  @Prop() readonly tarfInfo!: GetTarfRequestDetailResponse | null;
  @Ref("form") readonly form!: VForm;

  formModel = false;
  shift = this.tarfInfo?.tarf ? this.tarfInfo?.tarf?.shiftType || 0 : "";
  reason: number[] =
    this.tarfInfo?.tarf?.reasons?.map((item: TarfReason) => item.id) || [];

  shifts = [...shifts];
  searchInput = null;

  showDate = false;
  adjustmentDate = this.tarfInfo?.tarf?.adjustmentDateTime.substr(0, 10) || "";
  formattedAdjustmentDate = "";
  maxDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  employeeComment = this.tarfInfo?.tarf?.requesterComment || "";
  denyComment = this.tarfInfo?.tarf?.deniedComment || "";
  workTimeIn = {
    hours: "",
    min: "",
    ampm: "",
  };
  workTimeOut = {
    hours: "",
    min: "",
    ampm: "",
  };
  mealTimeIn = {
    hours: "",
    min: "",
    ampm: "",
  };
  mealTimeOut = {
    hours: "",
    min: "",
    ampm: "",
  };
  hourItems: string[] = [];
  ampmItems = ["AM", "PM"];
  minItems: string[] = [];
  workTimeErrors: { timeIn: string[]; timeOut: string[] } = {
    timeIn: [],
    timeOut: [],
  };
  mealTimeErrors: { timeIn: string[]; timeOut: string[] } = {
    timeIn: [],
    timeOut: [],
  };
  required = [validation.requiredString];
  requiredShift = [validation.requriedNumber];
  requiredSelect = [(v: string) => !!v || ""];
  reasonRules = [validation.requireAutoSelectNumber];
  hasTimeIn = this.tarfInfo?.tarf?.timeIn;
  hasTimeOut = this.tarfInfo?.tarf?.timeOut;
  // TODO: Use meal time in and time out in tarfInfo
  hasMealTimeIn = this.tarfInfo?.tarf?.mealTimeIn;
  hasMealTimeOut = this.tarfInfo?.tarf?.mealTimeOut;
  formConfirmModal = false;
  text = "";
  hoursWorked = this.tarfInfo?.tarf?.hoursWorked
    ? `${this.tarfInfo?.tarf?.hoursWorked.toString().substring(0, 5)} hrs`
    : "";
  employeeName = this.hasTarfInfo
    ? `${this.tarfInfo?.tarf?.requestedBy?.firstName} ${this.tarfInfo?.tarf?.requestedBy?.lastName}`
    : "";
  employeeTitle = this.tarfInfo?.tarf?.requestedBy?.title || "";
  employeeId = this.tarfInfo?.tarf?.requestedBy?.externalEmployeeId || "";
  processing = {
    submitted: false,
    resubmitted: false,
  };

  async created() {
    this.setWorkTime();
    this.setMealTime();
    this.setHourMinDropDown();
    await this.$store.dispatch("tarf/getReasonList");
  }

  formatDate(date: string) {
    this.formattedAdjustmentDate = dateFormat(date);
  }

  get browserInfo() {
    const ua = navigator.userAgent.includes("Firefox");
    return ua;
  }

  get reasonList() {
    return this.$store.getters["tarf/reasonList"];
  }

  get isNoLunch() {
    const noLunch = this.reasonList?.find((item: TarfReason) =>
      item.name?.includes("No lunch taken"),
    );
    return this.reason.includes(noLunch.id);
  }

  setHourMinDropDown() {
    for (let i = 0; i < 60; i++) {
      if (i < 10) {
        this.minItems.push("0" + i);
      } else {
        this.minItems.push(`${i}`);
      }
    }
    for (let i = 1; i < 13; i++) {
      if (i < 10) {
        this.hourItems.push("0" + i);
      } else {
        this.hourItems.push(`${i}`);
      }
    }
  }

  get hasTarfInfo() {
    if (this.tarfInfo) return true;
    else return false;
  }

  get isEdit() {
    if (!this.tarfInfo || this.isDeny) return true;
    else return false;
  }

  get isCreate() {
    return (this.isForm && !checkSelfRole("employee")) || this.isRequest;
  }

  get currentUser() {
    const user = this.$store.getters["getCurrentEmployeeSelf"];
    if (!this.hasTarfInfo) {
      this.employeeName = `${user?.firstName} ${user?.lastName}`;
      this.employeeTitle = user?.title;
      this.employeeId = user?.externalEmployeeId;
    }
    return user;
  }

  setWorkTime() {
    if (this.hasTimeIn && this.hasTimeOut) {
      const timeIn = moment(this.hasTimeIn, ["HH:mm:ss"]).format("h:mm A");
      const [inTime, a] = timeIn.split(" ");
      this.workTimeIn.ampm = a;
      const [hourIn, minIn] = inTime.split(":");
      this.workTimeIn.hours = parseInt(hourIn) < 10 ? 0 + hourIn : hourIn;
      this.workTimeIn.min = minIn;
      const timeOut = moment(this.hasTimeOut, ["HH:mm:ss"]).format("h:mm A");
      const [outTime, p] = timeOut.split(" ");
      this.workTimeOut.ampm = p;
      const [hourOut, minOut] = outTime.split(":");
      this.workTimeOut.hours = parseInt(hourOut) < 10 ? 0 + hourOut : hourOut;
      this.workTimeOut.min = minOut;
    }
  }

  setMealTime() {
    if (this.hasMealTimeIn && this.hasMealTimeOut) {
      const timeIn = moment(this.hasMealTimeIn, ["HH:mm:ss"]).format("h:mm A");
      const [inTime, a] = timeIn.split(" ");
      this.mealTimeIn.ampm = a;
      const [hourIn, minIn] = inTime.split(":");
      this.mealTimeIn.hours = parseInt(hourIn) < 10 ? 0 + hourIn : hourIn;
      this.mealTimeIn.min = minIn;
      const timeOut = moment(this.hasMealTimeOut, ["HH:mm:ss"]).format(
        "h:mm A",
      );
      const [outTime, p] = timeOut.split(" ");
      this.mealTimeOut.ampm = p;
      const [hourOut, minOut] = outTime.split(":");
      this.mealTimeOut.hours = parseInt(hourOut) < 10 ? 0 + hourOut : hourOut;
      this.mealTimeOut.min = minOut;
    }
  }

  get isRequest() {
    return this.$route.path.includes("request");
  }

  get isForm() {
    return this.$route.path.includes("form");
  }

  get isDeny() {
    return this.$route.path.includes("denied");
  }

  validateTime(
    timeIn: {
      hours: string;
      min: string;
      ampm: string;
    },
    timeOut: {
      hours: string;
      min: string;
      ampm: string;
    },
  ) {
    const errors: { timeIn: string[]; timeOut: string[] } = {
      timeIn: [],
      timeOut: [],
    };
    if (timeIn.hours === "" || !timeIn.hours)
      errors.timeIn.push("Hour is required");
    if (timeOut.hours === "" || !timeOut.hours)
      errors.timeOut.push("Hour is required");
    if (timeIn.min === "" || !timeIn.min) errors.timeIn.push("Min is required");
    if (timeOut.min === "" || !timeOut.min)
      errors.timeOut.push("Min is required");
    if (timeIn.ampm === "" || !timeIn.ampm)
      errors.timeIn.push("AMPM is required");
    if (timeOut.ampm === "" || !timeOut.ampm)
      errors.timeOut.push("AMPM is required");
    if (!errors.timeIn.length && !errors.timeOut.length)
      return { timeIn: [], timeOut: [] };
    return errors;
  }

  resetTimeErrors() {
    this.workTimeErrors = { timeIn: [], timeOut: [] };
    this.mealTimeErrors = { timeIn: [], timeOut: [] };
  }

  calcHoursWorked() {
    // this.resetTimeErrors();
    const isWorkTimeValid = this.validateTime(
      this.workTimeIn,
      this.workTimeOut,
    );
    const isMealTimeValid = this.validateTime(
      this.mealTimeIn,
      this.mealTimeOut,
    );
    let workTimeDurationHour = 0;
    let workTimeDurationMin = 0;
    let mealTimeDurationHour = 0;
    let mealTimeDurationMin = 0;
    let timeIn, timeOut;
    if (!isWorkTimeValid.timeIn.length && !isWorkTimeValid.timeOut.length) {
      this.workTimeErrors = isWorkTimeValid;
      const rawTimeIn = `${this.workTimeIn.hours} : ${this.workTimeIn.min} ${this.workTimeIn.ampm}`;
      const rawTimeOut = `${this.workTimeOut.hours} : ${this.workTimeOut.min} ${this.workTimeOut.ampm}`;
      timeIn = moment(rawTimeIn, "HH:mm:ss a");
      timeOut = moment(rawTimeOut, "HH:mm:ss a");
      const duration = moment.duration(timeOut.diff(timeIn));
      workTimeDurationHour = parseInt(duration.asHours().toString());
      workTimeDurationMin = parseInt(duration.asMinutes().toString()) % 60;
      if (workTimeDurationHour < 0)
        workTimeDurationHour = 24 + workTimeDurationHour;
      if (workTimeDurationMin < 0) {
        workTimeDurationMin = 60 + workTimeDurationMin;
        workTimeDurationHour -= 1;
      }
      this.hoursWorked = `${Math.abs(workTimeDurationHour)
        .toString()
        .padStart(2, "0")}:${Math.abs(workTimeDurationMin)
        .toString()
        .padStart(2, "0")} hrs`;
    }
    if (!isMealTimeValid.timeIn.length && !isMealTimeValid.timeOut.length) {
      const rawMealTimeIn = `${this.mealTimeIn.hours} : ${this.mealTimeIn.min} ${this.mealTimeIn.ampm}`;
      const rawMealTimeOut = `${this.mealTimeOut.hours} : ${this.mealTimeOut.min} ${this.mealTimeOut.ampm}`;
      const mealTimeIn = moment(rawMealTimeIn, "HH:mm:ss a");
      const mealTimeOut = moment(rawMealTimeOut, "HH:mm:ss a");
      const duration = moment.duration(mealTimeOut.diff(mealTimeIn));
      mealTimeDurationHour = parseInt(duration.asHours().toString());
      mealTimeDurationMin = parseInt(duration.asMinutes().toString()) % 60;
      if (mealTimeDurationHour < 0)
        mealTimeDurationHour = 24 + mealTimeDurationHour;
      if (workTimeDurationMin < 0) {
        mealTimeDurationMin = 60 + mealTimeDurationMin;
        mealTimeDurationHour -= 1;
      }
      let minutes = workTimeDurationMin - mealTimeDurationMin;
      let hours = workTimeDurationHour - mealTimeDurationHour;
      if (minutes < 0) {
        hours -= 1;
        minutes = 60 - Math.abs(minutes);
      }
      if (timeIn && timeOut) {
        if (timeIn < mealTimeIn && timeOut > mealTimeOut) {
          this.mealTimeErrors = isMealTimeValid;
          this.hoursWorked = `${hours
            .toString()
            .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} Hrs`;
        } else {
          if (timeIn > mealTimeIn)
            this.mealTimeErrors.timeIn = [
              "Meal TimeIn cannot be greater then Work TimeIn.",
            ];
          if (timeOut < mealTimeOut)
            this.mealTimeErrors.timeOut = [
              "Meal TimeOut cannot be greater then Work TimeOut.",
            ];
        }
      }
    }
  }

  validateAllForm() {
    this.resetTimeErrors();
    const isWorkTimeValid = this.validateTime(
      this.workTimeIn,
      this.workTimeOut,
    );
    this.workTimeErrors.timeIn = isWorkTimeValid.timeIn.length
      ? isWorkTimeValid.timeIn
      : [];
    this.workTimeErrors.timeOut = isWorkTimeValid.timeOut.length
      ? isWorkTimeValid.timeOut
      : [];

    let isValidMealTime = false;
    if (!this.isNoLunch) {
      const isMealTimeValid = this.validateTime(
        this.mealTimeIn,
        this.mealTimeOut,
      );
      this.mealTimeErrors.timeIn = isMealTimeValid.timeIn.length
        ? isMealTimeValid.timeIn
        : [];
      this.mealTimeErrors.timeOut = isMealTimeValid.timeOut.length
        ? isMealTimeValid.timeOut
        : [];
      if (!isMealTimeValid.timeIn.length && !isMealTimeValid.timeOut.length)
        isValidMealTime = true;
    } else {
      isValidMealTime = true;
    }
    const isValid = this.form.validate();
    if (
      isValid &&
      !isWorkTimeValid.timeIn.length &&
      !isWorkTimeValid.timeOut.length &&
      isValidMealTime
    )
      return true;
    return false;
  }

  requestPayload() {
    let isChanged = true;

    let adjustmentDateTime = hourMinAdder(this.adjustmentDate);
    const rawTimeIn = `${this.workTimeIn.hours} : ${this.workTimeIn.min} ${this.workTimeIn.ampm}`;
    const rawTimeOut = `${this.workTimeOut.hours} : ${this.workTimeOut.min} ${this.workTimeOut.ampm}`;
    const rawMealTimeIn = `${this.mealTimeIn.hours} : ${this.mealTimeIn.min} ${this.mealTimeIn.ampm}`;
    const rawMealTimeOut = `${this.mealTimeOut.hours} : ${this.mealTimeOut.min} ${this.mealTimeOut.ampm}`;
    let timeIn = moment(rawTimeIn, ["h:mm A"]).format("HH:mm");
    let timeOut = moment(rawTimeOut, ["h:mm A"]).format("HH:mm");
    let mealTimeIn = !this.isNoLunch
      ? moment(rawMealTimeIn, ["h:mm A"]).format("HH:mm")
      : "";
    let mealTimeOut = !this.isNoLunch
      ? moment(rawMealTimeOut, ["h:mm A"]).format("HH:mm")
      : "";
    let hoursWorked = this.hoursWorked;
    const reasonId = this.reason.filter(item => item);

    if (adjustmentDateTime === this.tarfInfo?.tarf?.adjustmentDateTime) {
      adjustmentDateTime = "";
    }
    if (timeIn + ":00" === this.tarfInfo?.tarf?.timeIn) timeIn = "";
    if (timeOut + ":00" === this.tarfInfo?.tarf?.timeOut) timeOut = "";
    if (
      this.hoursWorked.slice(0, 5) + ":00" ===
      this.tarfInfo?.tarf?.hoursWorked
    )
      hoursWorked = "";
    if (mealTimeIn + ":00" === this.tarfInfo?.tarf?.mealTimeIn) mealTimeIn = "";
    if (mealTimeOut + ":00" === this.tarfInfo?.tarf?.mealTimeOut)
      mealTimeOut = "";

    isChanged =
      adjustmentDateTime +
      timeIn +
      timeOut +
      mealTimeIn +
      mealTimeOut +
      hoursWorked
        ? true
        : false;

    if (this.isRequest || (this.isForm && !checkSelfRole("employee"))) {
      return {
        reasonId,
        adjustmentDateTime,
        timeIn,
        timeOut,
        mealTimeIn,
        mealTimeOut,
        hoursWorked: hoursWorked.slice(0, 5),
        shiftType: Number(this.shift),
        requesterComment: this.employeeComment,
      };
    } else if (this.isDeny) {
      if (isChanged) {
        return {
          adjustmentDateTime,
          timeIn,
          timeOut,
          hoursWorked: hoursWorked.slice(0, 5),
          mealTimeIn,
          mealTimeOut,
        };
      } else {
        return null;
      }
    } else return null;
  }

  async submit() {
    this.text =
      "Your request has been submitted to your supervisor for approval.";
    if (this.validateAllForm()) {
      try {
        this.processing.submitted = true;
        const requestBody = this.requestPayload();
        await this.$store.dispatch(
          "tarf/postEmployeeTimeAdjustmentFormRequest",
          requestBody as TarfRequestRequestBody,
        );
        this.formConfirmModal = true;
        if (checkSelfRole("employee"))
          await this.$store.dispatch(
            "tarf/fetchEmployeeTarfList",
            statusId.new,
          );
        await this.$store.dispatch("tarf/fetchTarfStatuses", {
          force: true,
          toFetch: -1,
        });
        this.form?.reset();
        this.processing.submitted = false;
      } catch (e) {
        this.$store.dispatch("alert/warning", e);
        this.processing.submitted = false;
      }
    }
    this.$emit("reInitiate");
  }

  async reSubmit() {
    this.text =
      "Your request has been resubmitted to your supervisor for approval.";
    const requestBody = this.requestPayload();
    if (this.validateAllForm() && requestBody) {
      try {
        this.processing.resubmitted = true;
        await this.$store.dispatch("tarf/resubmitTarrf", {
          tarfId: this.tarfInfo?.tarf?.id,
          requestBody: { status: statusId.resubmit },
          editRequestBody: requestBody,
        });
        this.formConfirmModal = true;
        if (checkSelfRole("employee"))
          await this.$store.dispatch(
            "tarf/fetchEmployeeTarfList",
            statusId.deny,
          );
        await this.$store.dispatch("tarf/fetchTarfStatuses", {
          force: true,
          toFetch: -1,
        });
        this.form.resetValidation();
        this.processing.resubmitted = false;
      } catch (error) {
        this.$store.dispatch("alert/warning", error);
        this.processing.resubmitted = false;
      }
    } else {
      this.$store.dispatch(
        "alert/error",
        "please change the field as per the Reason for Denial comment.",
      );
    }
  }

  handleFomrConfirmModal() {
    this.formConfirmModal = !this.formConfirmModal;
    if (this.$route.path.includes("employee"))
      this.$router.push({ path: `/form/employee` });
    else this.$router.push({ path: `/form/supervisor` });
  }

  setEmployeeDetail() {
    if (this.currentUser) {
      this.employeeName = `${this.currentUser?.firstName} ${this.currentUser?.lastName}`;
      this.employeeTitle = this.currentUser?.title;
      this.employeeId = this.currentUser?.externalEmployeeId;
    }
    this.adjustmentDate = "";
    this.formattedAdjustmentDate = "";
  }

  submitAnother() {
    this.form.reset();
    this.form.resetValidation();
    this.setEmployeeDetail();
    this.formConfirmModal = !this.formConfirmModal;
  }

  remove(id: number) {
    const findex = this.reason.findIndex(item => item === id);
    if (findex) this.reason.splice(findex, 1);
  }
}
