
































































import { Component, Ref, Vue } from "vue-property-decorator";
import { AuthService, Company, RegisterAccountRequestBody } from "@/api";
import { VForm } from "@/common/vuetify_types";
import auth from "@/auth";
import * as validation from "@/tool/validation";

@Component
export default class ActivateAccount extends Vue {
  @Ref("employeeActivationForm") employeeActivationForm!: VForm;
  loginTypeEmail = 1;
  loginTypeEmployeeId = 2;

  loginTypeRadioGroup = this.loginTypeEmail;

  company: Company | null = null;
  companyCode = "";
  externalEmployeeId = "";
  email = "";
  resetCode = "";
  password = "";
  isPasswordVisible = false;
  snackbar = false;
  errorText = "";
  emailError = "";
  idError = "";
  codeError = "";
  passwordError = "";
  isActivate = this.$route.path.includes("activate");
  submitting = false;

  rules = {
    required: validation.requiredString,
    validEmail: validation.emailValid,
    passLen: (v: string) => validation.minLen(v, 6),
    spaceOnly: validation.noSpace,
    numberOnly: validation.numberOnly,
  };

  created() {
    // set query param
    this.externalEmployeeId = (this.$route.query.employeeID as string) ?? "";
    this.email = (this.$route.query.email as string) ?? "";

    if (this.externalEmployeeId) {
      this.loginTypeRadioGroup = this.loginTypeEmployeeId;
    } else {
      this.loginTypeRadioGroup = this.loginTypeEmail;
    }

    this.resetCode = (this.$route.query.activationCode as string) ?? "";
    this.companyCode = (this.$route.query.companyCode as string) ?? "";
  }

  async load() {
    try {
      const comp = await AuthService.lookupCompany({
        companyCode: this.companyCode?.trim(),
      });
      this.company = comp;
    } catch (e) {
      throw new Error("Company Code does not match");
    }
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  showError(text: string) {
    this.errorText = text;
    this.snackbar = true;
  }

  async activateAccount() {
    if (this.employeeActivationForm.validate()) {
      this.submitting = true;
      await this.load().catch(message => {
        this.showError(message);
        this.submitting = false;
      });
      if (this.company) {
        try {
          const requestBody = {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            companyId: this.company!.id,
            registrationCode: this.resetCode.trim(),
            email: this.email.trim(),
            externalEmployeeId: this.externalEmployeeId.trim(),
            password: this.password,
          } as RegisterAccountRequestBody;
          await AuthService.registerAccount({
            requestBody: requestBody,
          });
          // registration success, let's get them logged in!
          if (this.loginTypeRadioGroup === this.loginTypeEmail) {
            try {
              await auth.loginWithEmailAndPassword(this.email, this.password);
              await this.$router.replace("/");
            } catch (e) {
              await this.$router.replace("/login");
            }
          } else {
            try {
              await auth.loginWithEmployeeID(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.company!.id,
                this.externalEmployeeId,
                this.password,
              );
              await this.$router.replace("/");
            } catch (e) {
              await this.$router.replace("/login");
            }
          }
          this.submitting = false;
        } catch (e) {
          this.showError(
            this.isActivate
              ? "Error registering account."
              : "Error resetting new password.",
          );
          this.submitting = false;
        }
      }
    }
  }
}
