import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { Department, DepartmentsService } from "@/api";
import _ from "lodash";

export type CompanyState = {
  departments: Department[] | null;
};

const state: CompanyState = {
  departments: null,
};

const getters: GetterTree<CompanyState, RootState> = {
  departments(state) {
    return state.departments;
  },
};

const mutations: MutationTree<CompanyState> = {
  SET_DEPARTMENTS: (state, departments: Department[]) => {
    state.departments = _.sortBy(departments || [], ["name"]);
  },
};

const actions: ActionTree<CompanyState, RootState> = {
  loadDepartments: async ({ commit, rootState, getters }, force = false) => {
    const companyId = rootState.appContext.company?.id;
    let hasDepartment = getters["departments"];
    if (force == true) hasDepartment = false;
    if (companyId && !hasDepartment) {
      try {
        const departmentList = await DepartmentsService.listDepartments({
          companyId,
        });
        commit("SET_DEPARTMENTS", departmentList.departments);
        commit("departmentAdmin/SET_DEPARTMENTS", departmentList.departments, {
          root: true,
        });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
};

export const company: Module<CompanyState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
