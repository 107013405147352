import moment from "moment";

function calculateBudget(ppd: number, census: number) {
  const now = moment(); // create a new Moment object for the current date and time
  const lastDayOfMonth = now.endOf("month");
  const numOfDays = parseFloat(lastDayOfMonth.format("DD"));

  return ppd * census * numOfDays;
}

export { calculateBudget };
