























































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import { LoggedEvent } from "@/api";

@Component
export default class EventList extends Vue {
  @Prop() events!: LoggedEvent[];

  created() {
    if (this.$el) this.$el.scrollTop = 0;
  }

  formatEventDate(date: string) {
    const formattedDate = moment(date).format("MMM DD,YYYY ,hh:mm a");
    return formattedDate;
  }
}
