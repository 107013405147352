

















































































































import { Component, Vue } from "vue-property-decorator";
import { DepartmentEmployeeAssignment, Employee } from "@/api";
import _ from "lodash";
import * as roleUtils from "@/common/role_utils";
import AddEmployee from "@/views/company_directory/AddEmployee.vue";
import loading from "@/components/loader/loading.vue";

@Component({
  components: {
    AddEmployee,
    loading,
  },
})
export default class EmployeeList extends Vue {
  search = "";
  showAdd = false;
  width = this.$vuetify.breakpoint.smAndDown ? screen.width + "px" : 350 + "px";
  isDataLoading = false;
  counterDrawer = 0;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("directory/loadAllEmployees");
    this.isDataLoading = false;
  }

  get employeeId() {
    if (this.$route.params.id) {
      return parseInt(this.$route.params.id);
    } else {
      return 0;
    }
  }

  get employees(): { alphabet: string; records: Employee[] }[] {
    const search = !this.search ? "" : this.search;
    const employees = this.$store.state.directory.employees;
    const list = _.reduce(
      _.orderBy(
        _.filter(employees ?? [], (employee: Employee) =>
          _.includes(
            `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
            search.toLowerCase(),
          ),
        ),
        [
          (employee: Employee) =>
            `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
        ],
        ["asc"],
      ),
      (acc: { alphabet: string; records: Employee[] }[], cur) => {
        const alphabet = cur.firstName[0].toUpperCase();
        if (!acc[alphabet]) acc[alphabet] = { alphabet, records: [cur] };
        else acc[alphabet].records.push(cur);
        return acc;
      },
      [],
    );
    return Object.values(list);
  }

  get isAdmin() {
    const currentEmployeeSelf = this.$store.getters[
      "getCurrentEmployeeSelf"
    ] as Employee;
    if (currentEmployeeSelf && currentEmployeeSelf.roles) {
      return roleUtils.isAdmin();
    }
    return false;
  }

  get isSuperAdmin(): Employee {
    return this.$store.getters["isSuperAdmin"];
  }

  clearSearch() {
    this.search = "";
  }

  selectEmployee(employeeId: number) {
    this.$router.push({
      path: `/company-directory/profile/${employeeId}`,
    });
  }

  createEmployee() {
    this.counterDrawer++;
    this.showAdd = true;
  }

  close() {
    this.clearSearch();
    this.showAdd = false;
    this.counterDrawer++;
  }

  primaryDepartment(
    departments: DepartmentEmployeeAssignment[],
    primaryDepartment: number,
  ) {
    const primary = departments.find(
      item => item.departmentId === primaryDepartment,
    );
    return primary?.departmentName;
  }
}
