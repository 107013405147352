




















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Department, DepartmentCategory } from "@/api";
import { TableRow as ExpenseTableRow } from "@/common/ExpenseTableRow";
import { dateFormat } from "@/common/date_format";
import ExpenseForm from "../ExpenseForm.vue";
import FilterMobile from "../FilterMobile.vue";
import AddBudgetConfirmation from "../AddBudgetConfirmation.vue";
import { formatCurrency } from "@/tool/formatCurrency";
import moment from "moment";

@Component({
  components: {
    ExpenseForm,
    FilterMobile,
    AddBudgetConfirmation,
  },
})
export default class ReportMobile extends Vue {
  @Prop() census?: number;
  @Prop() budget?: number;
  @Prop() ppd?: number;
  @Prop() actualPPD?: number;
  @Prop() totalRemaining?: number;
  @Prop() departmentId?: number;
  @Prop() categoryId?: number;
  @Prop() expenses?: ExpenseTableRow[];
  @Prop() categories?: DepartmentCategory[];

  dateFormat = dateFormat;
  key = 0;
  popDialog = false;
  filterPop = false;
  editData: ExpenseTableRow | null = null;
  popBudgetDialog = false;
  formatCurrency = formatCurrency;
  currentMonth = moment().format("MMMM");

  created() {
    this.fetchExpenses();
  }

  editExpense(item: ExpenseTableRow) {
    this.key++;
    this.editData = item;
    this.popDialog = true;
  }

  addExpense() {
    if (!this.budget) {
      this.$store.dispatch("categoryBudget/setAskBudget", true);
      return;
    }
    this.key++;
    this.editData = null;
    this.popDialog = true;
  }

  fetchExpenses() {
    this.popDialog = false;
    this.$emit("fetch");
  }

  filterExpense({
    departmentId,
    categoryId,
    date,
  }: {
    departmentId: number;
    categoryId: number;
    date: string;
  }) {
    this.filterPop = false;
    this.currentMonth = moment(date).format("MMMM");
    this.$emit("filter", {
      departmentId,
      categoryId,
      date,
    });
  }

  get askBudget() {
    const ask = this.$store.getters["categoryBudget/askBudget"];
    this.popBudgetDialog = ask;
    return this.popBudgetDialog;
  }

  closeDialog() {
    this.$store.dispatch("categoryBudget/setAskBudget", false);
  }

  get department() {
    let name = "";
    this.$store.getters["departmentAdmin/departments"]?.map(
      (department: Department) => {
        if (department.id === this.departmentId) {
          name = department.name;
        }
        return department;
      },
    );
    return name;
  }

  get category() {
    let name = "";
    this.categories?.forEach(cat => {
      if (cat.id === this.categoryId) {
        name = cat.category;
      }
    });
    return name;
  }
}
