




















































import { Component, Vue } from "vue-property-decorator";
import loading from "@/components/loader/loading.vue";
import _ from "lodash";
import { EmployeeDocumentResponse } from "@/api";
import { dateFormat } from "@/common/date_format";

@Component({
  components: {
    loading,
  },
  watch: {
    async $route() {
      if (this.$route.query.menu) {
        this.$data.searchDoc = "";
        this.$data.isDataLoading = true;
        await this.$store.dispatch(
          "employeeDocuments/getApprovalDocumentList",
          {
            approval: Number(this.$route.params.status),
          },
        );
        this.$data.isDataLoading = false;
      }
    },
  },
})
export default class DocumentList extends Vue {
  activeMenu = "";
  searchDoc = "";
  isDataLoading = false;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("employeeDocuments/getApprovalDocumentList", {
      approval: Number(this.$route.params.status),
    });
    this.isDataLoading = false;
  }

  get documentList() {
    const search = !this.searchDoc ? "" : this.searchDoc;
    const documentList = this.$store.getters[
      "employeeDocuments/approvalDocumentList"
    ];
    const list = _.orderBy(
      _.filter(documentList ?? [], (document: EmployeeDocumentResponse) =>
        _.includes(
          `${document.document_name?.toLowerCase()} 
          ${document.firstName.toLowerCase()} 
          ${document.lastName.toLowerCase()} 
          ${document.created_at.toLowerCase()}`,
          search?.toLowerCase(),
        ),
      ),
      [(document: EmployeeDocumentResponse) => document.created_at],
      ["desc"],
    );
    return list;
  }

  goTo(path: string, employeeId: string) {
    this.$router.push({
      path: `/document-approval/${this.$route.params.status}/${path}`,
      query: { employeeId },
    });
  }

  formatDate(date: string) {
    const formattedDate = dateFormat(date);
    return formattedDate;
  }
  clearSearch() {
    this.searchDoc = "";
  }

  isActive(id: number) {
    return id.toString() === this.$route.params?.id;
  }
}
