






import { Component, Vue } from "vue-property-decorator";

@Component
export default class ScoreboardLayout extends Vue {
  created() {
    this.$store.dispatch("scoreboard/fetchCompanyScoreboards");
  }
}
