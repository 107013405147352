
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class CompanyDirectoryRoot extends Vue {
  get hasDetail() {
    return this.$route.params.id || this.$route.path.endsWith("add");
  }
}
