/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Error = {
    properties: {
        statusText: {
    type: 'string',
    isRequired: true,
},
        message: {
    type: 'string',
    isRequired: true,
},
    },
};