

































































import { Vue, Component } from "vue-property-decorator";
import { AnniversaryListing } from "@/api";
import loading from "@/components/loader/loading.vue";
import { list as groupAnniversaryList } from "@/common/date_group_order";

@Component({
  components: {
    loading,
  },
})
export default class AnniversaryList extends Vue {
  isDataLoading = false;

  selectEmployee(id: number) {
    this.$router.push(`/company-directory/profile/${id}`);
  }

  get anniversaries() {
    const list = this.$store.getters[
      "dashboard/anniversaryList"
    ] as AnniversaryListing;
    const anniversaries = groupAnniversaryList(list, true);
    return anniversaries;
  }
}
