/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DepartmentPhoneNumber = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        value: {
    type: 'string',
    isRequired: true,
},
        type: {
    type: 'number',
    isRequired: true,
},
    },
};