






























































































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { ActiveSurveyForm, SurveyOverviewDetails } from "@/api";
import { dateFormat } from "@/common/date_format";
import Loading from "@/components/loader/loading.vue";
import OverviewCard from "@/components/card/OverviewCard.vue";
import StartSurvey from "@/views/survey/overview/StartSurvey.vue";
import OptionsSurvey from "@/views/survey/form/OptionsSurvey.vue";

@Component({
  components: {
    Loading,
    OverviewCard,
    StartSurvey,
    OptionsSurvey,
  },
})
export default class Overview extends Vue {
  loading = false;
  surveyOptionDialog = false;

  startDate = moment()
    .startOf("week")
    .format("YYYY-MM-DD");
  endDate = moment()
    .endOf("week")
    .format("YYYY-MM-DD");
  formErrors = "";
  showStartDate = false;
  showEndDate = false;
  filtering = false;
  isAdmission = true;
  dischargeForm!: ActiveSurveyForm;
  admissionForm!: ActiveSurveyForm;

  async created() {
    this.loading = true;
    const dispatchAdmission = this.$route.query.dispatchAdmission;
    if (dispatchAdmission) {
      this.isAdmission = dispatchAdmission == "true" ? true : false;
    }
    await this.setfilterDate();
    const payload = this.getPayload();
    const activeForm = this.$store.dispatch(
      "survey/fetchActiveSurveyForm",
      true,
    );
    const overview = this.$store.dispatch("survey/fetchOverview", payload);
    await Promise.all([activeForm, overview]);
    this.loading = false;
  }

  get formattedStartDate() {
    if (this.startDate) {
      this.formatDate(true);
      return dateFormat(this.startDate);
    }
    return "";
  }
  get formattedEndDate() {
    if (this.endDate) {
      this.formatDate(false);
      return dateFormat(this.endDate);
    }
    return "";
  }

  get overviewDetails() {
    const toFetch = this.isAdmission
      ? "survey/admissionOverview"
      : "survey/dischargeOverview";
    const report = this.$store.getters[toFetch] as SurveyOverviewDetails;
    return report;
  }

  get activeForm() {
    this.dischargeForm = this.$store.getters[
      "survey/activeDischargeForm"
    ] as ActiveSurveyForm;
    this.admissionForm = this.$store.getters[
      "survey/activeAdmissionForm"
    ] as ActiveSurveyForm;
    return this.dischargeForm && this.admissionForm ? true : false;
  }

  async setfilterDate() {
    const beginAt = await this.$store.getters["survey/beginAt"];
    const endAt = await this.$store.getters["survey/endAt"];
    if (endAt && beginAt) {
      this.startDate = beginAt;
      this.endDate = endAt;
    } else {
      this.startDate = moment()
        .startOf("week")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .endOf("week")
        .format("YYYY-MM-DD");
    }
    if (this.startDate) this.formatDate(true);
    if (this.endDate) this.formatDate(false);
  }

  formatDate(isStartDate: boolean) {
    if (isStartDate) {
      this.$store.dispatch("survey/setBeginAt", this.startDate);
    } else {
      this.$store.dispatch("survey/setEndAt", this.endDate);
    }
  }

  async selectedDate() {
    if (this.startDate && this.endDate) {
      const payload = this.getPayload();

      if (this.startDate < this.endDate) {
        this.formErrors = "";
        this.filtering = true;
        await this.$store.dispatch("survey/fetchOverview", payload);
        this.filtering = false;
      } else {
        this.formErrors = "start date should not be greater than end date";
      }
    }
  }

  getPayload() {
    return {
      beginAt: this.startDate,
      endAt: this.endDate,
      dispatchAdmission: this.isAdmission,
    };
  }

  async clearFilter() {
    this.startDate = moment()
      .startOf("week")
      .format("YYYY-MM-DD");
    this.endDate = moment()
      .endOf("week")
      .format("YYYY-MM-DD");
    this.formErrors = "";
    this.filtering = true;
    this.$store.dispatch("survey/setBeginAt");
    this.$store.dispatch("survey/setEndAt");
    const payload = this.getPayload();
    await this.$store.dispatch("survey/fetchOverview", payload);
    this.filtering = false;
  }

  goTo(title: string, overviewId?: string) {
    this.$router.push({
      path: `/survey/overview/details`,
      query: {
        dispatchAdmission: this.isAdmission ? "true" : undefined,
        beginAt: this.startDate,
        endAt: this.endDate,
        title,
        overviewId,
      },
    });
  }

  destroyed() {
    if (!this.$route.path.includes("overview")) {
      this.$store.dispatch("survey/setBeginAt");
      this.$store.dispatch("survey/setEndAt");
    }
  }

  getPercentage(actual: number, total: number) {
    const percent = (actual / (total * 5)) * 100;
    if (isNaN(percent)) {
      return 0;
    }
    return percent.toFixed(2) || 0;
  }

  complete(isAdmission: boolean) {
    this.isAdmission = isAdmission;
    this.clearFilter();
  }

  createSurveyForm(to: string) {
    this.$router.push({
      path: `/survey/${to}/form`,
    });
    this.surveyOptionDialog = !this.surveyOptionDialog;
  }
}
