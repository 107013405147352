import { RouteConfig } from "vue-router";
import DocumentMenu from "@/views/employee_document/DocumentMenu.vue";
import DocumentList from "@/views/employee_document/DocumentList.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    components: {
      default: DocumentMenu,
      documentMenu: DocumentMenu,
      docuemtnList: DocumentList,
    },
    meta: {
      title: "Company Document",
      requiresAuth: true,
    },
  },
  {
    path: ":documentId",
    components: {
      default: DocumentList,
      documentMenu: DocumentMenu,
      docuemtnList: DocumentList,
    },
    meta: {
      title: "Company Document",
      requiresAuth: true,
    },
  },
];

export default routes;
