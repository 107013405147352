import { render, staticRenderFns } from "./DocumentMenu.vue?vue&type=template&id=3db67e1e&scoped=true"
import script from "./DocumentMenu.vue?vue&type=script&lang=ts"
export * from "./DocumentMenu.vue?vue&type=script&lang=ts"
import style0 from "./DocumentMenu.vue?vue&type=style&index=0&id=3db67e1e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db67e1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VBtn,VDialog,VIcon,VList,VListItem,VListItemContent,VListItemTitle})
