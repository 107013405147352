/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EmployeeRanking = {
    properties: {
        score: {
    type: 'number',
    isRequired: true,
},
        rank: {
    type: 'number',
    isRequired: true,
},
        total: {
    type: 'number',
    isRequired: true,
},
    },
};