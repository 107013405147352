import _ from "lodash";

const taskStatus = [
  {
    id: 1,
    text: "Not Started",
    color: "text-red",
  },
  {
    id: 2,
    text: "In Progress",
    color: "text-orange",
  },
  {
    id: 3,
    text: "On Hold",
    color: "text-blue",
  },
  {
    id: 4,
    text: "Completed",
    color: "text-green",
  },
];

const getTaskStatusById = (id: number): string => {
  const status = _.find(taskStatus, stat => stat.id === id);
  if (!status) return "";
  return status.text;
};

const getTaskStatusIdByText = (text: string): number => {
  const status = _.find(
    taskStatus,
    stat => stat.text.toLowerCase() === text.toLowerCase(),
  );
  if (!status) return 0;
  return status.id;
};

export { taskStatus, getTaskStatusById, getTaskStatusIdByText };
