import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import {
  AuthService,
  CreateEmployeeRequestBody,
  Department,
  Employee,
  EmployeeFiveStarScore,
  EmployeesService,
  EventLogService,
  LoggedEventList,
  MeEventScoresResponse,
  OpenAPI,
  TransferEmployeeToDepartmentRequest,
  UpdateCompanyEmployeeRolesRequest,
  UpdateEmployeeProfileRequest,
} from "@/api";
import resolve from "@/common/resolver";
import _ from "lodash";
import { dateCheckifExist } from "@/common/date_format";

export type CompanyDirectoryState = {
  selectedDepartment: Department | undefined;
  employees: Employee[] | undefined;
  archivedEmployees: Employee[] | undefined;
  selectedEmployee: Employee | undefined;
  titleEmployees: Employee[] | undefined;
  selectedEmployeeScore: EmployeeFiveStarScore | undefined;
  selectedEmployeeEvent: LoggedEventList | undefined;
  selectedEmployeeEventScore: MeEventScoresResponse | undefined;
};

const state: CompanyDirectoryState = {
  selectedDepartment: undefined,
  employees: undefined,
  archivedEmployees: undefined,
  selectedEmployee: undefined,
  titleEmployees: undefined,
  selectedEmployeeScore: undefined,
  selectedEmployeeEvent: undefined,
  selectedEmployeeEventScore: undefined,
};

type EmployeeRequest = {
  employeeId?: number;
  beginDate?: string;
  endDate?: string;
  offset?: number;
  limit?: number;
};

const getters: GetterTree<CompanyDirectoryState, RootState> = {
  employees: state => {
    return state.employees;
  },
  archivedEmployees: state => {
    return state.archivedEmployees;
  },
  selectedEmployee(state) {
    return state.selectedEmployee;
  },
  titleEmployees(state) {
    return state.titleEmployees;
  },
  selectedEmployeeScore: state => state.selectedEmployeeScore,
  selectedEmployeeEvent: state => state.selectedEmployeeEvent,
  selectedEmployeeEventScore: state => state.selectedEmployeeEventScore,
};

const mutations: MutationTree<CompanyDirectoryState> = {
  SET_EMPLOYEES: (state, employees) => {
    state.employees = employees;
  },
  SET_ARCHIVED_EMPLOYEES: (state, employees) => {
    if (employees) {
      state.archivedEmployees = _.orderBy(employees, employee =>
        employee.firstName.trim().toUpperCase(),
      );
    } else {
      state.archivedEmployees = [];
    }
  },
  SET_SELECTED_DEPARTMENT: (state, department) => {
    state.selectedDepartment = department;
  },
  SET_SELECTED_EMPLOYEE: (state, employee) => {
    state.selectedEmployee = employee;
  },
  ADD_EMPLOYEE: (state, employee) => {
    // TODO: sorting
    state.employees = [...(state?.employees ?? []), employee];
  },
  UPDATE_EMPLOYEE_PROFILE: (state, employee) => {
    state.selectedEmployee = employee;
  },
  UPDATE_EMPLOYEE_ROLE: (state, employee) => {
    state.selectedEmployee = employee;
  },
  SET_TITLE_EMPLOYEES: (state, employees) => {
    state.titleEmployees = employees;
  },
  SET_SELECTED_EMPLOYEE_SCORE: (state, score) => {
    state.selectedEmployeeScore = score;
  },
  SET_SELECTED_EMPLOYEE_EVENT: (state, event) => {
    state.selectedEmployeeEvent = event;
  },
  SET_SELECTED_EMPLOYEE_EVENT_SCORE: (state, scores) => {
    state.selectedEmployeeEventScore = scores;
  },
};

const actions: ActionTree<CompanyDirectoryState, RootState> = {
  loadAllEmployees: ({ commit, rootState, getters }, force = false) => {
    const companyId = rootState.appContext.company?.id;
    const fetch = async () => {
      if (companyId) {
        const employeeList = await EmployeesService.listEmployees({
          companyId,
        });
        commit("SET_EMPLOYEES", employeeList.employees);
      }
    };
    if (!force) {
      const list = getters["employees"];
      if (!list) {
        fetch();
      }
    } else {
      fetch();
    }
  },
  loadAllArchivedEmployees: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    try {
      if (companyId) {
        const employeeList = await EmployeesService.listArchivedEmployees({
          companyId,
        });
        commit("SET_ARCHIVED_EMPLOYEES", employeeList.employees);
      }
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
  selectDepartment: ({ commit }, department: Department) => {
    commit("SET_SELECTED_DEPARTMENT", department);
  },
  selectEmployee: async (
    { commit, rootState, dispatch },
    { employeeId, offset, limit, beginDate, endDate }: EmployeeRequest,
  ) => {
    const companyId = rootState.appContext.company?.id;

    if (companyId && employeeId) {
      try {
        const employee = await EmployeesService.getEmployee({
          companyId,
          employeeId,
        }).catch(error => {
          if (error instanceof Error) {
            throw new Error(error.message);
          }
        });
        commit("SET_SELECTED_EMPLOYEE", employee);

        dispatch("getEmployeeScore", {
          employeeId,
          beginDate,
          endDate,
        });

        dispatch("getEmployeeEvent", {
          employeeId,
          offset: offset || 1,
          limit: limit || 5,
          beginDate,
          endDate,
          from: "action dispatch",
        });

        dispatch("getEmployeeEventScores", {
          employeeId,
          beginDate,
          endDate,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error, { root: true });
        }
      }
    }
  },
  getEmployeeEvent: async (
    { commit, rootState },
    { employeeId, offset, limit, beginDate, endDate }: EmployeeRequest,
  ) => {
    const companyId = rootState.appContext.company?.id;
    let beginAt, endAt;
    if (beginDate && endDate) {
      beginAt = new Date(beginDate).toISOString().substring(0, 10);
      endAt = new Date(endDate).toISOString().substring(0, 10);
    }
    if (companyId && employeeId) {
      try {
        const loggedEventList = await EventLogService.listLoggedEvents({
          companyId,
          employeeId,
          eventMetricId: -1,
          offset,
          limit,
          beginAt,
          endAt,
        }).catch(error => {
          if (error instanceof Error) {
            throw new Error(error.message);
          }
        });
        commit("SET_SELECTED_EMPLOYEE_EVENT", loggedEventList);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error, { root: true });
        }
      }
    }
  },
  getEmployeeScore: async (
    { commit, rootState },
    {
      employeeId,
      beginDate,
      endDate,
    }: { employeeId?: number; beginDate?: string; endDate?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    let beginAt, endAt;
    if (beginDate && endDate) {
      beginAt = new Date(beginDate).toISOString().substring(0, 10);
      endAt = new Date(endDate).toISOString().substring(0, 10);
    }
    if (companyId && employeeId) {
      try {
        const score = await EmployeesService.getFiveStarScore({
          companyId,
          employeeId,
          beginAt,
          endAt,
        }).catch(error => {
          if (error instanceof Error) {
            throw new Error(error.message);
          }
        });
        commit("SET_SELECTED_EMPLOYEE_SCORE", score);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error, { root: true });
        }
      }
    }
  },
  createEmployee: async (
    { commit, rootState, dispatch },
    createEmployeeRequest: CreateEmployeeRequestBody,
  ) => {
    const companyID = rootState.appContext?.company?.id;
    if (companyID) {
      try {
        const newEmployee = await EmployeesService.createEmployee({
          requestBody: createEmployeeRequest,
          companyId: companyID,
        });
        commit("ADD_EMPLOYEE", newEmployee);
        dispatch("getTitledEmployee", newEmployee.titleId);
        commit("alert/SET_SHOW_SUCCESS", "Successfully created", {
          root: true,
        });
        return newEmployee;
      } catch (e) {
        if (e instanceof Error) {
          if (e.message.includes("company_external_employee_id")) {
            commit(
              "alert/SET_SHOW_ERROR",
              "Error: Employee Id already in use",
              {
                root: true,
              },
            );
          } else if (e.message.includes("company_email")) {
            commit("alert/SET_SHOW_ERROR", "Error: Email already in use", {
              root: true,
            });
          } else {
            commit("alert/SET_SHOW_ERROR", e.message, {
              root: true,
            });
          }
        }
      }
    }
  },
  editEmployeeProfile: async (
    { commit, rootState },
    {
      requestBody,
      employeeId,
    }: { requestBody: UpdateEmployeeProfileRequest; employeeId: number },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        const employeeProfile = await EmployeesService.updateEmployeeProfile({
          companyId,
          employeeId,
          requestBody,
        });
        commit("UPDATE_EMPLOYEE_PROFILE", employeeProfile);
        commit("alert/SET_SHOW_SUCCESS", "Successfully edited", {
          root: true,
        });
      } catch (e) {
        if (e instanceof Error) {
          commit("alert/SET_SHOW_ERROR", e.message, { root: true });
        }
      }
    }
  },
  updateEmployeeRole: async (
    { commit, rootState },
    {
      requestBody,
      employeeId,
    }: { requestBody: UpdateCompanyEmployeeRolesRequest; employeeId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const updateRole = await EmployeesService.updateCompanyEmployeeRoles({
          companyId,
          employeeId,
          requestBody,
        });
        commit("UPDATE_EMPLOYEE_ROLE", updateRole);
        commit("alert/SET_SHOW_SUCCESS", "Successfully updated", {
          root: true,
        });
        return true;
      } catch (e) {
        if (e instanceof Error) {
          commit("alert/SET_SHOW_ERROR", e.message, { root: true });
        }
      }
    }
  },
  updateEmployeeDepartment: async (
    { commit, rootState },
    {
      employeeId,
      requestBody,
    }: { employeeId: number; requestBody: TransferEmployeeToDepartmentRequest },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        const changedDepartment = await EmployeesService.transferEmployeeToDepartment(
          {
            companyId,
            employeeId,
            requestBody,
          },
        );
        const employeeList = await EmployeesService.listEmployees({
          companyId,
        });
        commit("SET_EMPLOYEES", employeeList.employees);
        commit("SET_SELECTED_EMPLOYEE", changedDepartment);
        commit("alert/SET_SHOW_SUCCESS", "Successfully updated", {
          root: true,
        });
        return true;
      } catch (e) {
        if (e instanceof Error) {
          commit("alert/SET_SHOW_ERROR", e.message, { root: true });
        }
      }
    }
  },
  updateEmployeeLoginEmail: async (
    { commit, rootState },
    { employeeId, email }: { employeeId: number; email: string },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        const changedEmail = await AuthService.updateEmployeeLoginEmail({
          companyId,
          employeeId,
          email,
        });
        commit("SET_SELECTED_EMPLOYEE", changedEmail);
        commit("alert/SET_SHOW_SUCCESS", "Successfully updated", {
          root: true,
        });
        return true;
      } catch (e) {
        if (e instanceof Error) {
          commit("alert/SET_SHOW_ERROR", e.message, { root: true });
        }
      }
    }
  },
  updateEmployeeID: async (
    { commit, rootState },
    {
      employeeId,
      externalEmployeeId,
    }: { employeeId: number; externalEmployeeId: string },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        const changedId = await AuthService.updateEmployeeLoginEmployeeId({
          companyId,
          employeeId,
          externalEmployeeId,
        });
        commit("SET_SELECTED_EMPLOYEE", changedId);
        commit("alert/SET_SHOW_SUCCESS", "Successfully updated", {
          root: true,
        });
        return true;
      } catch (e) {
        if (e instanceof Error) {
          commit("alert/SET_SHOW_ERROR", e.message, { root: true });
        }
      }
    }
  },
  deleteEmployeeID: async (
    { commit, rootState, dispatch },
    employeeId: number,
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await EmployeesService.archiveEmployee({
          companyId,
          employeeId,
        });
        dispatch("loadAllEmployees");
        commit("alert/SET_SHOW_SUCCESS", "Successfully archived", {
          root: true,
        });
        return true;
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  passwordResetLink: async ({ commit, rootState }, employeeId: number) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await AuthService.sendPasswordResetLink({
          employeeId,
          companyId,
        });
        return true;
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  uploadProfileImage: async (
    { commit, rootState, dispatch },
    body: { employeeId: number; requestBody: FormData },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const res = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/employees/${body.employeeId}/profile-image`,
          {
            method: "POST",
            body: body.requestBody,
            headers: {
              authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
            },
          },
        );
        dispatch("selectEmployee", { employeeId: body.employeeId });
        return { res: res, accepted: true };
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  restoreArchiveEmployee: async (
    { commit, rootState, dispatch },
    employeeId: number,
  ) => {
    const companyId = rootState.appContext.company?.id;
    try {
      if (companyId) {
        await EmployeesService.archiveEmployee1({ companyId, employeeId });
        dispatch("loadAllEmployees");
        dispatch("loadAllArchivedEmployees");
        commit("alert/SET_SHOW_SUCCESS", "Successfully restored", {
          root: true,
        });
      }
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
  getTitledEmployee: async (
    { commit, rootState },
    departmentTitleId: number,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const employeesList = await EmployeesService.listEmployees({
          companyId,
          departmentTitleId,
        });
        commit("SET_TITLE_EMPLOYEES", employeesList);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  destroyArchivedEmployees: ({ commit }) => {
    commit("SET_ARCHIVED_EMPLOYEES", null);
  },
  activationCodeResend: async ({ commit, rootState }, employeeId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await EmployeesService.resendActivationCode({
          companyId,
          employeeId,
        });
        return true;
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  updatePhoneVisibility: async (
    { commit, rootState },
    { employeeId, visible }: { employeeId: number; visible: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const employee = await EmployeesService.updateEmployeePhoneVisibility({
          companyId,
          employeeId,
          visible,
        });
        commit("SET_SELECTED_EMPLOYEE", employee);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  getEmployeeEventScores: async (
    { commit, rootState },
    {
      employeeId,
      beginDate,
      endDate,
    }: {
      employeeId: number;
      beginDate?: string | undefined;
      endDate?: string | undefined;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    const { beginAt, endAt } = dateCheckifExist(beginDate, endDate);
    if (companyId) {
      try {
        const scores = await EmployeesService.getEmployeeScores({
          companyId,
          employeeId,
          beginAt,
          endAt,
        });
        commit("SET_SELECTED_EMPLOYEE_EVENT_SCORE", scores);
      } catch (error) {
        console.log(error);
      }
    }
  },
};

export const directory: Module<CompanyDirectoryState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
