import { render, staticRenderFns } from "./EventDepartmentList.vue?vue&type=template&id=74fd9b0e&scoped=true"
import script from "./EventDepartmentList.vue?vue&type=script&lang=ts"
export * from "./EventDepartmentList.vue?vue&type=script&lang=ts"
import style0 from "./EventDepartmentList.vue?vue&type=style&index=0&id=74fd9b0e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74fd9b0e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VList,VListItem,VListItemContent,VSubheader,VTextField})
