var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-fill fill-height flex-column"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{attrs:{"text":""},on:{"~click":function($event){return _vm.back($event)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" Go back ")],1):_vm._e(),_c('div',[_c('p',{staticClass:"display-1 text-center"},[_vm._v("Employee Document")]),_c('div',[_c('v-data-table',{staticClass:"pa-4",attrs:{"headers":_vm.headers,"loading":_vm.isDataLoading,"items":_vm.activeOwnerDocuments,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"item.document_name",fn:function(ref){
var item = ref.item;
return [_c('PreviewDocument',{key:item.id,attrs:{"documentName":_vm.documentName,"document":item}})]}},{key:"item.document_path",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{key:item.document_path,attrs:{"color":"primary darken-2"},on:{"click":function($event){return _vm.downloadItem(item.id, item.document_name, item.employee_id)}}},[_vm._v(" mdi-tray-arrow-down ")])]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.primaryDepartmentName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.primaryDepartmentName)+" - "+_vm._s(item.title)+" ")]}},{key:"item.expiration_date",fn:function(ref){
var item = ref.item;
return [_c('span',{key:item.expiration_date},[_vm._v(" "+_vm._s(_vm.formatDate(item.expiration_date))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.expiredStatus(item.status) === 0)?_c('v-icon',{key:item.status,attrs:{"color":"red"}},[_vm._v(" mdi-alert-circle ")]):_vm._e(),(_vm.expiredStatus(item.status) === 1)?_c('v-icon',{key:item.status,attrs:{"color":"yellow"}},[_vm._v(" mdi-alert-outline ")]):_vm._e(),(_vm.expiredStatus(item.status) === 2)?_c('v-icon',{key:item.status,attrs:{"color":"green"}},[_vm._v(" mdi-file-document-multiple ")]):_vm._e()]}},{key:"item.approval",fn:function(ref){
var item = ref.item;
return [(_vm.approvalStatus(item.approval) === 0)?_c('v-icon',{key:item.approval,attrs:{"color":"green"}},[_vm._v(" mdi-progress-clock ")]):_vm._e(),(_vm.approvalStatus(item.approval) === 1)?_c('v-icon',{key:item.approval,attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):_vm._e(),(_vm.approvalStatus(item.approval) === 2)?_c('v-icon',{key:item.approval,attrs:{"color":"red"}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"mt-8"},[_c('p',{staticClass:"display-1 text-center"},[_vm._v("Archived Document")]),_c('div',[_c('v-data-table',{staticClass:"pa-4 mb-16",attrs:{"headers":_vm.headers,"loading":_vm.isDataLoading,"items":_vm.archivedOwnerDocuments,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"item.document_name",fn:function(ref){
var item = ref.item;
return [_c('PreviewDocument',{key:item.id,attrs:{"documentName":_vm.documentName,"document":item}})]}},{key:"item.expiration_date",fn:function(ref){
var item = ref.item;
return [_c('span',{key:item.expiration_date},[_vm._v(" "+_vm._s(_vm.formatDate(item.expiration_date))+" ")])]}},{key:"item.document_path",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{key:item.document_path,attrs:{"color":"primary darken-2"},on:{"click":function($event){return _vm.downloadItem(item.id, item.document_name, item.employee_id)}}},[_vm._v(" mdi-tray-arrow-down ")])]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.primaryDepartmentName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.primaryDepartmentName)+" - "+_vm._s(item.title)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.expiredStatus(item.status) === 0)?_c('v-icon',{key:item.status,attrs:{"color":"red"}},[_vm._v(" mdi-alert-circle ")]):_vm._e(),(_vm.expiredStatus(item.status) === 1)?_c('v-icon',{key:item.status,attrs:{"color":"yellow"}},[_vm._v(" mdi-alert-outline ")]):_vm._e(),(_vm.expiredStatus(item.status) === 2)?_c('v-icon',{key:item.status,attrs:{"color":"green"}},[_vm._v(" mdi-file-document-multiple ")]):_vm._e()]}},{key:"item.approval",fn:function(ref){
var item = ref.item;
return [(_vm.approvalStatus(item.approval) === 0)?_c('v-icon',{key:item.approval,attrs:{"color":"green"}},[_vm._v(" mdi-progress-clock ")]):_vm._e(),(_vm.approvalStatus(item.approval) === 1)?_c('v-icon',{key:item.approval,attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):_vm._e(),(_vm.approvalStatus(item.approval) === 2)?_c('v-icon',{key:item.approval,attrs:{"color":"red"}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e()]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }