















































import { Component, Vue } from "vue-property-decorator";
import CamAccess from "@/components/camAccess/CamAccess.vue";
import { maxFileSize } from "@/tool/validation";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  components: {
    CamAccess,
  },
})
export default class ImageInput extends Vue {
  camDialog = false;
  camKey = 0;
  imageRules = (v: File) => maxFileSize(v, 2);
  clr = 0;

  selectFile(event: HTMLInputEvent) {
    this.clr++;
    if (event) {
      // eslint-disable-next-line
      const file = event.target.files![0];
      if (file?.type?.includes("image")) {
        const isValid = this.imageRules(file);
        if (isValid === true) this.updateFile(file);
        else this.$store.dispatch("alert/warning", isValid);
      }
    }
  }

  updateFile(file: File | null) {
    this.$emit("updateFile", file);
  }

  toggleCamShow() {
    this.camKey++;
    this.camDialog = !this.camDialog;
  }
}
