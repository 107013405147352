/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountCompanyDocumentResponse } from '../models/CountCompanyDocumentResponse';
import type { CountEmployeeDocumentApprovalRequest } from '../models/CountEmployeeDocumentApprovalRequest';
import { request as __request } from '../core/request';

export class CountDocumentService {

    /**
     * count the employee document by approval only by owner
     * @returns CountEmployeeDocumentApprovalRequest count of employee document
     * @throws ApiError
     */
    public static async countEmployeeDocumentByApproval({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<CountEmployeeDocumentApprovalRequest> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employee-document/count`,
        });
        return result.body;
    }

    /**
     * count the employee document on users role
     * @returns CountCompanyDocumentResponse count of employee document based on their role
     * @throws ApiError
     */
    public static async getCountCompanyDocument({
companyId,
departmentId,
role,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** role of user **/
role?: string,
}): Promise<CountCompanyDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/department/${departmentId}/company-documents/count`,
            query: {
                'role': role,
            },
        });
        return result.body;
    }

    /**
     * count the own document
     * @returns CountCompanyDocumentResponse count of own document
     * @throws ApiError
     */
    public static async getCountOwnDocument({
companyId,
departmentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
}): Promise<CountCompanyDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/department/${departmentId}/company-documents/count-own`,
        });
        return result.body;
    }

}