






















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import moment from "moment";
import {
  GroupAdmin,
  GroupParticipants,
  MessageGroup,
  MessageGroupDetails,
  MessageGroupRequest,
} from "@/api";
import { getEmployeeLetter } from "@/tool/profile";
import AddGroupForm from "@/views/chat/AddGroupForm.vue";
import UpdateGroupName from "@/views/chat/UpdateGroupName.vue";
import AddGroupParticipants from "@/views/chat/AddGroupParticipants.vue";
import ConfirmationModule from "@/components/confirmationModule/ConfirmationModule.vue";
import { selfData } from "@/common/role_utils";

@Component({
  components: {
    AddGroupForm,
    UpdateGroupName,
    AddGroupParticipants,
    ConfirmationModule,
  },
})
export default class GroupDetails extends Vue {
  @Prop() readonly name!: string;
  @Watch("selectedGroup", { deep: true, immediate: true })
  onSelectedGroupChange(newVal: MessageGroup) {
    if (newVal) {
      this.fetchGroupDetails(newVal.id);
    }
  }

  valid = false;
  showAll = false;
  showForm = false;
  showParticipantForm = false;
  isGroupAdmin = false;
  confirmRemove = false;
  confirmRemoveAdmin = false;
  removing = false;
  counter = 0;
  adminCount = 0;
  loading = false;
  getEmployeeLetter = getEmployeeLetter;
  participants: GroupParticipants[] = [];
  removingEmployee: number[] = [];
  removingAdmin = 0;
  text = "Are you sure. You want to remove the user from group?";

  created() {
    if (this.selectedGroup) {
      this.fetchGroupDetails(this.selectedGroup.id);
    }
  }

  async fetchGroupDetails(id: number) {
    this.loading = true;
    await this.$store.dispatch("chat/fetchGroupDetails", id);
    this.loading = false;
  }

  get selectedGroup(): MessageGroup {
    return this.$store.getters["chat/selectedGroup"];
  }

  get groupDetails(): MessageGroupDetails {
    const details = this.$store.getters[
      "chat/groupDetails"
    ] as MessageGroupDetails;
    if (details && details.participants) {
      const orederdParticipants = _.orderBy(
        details.participants,
        ["isAdmin", "name"],
        ["desc", "asc"],
      );
      details.participants = orederdParticipants;
      const emps = this.showAll
        ? details.participants
        : _.slice(details.participants || [], 0, 5);

      if (emps) this.participants = emps;
      this.adminCount = 0;
      const selfParticipant = _.filter(details.participants, emp => {
        if (emp.isAdmin) {
          this.adminCount++;
        }
        return emp.id == selfData().id;
      });
      this.isGroupAdmin = selfParticipant[0].isAdmin;
    }
    return details;
  }

  get groupParticipantIds() {
    return _.map(
      this.groupDetails?.participants || [],
      (participant: GroupParticipants) => participant.id,
    );
  }

  formatDateShow(groupDetails: MessageGroupDetails) {
    if (groupDetails?.createdAt) {
      return `${groupDetails.name} was created at ${moment(
        groupDetails.createdAt,
      )
        .local()
        .format("MM/DD/YYYY")}}`;
    } else return "";
  }

  close() {
    this.showAll = false;
    this.$emit("close");
  }

  async removeParticipant() {
    this.removing = true;
    const payload = {
      id: this.groupDetails.id,
      companyId: selfData().companyId,
      name: this.groupDetails.name,
      employees: this.removingEmployee,
    } as MessageGroupRequest;
    await this.$store.dispatch("chat/RemoveGroupParticipants", payload);
    this.removing = false;
    this.confirmRemove = !this.confirmRemove;
    if (this.removingEmployee.includes(selfData().id)) {
      await this.$store.dispatch("chat/fetchGroupList");
      this.$router.push("/chat");
    }
    this.removingEmployee = [];
  }

  leaveGroup() {
    this.removingEmployee = [];
    this.text = "Are you sure. You want to leave the group?";
    this.removingEmployee.push(selfData().id);
    this.confirmRemove = !this.confirmRemove;
  }

  removeUser(id: number) {
    this.removingEmployee = [];
    this.removingEmployee.push(id);
    this.confirmRemove = !this.confirmRemove;
  }

  adminRoleRemove(id: number) {
    if (this.adminCount == 1) {
      this.$store.dispatch("alert/info", "A group need at least one admin.");
      return;
    }

    this.removingAdmin = id;
    this.text = "Are you sure. You want to remove user as group admin?";
    this.confirmRemoveAdmin = !this.confirmRemoveAdmin;
  }

  async removeRole() {
    this.removing = true;
    const payload = {
      groupId: this.groupDetails.id,
      employeeId: this.removingAdmin,
    } as GroupAdmin;
    await this.$store.dispatch("chat/removeMessageGroupAdmin", payload);
    this.removing = false;
    this.confirmRemoveAdmin = !this.confirmRemoveAdmin;
  }

  async adminRoleSet(id: number) {
    const payload = {
      groupId: this.groupDetails.id,
      employeeId: id,
    } as GroupAdmin;
    await this.$store.dispatch("chat/addMessageGroupAdmin", payload);
  }
}
