































import { Component, Vue, Ref } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";

@Component
export default class TarfDenyForm extends Vue {
  @Ref("form") form!: VForm;
  rejectComment = "";
  formRules = [
    (v: string) => !!v || "Required",
    (v: string) => !v || v.trim() !== "" || "Space only is not accepted",
  ];
  close() {
    this.$emit("close");
    this.form.reset();
  }

  submit() {
    const isValid = this.form.validate();
    if (isValid) {
      this.$emit("submit", this.rejectComment);
    }
  }
}
