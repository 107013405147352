import Vue from "vue";
import App from "@/App.vue";
import "./registerServiceWorker";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import auth from "@/auth";
import { OpenAPI } from "@/api";

Vue.config.productionTip = false;
// eslint-disable-next-line
OpenAPI.BASE = process.env.VUE_APP_BASE_API_URL!;

let app: Vue;
auth.addOnAuthStateChangedListener(async () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount("#app");
  }
});
