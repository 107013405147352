var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0 d-flex flex-fill justify-center"},[_c('v-btn',{staticClass:"pa-0 ma-0",class:{
      'flex-grow-1': _vm.isOpen,
      openWidth: _vm.isOpen,
      'flex-shrink-1': !_vm.isOpen,
      'justify-center': !_vm.isOpen,
    },attrs:{"rounded":_vm.isOpen,"fab":!_vm.isOpen,"id":"five-star-btn","x-large":_vm.isOpen,"small":!_vm.isOpen,"nav":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',{staticClass:"pa-0 ma-0",class:{ 'ml-1': _vm.isOpen },attrs:{"large":_vm.isOpen,"color":"deep-orange darken-5","center":""}},[_vm._v(" mdi-star-outline ")]),(_vm.isOpen)?_c('span',{class:{ 'ml-1': _vm.isOpen },attrs:{"id":"five-star-text"}},[_vm._v("New 5-Star Moment")]):_vm._e()],1),_c('v-dialog',{key:"001",attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('FiveStarForm',{key:_vm.counter,on:{"save":function($event){_vm.dialog = !_vm.dialog;
        _vm.counter++;},"close":function($event){_vm.dialog = !_vm.dialog;
        _vm.counter++;}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }