





















































































































































import { Component, Vue } from "vue-property-decorator";
import { LoggedEvent } from "@/api";
import ConfirmModal from "@/views/company_directory/ConfirmModal.vue";
import loading from "@/components/loader/loading.vue";
import moment from "moment";
import { checkSelfRole } from "@/common/role_utils";
import { printPage } from "@/tool/print";

@Component({
  components: {
    ConfirmModal,
    loading,
  },
  watch: {
    async $route() {
      this.$data.isDataLoading = true;
      await this.$store.dispatch(
        "activity/selectEvent",
        this.$route.params.eventId,
      );
      this.$data.isDataLoading = false;
      this.$el.scrollTop = 0;
    },
  },
})
export default class EventDetail extends Vue {
  eventIdConfirmModal = false;
  isDataLoading = false;
  archiving = false;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch(
      "activity/selectEvent",
      this.$route.params.eventId,
    );
  }

  get isSuperAdmin(): boolean {
    return this.$store.getters["isSuperAdmin"];
  }

  isRole(role: string) {
    return checkSelfRole(role);
  }

  get eventId(): string {
    return this.$route.params.eventId;
  }

  get loggedEvent(): LoggedEvent | undefined {
    if (this.eventId) {
      const event = this.$store.getters["activity/selectedEvent"];
      if (event) {
        this.isDataLoading = false;
        return event;
      } else {
        return undefined;
      }
    } else {
      const loading = this.$store.getters["activity/loading"];
      if (!loading) {
        this.isDataLoading = false;
      }
      return undefined;
    }
  }

  get postedDate(): string {
    const loggedEvent = this.loggedEvent;
    if (loggedEvent) {
      return moment(loggedEvent.createdAt).format("ll, LT");
    }
    return "";
  }

  backToList() {
    this.$router.push({ path: "/activity" });
  }

  async deleteFiveStar() {
    this.archiving = true;
    await this.$store.dispatch("EventMetricAdmin/deleteEventId", this.eventId);
    this.archiving = false;
    this.eventIdConfirmModal = false;
    this.$store.dispatch("activity/destroySelectedEvent");
    await this.$store.dispatch("activity/loadActivityFeed");
  }

  beforeDestroy() {
    this.$store.dispatch("activity/destroySelectedEvent");
  }

  goTo(id: number) {
    this.$router.push(`/company-directory/profile/${id}`);
  }

  printEvent() {
    const prtContent = document.getElementById("target")?.innerHTML;
    const styles = `<style>
        @media print {
          .v-btn {
            display: none;
          }
          .text-center {
            text-align: center;
          }
          .text-container {
            display: flex;
            direction: row;
            justify-content: space-around;
          }
          .d-flex {
            display: flex;
            direction: row;
            justify-content: center;
          }
          .v-avatar img {
            vertical-align: middle;
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }
          .fiveStar {
            display: flex;
            justify-content: center;
          }
          .image-container, .image-container-lg {
            text-align: center;
          }
          .image-container img, .image-container-lg img {
            max-width: 70%
          }
          .large-logo {
            display: block;
          }
          .large-logo img {
            position: absolute;
            bottom: 5px;
            left: 44%;
          }
        }
      </style>`;

    if (prtContent) printPage(styles, prtContent);
  }
}
