import { RouteConfig } from "vue-router";
import FormList from "@/views/guardian_angel/form_generator/FormList.vue";
import Question from "@/views/guardian_angel/form_generator/Question.vue";
import Overview from "@/views/guardian_angel/overview/Overview.vue";
import OverviewDetails from "@/views/guardian_angel/overview/OverviewDetails.vue";
import Summary from "@/views/guardian_angel/summary/Summary.vue";
import Assignments from "@/views/guardian_angel/assignments/Assignments.vue";
import Tracking from "@/views/guardian_angel/tracking/Tracking.vue";

const routes: Array<RouteConfig> = [
  {
    path: "overview",
    components: {
      default: Overview,
      list: FormList,
      question: Question,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      assignments: Assignments,
      tracking: Tracking,
    },
    meta: {
      title: "Overview",
      requiresAuth: true,
    },
  },
  {
    path: "overview/details",
    components: {
      default: OverviewDetails,
      list: FormList,
      question: Question,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      assignments: Assignments,
      tracking: Tracking,
    },
    meta: {
      title: "Overview Details",
      requiresAuth: true,
    },
  },
  {
    path: "form",
    components: {
      default: FormList,
      list: FormList,
      question: Question,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      assignments: Assignments,
      tracking: Tracking,
    },
    meta: {
      title: "Form List",
      requiresAuth: true,
    },
  },
  {
    path: "form/:formId/question",
    components: {
      default: Question,
      list: FormList,
      question: Question,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      assignments: Assignments,
      tracking: Tracking,
    },
    meta: {
      title: "Questions",
      requiresAuth: true,
    },
  },
  {
    path: "summary",
    components: {
      default: Summary,
      list: FormList,
      question: Question,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      assignments: Assignments,
      tracking: Tracking,
    },
    meta: {
      title: "Summary",
      requiresAuth: true,
    },
  },
  {
    path: "assignments",
    components: {
      default: Assignments,
      list: FormList,
      question: Question,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      assignments: Assignments,
      tracking: Tracking,
    },
    meta: {
      title: "Assignments",
      requiresAuth: true,
    },
  },
  {
    path: "tracking",
    components: {
      default: Tracking,
      list: FormList,
      question: Question,
      overview: Overview,
      details: OverviewDetails,
      summary: Summary,
      assignments: Assignments,
      tracking: Tracking,
    },
    meta: {
      title: "Tracking",
      requiresAuth: true,
    },
  },
];

export default routes;
