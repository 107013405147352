
































































































import { Component, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";
import statusId from "@/tool/tarfStatus";
import loading from "@/components/loader/loading.vue";
import {
  Company,
  GetTarfRequestDetailResponse,
  TarfListItem,
  TarfService,
} from "@/api";
import moment from "moment";
import { generatePDF } from "@/tool/pdfTools";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ProgressBar from "@/components/loader/ProgressBar.vue";

@Component({
  components: {
    loading,
    ProgressBar,
  },
  watch: {
    async $route() {
      if (this.$route.params.status && !this.$route.query.update) {
        this.$data.search = "";
        if (!this.$route.params.id) this.$data.isDataLoading = true;
        if (this.$route.params?.status.toLowerCase() === "new") {
          this.$data.statusId = statusId.new;
          this.$data.processedId = 0;
        } else if (this.$route.params?.status.toLowerCase() === "excused") {
          this.$data.statusId = statusId.excused;
          this.$data.processedId = 0;
        } else if (this.$route.params?.status.toLowerCase() === "processed") {
          this.$data.statusId = statusId.processed;
          this.$data.processedId = statusId.processed;
        } else if (this.$route.params?.status.toLowerCase() === "unexcused") {
          this.$data.statusId = statusId.unexcused;
          this.$data.processedId = 0;
        } else if (this.$route.params?.status.toLowerCase() === "denied") {
          this.$data.statusId = statusId.deny;
          this.$data.processedId = 0;
        } else if (this.$route.params?.status.toLowerCase() === "resubmit") {
          this.$data.statusId = statusId.resubmit;
          this.$data.processedId = 0;
        }
        await this.$store.dispatch("tarf/fetchSupervisorTarfList", {
          status: this.$data.statusId,
          processed: this.$data.processedId,
        });
        this.$data.selectAll = false;
        this.$data.isDataLoading = false;
      }
    },
  },
})
export default class RequestsList extends Vue {
  search = "";
  statusId = 0;
  processedId = 0;
  isDataLoading = false;
  downloadList: number[] = [];
  dataList: GetTarfRequestDetailResponse[] = [];
  dataBlobList: { fileName: string; blobData: Blob }[] = [];
  isFetched = false;
  fetching = false;
  selectAll = false;

  @Watch("isFetched", { immediate: true, deep: true })
  onFetchChanged() {
    if (this.isFetched) this.zipGenerator();
  }

  @Watch("selectAll", { immediate: true, deep: true })
  onSelectAllChange() {
    if (!this.selectAll) this.downloadList = [];
    else {
      const tarfs: number[] = [];
      this.requestList?.map(tarflist => {
        tarflist.months.map(tarfgroup =>
          tarfgroup?.tarfs.map(tarf => {
            if (tarf.id) tarfs.push(tarf.id);
          }),
        );
      });
      if (tarfs.length) this.downloadList = tarfs;
    }
  }

  progres = 0;
  downloadProgressCnfrm = false;

  async created() {
    this.isDataLoading = true;
    if (this.$route.params?.status.toLowerCase() === "new") {
      this.statusId = statusId.new;
      this.processedId = 0;
    } else if (this.$route.params?.status.toLowerCase() === "excused") {
      this.statusId = statusId.excused;
      this.processedId = 0;
    } else if (this.$route.params?.status.toLowerCase() === "processed") {
      this.statusId = statusId?.processed;
      this.processedId = statusId.processed;
    } else if (this.$route.params?.status.toLowerCase() === "unexcused") {
      this.statusId = statusId.unexcused;
      this.processedId = 0;
    } else if (this.$route.params?.status.toLowerCase() === "denied") {
      this.statusId = statusId.deny;
      this.processedId = 0;
    } else if (this.$route.params?.status.toLowerCase() === "resubmit") {
      this.statusId = statusId.resubmit;
      this.processedId = 0;
    }
    await this.$store.dispatch("tarf/fetchSupervisorTarfList", {
      status: this.statusId,
      processed: this.processedId,
    });
    this.isDataLoading = false;
  }

  clearSearch() {
    this.search = "";
  }

  get requestList() {
    const search = !this.search ? "" : this.search;
    const tarfList = this.$store.getters["tarf/supervisorTarfList"];
    if (!tarfList?.years) return null;
    const allLists: TarfListItem[] = [];
    _.map(tarfList.years, year =>
      _.map(year.months, month =>
        _.map(month.tarfs, tarf => {
          allLists.push(tarf);
        }),
      ),
    );
    const searchList = _.reduce(
      _.orderBy(
        _.filter(allLists ?? [], (tarf: TarfListItem) =>
          _.includes(
            `${tarf.requesterName?.toLowerCase()} ${this.formatDate(
              tarf.createdAt ?? "",
            ).toLowerCase()}`,
            search.toLowerCase(),
          ),
        ),
        ["createdAt"],
        ["desc"],
      ),
      (
        acc: {
          year: string;
          months: ({ month?: string; tarfs: TarfListItem[] } | undefined)[];
        }[],
        cur,
      ) => {
        const year = moment(cur.createdAt).format("YYYY");
        const month = moment(cur.createdAt).format("MMMM");
        const months = { month, tarfs: [cur] };
        if (!acc[Number(year)]) acc[Number(year)] = { year, months: [months] };
        else {
          if (_.find(acc[Number(year)].months, mnth => mnth?.month === month)) {
            const mIndex = _.findIndex(
              acc[Number(year)].months,
              mnth => mnth?.month === month,
            );
            acc[Number(year)].months[mIndex]?.tarfs.push(cur);
          } else {
            acc[Number(year)].months.push(months);
          }
        }
        return acc;
      },
      [],
    );
    const orderList = _.orderBy(Object.values(searchList), ["year"], "desc");
    return orderList;
  }

  goTo(path: string, id: number) {
    const status = this.$route.params?.status;
    this.$router.push({ path: `${path}${status}/${id}` });
  }

  formatDate(date: string) {
    const utcDate = moment(date).format("MM/DD/YYYY, h:mm A");
    return utcDate;
  }

  get routeId() {
    return this.$route.params?.id;
  }

  isPath(path: string) {
    return this.$route.path.includes(path);
  }

  get currentCompany(): Company {
    return this.$store.getters["getSelectedCompany"];
  }

  downloadHandler() {
    // Checking for the download list
    if (!this.downloadList.length) {
      this.$store.dispatch(
        "alert/warning",
        "Please select at least one TARF to download",
      );
      return;
    }
    // Creating variables
    let fetchListLength = 0;
    this.downloadProgressCnfrm = true;
    this.fetching = true;
    // Loop with selected tarf from list to fetch the details and store it on dataList variable
    this.downloadList.forEach(async (id: number) => {
      const res = await TarfService.getTarfRequestDetail({
        companyId: this.currentCompany.id,
        tarfId: id,
      });
      this.dataList.push(res);
      fetchListLength++;
      if (fetchListLength === this.downloadList.length) {
        this.fetching = false;
        setTimeout(() => {
          this.isFetched = true;
        }, 2000);
      }
    });
  }

  zipGenerator() {
    // Loop with dataList of the fetched data to generate pdf and storing blob conversion to dataBlobList
    if (this.dataList.length) {
      this.progres = 0;
      const ppf = 100 / this.dataList.length;
      this.dataList.forEach(tarfInfo => {
        const doc = generatePDF(
          this.isPath("resubmit"),
          this.isPath("processed"),
          this.isPath("denied"),
          tarfInfo, // should be fetched form downloadList
        );
        const blobData = doc.output("blob");
        if (blobData) {
          this.dataBlobList.push({
            fileName:
              `${tarfInfo.tarf?.requestedBy?.firstName} ${tarfInfo.tarf?.requestedBy?.lastName}` +
              moment(tarfInfo?.tarf?.adjustmentDateTime).format("MM-DD-YYYY") +
              ` ${tarfInfo.tarf?.id}`,
            blobData,
          });
          setTimeout(() => {
            this.progres += ppf;
          }, 500);
        }
      });
    }

    // generating zip file
    if (this.dataBlobList.length) {
      const zip = new JSZip();
      this.dataBlobList.forEach(data => {
        zip.file(`TARF-${data.fileName}.pdf`, data.blobData, {
          binary: true,
        });
      });

      zip
        .generateAsync({ type: "blob" })
        .then(function(content) {
          // see FileSaver.js
          saveAs(content, `tarf_bulk ${moment().format("MM/DD/YYYY")}.zip`);
        })
        .then(() => {
          // clearing list
          this.downloadList = [];
          this.dataList = [];
          this.dataBlobList = [];
          this.selectAll = false;
          this.isFetched = false;
          setTimeout(() => {
            this.downloadProgressCnfrm = false;
            this.progres = 0;
          }, 1000);
        });
    }
  }
}
