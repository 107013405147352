/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SurveyQuestion = {
    properties: {
        id: {
    type: 'number',
},
        departmentId: {
    type: 'number',
},
        alertDepartment: {
    type: 'boolean',
},
        question: {
    type: 'string',
    isRequired: true,
},
        allowComment: {
    type: 'boolean',
    isRequired: true,
},
        allowFeedback: {
    type: 'boolean',
    isRequired: true,
},
        order: {
    type: 'number',
},
        ratingScore: {
    type: 'boolean',
    isRequired: true,
},
    },
};