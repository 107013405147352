import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { AddRoom, Room, RoomService, Rooms } from "@/api";

export type RoomState = {
  rooms: Rooms | null;
  companyRooms: Rooms | null;
  selectedRoom: Room | null;
};

const state: RoomState = {
  rooms: null,
  companyRooms: null,
  selectedRoom: null,
};

const getters: GetterTree<RoomState, RootState> = {
  rooms: state => state.rooms,
  selectedRoom: state => state.selectedRoom,
  companyRooms: state => state.companyRooms,
};

const mutations: MutationTree<RoomState> = {
  SET_ROOMS: (state, rooms) => {
    state.rooms = rooms;
  },
  SET_COMPANYROOMS: (state, rooms) => {
    state.companyRooms = rooms;
  },
  ADD_ROOM: (state, room) => {
    if (state.rooms == null) {
      state.rooms = [];
    }
    state.rooms.push(room);
  },
  REMOVE_ROOM: (state, room) => {
    let index = state.rooms?.findIndex((item: Room) => item.id == room.id);
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.rooms?.splice(index, 1);
    }
  },
  UPDATE_ROOM: (state, room) => {
    let index = state.rooms?.findIndex((item: Room) => item.id == room.id);
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.rooms?.splice(index, 1);
      state.rooms?.push(room);
    }
  },
  SET_SELECTED_ROOM: (state, roomId) => {
    if (roomId && state.rooms?.length) {
      const room = state.rooms.find(room => room.id == roomId);
      state.selectedRoom = room ? room : null;
    } else state.selectedRoom = null;
  },
};

const actions: ActionTree<RoomState, RootState> = {
  fetchFloorRooms: async ({ commit, rootState }, floorId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const rooms = await RoomService.getFloorRooms({ companyId, floorId });
      commit("SET_ROOMS", rooms);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchCompanyRooms: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const rooms = await RoomService.getCompanyRooms({ companyId });
      commit("SET_COMPANYROOMS", rooms);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addFloorRoom: async (
    { commit, rootState, dispatch },
    { floorId, requestBody }: { floorId: number; requestBody: AddRoom },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const room = await RoomService.addFloorRoom({
        companyId,
        floorId,
        requestBody,
      });
      commit("ADD_ROOM", room);
      await dispatch("fetchFloorRooms", floorId);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateFloorRoom: async (
    { commit, rootState },
    { floorId, requestBody }: { floorId: number; requestBody: Room },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const room = await RoomService.updateFloorRoom({
        companyId,
        floorId,
        requestBody,
      });
      commit("UPDATE_ROOM", room);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeFloorRoom: async (
    { commit, rootState },
    { floorId, requestBody }: { floorId: number; requestBody: Room },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      await RoomService.deleteFloorRoom({
        companyId,
        floorId,
        requestBody,
      });
      commit("REMOVE_ROOM", requestBody);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  setSelectedRoom: ({ commit }, roomId: number) => {
    commit("SET_SELECTED_ROOM", roomId);
  },
};

export const room: Module<RoomState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
