/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TarfListItem = {
    properties: {
        createdAt: {
    type: 'string',
    format: 'date-time',
},
        reviewedAt: {
    type: 'string',
    format: 'date-time',
},
        id: {
    type: 'number',
},
        status: {
    type: 'number',
},
        processed: {
    type: 'number',
},
        requesterName: {
    type: 'string',
},
    },
};