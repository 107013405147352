/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ScoreboardEventBreakdownEmployeeScore = {
    properties: {
        employeeId: {
    type: 'number',
    isRequired: true,
},
        nonEmployeeName: {
    type: 'string',
},
        employeeFirstName: {
    type: 'string',
    isRequired: true,
},
        employeeLastName: {
    type: 'string',
    isRequired: true,
},
        title: {
    type: 'string',
    isRequired: true,
},
        primaryDepartmentName: {
    type: 'string',
    isRequired: true,
},
        total: {
    type: 'number',
    isRequired: true,
},
    },
};