import jsPDF from "jspdf";
import moment from "moment";
import store from "@/store";
import statusId from "@/tool/tarfStatus";
import {
  DepartmentEmployeeAssignment,
  GetTarfRequestDetailResponse,
  TarfReason,
} from "@/api";
import { dateFormat } from "@/common/date_format";
import shifts from "@/common/shift_type";
import _ from "lodash";

export const tarfShift = (id: number) => {
  const shift = shifts.find(ele => ele.id == id);
  return shift?.name;
};

export const selectedDepartment = (
  primaryId: number | undefined,
  departmentAssigned: DepartmentEmployeeAssignment[] | undefined,
) => {
  const primaryDepartment = _.find(
    departmentAssigned,
    department => department.departmentId === primaryId,
  );
  return primaryDepartment?.departmentName;
};

export const setWorkTime = (tarfInfo: GetTarfRequestDetailResponse | null) => {
  const hasTimeIn = tarfInfo?.tarf?.timeIn;
  const hasTimeOut = tarfInfo?.tarf?.timeOut;
  const workTimeIn = {
    hours: "",
    min: "",
    ampm: "",
  };
  const workTimeOut = {
    hours: "",
    min: "",
    ampm: "",
  };
  if (hasTimeIn && hasTimeOut) {
    const timeIn = moment(hasTimeIn, ["HH:mm:ss"]).format("h:mm A");
    const [inTime, a] = timeIn.split(" ");
    workTimeIn.ampm = a;
    const [hourIn, minIn] = inTime.split(":");
    workTimeIn.hours = parseInt(hourIn) < 10 ? 0 + hourIn : hourIn;
    workTimeIn.min = minIn;
    const timeOut = moment(hasTimeOut, ["HH:mm:ss"]).format("h:mm A");
    const [outTime, p] = timeOut.split(" ");
    workTimeOut.ampm = p;
    const [hourOut, minOut] = outTime.split(":");
    workTimeOut.hours = parseInt(hourOut) < 10 ? 0 + hourOut : hourOut;
    workTimeOut.min = minOut;
  }

  return { workTimeIn, workTimeOut };
};

export const setMealTime = (tarfInfo: GetTarfRequestDetailResponse | null) => {
  const hasMealTimeIn = tarfInfo?.tarf?.mealTimeIn;
  const hasMealTimeOut = tarfInfo?.tarf?.mealTimeOut;
  const mealTimeIn = {
    hours: "",
    min: "",
    ampm: "",
  };
  const mealTimeOut = {
    hours: "",
    min: "",
    ampm: "",
  };
  if (hasMealTimeIn && hasMealTimeOut) {
    const timeIn = moment(hasMealTimeIn, ["HH:mm:ss"]).format("h:mm A");
    const [inTime, a] = timeIn.split(" ");
    mealTimeIn.ampm = a;
    const [hourIn, minIn] = inTime.split(":");
    mealTimeIn.hours = parseInt(hourIn) < 10 ? 0 + hourIn : hourIn;
    mealTimeIn.min = minIn;
    const timeOut = moment(hasMealTimeOut, ["HH:mm:ss"]).format("h:mm A");
    const [outTime, p] = timeOut.split(" ");
    mealTimeOut.ampm = p;
    const [hourOut, minOut] = outTime.split(":");
    mealTimeOut.hours = parseInt(hourOut) < 10 ? 0 + hourOut : hourOut;
    mealTimeOut.min = minOut;
  }

  return { mealTimeIn, mealTimeOut };
};

export const generatePDF = (
  isResubmit: boolean,
  isProcessed: boolean,
  isDenied: boolean,
  tarfInfo: GetTarfRequestDetailResponse | null,
) => {
  const x = 110;
  let y = 65;
  const z = 490;
  const doc = new jsPDF("p", "pt", "a4");
  let blockHeight;
  const currentCompany = store.getters["getSelectedCompany"];

  const shiftId = tarfInfo?.tarf?.shiftType;
  const shiftType = shiftId || shiftId === 0 ? tarfShift(shiftId) : "";

  const editorName = (): string => {
    const fname = tarfInfo?.tarf?.editedBy?.firstName || "";
    const lname = tarfInfo?.tarf?.editedBy?.lastName || "";
    const name = fname + " " + lname;
    const title = tarfInfo?.tarf?.editedBy?.title || "";
    let date = "";
    if (tarfInfo?.tarf?.editedOn)
      date = dateFormat(tarfInfo?.tarf?.editedOn) || "";
    return `${name} - ${title} - ${date}`;
  };

  const isEditedField = (field: string) => {
    return (
      tarfInfo?.tarf?.editedFields
        ?.trim()
        .split(",")
        .includes(field) && isProcessed
    );
  };

  const message =
    "It is the responsibility of the employee to turn in their TARF to HR/Payroll for proper adjustment day of/after time clock error.";

  const supervisorComment = isResubmit
    ? ""
    : isDenied
    ? tarfInfo?.tarf?.deniedComment || ""
    : tarfInfo?.tarf?.supervisorComment || "";

  const { workTimeIn, workTimeOut } = setWorkTime(tarfInfo);
  const { mealTimeIn, mealTimeOut } = setMealTime(tarfInfo);

  // line height measure
  const lineMeasurer = (text: string, len: number) => {
    const lineHeight = doc.getLineHeight();
    const splittedText = doc.splitTextToSize(text, len);
    const line = splittedText.length;
    blockHeight = lineHeight * line;
    return blockHeight;
  };

  // PDF write started
  if (isResubmit) {
    doc.setFontSize(16);
    doc.setTextColor(245, 9, 9);
    doc.text("Corrected and Resubmitted", z - 40, y - 55, {
      rotationDirection: 0,
      angle: 45,
    });
  }
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(28);
  if (currentCompany?.name)
    doc.text(currentCompany.name, x + 190, y, {
      maxWidth: 500,
      align: "center",
    });

  blockHeight = lineMeasurer(currentCompany.name, 500);
  doc.setFontSize(20);
  doc.text("Time Adjustment Request Form", x + 190, (y += blockHeight), {
    maxWidth: 420,
    align: "center",
  });

  doc.setFontSize(14);
  doc.text(
    "Fill out the form below to submit your time adjustment.",
    x + 190,
    (y += 20),
    {
      maxWidth: 420,
      align: "center",
    },
  );

  doc.setFontSize(12);
  doc.text("Employee Name: ", x, (y += 30));
  const name = `${tarfInfo?.tarf?.requestedBy?.firstName} ${tarfInfo?.tarf?.requestedBy?.lastName}`;
  blockHeight = lineMeasurer(name, 285);
  if (name) doc.text(name, x + 95, y, { maxWidth: 285 });
  y += blockHeight - 10;
  doc.line(x + 95, y + 3, z, y + 3);

  doc.text("Title: ", x, (y += 20));
  const title = tarfInfo?.tarf?.requestedBy?.title;
  let blockHeightTitle: number = blockHeight;
  if (title) {
    blockHeightTitle = lineMeasurer(title, 140);
    doc.text(title, x + 30, y, { maxWidth: 140 });
  }
  doc.line(x + 30, y + blockHeightTitle - 10, x + 170, y + blockHeight - 10);
  doc.text("Employee ID: ", x + 185, y);
  const id = tarfInfo?.tarf?.requestedBy?.externalEmployeeId;
  const blockHeightID = lineMeasurer(String(id), 120);
  if (id) doc.text(`${id}`, x + 260, y, { maxWidth: 120 });
  doc.line(x + 260, y + blockHeightID - 10, 490, y + blockHeightID - 10);
  y += blockHeightTitle > blockHeightID ? blockHeightTitle : blockHeightID;

  doc.text("Reason for Adjustment", x + 125, (y += 30));
  if (tarfInfo?.tarf?.reason || tarfInfo?.tarf?.reason) {
    const reasonNames = tarfInfo?.tarf?.reasons?.map(
      (item: TarfReason) => item.name,
    ) || [tarfInfo?.tarf?.reason.name || ""];
    const names = reasonNames.join(", ");
    if (names)
      doc.text(names, x, (y += 20), {
        maxWidth: z - x,
      }); //225
    blockHeight = lineMeasurer(names, z - x);
    y += blockHeight - 5;
    doc.line(x, y - 5, z, y - 5);
  }

  doc.text("Shift Worked Information", x + 120, (y += 20));
  doc.text("Date of Adjustment", x, (y += 20));
  if (tarfInfo?.tarf?.adjustmentDateTime)
    doc.text(dateFormat(tarfInfo?.tarf?.adjustmentDateTime), x, (y += 20));
  doc.line(x, y + 3, z, y + 3);
  doc.setFontSize(8);
  if (isEditedField("adjustmentDateTime")) doc.text(editorName(), x, (y += 10));

  doc.setFontSize(12);
  doc.text("Time In: ", x, (y += 30));
  doc.text(workTimeIn.hours, x + 50, y);
  doc.text(":", x + 65, y);
  doc.text(workTimeIn.min, x + 70, y);
  doc.text(workTimeIn.ampm, x + 90, y);
  doc.line(x + 50, y + 3, x + 110, y + 3);
  doc.setFontSize(8);
  if (isEditedField("timeIn")) doc.text(editorName(), x, (y += 10));

  doc.setFontSize(12);
  doc.text("Meal Time: ", x, (y += 30)); // 355

  doc.text(mealTimeIn.hours, x + 145 - 80, y); // 395
  doc.text(":", x + 160 - 80, y); // 395
  doc.text(mealTimeIn.min, x + 165 - 80, y); // 395
  doc.text(mealTimeIn.ampm, x + 185 - 80, y); // 395
  doc.line(x + 145 - 80, y + 3, x + 205 - 80, y + 3);

  doc.text("To", x + 215 - 80, y);

  doc.text(mealTimeOut.hours, x + 235 - 80, y); // 395
  doc.text(":", x + 250 - 80, y);
  doc.text(mealTimeOut.min, x + 255 - 80, y); // 395
  doc.text(mealTimeOut.ampm, x + 275 - 80, y); // 395
  doc.line(x + 235 - 80, y + 3, x + 295 - 80, y + 3);
  doc.setFontSize(8);
  if (isEditedField("meal_time_out") || isEditedField("meal_time_in"))
    doc.text(editorName(), x, (y += 10));

  doc.setFontSize(12);
  doc.text("Time Out: ", x, (y += 30)); // 315
  doc.text(workTimeOut.hours, x + 55, y); // 335
  doc.text(":", x + 70, y); // 335
  doc.text(workTimeOut.min, x + 75, y); // 335
  doc.text(workTimeOut.ampm, x + 95, y);
  doc.line(x + 55, y + 3, x + 115, y + 3);
  doc.setFontSize(8);
  if (isEditedField("timeOut")) doc.text(editorName(), x, (y += 10));

  doc.setFontSize(12);
  doc.text("Hours Worked: ", x, (y += 30));
  if (tarfInfo?.tarf?.hoursWorked)
    doc.text(tarfInfo?.tarf?.hoursWorked.substring(0, 5) + " hrs", x + 85, y);
  doc.line(x + 85, y + 3, x + 140, y + 3);
  doc.setFontSize(8);
  if (isEditedField("hoursworked")) doc.text(editorName(), x, (y += 10));

  doc.setFontSize(12);
  doc.text("Shift Type", x + 155, (y += 30));
  if (shiftType?.trim()) doc.text(shiftType, x, (y += 20));
  doc.line(x, y + 3, z, y + 3);

  if (tarfInfo?.tarf?.requesterComment) {
    doc.text("Comment from Employee", x + 125, (y += 20));
    doc.text(tarfInfo?.tarf?.requesterComment, x, (y += 20), {
      maxWidth: z - x,
    });
    blockHeight = lineMeasurer(tarfInfo?.tarf?.requesterComment, z - x);
    y += blockHeight - 10;
    doc.line(x, y + 3, z, y + 3);
  }

  if (supervisorComment) {
    doc.text("Comment from Supervisor", x + 125, (y += 20));
    doc.text(supervisorComment, x, (y += 20), { maxWidth: z - x });
    blockHeight = lineMeasurer(supervisorComment, z - x);
    y += blockHeight - 10;
    doc.line(x, y + 3, z, y + 3);
  }

  doc.text(message, x + 190, (y += 30), {
    maxWidth: 320,
    align: "center",
  });
  blockHeight = lineMeasurer(message, 320);
  y += blockHeight + 20;

  doc.text("Employee Signature", x, y);
  doc.text("Signature Date", x + 300, y);
  const department = selectedDepartment(
    tarfInfo?.tarf?.requestedBy?.primaryDepartmentId,
    tarfInfo?.tarf?.requestedBy?.departmentAssignments,
  );
  let employeeSignature!: string;
  if (tarfInfo?.tarf?.requestedBy) {
    employeeSignature =
      tarfInfo.tarf.requestedBy.firstName +
      " " +
      tarfInfo.tarf.requestedBy.lastName +
      " - " +
      tarfInfo.tarf.requestedBy.title +
      " - " +
      department;
    doc.text(employeeSignature, x, (y += 20), {
      maxWidth: 290,
      align: "left",
    });
  }
  blockHeight = lineMeasurer(employeeSignature, 290);

  if (tarfInfo?.tarf?.createdAt)
    doc.text(
      moment(tarfInfo?.tarf?.createdAt).format("MM/DD/YYYY hh:mm a"),
      x + 300,
      y,
      {
        maxWidth: x + 10,
        align: "left",
      },
    );

  const decide = tarfInfo?.tarf?.status;
  const isProcess = tarfInfo?.tarf?.processed;
  if (decide !== statusId.excused) doc.setFillColor(255, 255, 255);
  doc.rect(x, (y += blockHeight), 10, 10, "FD");
  doc.text("Excused", x + 15, y + 10);
  if (decide !== statusId.unexcused) doc.setFillColor(255, 255, 255);
  doc.rect(x + 80, y, 10, 10, "FD");
  doc.text("Unexcused", x + 95, y + 10);
  if (isProcess !== statusId.processed) doc.setFillColor(255, 255, 255);
  doc.rect(x + 160, y, 10, 10, "FD");
  doc.text("Processed", x + 175, y + 10);

  doc.text("Supervisor Signature", x, (y += 30));
  doc.text("Signature Date", x + 300, y);
  const superVisorDepartment = selectedDepartment(
    tarfInfo?.tarf?.requestedBy?.primaryDepartmentId,
    tarfInfo?.tarf?.requestedBy?.departmentAssignments,
  );
  if (tarfInfo?.tarf?.reviewedBy)
    doc.text(
      tarfInfo.tarf.reviewedBy.firstName +
        " " +
        tarfInfo.tarf.reviewedBy.lastName +
        " - " +
        tarfInfo.tarf.reviewedBy.title +
        " - " +
        superVisorDepartment,
      x,
      (y += 20),
      {
        maxWidth: 290,
        align: "left",
      },
    );
  if (tarfInfo?.tarf?.reviewedAt)
    doc.text(
      moment(tarfInfo?.tarf?.reviewedAt).format("MM/DD/YYYY hh:mm a"),
      x + 300,
      y,
      {
        maxWidth: x + 10,
        align: "left",
      },
    );

  return doc;
};
