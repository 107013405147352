
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class ChatLayout extends Vue {
  created() {
    this.$store.dispatch("directory/loadAllEmployees");
  }

  get chatSelected() {
    return this.$route.params.id;
  }
}
