












import { Component, Vue } from "vue-property-decorator";
import TARFform from "@/components/tarf_form/TARFform.vue";

@Component({
  components: {
    TARFform,
  },
})
export default class NewRequest extends Vue {}
