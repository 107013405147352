var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-8 py-12",staticStyle:{"height":"100%"}},[(_vm.forms)?[_c('div',{staticClass:"d-flex flex-fill mb-8 flex-wrap",class:{
        'justify-center': _vm.$vuetify.breakpoint.smAndDown,
        'justify-space-between': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"font-weight-bold text-grey"},[_vm._v("Guardian Angel Form")]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"px-4",attrs:{"color":"#a8c8ff","rounded":""},on:{"click":function($event){_vm.createDialog = !_vm.createDialog}}},[_c('span',{staticClass:"text-blue overline font-weight-bold"},[_vm._v("Create a new GA Form")])])],1)]),_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"3"}},[_c('div',{staticClass:"d-flex flex-fill flex-wrap",class:{
          'justify-center': _vm.$vuetify.breakpoint.smAndDown,
          'justify-space-between': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('div',[_c('v-select',{attrs:{"items":_vm.formStatus,"label":"Status","item-text":"status","item-value":"value","name":"departmentID","outlined":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"icon":"","color":"#8b8b8b"},on:{"click":function($event){_vm.copyConfirm = !_vm.copyConfirm}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"loading":_vm.deactivating,"icon":"","color":"#8b8b8b"},on:{"click":_vm.setInactive}},[_c('v-icon',[_vm._v("mdi-archive-outline")])],1),_c('v-btn',{attrs:{"loading":_vm.removing,"icon":"","color":"#8b8b8b"},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]),_c('v-radio-group',{model:{value:(_vm.selectedForm),callback:function ($$v) {_vm.selectedForm=$$v},expression:"selectedForm"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.forms,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-radio',{attrs:{"value":item.id}})]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('a',{class:{ 'text-decoration-underline': hover },on:{"click":function($event){return _vm.goTo(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:{ 'text-blue': item.status === 3 }},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}}],null,true)})],1)],1)]:[(_vm.loading)?_c('Loading',{attrs:{"loading":_vm.loading,"size":70,"width":7}}):[_c('div',{staticClass:"d-flex flex-column flex-fill fill-height justify-center align-center"},[_c('p',{staticClass:"text-blue button font-weight-bold"},[_vm._v(" No Guardian Angel Form ")]),_c('v-btn',{staticClass:"px-12",attrs:{"color":"#a8c8ff","rounded":""},on:{"click":function($event){_vm.createDialog = !_vm.createDialog}}},[_c('span',{staticClass:"text-blue overline font-weight-bold"},[_vm._v("Create a new GA Form")])])],1)]],_c('v-dialog',{attrs:{"max-width":"450px","persistent":""},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('OptionTemplateDialog',{on:{"blank":_vm.createBlank,"template":_vm.createTemplate,"close":function($event){_vm.createDialog = !_vm.createDialog}}})],1),_c('v-dialog',{staticClass:"rounded-dialog",attrs:{"max-width":"400px","persistent":""},model:{value:(_vm.nameConfirm),callback:function ($$v) {_vm.nameConfirm=$$v},expression:"nameConfirm"}},[_c('NameFormDialog',{attrs:{"loading":_vm.creating,"saved":_vm.saved},on:{"saved":function($event){_vm.saved = !_vm.saved},"save":_vm.save,"close":function($event){_vm.nameConfirm = !_vm.nameConfirm}}})],1),_c('v-dialog',{staticClass:"rounded-dialog",attrs:{"max-width":"400px","persistent":""},model:{value:(_vm.copyConfirm),callback:function ($$v) {_vm.copyConfirm=$$v},expression:"copyConfirm"}},[_c('NameFormDialog',{attrs:{"loading":_vm.copying},on:{"save":_vm.createCopy,"close":function($event){_vm.copyConfirm = !_vm.copyConfirm}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }