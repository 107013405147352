/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GuardianAngel = {
    properties: {
        id: {
    type: 'number',
},
        roomId: {
    type: 'number',
    isRequired: true,
},
        employeeId: {
    type: 'number',
    isRequired: true,
},
        primaryGuardian: {
    type: 'number',
    isRequired: true,
},
    },
};