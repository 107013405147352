








































































































































































































































































































































































































































































































































































































































































































































































import {
  DepartmentEmployeeAssignment,
  GetTarfRequestDetailResponse,
  TarfReason,
} from "@/api";
import { VForm } from "@/common/vuetify_types";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import shifts from "@/common/shift_type";
import tarfStatus from "@/tool/tarfStatus";
import statusId from "@/tool/tarfStatus";
import TarfConfirmModal from "@/components/tarf_form/TarfConfirmModal.vue";
import TarfDenyForm from "@/components/tarf_form/TarfDenyForm.vue";
import { generatePDF, setWorkTime, setMealTime } from "@/tool/pdfTools";
import moment from "moment";
import _ from "lodash";
import {
  checkSelf,
  checkSelfRole,
  checkSelfSubrole,
} from "@/common/role_utils";
import * as validation from "@/tool/validation";

@Component({
  components: {
    TarfConfirmModal,
    TarfDenyForm,
  },
})
export default class TARFform extends Vue {
  @Prop() readonly tarfInfo!: GetTarfRequestDetailResponse | null;
  @Ref("form") readonly form!: VForm;
  @Ref("printContent") readonly printCntent!: HTMLElement;

  formModel = false;
  showDate = false;
  employeeName = `${this.tarfInfo?.tarf?.requestedBy?.firstName} ${this.tarfInfo?.tarf?.requestedBy?.lastName}`;
  employeeTitle = this.tarfInfo?.tarf?.requestedBy?.title || "";
  employeeId = this.tarfInfo?.tarf?.requestedBy?.externalEmployeeId || "";
  reason = this.tarfInfo?.tarf?.reasons?.map((item: TarfReason) => item.id) || [
    this.tarfInfo?.tarf?.reason.id || 0,
  ];
  adjustmentDate = this.tarfInfo?.tarf?.adjustmentDateTime || "";
  formattedAdjustmentDate = this.formatDate(this.adjustmentDate);
  hoursWorked = this.tarfInfo?.tarf?.hoursWorked;
  shift = this.tarfInfo?.tarf ? this.tarfInfo?.tarf?.shiftType || 0 : "";
  shifts = [...shifts];
  employeeComment = this.tarfInfo?.tarf?.requesterComment || "";
  rejectConfirmModal = false;
  supervisorComment = "";
  hasTimeIn = this.tarfInfo?.tarf?.timeIn;
  hasTimeOut = this.tarfInfo?.tarf?.timeOut;
  hasMealTimeIn = this.tarfInfo?.tarf?.mealTimeIn;
  hasMealTimeOut = this.tarfInfo?.tarf?.mealTimeOut;
  hourItems: string[] = [];
  ampmItems = ["AM", "PM"];
  minItems: string[] = [];
  workTimeIn = {
    hours: "",
    min: "",
    ampm: "",
  };
  workTimeOut = {
    hours: "",
    min: "",
    ampm: "",
  };
  mealTimeIn = {
    hours: "",
    min: "",
    ampm: "",
  };
  mealTimeOut = {
    hours: "",
    min: "",
    ampm: "",
  };
  workTimeErrors: { timeIn: string[]; timeOut: string[] } = {
    timeIn: [],
    timeOut: [],
  };
  mealTimeErrors: { timeIn: string[]; timeOut: string[] } = {
    timeIn: [],
    timeOut: [],
  };
  formConfirmModal = false;
  text = "";
  maxDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  required = [validation.requiredString];
  requiredShift = [validation.requriedNumber];
  requiredSelect = [(v: string) => !!v || ""];
  reasonRules = [validation.requireAutoSelectNumber];
  editAdjustmentDate: boolean | undefined = false;
  editTimeIn: boolean | undefined = false;
  editTimeOut: boolean | undefined = false;
  editMealTimeIn: boolean | undefined = false;
  editMealTimeOut: boolean | undefined = false;
  editHoursWorked: boolean | undefined = false;
  searchInput = null;
  processing = {
    resubmitted: false,
    excused: false,
    unexcused: false,
    deny: false,
    processed: false,
  };

  created() {
    this.setWorkTime();
    this.setMealTime();
    this.setHourMinDropDown();
    this.supervisorComment = this.isResubmit
      ? ""
      : this.isDeny
      ? this.tarfInfo?.tarf?.deniedComment || ""
      : this.tarfInfo?.tarf?.supervisorComment || "";
  }

  get browserInfo() {
    const ua = navigator.userAgent.includes("Firefox");
    return ua;
  }

  get reasonList() {
    return this.$store.getters["tarf/reasonList"];
  }

  setHourMinDropDown() {
    for (let i = 0; i < 60; i++) {
      if (i < 10) {
        this.minItems.push("0" + i);
      } else {
        this.minItems.push(`${i}`);
      }
    }
    for (let i = 1; i < 13; i++) {
      if (i < 10) {
        this.hourItems.push("0" + i);
      } else {
        this.hourItems.push(`${i}`);
      }
    }
  }

  setWorkTime() {
    const { workTimeIn, workTimeOut } = setWorkTime(this.tarfInfo);
    this.workTimeIn = workTimeIn;
    this.workTimeOut = workTimeOut;
  }

  setMealTime() {
    const { mealTimeIn, mealTimeOut } = setMealTime(this.tarfInfo);
    this.mealTimeIn = mealTimeIn;
    this.mealTimeOut = mealTimeOut;
  }

  get tarfDetails() {
    if (this.tarfInfo) return this.tarfInfo?.tarf;
    else return null;
  }

  tarfShift(id: number) {
    const shift = shifts.find(ele => ele.id == id);
    return shift?.name;
  }

  // Route check
  get isNew() {
    return this.$route.params.status === "new";
  }
  get isExcused() {
    return this.$route.params.status === "excused";
  }
  get isUnexcused() {
    return this.$route.params.status === "unexcused";
  }

  get isProcessed() {
    return this.$route.path.includes("processed");
  }

  get isDeny() {
    return this.$route.path.includes("denied");
  }

  get isResubmit() {
    return this.$route.path.includes("resubmit");
  }
  // Route check end

  async excusedTarf() {
    const statId = this.tarfInfo?.tarf?.status;
    this.text =
      "The TARF Form has been excused and submitted to HR. Employee will be notified.";
    if (this.form.validate()) {
      try {
        this.processing.excused = true;
        await this.$store.dispatch("tarf/reviewTarfRequest", {
          requestBody: {
            status: tarfStatus.excused,
            supervisorComment: this.supervisorComment,
          },
          tarfId: this.tarfInfo?.tarf?.id,
        });
        this.formConfirmModal = !this.formConfirmModal;
        await this.$store.dispatch("tarf/fetchSupervisorTarfList", {
          status: statId,
          processed: 0,
        });
        this.$store.dispatch("tarf/fetchTarfStatuses", {
          force: true,
          toFetch: -1,
        });
        this.processing.excused = false;
      } catch (error) {
        if (error instanceof Error) {
          this.$store.dispatch("alert/warning", error.message);
        }
        this.processing.excused = false;
      }
    }
  }

  async unexcusedTarf() {
    const statId = this.tarfInfo?.tarf?.status;
    this.text =
      "The TARF Form has been unexcused and submitted to HR. Employee will be notified.";
    if (this.form.validate()) {
      try {
        this.processing.unexcused = true;
        await this.$store.dispatch("tarf/reviewTarfRequest", {
          requestBody: {
            status: tarfStatus.unexcused,
            supervisorComment: this.supervisorComment,
          },
          tarfId: this.tarfInfo?.tarf?.id,
        });
        this.formConfirmModal = !this.formConfirmModal;
        await this.$store.dispatch("tarf/fetchSupervisorTarfList", {
          status: statId,
          processed: 0,
        });
        this.$store.dispatch("tarf/fetchTarfStatuses", {
          force: true,
          toFetch: -1,
        });
        this.processing.unexcused = false;
      } catch (error) {
        if (error instanceof Error) {
          this.$store.dispatch("alert/warning", error.message);
        }
        this.processing.unexcused = false;
      }
    }
  }

  async processTarf() {
    const statId = this.tarfInfo?.tarf?.status;
    this.text = "The TARF Form has been processed from HR.";
    if (this.validateAllForm()) {
      try {
        this.processing.processed = true;
        const editRequestBody = this.requestPayload();
        await this.$store.dispatch("tarf/processTARFrequest", {
          tarfId: this.tarfInfo?.tarf?.id,
          reviewRequestBody: {
            processed: statusId.processed,
          },
          editRequestBody,
        });
        this.formConfirmModal = !this.formConfirmModal;
        await this.$store.dispatch("tarf/fetchSupervisorTarfList", {
          status: statId,
          processed: 0,
        });
        this.$store.dispatch("tarf/fetchTarfStatuses", {
          force: true,
          toFetch: -1,
        });
        this.processing.processed = false;
      } catch (error) {
        console.log(error);
        this.processing.processed = false;
      }
    } else {
      this.$store.dispatch(
        "alert/warning",
        "Please fullfill all the requirements.",
      );
    }
  }

  async denyTarf(rejectComment: string) {
    const statId = this.tarfInfo?.tarf?.status;
    this.rejectConfirmModal = !this.rejectConfirmModal;
    this.text =
      "The TARF Form has been denied and redirected to Employee. Employee will be notified.";
    if (this.form.validate()) {
      try {
        this.processing.deny = true;
        if (checkSelfRole("owner")) {
          await this.$store.dispatch("tarf/ownerDenyTarf", {
            tarfId: this.tarfInfo?.tarf?.id,
            requestBody: {
              status: tarfStatus.deny,
              supervisorComment: rejectComment,
            },
          });
        } else {
          await this.$store.dispatch("tarf/reviewTarfRequest", {
            requestBody: {
              status: tarfStatus.deny,
              supervisorComment: rejectComment,
            },
            tarfId: this.tarfInfo?.tarf?.id,
          });
        }
        this.formConfirmModal = !this.formConfirmModal;
        await this.$store.dispatch("tarf/fetchSupervisorTarfList", {
          status: statId,
          processed: 0,
        });
        this.$store.dispatch("tarf/fetchTarfStatuses", {
          force: true,
          toFetch: -1,
        });
        this.processing.deny = false;
      } catch (error) {
        if (error instanceof Error) {
          this.$store.dispatch("alert/warning", error.message);
        }
        this.processing.deny = false;
      }
    }
  }

  resetTimeErrors() {
    this.workTimeErrors = { timeIn: [], timeOut: [] };
    this.mealTimeErrors = { timeIn: [], timeOut: [] };
  }

  validateAllForm() {
    this.resetTimeErrors();
    const isWorkTimeValid = this.validateTime(
      this.workTimeIn,
      this.workTimeOut,
    );
    this.workTimeErrors.timeIn = isWorkTimeValid.timeIn.length
      ? isWorkTimeValid.timeIn
      : [];
    this.workTimeErrors.timeOut = isWorkTimeValid.timeOut.length
      ? isWorkTimeValid.timeOut
      : [];

    let isValidMealTime = false;
    if (!this.isNoLunch) {
      const isMealTimeValid = this.validateTime(
        this.mealTimeIn,
        this.mealTimeOut,
      );
      this.mealTimeErrors.timeIn = isMealTimeValid.timeIn.length
        ? isMealTimeValid.timeIn
        : [];
      this.mealTimeErrors.timeOut = isMealTimeValid.timeOut.length
        ? isMealTimeValid.timeOut
        : [];
      if (!isMealTimeValid.timeIn.length && !isMealTimeValid.timeOut.length)
        isValidMealTime = true;
    } else {
      isValidMealTime = true;
    }
    const isValid = this.form.validate();
    if (
      isValid &&
      !isWorkTimeValid.timeIn.length &&
      !isWorkTimeValid.timeOut.length &&
      isValidMealTime
    )
      return true;
    return false;
  }

  requestPayload() {
    let isChanged = true;
    let adjustmentDateTime = moment.utc(this.adjustmentDate).format();
    const rawTimeIn = `${this.workTimeIn.hours} : ${this.workTimeIn.min} ${this.workTimeIn.ampm}`;
    const rawTimeOut = `${this.workTimeOut.hours} : ${this.workTimeOut.min} ${this.workTimeOut.ampm}`;
    const rawMealTimeIn = `${this.mealTimeIn.hours} : ${this.mealTimeIn.min} ${this.mealTimeIn.ampm}`;
    const rawMealTimeOut = `${this.mealTimeOut.hours} : ${this.mealTimeOut.min} ${this.mealTimeOut.ampm}`;
    let timeIn = moment(rawTimeIn, ["h:mm A"]).format("HH:mm");
    let timeOut = moment(rawTimeOut, ["h:mm A"]).format("HH:mm");
    let mealTimeIn = !this.isNoLunch
      ? moment(rawMealTimeIn, ["h:mm A"]).format("HH:mm")
      : "";
    let mealTimeOut = !this.isNoLunch
      ? moment(rawMealTimeOut, ["h:mm A"]).format("HH:mm")
      : "";
    let hoursWorked = this.hoursWorked;

    if (
      adjustmentDateTime ===
      moment.utc(this.tarfInfo?.tarf?.adjustmentDateTime).format()
    ) {
      adjustmentDateTime = "";
    }
    if (timeIn + ":00" === this.tarfInfo?.tarf?.timeIn) timeIn = "";
    if (timeOut + ":00" === this.tarfInfo?.tarf?.timeOut) timeOut = "";
    if (
      this.hoursWorked?.slice(0, 5) + ":00" ===
      this.tarfInfo?.tarf?.hoursWorked
    )
      hoursWorked = "";
    if (mealTimeIn + ":00" === this.tarfInfo?.tarf?.mealTimeIn) mealTimeIn = "";
    if (mealTimeOut + ":00" === this.tarfInfo?.tarf?.mealTimeOut)
      mealTimeOut = "";

    isChanged =
      adjustmentDateTime +
      timeIn +
      timeOut +
      mealTimeIn +
      mealTimeOut +
      hoursWorked
        ? true
        : false;

    if (this.isDeny) {
      if (isChanged) {
        return {
          adjustmentDateTime,
          timeIn,
          timeOut,
          hoursWorked: hoursWorked?.slice(0, 5),
          mealTimeIn,
          mealTimeOut,
        };
      } else {
        return null;
      }
    } else if (this.isExcused || this.isUnexcused) {
      if (isChanged) {
        return {
          adjustmentDateTime,
          timeIn,
          timeOut,
          hoursWorked: hoursWorked?.slice(0, 5),
          mealTimeIn,
          mealTimeOut,
        };
      }
    } else return null;
  }

  async reSubmit() {
    const statId = this.tarfInfo?.tarf?.status;
    this.text =
      "Your request has been resubmitted to your supervisor for approval.";
    const requestBody = this.requestPayload();
    if (this.validateAllForm() && requestBody) {
      try {
        this.processing.resubmitted = true;
        await this.$store.dispatch("tarf/resubmitTarrf", {
          tarfId: this.tarfInfo?.tarf?.id,
          requestBody: { status: statusId.resubmit },
          editRequestBody: requestBody,
        });
        this.formConfirmModal = !this.formConfirmModal;
        await this.$store.dispatch("tarf/fetchSupervisorTarfList", {
          status: statId,
          processed: 0,
        });
        await this.$store.dispatch("tarf/fetchTarfStatuses", {
          force: true,
          toFetch: -1,
        });
        this.form.resetValidation();
        this.processing.resubmitted = false;
      } catch (error) {
        this.$store.dispatch("alert/warning", error);
        this.processing.resubmitted = false;
      }
    } else {
      this.$store.dispatch(
        "alert/error",
        "please change the field as per the Reason for Denial comment.",
      );
    }
  }

  selectedDepartment(
    primaryId: number | undefined,
    departmentAssigned: DepartmentEmployeeAssignment[] | undefined,
  ) {
    const primaryDepartment = _.find(
      departmentAssigned,
      department => department.departmentId === primaryId,
    );
    return primaryDepartment?.departmentName;
  }

  get currentCompany() {
    return this.$store.getters["getSelectedCompany"];
  }

  get editedFields() {
    const fields = this.tarfInfo?.tarf?.editedFields;
    const listFields = fields?.trim().split(",");
    if (this.tarfInfo?.tarf?.editedBy?.roles?.includes("owner"))
      return listFields;
    return undefined;
  }

  get isEditedDetail() {
    return this.isProcessed && this.editedFields;
  }

  editorName(): string {
    const fname = this.tarfInfo?.tarf?.editedBy?.firstName || "";
    const lname = this.tarfInfo?.tarf?.editedBy?.lastName || "";
    const name = fname + " " + lname;
    const title = this.tarfInfo?.tarf?.editedBy?.title || "";
    let date = "";
    if (this.tarfInfo?.tarf?.editedOn)
      date = this.formatDate(this.tarfInfo?.tarf?.editedOn) || "";
    return `${name} - ${title} - ${date}`;
  }

  async downloadHandler() {
    const doc = generatePDF(
      this.isResubmit,
      this.isProcessed,
      this.isDeny,
      this.tarfInfo,
    );

    doc.save(
      `TARF-${this.formatDate(
        this.tarfInfo?.tarf?.adjustmentDateTime || "",
      )}.pdf`,
    );
  }

  get isNoLunch() {
    const noLunch = this.reasonList?.find((item: TarfReason) =>
      item.name?.includes("No lunch taken"),
    );
    return this.reason.includes(noLunch.id);
  }

  validateTime(
    timeIn: {
      hours: string;
      min: string;
      ampm: string;
    },
    timeOut: {
      hours: string;
      min: string;
      ampm: string;
    },
  ) {
    const errors: { timeIn: string[]; timeOut: string[] } = {
      timeIn: [],
      timeOut: [],
    };
    if (timeIn.hours === "" || !timeIn.hours)
      errors.timeIn.push("Hour is required");
    if (timeOut.hours === "" || !timeOut.hours)
      errors.timeOut.push("Hour is required");
    if (timeIn.min === "" || !timeIn.min) errors.timeIn.push("Min is required");
    if (timeOut.min === "" || !timeOut.min)
      errors.timeOut.push("Min is required");
    if (timeIn.ampm === "" || !timeIn.ampm)
      errors.timeIn.push("AMPM is required");
    if (timeOut.ampm === "" || !timeOut.ampm)
      errors.timeOut.push("AMPM is required");
    if (!errors.timeIn.length && !errors.timeOut.length)
      return { timeIn: [], timeOut: [] };
    return errors;
  }

  calcHoursWorked() {
    // this.resetTimeErrors();
    const isWorkTimeValid = this.validateTime(
      this.workTimeIn,
      this.workTimeOut,
    );
    const isMealTimeValid = this.validateTime(
      this.mealTimeIn,
      this.mealTimeOut,
    );
    let workTimeDurationHour = 0;
    let workTimeDurationMin = 0;
    let mealTimeDurationHour = 0;
    let mealTimeDurationMin = 0;
    let timeIn, timeOut;
    if (!isWorkTimeValid.timeIn.length && !isWorkTimeValid.timeOut.length) {
      this.workTimeErrors = isWorkTimeValid;
      const rawTimeIn = `${this.workTimeIn.hours} : ${this.workTimeIn.min} ${this.workTimeIn.ampm}`;
      const rawTimeOut = `${this.workTimeOut.hours} : ${this.workTimeOut.min} ${this.workTimeOut.ampm}`;
      timeIn = moment(rawTimeIn, "HH:mm:ss a");
      timeOut = moment(rawTimeOut, "HH:mm:ss a");
      const duration = moment.duration(timeOut.diff(timeIn));
      workTimeDurationHour = parseInt(duration.asHours().toString());
      workTimeDurationMin = parseInt(duration.asMinutes().toString()) % 60;
      if (workTimeDurationHour < 0)
        workTimeDurationHour = 24 + workTimeDurationHour;
      if (workTimeDurationMin < 0) {
        workTimeDurationMin = 60 + workTimeDurationMin;
        workTimeDurationHour -= 1;
      }
      this.hoursWorked = `${Math.abs(workTimeDurationHour)
        .toString()
        .padStart(2, "0")}:${Math.abs(workTimeDurationMin)
        .toString()
        .padStart(2, "0")} hrs`;
    }
    if (!isMealTimeValid.timeIn.length && !isMealTimeValid.timeOut.length) {
      const rawMealTimeIn = `${this.mealTimeIn.hours} : ${this.mealTimeIn.min} ${this.mealTimeIn.ampm}`;
      const rawMealTimeOut = `${this.mealTimeOut.hours} : ${this.mealTimeOut.min} ${this.mealTimeOut.ampm}`;
      const mealTimeIn = moment(rawMealTimeIn, "HH:mm:ss a");
      const mealTimeOut = moment(rawMealTimeOut, "HH:mm:ss a");
      const duration = moment.duration(mealTimeOut.diff(mealTimeIn));
      mealTimeDurationHour = parseInt(duration.asHours().toString());
      mealTimeDurationMin = parseInt(duration.asMinutes().toString()) % 60;
      if (mealTimeDurationHour < 0)
        mealTimeDurationHour = 24 + mealTimeDurationHour;
      if (workTimeDurationMin < 0) {
        mealTimeDurationMin = 60 + mealTimeDurationMin;
        mealTimeDurationHour -= 1;
      }
      let minutes = workTimeDurationMin - mealTimeDurationMin;
      let hours = workTimeDurationHour - mealTimeDurationHour;
      if (minutes < 0) {
        hours -= 1;
        minutes = 60 - Math.abs(minutes);
      }
      if (timeIn && timeOut) {
        if (timeIn < mealTimeIn && timeOut > mealTimeOut) {
          this.mealTimeErrors = isMealTimeValid;
          this.hoursWorked = `${hours
            .toString()
            .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} Hrs`;
        } else {
          if (timeIn > mealTimeIn)
            this.mealTimeErrors.timeIn = [
              "Meal TimeIn cannot be greater then Work TimeIn.",
            ];
          if (timeOut < mealTimeOut)
            this.mealTimeErrors.timeOut = [
              "Meal TimeOut cannot be greater then Work TimeOut.",
            ];
        }
      }
    }
  }

  formatDate(date: string) {
    return moment(date).format("MM/DD/YYYY");
  }

  formatInputDate(date: string) {
    this.formattedAdjustmentDate = moment(date).format("MM/DD/YYYY");
  }

  get isEditTimeIn() {
    return this.editedFields?.includes("timeIn") && this.isProcessed;
  }
  get isEditTimeOut() {
    return this.editedFields?.includes("timeOut") && this.isProcessed;
  }
  get isEditMealTimeIn() {
    return this.editedFields?.includes("meal_time_in") && this.isProcessed;
  }
  get isEditMealTimeOut() {
    return this.editedFields?.includes("meal_time_out") && this.isProcessed;
  }
  get isEditAdjustmentDate() {
    return (
      this.editedFields?.includes("adjustmentDateTime") && this.isProcessed
    );
  }
  get isEditHoursWorked() {
    return this.editedFields?.includes("hoursworked") && this.isProcessed;
  }

  justHoverIt(field: string) {
    switch (field) {
      case "timein":
        this.editTimeIn = this.isEditedDetail && this.isEditTimeIn;
        break;

      case "timeout":
        this.editTimeOut = this.isEditedDetail && this.isEditTimeOut;
        break;

      case "mealtimein":
        this.editMealTimeIn = this.isEditedDetail && this.isEditMealTimeIn;
        break;

      case "mealtimeout":
        this.editMealTimeOut = this.isEditedDetail && this.isEditMealTimeOut;
        break;

      case "adjustmentdate":
        this.editAdjustmentDate =
          this.isEditedDetail && this.isEditAdjustmentDate;

        break;

      case "hoursworked":
        this.editHoursWorked = this.isEditedDetail && this.isEditHoursWorked;
        break;

      default:
        break;
    }
  }
  async goTo() {
    this.formConfirmModal = !this.formConfirmModal;
    await this.$router.push({
      path: `/form/supervisor/list/${this.$route.params.status}`,
      query: {
        update: "1",
      },
    });
  }

  // Check for user roles

  get isOwnerTarf() {
    return this.tarfInfo?.tarf?.requestedBy?.roles?.includes("owner");
  }

  get isAdminReviewAction() {
    return (
      (this.isNew || this.isResubmit) &&
      // eslint-disable-next-line
      (!checkSelf(this.tarfDetails?.requestedBy?.id!) || checkSelfSubrole()) &&
      (checkSelfRole("admin") || (checkSelfRole("owner") && this.isOwnerTarf))
    );
  }

  get isWaiting() {
    return (
      !this.isAdminReviewAction &&
      !this.isExcused &&
      !this.isUnexcused &&
      !this.isProcessed &&
      !this.isDeny
    );
  }

  get isOwnerReviewAction() {
    return (
      checkSelfRole("owner") &&
      // eslint-disable-next-line
      !checkSelf(this.tarfDetails?.requestedBy?.id!) &&
      (this.isExcused || this.isUnexcused)
    );
  }

  get isEdit() {
    return (
      (this.isExcused &&
        checkSelfRole("owner") &&
        // eslint-disable-next-line
        !checkSelf(this.tarfDetails?.requestedBy?.id!)) ||
      (this.isUnexcused &&
        checkSelfRole("owner") &&
        // eslint-disable-next-line
        !checkSelf(this.tarfDetails?.requestedBy?.id!)) ||
      // eslint-disable-next-line
      (this.isDeny && checkSelf(this.tarfDetails?.requestedBy?.id!))
    );
  }
}
