import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { Floor, FloorService, Floors } from "@/api";

export type FloorState = {
  floors: Floors | null;
};

const state: FloorState = {
  floors: null,
};

const getters: GetterTree<FloorState, RootState> = {
  floors: state => state.floors,
};

const mutations: MutationTree<FloorState> = {
  SET_FLOORS: (state, floors) => {
    state.floors = floors;
  },
  ADD_FLOOR: (state, floor) => {
    if (state.floors == null) {
      state.floors = [];
    }
    state.floors?.push(floor);
  },
  REMOVE_FLOOR: (state, floor) => {
    let index = state.floors?.findIndex((item: Floor) => item.id == floor.id);
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.floors?.splice(index, 1);
    }
  },
  UPDATE_FLOOR: (state, floor) => {
    let index = state.floors?.findIndex((item: Floor) => item.id == floor.id);
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.floors?.splice(index, 1);
      state.floors?.push(floor);
    }
  },
};

const actions: ActionTree<FloorState, RootState> = {
  fetchCompanyFloors: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const floors = await FloorService.getCompanyFloors({ companyId });
      commit("SET_FLOORS", floors);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addCompanyFloor: async (
    { commit, rootState, dispatch },
    requestBody: Floor,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const floor = await FloorService.addCompanyFloor({
        companyId,
        requestBody,
      });
      commit("ADD_FLOOR", floor);
      await dispatch("fetchCompanyFloors");
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateCompanyFloor: async ({ commit, rootState }, requestBody: Floor) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const floor = await FloorService.updateCompanyFloor({
        companyId,
        requestBody,
      });
      commit("UPDATE_FLOOR", floor);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  removeCompanyFloor: async ({ commit, rootState }, requestBody: Floor) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      await FloorService.deleteCompanyFloor({
        companyId,
        requestBody,
      });
      commit("REMOVE_FLOOR", requestBody);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const floor: Module<FloorState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
