
























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/loader/loading.vue";
import {
  CurrentActiveForm,
  InspectionDashboardReport,
  RoomGuardianAngel,
} from "@/api";
import { Pie } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _, { isNumber, isString } from "lodash";
import { dateFormat } from "@/common/date_format";
import moment from "moment";
import OverviewCard from "@/components/card/OverviewCard.vue";
import StartInspection from "@/views/guardian_angel/overview/StartInspection.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartDataLabels,
);

@Component({
  components: {
    Loading,
    Pie,
    OverviewCard,
    StartInspection,
  },
})
export default class Overview extends Vue {
  loading = false;
  pieCounter = 0;
  totalUnattendedRooms = 0;

  chartId = "pie-chart";
  datasetIdKey = "label";
  width = 350;
  height = 350;

  startDate = moment()
    .startOf("week")
    .format("YYYY-MM-DD");
  endDate = moment()
    .endOf("week")
    .format("YYYY-MM-DD");
  formErrors = "";
  showStartDate = false;
  showEndDate = false;

  chartData: {
    labels: string[];
    datasets: { backgroundColor: string[]; data: number[] }[];
  } = {
    labels: ["No data available"],
    datasets: [
      {
        backgroundColor: ["rgb(139,139,139)"],
        data: [100],
      },
    ],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (_: Event, array: { index: number }[]) => {
      if (array.length != 0) {
        const position = array[0].index;
        const activeLabel = this.chartData.labels[position];
        const department = this.summaryReport.chartData.find(
          department =>
            department.department.name.toLowerCase() ===
            activeLabel.toLowerCase(),
        );
        const departmentId = department?.department.id.toString();
        if (departmentId) {
          this.goTo(
            "getDepartmentItemsMissed",
            "Department Unmet Items",
            departmentId,
          );
        }
      }
    },
    plugins: {
      datalabels: {
        color: function(ctx: { dataset: { backgroundColor: string[] } }) {
          const backgroundColors = ctx.dataset.backgroundColor || [];
          const textColors: string[] = [];
          backgroundColors?.forEach(item => {
            if (item && isString(item)) {
              const [r, g, b] = item
                ?.split("(")[1]
                ?.split(")")[0]
                ?.split(",");

              const luminance =
                0.299 * Number(r) + 0.587 * Number(g) + 0.114 * Number(b);
              textColors?.push(luminance > 128 ? "black" : "white");
            }
          });
          return textColors;
        },
        font: {
          weight: "bold",
          size: 16,
        },
        // eslint-disable-next-line
        formatter: function(value: number) {
          return value + "%";
        },
        anchor: "center",
        align: "center",
        display: "auto",
      },
    },
  };

  async created() {
    this.loading = true;
    await this.setfilterDate();
    const filteredDate = {
      beginAt: this.startDate,
      endAt: this.endDate,
    };
    const activeForm = this.$store.dispatch("inspection/fetchActiveForm");
    const summaryReport = this.$store.dispatch(
      "inspection/fetchSummaryReport",
      filteredDate,
    );
    await Promise.all([activeForm, summaryReport]);
    this.loading = false;
  }

  get formattedStartDate() {
    if (this.startDate) {
      this.formatDate(this.startDate, true);
      return dateFormat(this.startDate);
    }
    return "";
  }
  get formattedEndDate() {
    if (this.endDate) {
      this.formatDate(this.endDate, false);
      return dateFormat(this.endDate);
    }
    return "";
  }

  get summaryReport() {
    const report = this.$store.getters[
      "inspection/summaryReport"
    ] as InspectionDashboardReport;
    this.chartData.datasets[0].backgroundColor = ["rgb(139,139,139)"];
    this.chartData.labels = ["No data available"];
    this.chartData.datasets[0].data = [100];
    if (report && report?.chartData) {
      this.chartData.datasets[0].backgroundColor = [];
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];
      report?.chartData.forEach(item => {
        this.chartData.labels.push(item.department.name);
        if (isNumber(item?.total))
          this.chartData.datasets[0].data.push(Math.floor(item.total));
        this.chartData.datasets[0].backgroundColor.push(this.dynamicColors());
      });
    }
    this.pieCounter++;
    this.totalUnattendedRooms =
      report?.unattendedRooms?.unattendedRoom?.length || 0;
    return report;
  }

  get activeForm() {
    return this.$store.getters["inspection/activeForm"] as CurrentActiveForm;
  }

  async setfilterDate() {
    const beginAt = await this.$store.getters["inspection/beginAt"];
    const endAt = await this.$store.getters["inspection/endAt"];
    if (endAt && beginAt) {
      this.startDate = beginAt;
      this.endDate = endAt;
    } else {
      this.startDate = moment()
        .startOf("week")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .endOf("week")
        .format("YYYY-MM-DD");
    }
    if (this.startDate) this.formatDate(this.startDate, true);
    if (this.endDate) this.formatDate(this.endDate, false);
  }

  dynamicColors() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  }

  getGuardianName(guardianAngel: Array<RoomGuardianAngel>): string {
    let name = "";
    if (guardianAngel.length) {
      _.forEach(guardianAngel, item => {
        if (item.primary == 1) name = item.name;
        else name = item.name;
      });
    }
    return name;
  }

  formatDate(date: string, isStartDate: boolean) {
    if (isStartDate) {
      this.$store.dispatch("inspection/setBeginAt", this.startDate);
    } else {
      this.$store.dispatch("inspection/setEndAt", this.endDate);
    }
  }

  async selectedDate() {
    if (this.startDate && this.endDate) {
      const filteredDate = {
        beginAt: this.startDate,
        endAt: this.endDate,
      };

      if (this.startDate < this.endDate) {
        this.formErrors = "";
        this.loading = true;
        await this.$store.dispatch(
          "inspection/fetchSummaryReport",
          filteredDate,
        );
        this.loading = false;
      } else {
        this.formErrors = "start date should not be greater than end date";
      }
    }
  }

  clearFilter() {
    this.startDate = moment()
      .startOf("week")
      .format("YYYY-MM-DD");
    this.endDate = moment()
      .endOf("week")
      .format("YYYY-MM-DD");
    this.formErrors = "";
    this.$store.dispatch("inspection/setBeginAt");
    this.$store.dispatch("inspection/setEndAt");
    const filteredDate = {
      beginAt: this.startDate,
      endAt: this.endDate,
    };
    this.$store.dispatch("inspection/fetchSummaryReport", filteredDate);
  }

  goTo(query: string, title: string, departmentId?: string) {
    this.$router.push({
      path: `/guardian-angel/overview/details`,
      query: {
        summary: query,
        beginAt: this.startDate,
        endAt: this.endDate,
        title,
        departmentId,
      },
    });
  }

  destroyed() {
    if (!this.$route.path.includes("overview")) {
      this.$store.dispatch("inspection/setBeginAt");
      this.$store.dispatch("inspection/setEndAt");
    }
  }
}
