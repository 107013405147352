/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReviewTarfRequestByOwnerRequestBody = {
    properties: {
        processed: {
    type: 'number',
},
        status: {
    type: 'number',
},
    },
};