










































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import UploadDoc from "@/components/EmployeeDocument/UploadDoc.vue";

@Component({
  components: {
    UploadDoc,
  },
})
export default class CamAccess extends Vue {
  @Ref("video") readonly video!: HTMLVideoElement;
  @Prop() readonly name!: string;
  @Prop() readonly id!: number;
  @Prop() readonly document!: boolean;

  mediaStream: MediaStream | null = null;
  capturedImage: File | null = null;
  imageUrl: string | null = null;
  uploadDialog = false;
  uploadKey = 0;

  created() {
    this.captureStream();
  }

  captureStream() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(mediaStream => {
        this.video.srcObject = mediaStream;
        this.video.play();
        this.mediaStream = mediaStream;
      })
      .catch(error => {
        console.log(error);
      });
  }

  captureImage() {
    const canvas = document.createElement("canvas");
    // Set canvas width and height
    canvas.width = this.video.width;
    canvas.height = this.video.height;
    // Draw a new image
    canvas
      .getContext("2d")
      ?.drawImage(this.video, 0, 0, this.video.width, this.video.height);

    const setFile = (dataFile: File) => {
      if (dataFile) {
        if (this.document) {
          this.$emit("capture", dataFile);
          this.closeDialog();
        } else {
          this.capturedImage = dataFile;
          this.uploadKey++;
          this.uploadDialog = !this.uploadDialog;
          this.closeDialog();
        }
      }
      this.mediaStream?.getTracks().forEach(function(track) {
        track.stop();
      });
    };

    this.imageUrl = canvas.toDataURL("image/jpeg");

    // Take a shot
    canvas.toBlob(function(blob) {
      if (blob) {
        const data = new File([blob], "capture.jpg", { type: "image/jpeg" });
        setFile(data);
      }
    }, "image/jpeg");
  }

  closeDialog() {
    this.mediaStream?.getTracks().forEach(function(track) {
      track.stop();
    });
    this.$emit("close");
  }

  closeUpload() {
    this.uploadKey++;
    this.uploadDialog = !this.uploadDialog;
  }
}
