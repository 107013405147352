






























import { Component, Ref, Prop, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { Department } from "@/api";
import { noSpace, requiredString } from "@/tool/validation";

@Component
export default class EditDepartmentModal extends Vue {
  @Ref("form") form!: VForm;

  @Prop(Object) department: Department = this.$props.department;
  name = this.department.name;
  updating = false;
  errorMessage = "";

  requiredTextInput = [requiredString, noSpace];

  close() {
    this.form.resetValidation();
    this.errorMessage = "";
    this.name = this.department.name;
    this.$emit("close");
  }

  isSame = (v: string) =>
    v.toLowerCase().trim() !== this.department?.name?.toLowerCase().trim() ||
    "Same as previous";

  async save() {
    const isValid = this.form.validate();
    const isSameValid = this.isSame(this.name);
    if (isSameValid === "Same as previous") {
      this.errorMessage = isSameValid;
      return;
    }
    if (isValid) {
      try {
        this.updating = true;
        await this.$store.dispatch("departmentAdmin/updateDepartment", {
          ...this.department,
          name: this.name.trim(),
        } as Department);
        this.updating = false;
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    }
  }
}
