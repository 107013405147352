

































import { Component, Vue } from "vue-property-decorator";

@Component
export default class OptionTemplateDialog extends Vue {
  blank() {
    this.$emit("blank");
  }

  template() {
    this.$emit("template");
  }

  close() {
    this.$emit("close");
  }
}
