/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentActiveForm } from '../models/CurrentActiveForm';
import type { Form } from '../models/Form';
import type { Forms } from '../models/Forms';
import type { Question } from '../models/Question';
import type { Questions } from '../models/Questions';
import type { ResponseForm } from '../models/ResponseForm';
import type { ResponseQuestion } from '../models/ResponseQuestion';
import { request as __request } from '../core/request';

export class QuestionService {

    /**
     * Get inspection form list
     * @returns Forms form list of a company
     * @throws ApiError
     */
    public static async getForms({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Forms> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/form`,
        });
        return result.body;
    }

    /**
     * Add an inspection form
     * @returns ResponseForm added inspection form
     * @throws ApiError
     */
    public static async addForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Form,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update an inspection form
     * @returns ResponseForm updated inspection form
     * @throws ApiError
     */
    public static async updateForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: ResponseForm,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete an inspection form
     * @returns ResponseForm deleted inspection form
     * @throws ApiError
     */
    public static async deleteForm({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: ResponseForm,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/form`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get inspection active form with questions
     * @returns CurrentActiveForm active form of a company with questions
     * @throws ApiError
     */
    public static async getActiveForm({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<CurrentActiveForm> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/active-form`,
        });
        return result.body;
    }

    /**
     * Get questions list
     * @returns Questions question list for inspection
     * @throws ApiError
     */
    public static async getQuestions({
companyId,
formId,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
}): Promise<Questions> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/form/${formId}/question`,
        });
        return result.body;
    }

    /**
     * Add a question for inspection
     * @returns ResponseQuestion added question for the inspection
     * @throws ApiError
     */
    public static async addQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: Question,
}): Promise<ResponseQuestion> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update inspection question
     * @returns ResponseQuestion inspection question updated
     * @throws ApiError
     */
    public static async updateQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: ResponseQuestion,
}): Promise<ResponseQuestion> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete inspection question
     * @returns ResponseQuestion inspection question deleted
     * @throws ApiError
     */
    public static async deleteQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: ResponseQuestion,
}): Promise<ResponseQuestion> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/form/${formId}/question`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Sort questions order ranking
     * @returns Questions added question for the inspection
     * @throws ApiError
     */
    public static async sortQuestion({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: Questions,
}): Promise<Questions> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/form/${formId}/question/sort`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Copy other forms
     * @returns ResponseForm form copied
     * @throws ApiError
     */
    public static async copyForm({
companyId,
formId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The formId of the company **/
formId: number,
requestBody: Form,
}): Promise<ResponseForm> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/form/${formId}/copy`,
            body: requestBody,
        });
        return result.body;
    }

}