






































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import * as validation from "@/tool/validation";
import { DepartmentCategory } from "@/api";

@Component
export default class AddDepartmentModal extends Vue {
  @Ref("form") form!: VForm;
  @Prop(Number) departmentId!: number;
  @Prop(String) title!: string;
  @Prop(Object) cat!: DepartmentCategory;

  category = this.cat?.category || "";
  loading = false;
  requiredTextInput = [
    validation.requiredString,
    validation.noSpace,
    (v: string) => validation.maxLen(v, 50),
  ];
  isUpdate = this.cat ? true : false;

  close() {
    this.form.reset();
    this.$emit("close");
  }

  async save() {
    const isValid = this.form.validate();
    if (isValid) {
      try {
        this.loading = true;
        await this.$store.dispatch("departmentAdmin/addDepartmentCategory", {
          departmentId: this.departmentId,
          cat: this.category.trim(),
        });
        this.loading = false;
        this.form.reset();
        this.$emit("save");
      } catch (e) {
        console.log(e);
      }
    }
  }

  async update() {
    const isValid = this.form.validate();
    if (isValid) {
      this.loading = true;
      await this.$store.dispatch("departmentAdmin/updateDepartmentCategory", {
        catId: this.cat.id,
        cat: this.category,
        departmentId: this.departmentId,
      });
      this.loading = false;
      this.form.reset;
      this.$emit("save");
    }
  }
}
