export const printPage = (styles: string, content: string) => {
  // eslint-disable-next-line
  const frame1: any = document.createElement("iframe");
  // eslint-disable-next-line
  const wndw: any = window;
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);
  const frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument?.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(`<html><head>${styles}<title>DIV Contents</title>`);
  frameDoc.document.write("</head><body>");
  frameDoc.document.write(content);
  frameDoc.document.write("</body></html>");
  frameDoc.document.close();
  setTimeout(function() {
    wndw.frames["frame1"].focus();
    wndw.frames["frame1"].print();
    document.body.removeChild(frame1);
  }, 500);
};
