















































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import * as validation from "@/tool/validation";

@Component
export default class AddDepartmentModal extends Vue {
  @Ref("form") form!: VForm;
  @Prop(Boolean) loading!: boolean;
  @Prop(Boolean) saved!: boolean;
  @Watch("saved", { immediate: true, deep: true })
  onSaveChanged(val: boolean) {
    if (val) {
      this.form.reset();
      this.$emit("saved");
    }
  }

  name = "";
  rules = [validation.requiredString, validation.noSpace];

  close() {
    this.form.reset();
    this.$emit("close");
  }

  save() {
    if (this.form.validate()) {
      this.$emit("save", this.name);
    }
  }
}
