











































import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
import Loading from "@/components/loader/loading.vue";

@Component({
  components: {
    Loading,
  },
})
export default class InactiveUser extends Vue {
  isDataLoading = false;

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("superAdmin/fetchInactiveList");
    this.isDataLoading = false;
  }

  get inactiveEmployees() {
    const list = this.$store.getters["superAdmin/inactiveEmployees"];
    if (list) {
      const alphabetized = list.employees;
      return _.orderBy(alphabetized, employee =>
        employee.firstName.toLowerCase(),
      );
    } else {
      return [];
    }
  }

  destroyed() {
    this.$store.dispatch("superAdmin/clearInactiveList");
  }
}
