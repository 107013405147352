

















































































import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/loader/loading.vue";
import PersonalChat from "@/views/chat/PersonalChat.vue";
import GroupChat from "@/views/chat/GroupChat.vue";
import { isAdmin } from "@/common/role_utils";
import AddGroupForm from "@/views/chat/AddGroupForm.vue";

@Component({
  components: {
    Loading,
    PersonalChat,
    GroupChat,
    AddGroupForm,
  },
})
export default class ChatListComponent extends Vue {
  isDataLoading = false;
  showForm = false;
  tab = 0;
  counter = 0;
  tabItems = [
    {
      icon: "mdi-account",
      value: "personal",
      content: "PersonalChat",
    },
    {
      icon: "mdi-account-group",
      value: "group",
      content: "GroupChat",
    },
  ];

  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("chat/fetchChatList");
    await this.$store.dispatch("chat/fetchGroupList");
    this.isDataLoading = false;

    if (this.$route.query.isGroup) {
      this.tab = 1;
    }
  }

  get search() {
    return this.$store.getters["chat/chatListSearch"];
  }

  set search(val: string) {
    let search = "";
    if (val.length) {
      search = val;
    }
    this.$store.dispatch("chat/setChatListSearch", search);
  }

  clearSearch() {
    this.search = "";
  }

  removeSelectedEmployee() {
    this.$store.dispatch("chat/setSelectedEmployee", {
      employee: null,
      force: true,
    });
    this.$store.dispatch("chat/setSelectedGroup", {
      group: null,
      force: true,
    });
    if (this.$route.params.id) this.$router.replace("/chat");
  }

  get isAdmin() {
    return isAdmin();
  }

  get isSuperAdmin() {
    return this.$store.getters["isSuperAdmin"];
  }

  createGroup() {
    alert("create group");
  }
}
