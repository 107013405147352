



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OptionSurvey extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly optionOne!: string;
  @Prop() readonly optionTwo!: string;

  admission() {
    this.$emit("admission");
  }

  discharge() {
    this.$emit("discharge");
  }

  close() {
    this.$emit("close");
  }
}
