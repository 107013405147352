/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TarfRequestRequestBody = {
    properties: {
        reasonId: {
    type: 'array',
    contains: {
    type: 'number',
},
    isRequired: true,
},
        adjustmentDateTime: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
        timeIn: {
    type: 'string',
    isRequired: true,
    format: 'time',
},
        timeOut: {
    type: 'string',
    isRequired: true,
    format: 'time',
},
        shiftType: {
    type: 'number',
    isRequired: true,
},
        requesterComment: {
    type: 'string',
},
        hoursWorked: {
    type: 'string',
    isRequired: true,
    format: 'time',
},
        mealTimeIn: {
    type: 'string',
    format: 'time',
},
        mealTimeOut: {
    type: 'string',
    format: 'time',
},
    },
};