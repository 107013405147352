









































import { Component, Prop, Vue } from "vue-property-decorator";
import NavigationItem from "./NavigationItem.vue";

@Component({
  components: { NavigationItem },
})
export default class ExpensionNavigationItem extends Vue {
  @Prop() icon!: string;
  @Prop() label!: string;
  @Prop() subItems!: {
    icon: string;
    label: string;
    rootPath: string;
    notice?: string;
  }[];
}
