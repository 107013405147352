







































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { CategoryBudget, Census, CompanyBudgets, PostBudget } from "@/api";
import BudgetForm from "./BudgetForm.vue";
import Loading from "@/components/loader/loading.vue";
import ConfirmationForList from "@/components/confirmationModule/ConfirmationForList.vue";
import {
  floatOnly,
  noSpace,
  numberOnly,
  rejectZero,
  requiredString,
  requriedNumber,
} from "@/tool/validation";
import moment from "moment";
import _ from "lodash";
import { VForm } from "@/common/vuetify_types";
import { checkSelfRole } from "@/common/role_utils";
import { dateCheckifExist } from "@/common/date_format";

@Component({
  components: {
    BudgetForm,
    Loading,
    ConfirmationForList,
  },
})
export default class CompanyBudget extends Vue {
  @Ref("form") form!: VForm;
  @Watch("companyBudget", { immediate: true, deep: true })
  // eslint-disable-next-line
  onMenuChange(newVal: CompanyBudgets[]) {
    if (newVal?.length) {
      this.allBudgets = JSON.parse(JSON.stringify(newVal));
    }
  }

  allBudgets: CompanyBudgets[] = [];
  loadingBudgets = false;
  isValid = false;
  saving = false;
  savingCensus = false;
  currentDate = moment().format("MMMM, YYYY");
  search = "";
  census = 0;
  reqNum = [requriedNumber, numberOnly, rejectZero];
  isCensus = false;
  confirmSaving = false;
  payload: PostBudget = [];
  zeroPpdCats: string[] = [];

  async created() {
    if (!checkSelfRole("owner"))
      this.$router.push({ path: "/expense-report/admin" });
    this.loadingBudgets = true;
    const { beginAt, endAt } = dateCheckifExist();
    await this.$store.dispatch("categoryBudget/getCensus", { beginAt, endAt });
    await this.$store.dispatch("categoryBudget/getCompanyBudget");
    this.loadingBudgets = false;
  }

  clearSearch() {
    this.search = "";
  }

  get companyBudget() {
    const search = !this.search ? "" : this.search;
    const budgets = this.$store.getters[
      "categoryBudget/companyBudget"
    ] as CompanyBudgets[];

    const list = _.sortBy(
      _.filter(budgets, department =>
        _.includes(`${department.name.toLowerCase()}`, search.toLowerCase()),
      ),
      [
        (department: CompanyBudgets) =>
          `${department.name.trim().toUpperCase()}`,
      ],
    );
    return Object.values(list);
  }

  get companyCensus() {
    const companyCensus = this.$store.getters[
      "categoryBudget/census"
    ] as Census;
    this.census = companyCensus?.census || 0;
    return companyCensus;
  }

  updateAllBudgets({
    cat,
    dindex,
    cindex,
  }: {
    cat: CategoryBudget;
    dindex: number;
    cindex: number;
  }) {
    this.allBudgets[dindex].categories[cindex] = cat;
  }

  censusError() {
    this.form.validate();
  }

  resetAll() {
    this.allBudgets = JSON.parse(JSON.stringify(this.companyBudget));
    this.census = this.companyCensus?.census || 0;
  }

  validateAll(budgets: PostBudget) {
    let valid = true;
    budgets.map(bud => {
      const validPpd =
        rejectZero(bud.ppd) && floatOnly(bud.ppd) && bud.ppd != 0;

      const validCensus =
        requriedNumber(this.census) &&
        numberOnly(this.census) &&
        rejectZero(this.census);

      const validGlCode =
        requiredString(bud.glCode) &&
        noSpace(bud.glCode) &&
        (bud.glCode !== "" || `required`);

      const ofType =
        typeof validPpd === "string" ||
        typeof validCensus === "string" ||
        typeof validGlCode === "string";

      if (ofType) valid = false;
      return bud;
    });
    return valid;
  }

  async saveCensus() {
    if (this.companyCensus?.census !== this.census) {
      if (!this.form.validate()) return;
      this.savingCensus = true;
      await this.$store.dispatch("categoryBudget/addCensus", {
        census: parseInt(this.census.toString()),
      } as Census);
      this.savingCensus = false;
    }
  }

  confirmationForZero(isCensus: boolean) {
    const payload: PostBudget = [];
    this.zeroPpdCats = [];

    this.allBudgets.map(dept => {
      dept.categories.map(cat => {
        const ppd = parseFloat(cat.ppd?.toString());
        if (cat.ppd == 0) {
          this.zeroPpdCats.push(`${cat.category} - Budget: ${cat.budget}`);
        }
        payload.push({
          balance: cat.balance,
          budget: cat.budget,
          catId: cat.category_id,
          glCode: cat.glCode,
          ppd: ppd,
        });
      });
      return dept;
    });

    if (!payload?.length) {
      this.saving = false;
      this.$store.dispatch("alert/error", "There is no budget");
      return;
    }

    this.isCensus = isCensus;
    this.payload = payload;

    if (this.zeroPpdCats.length) {
      this.confirmSaving = true;
      return;
    }
    this.saveAll();
  }

  async saveAll() {
    this.confirmSaving = false;
    this.isValid = false;
    if (this.isCensus) {
      const message =
        "The company census updated and budget recalculated successfully";

      await this.$store.dispatch("categoryBudget/addBudgetToCompanyCategory", {
        requestBody: this.payload,
        message,
      });
    } else {
      const message = "The company all categories budget added successfully";

      if (this.validateAll(this.payload)) {
        this.saving = true;
        this.saveCensus();

        await this.$store.dispatch(
          "categoryBudget/addBudgetToCompanyCategory",
          {
            requestBody: this.payload,
            message,
          },
        );
        this.saving = false;
      } else {
        this.$store.dispatch("alert/error", "Validation Failed");
        this.isValid = true;
        this.form.validate();
      }
    }
    this.isCensus = false;
    this.payload = [];
    this.zeroPpdCats = [];
  }
}
