/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ChatList = {
    properties: {
        employeeId: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        profile: {
    type: 'string',
},
        lastMessage: {
    type: 'string',
},
        readAt: {
    type: 'string',
},
        sentAt: {
    type: 'string',
},
    },
};