import { AnniversaryListing } from "@/api";
import _ from "lodash";
import moment from "moment";

const offsetMin = (paramDate: string | undefined) => {
  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  if (paramDate) {
    const anniversary = new Date(paramDate);
    const hire = new Date(
      anniversary.getTime() + anniversary.getTimezoneOffset() * 60000,
    );
    return hire.toISOString();
  }
};

const diffYearDate = (paramDate: string | undefined) => {
  if (paramDate) {
    const currentDate = moment();
    const offsetDate = offsetMin(paramDate);
    const hired = moment(offsetDate);
    const day = moment().format("DD");
    const month = moment().format("MMMM");
    // eslint-disable-next-line
    const hireDay = new Date(offsetDate!).getDate().toString();
    // eslint-disable-next-line
    const hireMonth = moment(new Date(offsetDate!).getTime()).format("MMMM");
    const duration = moment.duration(currentDate.diff(hired));
    if (month === hireMonth) {
      if (Number(day) > Number(hireDay)) {
        return duration.years() + 1;
      }
    }
    if (duration.months() >= 11) {
      return duration.years() + 1;
    }
    return duration.years();
  }
};

const isToday = (paramDate: string | undefined) => {
  if (paramDate) {
    const day = moment().format("DD");
    const month = moment().format("MM");
    const paramDay = paramDate.split("-")[2];
    const paramMonth = paramDate.split("-")[1];
    if (day === paramDay && month === paramMonth) {
      return true;
    } else return false;
  }
  return false;
};

export const list = (dateList: AnniversaryListing, isAnniversary: boolean) => {
  let lists;
  let pastDayOfMonthOrder = 0;
  let pastDayOfMonth;
  // eslint-disable-next-line
  const pastDayOfMonthEmployees: any[] = [];
  if (dateList) {
    const orderNumber = (paramDate: string | undefined) => {
      if (paramDate) {
        const day = moment().format("DD");
        const month = moment().format("MM");
        const paramDay = paramDate.split("-")[2];
        const paramMonth = paramDate.split("-")[1];
        if (month !== paramMonth) return Number(paramDay);
        if (Number(day) <= Number(paramDay)) {
          return Number(paramDay) - Number(day) + 1;
        } else {
          pastDayOfMonthOrder -= Number(paramDay);
          return pastDayOfMonthOrder;
          //   return 32 - Math.abs(Number(paramDay) - Number(day));
        }
      }
    };

    lists = _.map(dateList.anniversaries, anniversary => {
      return {
        month: moment(
          isAnniversary
            ? anniversary.employees[0].hireDate
            : anniversary.employees[0].birthday,
        ).format("MMMM"),
        employees: anniversary.employees.map(employee => {
          const day = isAnniversary
            ? employee.hireDate?.split("-")[2]
            : employee.birthday?.split("-")[2];
          return {
            ...employee,
            day: day,
            highlight: isToday(
              isAnniversary ? employee.hireDate : employee.birthday,
            ),
            orderWith: orderNumber(
              isAnniversary ? employee.hireDate : employee.birthday,
            ),
            yearsOfService: diffYearDate(employee.hireDate),
          };
        }),
      };
    });
    if (lists) {
      lists = _.map(lists, anni => {
        anni.employees.forEach(employee => {
          if (!employee.orderWith) return;
          if (employee.orderWith < 0) {
            pastDayOfMonth = anni.month;
            pastDayOfMonthEmployees.push(employee);
          }
        });
        return {
          ...anni,
          employees: _.orderBy(
            _.filter(anni.employees, employee => {
              if (!employee.orderWith) return false;
              if (employee.orderWith >= 0) return true;
            }),
            ["orderWith"],
            ["asc"],
          ),
        };
      });
      if (pastDayOfMonth) {
        if (pastDayOfMonthEmployees) {
          lists.push({
            month: pastDayOfMonth,
            employees: pastDayOfMonthEmployees,
          });
        }
      }
    }
  }
  return lists?.filter(list => list.employees.length) || [];
};
