















import { Component, Vue } from "vue-property-decorator";

@Component
export default class EmployeeDocumentLayout extends Vue {
  // TODO: remove after socket.io implemented
  intervalSetup: null | ReturnType<typeof setTimeout> = null;

  created() {
    this.fetchCount();
    // TODO: remove after socket.io implemented
    this.intervalSetup = setInterval(this.fetchCount, 180000);
  }

  // TODO: remove after socket.io implemented
  destroyed() {
    if (this.intervalSetup) clearInterval(this.intervalSetup);
  }

  async fetchCount() {
    await this.$store.dispatch("employeeDocuments/getSelfDocumentCount");
  }

  get isList() {
    return this.$route.params.documentId;
  }
}
