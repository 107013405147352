















































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { CreateEmployeeRequestBody, Department } from "@/api";
import { VForm } from "@/common/vuetify_types";
import AddBulkEmployee from "@/views/company_directory/AddBulkEmployee.vue";
import { dateFormat } from "@/common/date_format";
import moment from "moment";
import shifts from "@/common/shift_type";
import { subRoles } from "@/tool/subRoles";
import { checkSelfRole, checkSelfSubrole } from "@/common/role_utils";
import * as validation from "@/tool/validation";

@Component({
  components: {
    AddBulkEmployee,
  },
})
export default class AddEmployee extends Vue {
  @Ref("addEmployeeForm") readonly form!: VForm;

  isDeaprtmentSelected = false;
  isDirectory = true;
  importEmployee = false;
  valid = false;
  firstname = "";
  lastname = "";
  email = "";
  shift = null;
  shifts = [...shifts];
  employeeID = "";
  departmentID = 0;
  showDate = false;
  hire_date = "";
  birthPicker = false;
  birthday = "";
  formattedhireDate = "";
  formattedBirthday = "";
  formErrors = "";
  phone = "";
  role = "";
  title = 0;
  roles: string[] = [];
  loading = false;
  subRoles = subRoles;
  subRole: number | undefined = 1;
  maxDate = new Date().toISOString().substr(0, 10);
  width = this.$vuetify.breakpoint.smAndDown ? screen.width + "px" : 350 + "px";
  fnameRules = [
    validation.requiredString,
    validation.noSpace,
    (v: string) => validation.maxLen(v, 20),
  ];
  lnameRules = [
    validation.requiredString,
    validation.noSpace,
    (v: string) => validation.maxLen(v, 20),
  ];
  employeeIDRules = [
    validation.requiredString,
    validation.noSpace,
    validation.numberOnly,
  ];
  emailRules = [validation.requiredString, validation.emailValid];
  phoneRules = [
    validation.requiredString,
    validation.numberOnly,
    validation.noSpace,
    (v: string) => validation.maxLenInt(v, 10),
  ];
  required_select = (v: string) =>
    (v !== "" && v !== undefined && v != "0") || "Required";
  date_required = validation.requiredSelect;
  requiredShift = [validation.requriedNumber];

  async created() {
    await this.$store.dispatch("company/loadDepartments");
    if (this.$route.params?.deptId && this.$route.params?.titleId) {
      this.isDirectory = false;
      this.departmentID = parseInt(this.$route.params.deptId);
      this.title = parseInt(this.$route.params.titleId);
      this.getDepartmentTitle();
    }
  }

  get departments(): Department[] {
    return this.$store.getters["company/departments"]?.map(
      (item: Department) => {
        return {
          ...item,
          header: undefined,
        };
      },
    );
  }

  get roleItems() {
    if (checkSelfRole("owner") || this.isSuperAdmin) {
      this.roles = ["employee", "admin", "owner"];
      return this.roles;
    } else {
      this.roles = ["employee", "admin"];
      return this.roles;
    }
  }

  get isSuperAdmin() {
    return this.$store.getters["isSuperAdmin"];
  }

  get isSalaryOwner() {
    return (checkSelfRole("owner") && checkSelfSubrole()) || this.isSuperAdmin;
  }

  formatDate(date: string, isBirthday: boolean) {
    if (isBirthday) this.formattedBirthday = dateFormat(date);
    else this.formattedhireDate = dateFormat(date);
  }
  selectedDate() {
    if (this.birthday && this.hire_date) {
      if (this.birthday < this.hire_date) {
        const indate = this.birthday.substr(0, 10);
        const hired = this.hire_date.substr(0, 10);
        const birthday = moment(indate);
        const currentdate = moment(hired);
        const duration = moment.duration(currentdate.diff(birthday));
        if (duration.years() > 13) {
          this.formErrors = "";
        } else {
          this.formErrors = "Must be 14 years old at least";
        }
      } else {
        this.formErrors = "birthday should not be greater than hire date";
      }
    }
  }
  async createEmployee() {
    const isValid = this.form.validate();
    if (isValid && this.departmentID) {
      const request = {
        firstName: this.firstname.trim(),
        lastName: this.lastname.trim(),
        primaryDepartmentId: this.departmentID,
        companyRole: this.role,
        titleId: this.title,
        birthday: new Date(this.birthday).toISOString(),
        hireDate: new Date(this.hire_date).toISOString(),
        phone: this.phone,
        email: this.email.trim(),
        // eslint-disable-next-line @typescript-eslint/camelcase
        externalEmployeeId: this.employeeID.trim(),
        subRole: this.subRole,
        shift: Number(this.shift),
      } as CreateEmployeeRequestBody;
      try {
        this.loading = true;

        const result = await this.$store.dispatch(
          "directory/createEmployee",
          request,
        );
        this.loading = false;
        if (result) {
          this.form.reset();
          this.email = "";
          this.employeeID = "";
          this.$emit("save");
          if (this.$route.params.titleName)
            this.$router.push(
              `/departments/${result.primaryDepartmentId}/title/${result.title}/${result.titleId}/employees/${result.id}`,
            );
          else this.$router.push(`/company-directory/profile/${result.id}`);
        }
      } catch (e) {
        console.log(`${e}`);
      }
    }
  }

  bulkImport() {
    this.importEmployee = true;
  }

  close() {
    this.form.reset();
    this.email = "";
    this.employeeID = "";
    this.$emit("close");
  }

  closeImport() {
    this.importEmployee = false;
    this.close();
  }

  get titleList() {
    const list = this.$store.getters["departmentAdmin/departmentTitle"];
    if (list) {
      return list;
    } else {
      return [];
    }
  }

  async getDepartmentTitle() {
    this.isDeaprtmentSelected = false;
    await this.$store.dispatch("departmentAdmin/getTitle", this.departmentID);
    this.isDeaprtmentSelected = true;
  }
}
