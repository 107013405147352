

















import { Vue, Component } from "vue-property-decorator";
import LogLayoutMobile from "@/views/admin/event_metrics/log_event/LogLayoutMobile.vue";

@Component({
  components: {
    LogLayoutMobile,
  },
})
export default class LogLayout extends Vue {
  get isLogEvent() {
    return this.$route.params.id && this.$route.path.includes("logevent");
  }
}
