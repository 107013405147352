/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ResponseSurveyData = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        questionId: {
    type: 'number',
    isRequired: true,
},
        question: {
    type: 'string',
    isRequired: true,
},
        order: {
    type: 'number',
    isRequired: true,
},
        comment: {
    type: 'string',
    isRequired: true,
},
        feedback: {
    type: 'string',
    isRequired: true,
},
        rating: {
    type: 'number',
    isRequired: true,
},
    },
};