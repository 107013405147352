
















































import { ChatList, ChatLists } from "@/api";
import { selfData } from "@/common/role_utils";
import _ from "lodash";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class PersonalChat extends Vue {
  get search() {
    return this.$store.getters["chat/chatListSearch"];
  }

  get employeeId() {
    if (this.$route.params.id) {
      return parseInt(this.$route.params.id);
    } else {
      return 0;
    }
  }

  get employees(): ChatLists {
    const employees = this.$store.state.chat.chatList as ChatLists;
    const list = _.filter(
      employees ?? [],
      (employee: ChatList) =>
        _.includes(employee.name.toLowerCase(), this.search.toLowerCase()) &&
        employee.employeeId != selfData().id,
    );

    if (this.employeeId) {
      const selectedEmp = _.find(
        list,
        (emp: ChatList) => emp?.employeeId == this.employeeId,
      );
      if (selectedEmp) {
        this.$store.dispatch("chat/setSelectedEmployee", {
          employee: selectedEmp,
          force: false,
        });
      }
    }

    return Object.values(list);
  }

  selectEmployee(employee: ChatList) {
    this.$store.dispatch("chat/setSelectedEmployee", { employee, force: true });
    this.$router.push({
      path: `/chat/${employee.employeeId}`,
    });
  }

  getEmployeeLetter(name: string) {
    if (name) {
      const [fname, lname] = name.split(" ");
      return `${fname[0]}${lname[0]}`;
    }
    return "";
  }
}
