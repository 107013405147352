/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddRoom } from '../models/AddRoom';
import type { Room } from '../models/Room';
import type { Rooms } from '../models/Rooms';
import { request as __request } from '../core/request';

export class RoomService {

    /**
     * Get a company floor rooms
     * @returns Rooms Company Floor Rooms
     * @throws ApiError
     */
    public static async getFloorRooms({
companyId,
floorId,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
}): Promise<Rooms> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/floor/${floorId}/room`,
        });
        return result.body;
    }

    /**
     * Add a company floor
     * @returns Room added Company Floor a Room
     * @throws ApiError
     */
    public static async addFloorRoom({
companyId,
floorId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
requestBody: AddRoom,
}): Promise<Room> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/floor/${floorId}/room`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update a company floor room
     * @returns Room Company Floor Room updated
     * @throws ApiError
     */
    public static async updateFloorRoom({
companyId,
floorId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
requestBody: Room,
}): Promise<Room> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/floor/${floorId}/room`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete a company floor room
     * @returns any Company Floor Room deleted successfully
     * @throws ApiError
     */
    public static async deleteFloorRoom({
companyId,
floorId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
requestBody: Room,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/floor/${floorId}/room`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a company rooms
     * @returns Rooms Company Rooms
     * @throws ApiError
     */
    public static async getCompanyRooms({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Rooms> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/room`,
        });
        return result.body;
    }

}