/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExpirationNotification } from '../models/ExpirationNotification';
import type { NotifyOnEmployeeDocumentResponse } from '../models/NotifyOnEmployeeDocumentResponse';
import { request as __request } from '../core/request';

export class EmployeeDocumentNotificationService {

    /**
     * get employee document expiration notification
     * @returns ExpirationNotification The notification list
     * @throws ApiError
     */
    public static async getExpirationNotificationOnDashboard({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<ExpirationNotification> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}/employee-document/notification`,
        });
        return result.body;
    }

    /**
     * Notify for expired or warning for employee document oon employee document list notify_flag is equals to 0=not-expired,1=expired-after-2-week,2=expired-after-1-week,3=expired
     * @returns NotifyOnEmployeeDocumentResponse notification msg
     * @throws ApiError
     */
    public static async getEmployeeDocumentExpirationNotificationByDepartmentDocument({
companyId,
departmentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
}): Promise<NotifyOnEmployeeDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/department/${departmentId}/employee-document/notify`,
        });
        return result.body;
    }

    /**
     * company document expiration notification only for owner and admin employee document list notify_flag is equals to 0=not-expired,1=expired-after-2-week,2=expired-after-1-week,3=expired
     * @returns NotifyOnEmployeeDocumentResponse notification msg
     * @throws ApiError
     */
    public static async expirationNotificationForAdminAndOwner({
companyId,
departmentId,
role,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** role of user **/
role?: string,
}): Promise<NotifyOnEmployeeDocumentResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/department/${departmentId}/company-documents/notify`,
            query: {
                'role': role,
            },
        });
        return result.body;
    }

    /**
     * company document expiration notification only for owner and admin on dashboard
     * @returns ExpirationNotification notification msg
     * @throws ApiError
     */
    public static async expirationNotificationForAdminAndOwnerOnDashboard({
companyId,
departmentId,
role,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** role of user **/
role?: string,
}): Promise<ExpirationNotification> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/department/${departmentId}/company-documents/dashboard/notify`,
            query: {
                'role': role,
            },
        });
        return result.body;
    }

}