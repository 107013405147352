






























import { Component, Ref, Prop, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { noSpace, requiredString } from "@/tool/validation";
import { AddBed, Bed } from "@/api";

@Component
export default class BedFormModal extends Vue {
  @Ref("form") form!: VForm;

  @Prop(String) title!: string;
  @Prop(String) action!: string;
  @Prop(Object) bed!: Bed;
  @Prop(Boolean) loader!: boolean;

  name = this.bed?.name ? this.bed?.name : "";
  errorMessage = "";
  requiredTextInput = [requiredString, noSpace];

  close() {
    this.form.resetValidation();
    this.errorMessage = "";
    this.$emit("close");
  }

  validate() {
    this.errorMessage = "";
    let same = false;
    const isValid = this.form.validate();
    if (!isValid) same = true;
    if (this.name?.trim().length == 0) {
      this.errorMessage = "Required";
      same = true;
    }
    if (this.name?.trim() === this.bed?.name.trim()) {
      this.errorMessage = "Title is same as previous";
      same = true;
    }
    return same;
  }

  async save() {
    if (!this.validate()) {
      let requestBody;
      if (this.title === "Add") {
        requestBody = {
          name: this.name,
        } as AddBed;
      } else {
        requestBody = {
          id: this.bed?.id,
          name: this.name,
          roomId: this.bed?.roomId,
        } as Bed;
      }
      this.$emit("save", requestBody);
    }
  }
}
