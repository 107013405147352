



































import { Component, Vue } from "vue-property-decorator";
import * as roleUtils from "@/common/role_utils";
import { TarfStatusDetail } from "@/api";
import _, { parseInt } from "lodash";
import { orderStatusList, OrderStatus } from "@/tool/orderStatuses";
import statusId from "@/tool/tarfStatus";
import Loading from "@/components/loader/loading.vue";

@Component({
  watch: {
    async $route() {
      if (!this.$route.path.includes("tarfs")) {
        this.$data.loading = true;
        const employeeId = parseInt(this.$route.params.id);
        await this.$store.dispatch(
          "tarf/fetchEmployeeTarfStatusesAdmin",
          employeeId,
        );
        this.$data.loading = false;
      }
    },
  },
  components: {
    Loading,
  },
})
export default class TarfHistoryStatuses extends Vue {
  orderTarfStatus!: OrderStatus[];
  loading = false;

  async created() {
    this.loading = true;
    const employeeId = parseInt(this.$route.params.id);
    await this.$store.dispatch(
      "tarf/fetchEmployeeTarfStatusesAdmin",
      employeeId,
    );
    this.loading = false;
    this.orderTarfStatus = _.concat(orderStatusList);
  }

  get tarfStatuses() {
    const statuses = this.$store.getters["tarf/employeeTarfStatusAdmin"];
    return statuses?.statuses ?? [];
  }

  getStatus(id: number) {
    const status = _.find(
      this.tarfStatuses,
      (status: TarfStatusDetail) => status.id == id,
    );
    return status?.count || 0;
  }

  getColor(id: number) {
    if (id === statusId.new) return "primary";
    else if (id === statusId.excused) return "green";
    else if (id === statusId.unexcused) return "red";
    else if (id === statusId.deny) return "pink";
    else if (id === statusId.resubmit) return "purple ";
    else return "grey";
  }

  goTo(status: string) {
    const id = this.$route.params.id;
    if (!id) return;
    if (status.includes("Corrected")) status = "Resubmitted";
    else if (status.includes("Excused")) status = "Excused";
    else if (status.includes("Unexcused")) status = "Unexcused";
    const path = `/admin/tarf-history/employee/${id}/tarfs/${status}`;
    this.$router.push({ path });
  }

  get activeMenu() {
    if (this.$route.path.includes("new")) return statusId.new;
    else if (this.$route.params.status === "excused") return statusId.excused;
    else if (this.$route.params.status === "unexcused")
      return statusId.unexcused;
    else if (this.$route.path.includes("processed")) return 3;
    else if (this.$route.path.includes("resubmit")) return statusId.resubmit;
    else if (this.$route.path.includes("denied")) return statusId.deny;
    else if (this.$route.path.includes("supervisor/form")) return "tarf";
    else return "";
  }

  get isAdmin() {
    return roleUtils.isAdmin();
  }

  get isSuperAdmin() {
    return this.$store.getters["isSuperAdmin"];
  }
}
