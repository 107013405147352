import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import _, { isNumber } from "lodash";
import { RootState } from "@/store/types";
import {
  EventMetric,
  CreateDepartmentRequestBody,
  Department,
  DepartmentsService,
  Employee,
  EventMetricsService,
  AddDepartmentPhoneNumberRequestBody,
  DepartmentService,
  AddDepartmentTitleRequestBody,
  DepartmentTitle,
  DepartmentDocumentService,
  DocumentNameService,
  CreateDocumentRequest,
  DocumentListResponse,
  DepartmentDocumentListResponse,
  DepartmentCategory,
  AddDepartmentCategoryRequestBody,
} from "@/api";
import moment from "moment";

export type AdminDepartmentState = {
  departments: Department[] | null;
  archivedDepartments: Department[] | null;
  departmentEmployees?: Employee[] | null;
  selectedDepartment: Department | null;
  departmentMetrics: EventMetric[] | null;
  departmentTitles: DepartmentTitle[] | null;
  titleDepartment: DepartmentTitle[] | null;
  documents: DocumentListResponse | null;
  departmentDocuments: DepartmentDocumentListResponse | null;
  categories: DepartmentCategory[] | null;
};

const state: AdminDepartmentState = {
  departments: null,
  archivedDepartments: null,
  departmentEmployees: null,
  selectedDepartment: null,
  departmentMetrics: null,
  departmentTitles: null,
  titleDepartment: null,
  documents: null,
  departmentDocuments: null,
  categories: null,
};

const getters: GetterTree<AdminDepartmentState, RootState> = {
  departments(state) {
    return state.departments;
  },
  archivedDepartments(state) {
    return state.archivedDepartments;
  },
  selectedDepartment(state) {
    return state.selectedDepartment;
  },
  departmentEmployees(state) {
    return state.departmentEmployees;
  },
  departmentMetrics(state) {
    return state.departmentMetrics;
  },
  departmentTitle(state) {
    return state.departmentTitles;
  },
  titleDepartment(state) {
    return state.titleDepartment;
  },
  documents(state) {
    return state.documents;
  },
  departmentDocuments(state) {
    return state.departmentDocuments;
  },
  categories: state => state.categories,
};

const mutations: MutationTree<AdminDepartmentState> = {
  SET_DEPARTMENTS: (state, departments: Department[]) => {
    state.departments = departments;
  },
  SET_ARCHIVED_DEPARTMENTS: (state, departments: Department[]) => {
    state.archivedDepartments = departments;
  },
  SET_SELECTED_DEPARTMENT: (state, department) => {
    state.selectedDepartment = department;
  },
  ADD_DEPARTMENT: (state, department) => {
    state.departments = [...(state?.departments ?? []), department];
  },
  SET_DEPARTMENT_EMPLOYEES: (state, employees: Employee[]) => {
    state.departmentEmployees = employees;
  },
  EDIT_DEPARTMENT: (state, department) => {
    const idx = department.isArchived
      ? _.findIndex(
          state?.archivedDepartments,
          item => item.id === department.id,
        )
      : _.findIndex(state?.departments, item => item.id === department.id);
    if (department.isArchived) {
      if (state.archivedDepartments) {
        Vue.set(state.archivedDepartments, idx, department);
        state.selectedDepartment = department;
      }
    } else {
      if (state.departments) {
        Vue.set(state.departments, idx, department);
        state.selectedDepartment = department;
      }
    }
  },
  SET_EVENT_METRICS: (state, eventMetrics) => {
    state.departmentMetrics = _.uniqBy(eventMetrics, event => event.name);
  },
  SET_DEPARTMENT_TITLES: (state, titles) => {
    state.departmentTitles = titles;
  },
  SET_TITLES_DEPARTMENT: (state, titles) => {
    state.titleDepartment = titles;
  },
  SET_DOCUMENTS: (state, documents) => {
    state.documents = documents;
  },
  SET_DEPARTMENT_DOCUMENTS: (state, departmentDocuments) => {
    state.departmentDocuments = departmentDocuments;
  },
  ADD_DOCUMENTS: (state, document) => {
    if (!state.documents) state.documents = [document];
    else state.documents?.push(document);
  },
  ADD_DEPARTMENT_DOCUMENTS: (state, document) => {
    if (!state.departmentDocuments) state.departmentDocuments = [document];
    else state.departmentDocuments?.push(document);
  },
  UPDATE_DOCUMENT: (state, updateDocument) => {
    const findex = state.documents?.findIndex(
      document => document.id === updateDocument.id,
    );
    if (!findex && findex !== 0) return;
    if (state.documents) state.documents[findex].name = updateDocument.name;
  },
  UPDATE_DEPARTMENT_DOCUMENT: (state, updateDocument) => {
    const findex = state.departmentDocuments?.findIndex(
      document => document.document_id === updateDocument.id,
    );
    if (!findex && findex !== 0) return;
    if (state.departmentDocuments)
      /* eslint-disable @typescript-eslint/camelcase */
      state.departmentDocuments[findex].document_name = updateDocument.name;
  },
  SET_DEPARTMENT_CATEGORY: (state, categories) => {
    state.categories = categories;
  },
  ADD_DEPARTMENT_CATEGORY: (state, category) => {
    state.categories?.push(category);
  },
  UPDATE_DEPARTMENT_CATEGORY: (state, category) => {
    const findex = state.categories?.findIndex(cat => cat.id === category.id);
    if (!isNumber(findex)) return;
    if (state.categories) {
      state.categories[findex] = category;
    }
  },
  REMOVE_DEPARTMENT_CATEGORY: (state, catId) => {
    const findex = state.categories?.findIndex(cat => cat.id === catId);
    if (!isNumber(findex)) return;
    if (state.categories) {
      state.categories.splice(findex, 1);
    }
  },
};

const actions: ActionTree<AdminDepartmentState, RootState> = {
  loadArchiveDepartment: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;

    if (companyId) {
      try {
        const archivedDepartmentList = await DepartmentsService.listArchivedDepartments(
          {
            companyId,
          },
        );
        commit("SET_ARCHIVED_DEPARTMENTS", archivedDepartmentList.departments);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  selectDepartment: async (
    { commit, rootState, dispatch },
    departmentId: number,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const department = DepartmentsService.getDepartment({
          companyId,
          departmentId,
        });
        const departmentEmployees = DepartmentsService.listDepartmentEmployees({
          companyId,
          departmentId,
        });
        const departmentMetrics = EventMetricsService.listEventMetricsTrackedAtDepartment(
          {
            companyId,
            departmentId,
          },
        );
        const departmentTitle = DepartmentService.getDepartmentTitles({
          companyId,
          departmentId,
        });
        const departmentCategory = DepartmentService.getDepartmentCategories({
          companyId,
          departmentId,
        });
        const doc = dispatch("getDocuments", true);
        const docs = dispatch("getDepartmentDocuments", departmentId);
        await Promise.all([
          department,
          departmentEmployees,
          departmentMetrics,
          departmentTitle,
          doc,
          docs,
          departmentCategory,
        ]);
        commit("SET_SELECTED_DEPARTMENT", await department);
        commit(
          "SET_DEPARTMENT_EMPLOYEES",
          (await departmentEmployees).employees,
        );
        commit("SET_EVENT_METRICS", (await departmentMetrics).eventMetrics);
        commit("SET_TITLES_DEPARTMENT", (await departmentTitle).titles);
        commit(
          "SET_DEPARTMENT_CATEGORY",
          (await departmentCategory).categories || [],
        );
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  createDepartment: async (
    { commit, rootState },
    createDepartmentRequest: CreateDepartmentRequestBody,
  ) => {
    const companyID = rootState.appContext?.company?.id;
    if (companyID) {
      try {
        const newDepartment = await DepartmentsService.createDepartment({
          requestBody: createDepartmentRequest,
          companyId: companyID,
        });
        commit("ADD_DEPARTMENT", newDepartment);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  updateDepartment: async (
    { commit, rootState, dispatch },
    createDepartmentRequest: Department,
  ) => {
    const companyID = rootState.appContext?.company?.id;
    const departmentID = createDepartmentRequest.id;
    if (companyID && departmentID) {
      try {
        const department = await DepartmentsService.updateDepartment({
          companyId: companyID,
          departmentId: departmentID,
          requestBody: createDepartmentRequest,
        });
        dispatch("company/loadDepartments", true, { root: true });
        commit("alert/SET_SHOW_SUCCESS", "Department Successfully Updated", {
          root: true,
        });
        dispatch("loadArchiveDepartment");
        commit("EDIT_DEPARTMENT", department);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  addDepartmentEmployee: async (
    { commit, rootState },
    { departmentId, employeeId },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.addEmployeeToDepartment({
          companyId,
          departmentId,
          employeeId,
        });
        const departmentEmployees = await DepartmentsService.listDepartmentEmployees(
          {
            companyId,
            departmentId,
          },
        );
        commit("SET_DEPARTMENT_EMPLOYEES", departmentEmployees.employees);
        commit("alert/SET_SHOW_SUCCESS", "Member Successfully Added", {
          root: true,
        });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  addDepartmentAdmin: async (
    { commit, rootState },
    { departmentId, employeeId },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.addAdminToDepartment({
          companyId,
          departmentId,
          employeeId,
        });
        const departmentEmployees = await DepartmentsService.listDepartmentEmployees(
          {
            companyId,
            departmentId,
          },
        );
        commit("SET_DEPARTMENT_EMPLOYEES", departmentEmployees.employees);
        commit("alert/SET_SHOW_SUCCESS", "Admin Successfully Added", {
          root: true,
        });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  removeDepartmentEmployee: async (
    { commit, rootState },
    { departmentId, employeeId },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.removeEmployeeFromDepartment({
          companyId,
          departmentId,
          employeeId,
        });
        commit(
          "alert/SET_SHOW_SUCCESS",
          "Employee Removed Successfully from the Department",
          { root: true },
        );
        const departmentEmployees = await DepartmentsService.listDepartmentEmployees(
          {
            companyId,
            departmentId,
          },
        );
        commit("SET_DEPARTMENT_EMPLOYEES", departmentEmployees.employees);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  removeDepartmentAdmin: async (
    { commit, rootState },
    { departmentId, employeeId },
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.removeDepartmentAdmin({
          companyId,
          departmentId,
          employeeId,
        });
        commit(
          "alert/SET_SHOW_SUCCESS",
          "Admin Removed Successfully from the department",
          { root: true },
        );
        const departmentEmployees = await DepartmentsService.listDepartmentEmployees(
          {
            companyId,
            departmentId,
          },
        );
        commit("SET_DEPARTMENT_EMPLOYEES", departmentEmployees.employees);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  archiveDepartment: async (
    { commit, dispatch, rootState },
    departmentId: number,
  ) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.archiveDepartment({
          companyId,
          departmentId,
        });
        dispatch("selectDepartment", departmentId);
        dispatch("loadArchiveDepartment");
        dispatch("company/loadDepartments", true, { root: true });
        commit("alert/SET_SHOW_SUCCESS", "Department Archived", { root: true });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  addExtensionOrPhone: async (
    { dispatch, rootState },
    {
      departmentId,
      requestBody,
    }: {
      departmentId: number;
      requestBody: AddDepartmentPhoneNumberRequestBody;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.addDepartmentPhonenumbers({
          companyId,
          departmentId,
          requestBody,
        });
        dispatch("selectDepartment", departmentId);
      } catch (error) {
        console.log(error);
      }
    }
  },
  deleteExtensionOrPhone: async (
    { dispatch, rootState },
    {
      departmentId,
      phonenumberId,
    }: { departmentId: number; phonenumberId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.deleteDepartmentPhonenumber({
          companyId,
          departmentId,
          phonenumberId,
        });
        dispatch("selectDepartment", departmentId);
      } catch (error) {
        console.log(error);
      }
    }
  },
  addTitle: async (
    { dispatch, commit, rootState },
    {
      departmentId,
      requestBody,
    }: { departmentId: number; requestBody: AddDepartmentTitleRequestBody },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentService.addDepartmentTitle({
          companyId,
          departmentId,
          requestBody,
        });
        dispatch("selectDepartment", departmentId);
        commit("alert/SET_SHOW_SUCCESS", "Title Successfully Added", {
          root: true,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  updateTitle: async (
    { dispatch, commit, rootState },
    {
      departmentId,
      titleId,
      requestBody,
    }: {
      departmentId: number;
      titleId: number;
      requestBody: AddDepartmentTitleRequestBody;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentService.updateDepartmentTitle({
          companyId,
          departmentId,
          titleId,
          requestBody,
        });
        dispatch("selectDepartment", departmentId);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  deleteTitle: async (
    { dispatch, commit, rootState },
    {
      departmentId,
      titleId,
      requestBody,
    }: {
      departmentId: number;
      titleId: number;
      requestBody: AddDepartmentTitleRequestBody;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentService.deleteDepartmentTitle({
          companyId,
          departmentId,
          titleId,
          requestBody,
        });
        dispatch("selectDepartment", departmentId);
        commit("alert/SET_SHOW_SUCCESS", "Title Successfully Deleted", {
          root: true,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  getTitle: async ({ commit, rootState }, departmentId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const departmentTitle = await DepartmentService.getDepartmentTitles({
          companyId,
          departmentId,
        });
        commit("SET_DEPARTMENT_TITLES", departmentTitle.titles);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  onDragChanged: async (
    { commit, rootState },
    { events, requestBody, departmentId },
  ) => {
    commit("SET_TITLES_DEPARTMENT", events);
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentService.sortDepartmentTitles({
          companyId,
          departmentId,
          requestBody,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  getDepartmentTitles: async ({ commit, rootState }, departmentId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const departmentTitle = await DepartmentService.getDepartmentTitles({
          companyId,
          departmentId,
        });
        commit("SET_TITLES_DEPARTMENT", departmentTitle.titles);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  getDocuments: async ({ commit, rootState, getters }, force = false) => {
    const companyId = rootState.appContext.company?.id;
    let dataExists = getters["documents"];
    if (force == true) dataExists = null;
    if (dataExists) return;
    if (companyId) {
      try {
        const documents = await DocumentNameService.getDocumentList({
          companyId,
        });
        commit("SET_DOCUMENTS", documents);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  getDepartmentDocuments: async (
    { commit, rootState },
    departmentId: number,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const documents = await DepartmentDocumentService.getDepartmentDocumentByDepartmentId(
          {
            companyId,
            departmentId,
          },
        );
        commit("SET_DEPARTMENT_DOCUMENTS", documents);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  addDocument: async (
    { commit, rootState, dispatch },
    payload: {
      departmentId: number;
      requestBody: CreateDocumentRequest;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const document = await DocumentNameService.createDocument({
          companyId,
          requestBody: payload.requestBody,
        });
        commit("ADD_DOCUMENTS", document);
        dispatch("addDepartmentDocument", {
          departmentId: payload.departmentId,
          documentId: document.id,
        });
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  addDepartmentDocument: async (
    { commit, rootState },
    payload: {
      departmentId: number;
      documentId: number;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const departmentDocuments = await DepartmentDocumentService.createDepartmentDocument(
          {
            companyId,
            departmentId: payload.departmentId,
            documentId: payload.documentId,
          },
        );
        commit("ADD_DEPARTMENT_DOCUMENTS", departmentDocuments);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  updateDocumentTitle: async (
    { commit, rootState },
    payload: {
      documentId: number;
      requestBody: CreateDocumentRequest;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const updatedDocument = await DocumentNameService.updateDocument({
          companyId,
          documentId: payload.documentId,
          requestBody: payload.requestBody,
        });
        commit("UPDATE_DOCUMENT", updatedDocument);
        commit("UPDATE_DEPARTMENT_DOCUMENT", updatedDocument);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  addDepartmentCategory: async (
    { rootState, commit },
    { cat, departmentId }: { cat: string; departmentId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    const newCat = cat;
    if (companyId) {
      try {
        const category = await DepartmentService.addDepartmentCategory({
          companyId,
          departmentId,
          requestBody: {
            category: newCat,
          } as AddDepartmentCategoryRequestBody,
        });
        commit("ADD_DEPARTMENT_CATEGORY", category);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  updateDepartmentCategory: async (
    { rootState, commit },
    {
      cat,
      departmentId,
      catId,
    }: { cat: string; departmentId: number; catId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const category = await DepartmentService.updateDepartmentCategory({
          companyId,
          departmentId,
          requestBody: {
            id: catId,
            category: cat,
          },
        });
        commit("UPDATE_DEPARTMENT_CATEGORY", category);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },

  removeDepartmentCategory: async (
    { rootState, commit },
    { departmentId, catId }: { departmentId: number; catId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentService.deleteDepartmentCategory({
          companyId,
          departmentId,
          requestBody: {
            id: catId,
          },
        });
        commit("REMOVE_DEPARTMENT_CATEGORY", catId);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  getCategories: async (
    { commit, rootState },
    { departmentId, beginAt }: { departmentId: number; beginAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) return "Company Id not found";

    try {
      const departmentCategory = await DepartmentService.getDepartmentCategories(
        {
          companyId,
          departmentId,
        },
      );

      if (beginAt) {
        const date = moment().format("YYYY-MM");
        const filterDate = beginAt.slice(0, 7);
        if (!departmentCategory?.categories && date === filterDate) {
          commit("categoryBudget/SET_ASK_BUDGET", true);
        }
      }

      commit("SET_DEPARTMENT_CATEGORY", departmentCategory.categories || []);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addDepartmentLead: async (
    { commit, rootState },
    { departmentId, employeeId }: { departmentId: number; employeeId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.addLeadToDepartment({
          companyId,
          departmentId,
          employeeId,
        });
        commit(
          "alert/SET_SHOW_SUCCESS",
          "Employee lead added Successfully to the Department",
          { root: true },
        );
        const departmentEmployees = await DepartmentsService.listDepartmentEmployees(
          {
            companyId,
            departmentId,
          },
        );
        commit("SET_DEPARTMENT_EMPLOYEES", departmentEmployees.employees);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  removeDepartmentLead: async (
    { commit, rootState },
    { departmentId, employeeId }: { departmentId: number; employeeId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await DepartmentsService.removeLeadToDepartment({
          companyId,
          departmentId,
          employeeId,
        });
        commit(
          "alert/SET_SHOW_SUCCESS",
          "Removed employee lead Successfully from the Department",
          { root: true },
        );
        const departmentEmployees = await DepartmentsService.listDepartmentEmployees(
          {
            companyId,
            departmentId,
          },
        );
        commit("SET_DEPARTMENT_EMPLOYEES", departmentEmployees.employees);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
};

export const departmentAdmin: Module<AdminDepartmentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
