/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DepartmentDocumentResponse = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        department_id: {
    type: 'number',
    isRequired: true,
},
        department_name: {
    type: 'string',
    isRequired: true,
},
        document_id: {
    type: 'number',
    isRequired: true,
},
        document_name: {
    type: 'string',
    isRequired: true,
},
        created_at: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
    },
};