/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TermsAndCondition } from '../models/TermsAndCondition';
import type { TermsAndConditionList } from '../models/TermsAndConditionList';
import type { TermsAndConditionListRequest } from '../models/TermsAndConditionListRequest';
import type { TermsAndConditionRequest } from '../models/TermsAndConditionRequest';
import { request as __request } from '../core/request';

export class TermsAndConditionService {

    /**
     * Create an terms and condition for a company
     * @returns TermsAndCondition The created terms and condition
     * @throws ApiError
     */
    public static async createTermsAndCondition({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: TermsAndConditionRequest,
}): Promise<TermsAndCondition> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/terms-and-condition`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get Terms and condition of comapny
     * @returns TermsAndConditionList The created terms and condition
     * @throws ApiError
     */
    public static async getTermsAndCondition({
requestBody,
}: {
requestBody: TermsAndConditionListRequest,
}): Promise<TermsAndConditionList> {
        const result = await __request({
            method: 'POST',
            path: `/terms-and-condition`,
            body: requestBody,
        });
        return result.body;
    }

}