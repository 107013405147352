
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EditConfirmModal extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly body!: string;
  @Prop() readonly confirm!: boolean;

  get isReset() {
    return this.title.includes("Reset");
  }
  yes() {
    this.$emit("confirm");
  }
  close() {
    this.$emit("close");
  }
}
