/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TarfRequestsGroupedMonth = {
    properties: {
        month: {
    type: 'number',
},
        tarfs: {
    type: 'array',
    contains: {
        type: 'TarfListItem',
    },
},
    },
};