import { render, staticRenderFns } from "./ImageInputMobile.vue?vue&type=template&id=4a9a42ac&scoped=true"
import script from "./ImageInputMobile.vue?vue&type=script&lang=ts"
export * from "./ImageInputMobile.vue?vue&type=script&lang=ts"
import style0 from "./ImageInputMobile.vue?vue&type=style&index=0&id=4a9a42ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a9a42ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCardText,VDialog,VFileInput,VIcon})
