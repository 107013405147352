/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnniversaryListing } from '../models/AnniversaryListing';
import type { BulkCreateEmployeeResponse } from '../models/BulkCreateEmployeeResponse';
import type { CreateEmployeeRequestBody } from '../models/CreateEmployeeRequestBody';
import type { Employee } from '../models/Employee';
import type { EmployeeFiveStarScore } from '../models/EmployeeFiveStarScore';
import type { EmployeeListing } from '../models/EmployeeListing';
import type { MeEventScoresResponse } from '../models/MeEventScoresResponse';
import type { RecentlyJoinedGroupedMonth } from '../models/RecentlyJoinedGroupedMonth';
import type { TransferEmployeeToDepartmentRequest } from '../models/TransferEmployeeToDepartmentRequest';
import type { UpdateCompanyEmployeeRolesRequest } from '../models/UpdateCompanyEmployeeRolesRequest';
import type { UpdateEmployeeProfileRequest } from '../models/UpdateEmployeeProfileRequest';
import { request as __request } from '../core/request';

export class EmployeesService {

    /**
     * Get a list of employees at a company
     * @returns EmployeeListing A list of archived employees at a company
     * @throws ApiError
     */
    public static async listArchivedEmployees({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<EmployeeListing> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/archived-employees`,
        });
        return result.body;
    }

    /**
     * Get a list of employees at a company
     * @returns EmployeeListing A list of employees at a company
     * @throws ApiError
     */
    public static async listEmployees({
companyId,
departmentTitleId,
}: {
/** The companyId **/
companyId: number,
/** The department title id to filter employees by their assigned title **/
departmentTitleId?: number,
}): Promise<EmployeeListing> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees`,
            query: {
                'departmentTitleId': departmentTitleId,
            },
        });
        return result.body;
    }

    /**
     * Create an employee record at a company
     * @returns Employee The created employee
     * @throws ApiError
     */
    public static async createEmployee({
companyId,
requestBody,
departmentTitleId,
}: {
/** The companyId **/
companyId: number,
requestBody: CreateEmployeeRequestBody,
/** The department title id to filter employees by their assigned title **/
departmentTitleId?: number,
}): Promise<Employee> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/employees`,
            query: {
                'departmentTitleId': departmentTitleId,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a list of employees that are yet to be activated
     * @returns EmployeeListing a list of employees
     * @throws ApiError
     */
    public static async getActivationPendingEmployees({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<EmployeeListing> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/activation-pending`,
        });
        return result.body;
    }

    /**
     * Get a list of upcoming anniversary of employees at a company
     * @returns AnniversaryListing A list of upcoming anniversaries at a company
     * @throws ApiError
     */
    public static async getUpcominAnniversary({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<AnniversaryListing> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/upcoming-anniversary`,
        });
        return result.body;
    }

    /**
     * Get a list of employees at a company who joined less than 31 days ago
     * @returns RecentlyJoinedGroupedMonth A list of employees
     * @throws ApiError
     */
    public static async getRecentlyJoinedEmployees({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<RecentlyJoinedGroupedMonth> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/recently-joined`,
        });
        return result.body;
    }

    /**
     * Get a list of upcoming birthday of employees at a company
     * @returns AnniversaryListing A list of upcoming birthdays at a company
     * @throws ApiError
     */
    public static async getUpcominAnniversary1({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<AnniversaryListing> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/upcoming-birthday`,
        });
        return result.body;
    }

    /**
     * Create an employee record at a company
     * @returns BulkCreateEmployeeResponse describes the result of the bulk upload
     * @throws ApiError
     */
    public static async addEmployeesInBulk({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: any,
}): Promise<BulkCreateEmployeeResponse> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/employees/bulk`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get an employee record
     * @returns Employee An employee record
     * @throws ApiError
     */
    public static async getEmployee({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<Employee> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}`,
            errors: {
                404: `Employee not found`,
            },
        });
        return result.body;
    }

    /**
     * Delete an employee record
     * @returns any Employee successfully deleted
     * @throws ApiError
     */
    public static async deleteEmployee({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/employees/${employeeId}`,
            errors: {
                404: `Employee not found`,
            },
        });
        return result.body;
    }

    /**
     * Get a five star score for an employee
     * @returns EmployeeFiveStarScore An information about five star score
     * @throws ApiError
     */
    public static async getFiveStarScore({
companyId,
employeeId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
beginAt?: string,
endAt?: string,
}): Promise<EmployeeFiveStarScore> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}/fivestar-score`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Resend activation code for an employee. The email is sent to the employee if they have an email or otherwise to the company admin
     * @returns any Activation code successfully sent
     * @throws ApiError
     */
    public static async resendActivationCode({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/employees/${employeeId}/activation-code`,
            errors: {
                404: `Employee not found`,
            },
        });
        return result.body;
    }

    /**
     * Archive an employee record.
     * @returns any Employee successfully archived
     * @throws ApiError
     */
    public static async archiveEmployee({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/employees/${employeeId}/archive`,
            errors: {
                404: `Employee not found`,
            },
        });
        return result.body;
    }

    /**
     * Un-archive an employee record.
     * @returns any Employee successfully archived
     * @throws ApiError
     */
    public static async archiveEmployee1({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/employees/${employeeId}/unarchive`,
            errors: {
                404: `Employee not found`,
            },
        });
        return result.body;
    }

    /**
     * update employee profile (employee self and admins only). Note that only company admins can update the employee's title.
     * @returns Employee Employee profile successfully updated
     * @throws ApiError
     */
    public static async updateEmployeeProfile({
companyId,
employeeId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
requestBody: UpdateEmployeeProfileRequest,
}): Promise<Employee> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/profile`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Set or replace an employee's profile image. This can be performed only by company admins or the employee themselves. Images must be limited to 5MB in size.
     * @returns any successfully set the employee's profile image
     * @throws ApiError
     */
    public static async setProfileImage({
companyId,
employeeId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
requestBody: any,
}): Promise<{
profileImage?: string,
}> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/employees/${employeeId}/profile-image`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get Employee event scores
     * @returns MeEventScoresResponse An information about event score of employee
     * @throws ApiError
     */
    public static async getEmployeeScores({
companyId,
employeeId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
beginAt?: string,
endAt?: string,
}): Promise<MeEventScoresResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}/event-score`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * update employee's company roles (company admins only)
     * @returns Employee Employee's company roles successfully updated
     * @throws ApiError
     */
    public static async updateCompanyEmployeeRoles({
companyId,
employeeId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
requestBody: UpdateCompanyEmployeeRolesRequest,
}): Promise<Employee> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/company-employee-roles`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * transfer employee to another department (company admins only)
     * @returns Employee Employee successfully transferred
     * @throws ApiError
     */
    public static async transferEmployeeToDepartment({
companyId,
employeeId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
requestBody: TransferEmployeeToDepartmentRequest,
}): Promise<Employee> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/primary-department-id`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update employee phone visibility (company owner, admin and self can access)
     * @returns Employee Employee successfully updated
     * @throws ApiError
     */
    public static async updateEmployeePhoneVisibility({
companyId,
employeeId,
visible,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** 0 to hide and 1 to show phone number **/
visible: number,
}): Promise<Employee> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/phone-visibility`,
            query: {
                'visible': visible,
            },
        });
        return result.body;
    }

}