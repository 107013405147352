/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ScoreboardSummaryScore = {
    properties: {
        eventMetricId: {
    type: 'number',
    isRequired: true,
},
        eventName: {
    type: 'string',
    isRequired: true,
},
        total: {
    type: 'number',
    isRequired: true,
},
        leaderEmployeeId: {
    type: 'number',
},
        leaderEmployeeName: {
    type: 'string',
},
        leaderCount: {
    type: 'number',
},
        isPoster: {
    type: 'boolean',
    isRequired: true,
},
    },
};