





















































import { Component, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";
import moment from "moment";

@Component
export default class MonthPicker extends Vue {
  @Watch("menu", { immediate: true, deep: true })
  onMenuChange(newVal: boolean) {
    if (newVal || this.dates?.length) {
      this.label = "Years and Month";
    } else {
      this.label = moment().format("MMMM, YYYY");
    }
  }
  menu = false;
  dates: string[] = [];
  label = moment().format("MMMM, YYYY");

  get formattedDateMonth() {
    if (this.dates?.length) {
      let [first, second] = this.dates;
      if (first !== second && this.dates?.length == 2) {
        if (first > second) {
          const copy = first;
          first = second;
          second = copy;
        }
        return _.map([first, second], date => {
          const [year, month] = date.split("-");
          const monthStr = moment(month, "MM").format("MMMM");
          return `${monthStr}, ${year}`;
        }).join(" to ");
      } else {
        const [year, month] = first.split("-");
        const monthStr = moment(month, "MM").format("MMMM");
        return [`${monthStr}, ${year}`];
      }
    }
    return [];
  }

  updateFilter() {
    let [first, second] = this.dates;
    if (this.dates?.length == 2) {
      if (first > second) {
        const copy = first;
        first = second;
        second = copy;
      }
    } else {
      second = first;
    }
    const beginDate = moment(`${first}-01 00:00:00`).format("YYYY-MM-DD");
    const endDate = moment(`${second}-01 00:00:00`)
      .endOf("month")
      .format("YYYY-MM-DD");

    if (this.dates?.length) {
      // Emits here
      this.$emit("update", beginDate, endDate);
    }
  }

  clearFilter() {
    this.dates = [];
    this.label = moment().format("MMMM, YYYY");
    this.$emit("clear");
  }

  cancel() {
    this.menu = false;
    if (!this.dates?.length) this.label = moment().format("MMMM, YYYY");
  }
}
