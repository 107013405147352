

















































































import moment from "moment";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AddBudgetConfirmation from "../AddBudgetConfirmation.vue";
import FilterMobile from "../FilterMobile.vue";
import { formatCurrency } from "@/tool/formatCurrency";

type TableRow = {
  departmentId: number;
  categoryId: number;
  department: string;
  spend: string;
  balance: number;
  percent: string;
  actual: string;
  ppd: string;
  variance: string;
};

@Component({
  components: {
    FilterMobile,
    AddBudgetConfirmation,
  },
})
export default class ReportMobile extends Vue {
  @Prop() census?: number;
  @Prop() budget?: number;
  @Prop() expenses?: TableRow[];
  @Watch("expenses", { deep: true, immediate: true })
  onExpensesUpdate(newVal: TableRow[]) {
    this.totalSpend = 0;
    if (newVal?.length == 0) return;
    this.expenses?.forEach(expense => {
      this.totalSpend += parseFloat(expense.spend);
    });
  }

  totalSpend = 0;
  filterPop = false;
  popBudgetDialog = false;
  formatCurrency = formatCurrency;
  currentMonth = moment().format("MMMM");

  created() {
    const day = moment().format("DD");
    if (day === "01") {
      this.$store.dispatch("categoryBudget/setAskBudget", true);
    }
    this.totalSpend = 0;
    this.$emit("fetch");
  }

  selectCategory(expense: TableRow) {
    this.$emit("detail", expense);
  }

  filterExpense({
    departmentId,
    categoryId,
    date,
  }: {
    departmentId: number;
    categoryId: number;
    date: string;
  }) {
    this.totalSpend = 0;
    this.filterPop = false;
    this.currentMonth = moment(date).format("MMMM");
    this.$emit("filter", {
      departmentId,
      categoryId,
      date,
    });
  }

  get askBudget() {
    const ask = this.$store.getters["categoryBudget/askBudget"];
    this.popBudgetDialog = ask;
    return this.popBudgetDialog;
  }

  closeDialog() {
    this.$store.dispatch("categoryBudget/setAskBudget", false);
  }
}
