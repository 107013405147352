






































































import { Component, Vue } from "vue-property-decorator";
import ImageInput from "@/components/image_input/ImageInput.vue";

@Component({
  components: {
    ImageInput,
  },
})
export default class ImageOption extends Vue {
  showImage = false;
  showOptionInput = false;
  showFullImage = false;
  fileName = "";
  selectedFile: string | ArrayBuffer | null | undefined = "";
  file: File | null = null;

  updateFile(file: File | null) {
    if (file) {
      this.file = file;
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        this.selectedFile = event.target?.result;
      };
      this.showImage = true;
      reader.readAsDataURL(file);
      this.showOptionInput = !this.showOptionInput;
      this.$emit("updateFile", file || null);
    }
  }

  showOption() {
    if (this.file === null) {
      this.showOptionInput = !this.showOptionInput;
    }
  }

  removeImage() {
    this.showImage = false;
    this.fileName = "";
    this.selectedFile = "";
    this.file = null;
    this.$emit("remove");
  }

  showFullSize() {
    this.showFullImage = true;
  }
}
