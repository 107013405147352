import { render, staticRenderFns } from "./FormList.vue?vue&type=template&id=714ed7fc&scoped=true"
import script from "./FormList.vue?vue&type=script&lang=ts"
export * from "./FormList.vue?vue&type=script&lang=ts"
import style0 from "./FormList.vue?vue&type=style&index=0&id=714ed7fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "714ed7fc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VDataTable,VDialog,VHover,VIcon,VRadio,VRadioGroup,VSelect})
