
































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TarfConfirmModal extends Vue {
  @Prop() readonly text!: string;

  get upload() {
    return this.$route.path.includes("company-document");
  }

  close() {
    this.$emit("close");
  }
}
