



































































import { Component, Vue } from "vue-property-decorator";
import { LoggedEvent } from "@/api";
import loading from "@/components/loader/loading.vue";
import moment from "moment";

@Component({
  watch: {
    $route() {
      if (this.$route.path.endsWith("activity")) {
        this.$store.dispatch("activity/loadActivityFeed");
        if (!this.$vuetify.breakpoint.smAndDown) {
          const feed = this.$store.getters["activity/activityFeed"];
          if (feed && feed.length !== 0) {
            this.$store.dispatch("activity/latestEvent", feed[0]);
            this.$router.replace({
              path: `/activity/${feed[0].id}`,
            });
          }
        }
      }
    },
  },
  components: {
    loading,
  },
})
export default class EventFeed extends Vue {
  latestEvent!: LoggedEvent | null;
  isDataLoading = false;
  async created() {
    this.isDataLoading = true;
    this.$store.dispatch("activity/updateLoading", this.isDataLoading);
    await this.$store.dispatch("activity/loadActivityFeed");
    this.isDataLoading = false;
    this.$store.dispatch("activity/updateLoading", this.isDataLoading);
  }

  get activityFeed(): LoggedEvent[] {
    const feed = this.$store.getters["activity/activityFeed"];
    if (feed && feed.length !== 0) {
      if (
        !this.$vuetify.breakpoint.smAndDown &&
        !this.$store.getters["activity/selectedEvent"]
      ) {
        this.$store.dispatch("activity/latestEvent", null);
        this.viewDetail(feed[0]);
      }
      return feed?.map((event: LoggedEvent) => {
        return {
          ...event,
          createdAt: moment(event.createdAt).format("ll, LT"),
        };
      });
    } else {
      return [];
    }
  }

  get latestEventFeed(): LoggedEvent | null {
    this.latestEvent = this.$store.getters["activity/latestFeed"];
    return this.latestEvent;
  }

  viewDetail(event: LoggedEvent) {
    this.$store.dispatch("activity/latestEvent", event);
    this.$router.push({
      path: `/activity/${event.id}`,
    });
  }

  beforeDestroy() {
    if (!this.$vuetify.breakpoint.smAndDown) {
      this.$store.dispatch("activity/destroyActivityFeed");
      this.$store.dispatch("activity/destroySelectedEvent");
    }
  }
}
