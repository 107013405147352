/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DepartmentPhoneNumberRequestBody = {
    properties: {
        value: {
    type: 'string',
    isRequired: true,
},
        type: {
    type: 'number',
    isRequired: true,
},
    },
};