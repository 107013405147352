import { Employee } from "@/api";
import store from "@/store";
import { getSubRoles } from "@/tool/subRoles";

export const selfData = (): Employee => {
  return store.getters["getCurrentEmployeeSelf"];
};

export const isSuperAdmin = (): boolean => {
  return store.getters["isSuperAdmin"];
};

export function isAdmin() {
  if (selfData()?.roles) {
    for (const role of selfData()?.roles) {
      if (role == "admin" || role == "owner") {
        return true;
      }
    }
  }
  return false;
}

export const checkSelfRole = (role: string) => {
  const self = selfData();
  if (self?.roles?.includes(role.toLowerCase())) return true;
  return false;
};

export const checkOtherRole = (other: Employee, role: string) => {
  if (other?.roles?.includes(role.toLowerCase())) return true;
  return false;
};

export const checkSelf = (id: number) => {
  const self = selfData();
  if (id && id === self?.id) return true;
  return false;
};

export const checkSelfSubrole = () => {
  if (selfData()) {
    const isSalary = getSubRoles(selfData().subRole);
    if (isSalary === 1) return false;
    else if (isSalary === 2) return true;
    else return false;
  } else {
    return false;
  }
};

export const checkSelfLead = () => {
  const me = selfData();
  if (me?.lead) return true;
  return false;
};

export const checkGuardianAngel = () => {
  return isSuperAdmin() ? false : selfData().guardianAngel;
};
