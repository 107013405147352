/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DocumentPathResponse = {
    properties: {
        url: {
    type: 'string',
    isRequired: true,
},
    },
};