/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MessageGroup = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        readAt: {
    type: 'string',
},
        sentAt: {
    type: 'string',
},
    },
};