/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Census } from '../models/Census';
import type { Company } from '../models/Company';
import type { CompanyBudgets } from '../models/CompanyBudgets';
import type { DepartmentHasBudget } from '../models/DepartmentHasBudget';
import type { PostBudget } from '../models/PostBudget';
import { request as __request } from '../core/request';

export class CompaniesService {

    /**
     * Delete a company
     * @returns any Successfully deleted this company
     * @throws ApiError
     */
    public static async deleteCompany({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/super-admin/companies/${companyId}`,
        });
        return result.body;
    }

    /**
     * Get a company
     * @returns Company A single company
     * @throws ApiError
     */
    public static async getCompany({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<Company> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}`,
            errors: {
                404: `A company with this ID could not be found`,
            },
        });
        return result.body;
    }

    /**
     * Update a company
     * @returns Company Successfully updated the company
     * @throws ApiError
     */
    public static async updateCompany({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Company,
}): Promise<Company> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a budget of a Company category
     * @returns CompanyBudgets The list of department and it's categories within the given company
     * @throws ApiError
     */
    public static async getCompanyCategoryBudget({
companyId,
}: {
companyId: number,
}): Promise<CompanyBudgets> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/budget`,
        });
        return result.body;
    }

    /**
     * Insert bulk company department category budget for all
     * @returns any The company categories budget added successfully
     * @throws ApiError
     */
    public static async addCompanyCategoryBudget({
companyId,
requestBody,
}: {
companyId: number,
requestBody: PostBudget,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/budget`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a list of department that has budget
     * @returns DepartmentHasBudget Department list that has budget
     * @throws ApiError
     */
    public static async getDepartmentHasBudget({
companyId,
beginAt,
endAt,
}: {
companyId: any,
beginAt: string,
endAt: string,
}): Promise<DepartmentHasBudget> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/budget/departments`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Get a company census
     * @returns Census Company Census for the budget
     * @throws ApiError
     */
    public static async getCompanyCensus({
companyId,
beginAt,
endAt,
}: {
companyId: any,
beginAt: string,
endAt: string,
}): Promise<Census> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/census`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Add census for the budget of the current month
     * @returns Census Company Census for the budget
     * @throws ApiError
     */
    public static async addCompanyCensus({
companyId,
requestBody,
}: {
companyId: any,
requestBody: Census,
}): Promise<Census> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/census`,
            body: requestBody,
        });
        return result.body;
    }

}