import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  CalendarCount,
  CalendarEvent,
  CalendarEventList,
  CalendarService,
} from "@/api";
import moment from "moment";

type CalendarState = {
  events: CalendarEventList | null;
  count: CalendarCount | null;
};

const state: CalendarState = {
  events: null,
  count: null,
};

const getters: GetterTree<CalendarState, RootState> = {
  events: state => state.events,
  count: state => state.count,
};

const mutations: MutationTree<CalendarState> = {
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_EVENTS: (state, events) => {
    state.events = events;
  },
  ADD_EVENT: (state, event) => {
    if (state.events === null) {
      state.events = [];
    }
    state.events.push(event);
  },
  UPDATE_EVENT: (state, event) => {
    let index = state.events?.findIndex(
      (item: CalendarEvent) => item.id == event.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.events?.splice(index, 1);
      state.events?.push(event);
    }
  },
  DELETE_EVENT: (state, event) => {
    let index = state.events?.findIndex(
      (item: CalendarEvent) => item.id == event.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.events?.splice(index, 1);
    }
  },
};

const actions: ActionTree<CalendarState, RootState> = {
  fetchEvents: async (
    { commit, rootState },
    filterDate: { beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const events = await CalendarService.getCompanyCalendarEvents({
        companyId,
        beginAt: filterDate.beginAt,
        endAt: filterDate.endAt,
      });
      commit("SET_EVENTS", events);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addEvent: async ({ commit, rootState }, requestBody: CalendarEvent) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const event = await CalendarService.addEvent({
        companyId,
        requestBody,
      });
      commit("ADD_EVENT", event);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateEvent: async ({ commit, rootState }, requestBody: CalendarEvent) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const event = await CalendarService.updateEvent({
        companyId,
        requestBody,
      });
      commit("UPDATE_EVENT", event);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  deleteEvent: async ({ commit, rootState }, requestBody: CalendarEvent) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const event = await CalendarService.deleteEvent({
        companyId,
        requestBody,
      });
      commit("DELETE_EVENT", event);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchCount: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const date = moment()
        .local()
        .format("YYYY-MM-DD");
      const count = await CalendarService.getCalendarEventCount({
        companyId,
        date,
      });
      commit("SET_COUNT", count);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const calendar: Module<CalendarState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
