





















import { Vue, Component } from "vue-property-decorator";

@Component
export default class TarfHistoryLayout extends Vue {
  isInPath(check: string) {
    if (this.$route.params.id) return this.$route.path.includes(check);
    return false;
  }
}
