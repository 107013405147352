









































































import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import { isString } from "lodash";
import { CompanyGuardianAngel, CompanyGuardianAngels } from "@/api";
import Loading from "@/components/loader/loading.vue";
import EditAssignments from "./EditAssignments.vue";

@Component({
  components: { Loading, EditAssignments },
})
export default class Assignments extends Vue {
  loading = false;
  dialog = false;
  floorId = 0;
  guardianId = 0;
  counter = 0;
  guardianLists: CompanyGuardianAngels = [];
  guardianAngel: CompanyGuardianAngel | null = null;
  headers = [
    {
      text: "Room No.",
      align: "start",
      value: "room.name",
      sortable: false,
      width: "15%",
    },
    {
      text: "Bed No.",
      align: "start",
      value: "bed.name",
      sortable: false,
      width: "15%",
    },
    {
      text: "Guardian Angel",
      align: "end",
      value: "employeeName",
    },
    {
      text: "Edit Assignment",
      align: "start",
      value: "id",
      width: "15%",
      sortable: false,
    },
  ];

  async created() {
    this.loading = true;
    const guardianAngels = this.$store.dispatch(
      "guardian/fetchCompanyGuardianAngels",
    );
    const floors = this.$store.dispatch("floor/fetchCompanyFloors");

    await Promise.all([guardianAngels, floors]);
    this.loading = false;
  }

  get GuardianAngels() {
    let lists =
      (this.$store.getters[
        "guardian/companyGuardianAngels"
      ] as CompanyGuardianAngels) || [];
    this.guardianLists = lists;
    if (this.floorId && !isString(lists)) {
      lists = _.filter(lists, item => item?.floor?.id == this.floorId);
    }
    if (this.guardianId && !isString(lists)) {
      lists = _.filter(lists, item => item.employeeId == this.guardianId);
    }
    return lists;
  }

  get floors() {
    return this.$store.getters["floor/floors"];
  }

  edit(item: CompanyGuardianAngel) {
    this.guardianAngel = item;
    this.counter++;
    if (this.guardianAngel) this.dialog = true;
  }
}
