





































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { EventMetric, ScoreboardSummary } from "@/api";
import _ from "lodash";
import { addDayOnDate, dateFormat } from "@/common/date_format";
@Component
export default class ScoreboardSummaryCard extends Vue {
  @Prop() option!: ScoreboardSummary[];
  itemsToFilter: number[] = [];
  pushLIST: ScoreboardSummary[] = [];
  eventsToFilter: number[] = [];
  eventPushList: EventMetric[] = [];
  startDate = "";
  endDate = "";
  formErrors = "";
  showStartDate = false;
  showEndDate = false;
  formattedStartDate = "";
  formattedEndDate = "";

  created() {
    if (this.stateStartDate) this.startDate = this.stateStartDate;
    if (this.stateEndDate) this.endDate = this.stateEndDate;
  }

  get stateStartDate() {
    return this.$store.getters["scoreboard/startDate"];
  }

  get stateEndDate() {
    return this.$store.getters["scoreboard/endDate"];
  }

  get departmentList() {
    const items = this.$store.getters["departmentAdmin/departments"];
    let list;
    if (items) {
      list = [
        {
          companyId: 8,
          createdAt: "2021-03-25T05:50:00.568215Z",
          id: null,
          isArchived: false,
          name: "Company",
        },
        ...items,
      ];
    }
    return list;
  }
  formatDate(date: string, isStartDate: boolean) {
    if (isStartDate) this.formattedStartDate = dateFormat(date);
    else this.formattedEndDate = dateFormat(date);
  }
  get selectedItems() {
    const items = [...this.option];
    const list = items.map(item => item.departmentId);
    this.itemsToFilter = list;
    return list;
  }

  set selectedItems(value) {
    let diff: number[];
    if (this.itemsToFilter.length > value.length) {
      diff = _.difference(this.itemsToFilter, value);
      const findex = _.findIndex(
        this.itemsToFilter,
        event => event === diff[0],
      );
      this.pushLIST.push(this.option[findex]);
      this.$store.dispatch("scoreboard/filterScoreboards", findex);
    } else {
      diff = _.difference(value, this.itemsToFilter);
      const findex = _.filter(
        this.pushLIST,
        event => event.departmentId === diff[0],
      );
      this.$store.dispatch("scoreboard/addFilterScoreboards", findex);
      const remove = _.findIndex(
        this.pushLIST,
        event => event.departmentId === diff[0],
      );
      this.pushLIST.splice(remove, 1);
    }
  }

  get eventLists() {
    const list = this.$store.getters["scoreboard/trackedEvents"];
    return _.orderBy(list, event => event.eventName.toLowerCase());
  }
  async selectedDate() {
    if (this.startDate && this.endDate) {
      const filteredDate = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.startDate < this.endDate) {
        this.formErrors = "";
        try {
          await this.$store.dispatch(
            "scoreboard/setStartEndDate",
            filteredDate,
          );
          await this.$store.dispatch("scoreboard/fetchCompanyScoreboards", {
            startDate: filteredDate.startDate,
            endDate: addDayOnDate(filteredDate.endDate),
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        this.formErrors = "start date should not be greater than end date";
      }
    } else {
      this.formErrors = "require both dates";
    }
  }
  get selectedEvents() {
    const [companyEvent] = _.filter(
      [...this.option],
      scoreboard => scoreboard.title.toLowerCase() === "company".toLowerCase(),
    );
    if (!companyEvent) return this.eventsToFilter;
    this.eventsToFilter = _.concat(
      _.map(companyEvent.summaryScores, event => event.eventMetricId),
    );
    return this.eventsToFilter;
  }

  set selectedEvents(value) {
    if (value.length < this.eventsToFilter.length) {
      const diff = _.difference(this.eventsToFilter, value);
      const findex = _.findIndex(
        this.eventsToFilter,
        event => event === diff[0],
      );
      this.eventPushList.push(
        this.$store.getters["scoreboard/trackedEvents"][findex],
      );
      this.$store.dispatch("scoreboard/removeEvent", diff[0]);
    } else {
      const diff = _.difference(value, this.eventsToFilter);
      const remove = _.findIndex(
        this.eventPushList,
        event => event.id === diff[0],
      );
      this.$store.dispatch("scoreboard/addEvent", diff[0]);
      this.eventPushList.splice(remove, 1);
    }
  }

  destroyed() {
    if (!this.$route.path.includes("scoreboard"))
      this.$store.dispatch("scoreboard/setStartEndDate", {
        startDate: null,
        endDate: null,
      });
  }
}
