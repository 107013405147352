import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import {
  CreateCustomEventMetricRequestBody,
  EventLogService,
  EventMetric,
  EventMetricsService,
  LogEventRequestBody,
  OpenAPI,
  EventCount,
} from "@/api";
import resolve from "@/common/resolver";

export type EventMetricAdminState = {
  standardEvents: EventMetric[] | null;
  availableEvents: EventMetric[] | null | undefined;
  departmentEvents: EventMetric[] | null;
  companyTrackEvents: EventMetric[] | null;
  archiveCustomEvents: EventMetric[] | null | undefined;
  customEvents: EventMetric[] | null | undefined;
  departmentTrackEvents: EventMetricsService[] | null;
  eventCount: EventCount | null;
};

const state: EventMetricAdminState = {
  standardEvents: null,
  availableEvents: null,
  departmentEvents: null,
  companyTrackEvents: null,
  archiveCustomEvents: null,
  customEvents: null,
  departmentTrackEvents: null,
  eventCount: null,
};

const getters: GetterTree<EventMetricAdminState, RootState> = {
  standardEventList(state) {
    const standard = state.availableEvents?.filter(
      (item: EventMetric) => item.companyId === null,
    );
    return standard;
  },
  availableEventList(state) {
    return state.availableEvents;
  },
  departmentEventList(state) {
    return state.departmentEvents;
  },
  customEventList(state) {
    return state.customEvents;
  },
  archievedEventList(state) {
    return state.archiveCustomEvents;
  },
  companyTrackEventList(state) {
    return state.companyTrackEvents;
  },
  departmentTrackEvents(state) {
    return state.departmentTrackEvents;
  },
  eventCount(state) {
    return state.eventCount;
  },
};

const mutations: MutationTree<EventMetricAdminState> = {
  SET_EVENT_METRIC_LIST: (state, standardEvents: EventMetric[]) => {
    state.standardEvents = standardEvents;
  },
  SET_AVAILABLE_EVENT_METRIC_LIST: (state, availableEvents: EventMetric[]) => {
    state.availableEvents = availableEvents;
    state.customEvents = state.availableEvents?.filter(
      (item: EventMetric) =>
        item.companyId !== null && item.isArchived !== true,
    );
    state.standardEvents = state.availableEvents?.filter(
      (item: EventMetric) => item.companyId === null,
    );
  },
  SET_EVENT_METRIC_TRACKED_AT_DEPARTMENT: (
    state,
    eventMetrics: EventMetric[],
  ) => {
    state.departmentEvents = eventMetrics;
  },
  ADD_CUSTOM_EVENT: (state, customEvent: EventMetric) => {
    state.customEvents = [...(state.customEvents ?? []), customEvent];
  },
  SET_COMPANY_TRACK_EVENT: (state, companyTrackEvents: EventMetric[]) => {
    state.companyTrackEvents = companyTrackEvents;
  },
  UPDATE_CUSTOM_EVENT: (state, updatedEvent: EventMetric) => {
    state.customEvents = state.customEvents?.map(event => {
      if (event.id === updatedEvent.id) {
        event = updatedEvent;
      }
      return event;
    });
  },
  ADD_ARCHIVE_CUSTOM_EVENT: (state, archivedEvent: EventMetric) => {
    state.archiveCustomEvents = [
      ...(state.archiveCustomEvents ?? []),
      archivedEvent,
    ];
  },
  SET_ARCHIVED_CUSTOM_EVENT: (state, archiveEvents: EventMetric[]) => {
    state.archiveCustomEvents = archiveEvents;
  },
  REMOVE_CUSTOM_EVENT: (state, archivedEvent: EventMetric) => {
    state.customEvents = state.customEvents?.filter(
      event => event.id !== archivedEvent.id,
    );
  },
  REMOVE_ARCHIVE_CUSTOM_EVENT: (state, updatedEvent: EventMetric) => {
    state.archiveCustomEvents = state.archiveCustomEvents?.filter(
      event => event.id !== updatedEvent.id,
    );
  },
  TRACK_EVENT_AT_DEPARTMENT: (
    state,
    departmentEventTrackList: EventMetric[],
  ) => {
    state.departmentTrackEvents = departmentEventTrackList;
  },
  SET_EVENT_COUNT: (state, count) => {
    state.eventCount = count;
  },
};

const actions: ActionTree<EventMetricAdminState, RootState> = {
  loadStandardEvents: async ({ commit }) => {
    try {
      const eventList = await EventMetricsService.listStandardEventMetrics();
      commit("SET_EVENT_METRIC_LIST", eventList.eventMetrics);
    } catch (e) {
      commit("alert/SET_SHOW_ERROR", e, { root: true });
    }
  },
  loadAvailableEvents: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const eventList = await EventMetricsService.listAvailableCompanyEventMetrics(
          { companyId },
        );
        commit("SET_AVAILABLE_EVENT_METRIC_LIST", eventList.eventMetrics);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  loadEventTrackedAtDepartment: async (
    { commit, rootState },
    departmentId: number,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const eventList = await EventMetricsService.listEventMetricsTrackedAtDepartment(
          { companyId, departmentId },
        );
        commit(
          "SET_EVENT_METRIC_TRACKED_AT_DEPARTMENT",
          eventList.eventMetrics,
        );
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  addCustomEvent: async (
    { commit, rootState },
    requestBody: CreateCustomEventMetricRequestBody,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const customEvent = await EventMetricsService.createCustomEventMetric({
          companyId,
          requestBody,
        });
        commit("ADD_CUSTOM_EVENT", customEvent);
        commit("alert/SET_SHOW_SUCCESS", "Custom Event Successfully Added", {
          root: true,
        });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  updateCustomEvent: async (
    { commit, rootState },
    requestBody: EventMetric,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const updateEvent = await EventMetricsService.updateCustomEventMetric({
          companyId,
          eventMetricId: requestBody.id,
          requestBody,
        });
        commit("UPDATE_CUSTOM_EVENT", updateEvent);
        commit("alert/SET_SHOW_SUCCESS", "Event Successfully Edited", {
          root: true,
        });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  loadArchivedCustomEvents: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const archivedEvents = await EventMetricsService.listArchivedCustomEventMetrics(
          { companyId },
        );
        commit("SET_ARCHIVED_CUSTOM_EVENT", archivedEvents.eventMetrics);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  archiveCustomEvent: async ({ commit, rootState }, eventMetricId: number) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const archivedEvent = await EventMetricsService.archiveCustomEventMetric(
          {
            companyId,
            eventMetricId,
          },
        ).then(async () => {
          const getEvent = await EventMetricsService.getCustomEventMetric({
            companyId,
            eventMetricId,
          });
          return getEvent;
        });
        commit("ADD_ARCHIVE_CUSTOM_EVENT", archivedEvent);
        commit("REMOVE_CUSTOM_EVENT", archivedEvent);
        commit("alert/SET_SHOW_SUCCESS", "Event Successfully Archived", {
          root: true,
        });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  removeArchivedCustomEvent: async (
    { commit, rootState },
    requestBody: EventMetric,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const updateEvent = await EventMetricsService.updateCustomEventMetric({
          companyId,
          eventMetricId: requestBody.id,
          requestBody,
        });
        commit("ADD_CUSTOM_EVENT", updateEvent);
        commit("REMOVE_ARCHIVE_CUSTOM_EVENT", updateEvent);
        commit("alert/SET_SHOW_SUCCESS", "Event Successfully Restored", {
          root: true,
        });
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  trackCompanyEvent: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;

    if (companyId) {
      try {
        const eventlist = await EventMetricsService.listEventMetricsTrackedAtCompany(
          {
            companyId,
            includeDepartmentEventMetrics: false,
          },
        );
        commit("SET_COMPANY_TRACK_EVENT", eventlist.eventMetrics);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  logNewEvent: async (
    { commit, rootState },
    {
      requestBody,
      count,
    }: {
      requestBody: LogEventRequestBody;
      count?: number;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    let formData = null;
    formData = new FormData();
    formData.append("eventMetricId", requestBody.eventMetricId.toString());
    formData.append("employeeIds", JSON.stringify(requestBody.employeeIds));
    formData.append("note", requestBody.note ?? "");
    formData.append("timestamp", requestBody.timestamp);
    formData.append("giver", requestBody.giver ?? "self");
    // eslint-disable-next-line
    formData.append("departmentId", requestBody.departmentId!.toString());
    // eslint-disable-next-line
    formData.append("approved", requestBody.approved!.toString());
    if (requestBody.document) {
      formData.append(
        "document",
        requestBody.document,
        requestBody.document?.name,
      );
    }
    if (companyId) {
      try {
        await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/event-log?count=${count}`,
          {
            method: "POST",
            body: formData,
            headers: {
              authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
            },
          },
        );
        commit("alert/SET_SHOW_SUCCESS", "Event Logged Successfully", {
          root: true,
        });
      } catch (e) {
        if (e instanceof Error) {
          throw new Error(e.message);
        }
      }
    }
  },

  trackEventByDepartment: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const eventlist = await EventMetricsService.listAllTrackedEventMetricsByDepartment(
          {
            companyId,
          },
        );
        commit("TRACK_EVENT_AT_DEPARTMENT", eventlist.trackedMetrics);
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  addNewTrackedDepartmentEvent: async (
    { commit, dispatch, rootState },
    {
      eventMetricId,
      departmentId,
    }: { eventMetricId: number; departmentId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await EventMetricsService.startTrackingEventMetricAtDepartment({
          companyId,
          eventMetricId,
          departmentId,
        });
        dispatch("trackEventByDepartment");
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  stopTrackedDepartmentEvent: async (
    { commit, rootState, dispatch },
    {
      eventMetricId,
      departmentId,
    }: { eventMetricId: number; departmentId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await EventMetricsService.stopTrackingEventMetricAtDepartment({
          companyId,
          eventMetricId,
          departmentId,
        });
        dispatch("trackEventByDepartment");
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },

  addNewTrackedEventAtCompany: async (
    { commit, rootState, dispatch },
    { eventMetricId }: { eventMetricId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await EventMetricsService.startTrackingEventMetricAtCompany({
          companyId,
          eventMetricId,
        });
        dispatch("trackCompanyEvent");
        dispatch("trackEventByDepartment");
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  stopTrackedEventAtCompany: async (
    { commit, rootState, dispatch },
    { eventMetricId }: { eventMetricId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        await EventMetricsService.stopTrackingEventMetricAtCompany({
          companyId,
          eventMetricId,
        });
        dispatch("trackCompanyEvent");
        dispatch("trackEventByDepartment");
      } catch (e) {
        commit("alert/SET_SHOW_ERROR", e, { root: true });
      }
    }
  },
  deleteEventId: async ({ commit, rootState }, eventId: number) => {
    const companyId = rootState.appContext?.company?.id;
    if (companyId) {
      try {
        await EventLogService.deleteEvent({
          companyId,
          eventId,
        });
        commit("alert/SET_SHOW_SUCCESS", "Successfully Archived", {
          root: true,
        });
      } catch (e) {
        if (e instanceof Error) {
          commit("alert/SET_SHOW_ERROR", e.message, { root: true });
        }
      }
    }
  },
  fetchEventCount: async (
    { commit, rootState },
    { eventId, date }: { eventId: number; date: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const count = await EventLogService.getEventCount({
          companyId,
          eventId,
          date,
        });
        commit("SET_EVENT_COUNT", count);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
};

export const EventMetricAdmin: Module<EventMetricAdminState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
