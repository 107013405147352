var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"600px"}},[_c('v-card-title',{staticClass:"justify-center align-center"},[_vm._v(" Add Event Form ")]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Event Name","rules":_vm.textRules},model:{value:(_vm.event.name),callback:function ($$v) {_vm.$set(_vm.event, "name", $$v)},expression:"event.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","height":"100px","dense":"","label":"Description","error-messages":_vm.commentError},model:{value:(_vm.event.description),callback:function ($$v) {_vm.$set(_vm.event, "description", $$v)},expression:"event.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"ma-2",attrs:{"items":_vm.colors,"item-text":"text","item-value":"value","dense":"","outlined":"","hide-details":"","label":"Color"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":item.value,"size":"20"}}),_c('span',[_vm._v(_vm._s(item.text))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":item.value,"size":"20"}}),_c('span',[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.event.color),callback:function ($$v) {_vm.$set(_vm.event, "color", $$v)},expression:"event.color"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{staticClass:"mx-1",attrs:{"dense":"","label":"All Day"},on:{"change":_vm.udpateTime},model:{value:(_vm.event.timed),callback:function ($$v) {_vm.$set(_vm.event, "timed", $$v)},expression:"event.timed"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"Start Date","outlined":"","readonly":"","rules":_vm.textRules},model:{value:(_vm.formattedStartDate),callback:function ($$v) {_vm.formattedStartDate=$$v},expression:"formattedStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{attrs:{"min":_vm.minDate,"no-title":""},on:{"input":function($event){_vm.formatDate(_vm.event.start, true);
                  _vm.startDatePicker = false;}},model:{value:(_vm.event.start),callback:function ($$v) {_vm.$set(_vm.event, "start", $$v)},expression:"event.start"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"startTimeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.event.startTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.event, "startTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.event, "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time","disabled":_vm.event.timed,"readonly":"","dense":"","outlined":"","rules":_vm.event.timed ? [] : _vm.textRules},model:{value:(_vm.event.startTime),callback:function ($$v) {_vm.$set(_vm.event, "startTime", $$v)},expression:"event.startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimePicker),callback:function ($$v) {_vm.startTimePicker=$$v},expression:"startTimePicker"}},[(_vm.startTimePicker)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.startTimeMenu.save(_vm.event.startTime)}},model:{value:(_vm.event.startTime),callback:function ($$v) {_vm.$set(_vm.event, "startTime", $$v)},expression:"event.startTime"}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"End Date","outlined":"","readonly":"","rules":_vm.textRules},model:{value:(_vm.formattedEndDate),callback:function ($$v) {_vm.formattedEndDate=$$v},expression:"formattedEndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}},[_c('v-date-picker',{attrs:{"min":_vm.minDate,"no-title":""},on:{"input":function($event){_vm.formatDate(_vm.event.end, false);
                  _vm.endDatePicker = false;}},model:{value:(_vm.event.end),callback:function ($$v) {_vm.$set(_vm.event, "end", $$v)},expression:"event.end"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"endTimeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.event.endTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.event, "endTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.event, "endTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time","disabled":_vm.event.timed,"readonly":"","dense":"","outlined":"","rules":_vm.event.timed ? [] : _vm.textRules},model:{value:(_vm.event.endTime),callback:function ($$v) {_vm.$set(_vm.event, "endTime", $$v)},expression:"event.endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimePicker),callback:function ($$v) {_vm.endTimePicker=$$v},expression:"endTimePicker"}},[(_vm.endTimePicker)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.endTimeMenu.save(_vm.event.endTime)}},model:{value:(_vm.event.endTime),callback:function ($$v) {_vm.$set(_vm.event, "endTime", $$v)},expression:"event.endTime"}}):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end align-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","outlined":"","loading":_vm.saving},on:{"click":_vm.onEventSubmit}},[_vm._v(_vm._s(_vm.isupdate ? "Update" : "Submit"))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"error","outlined":""},on:{"click":_vm.close}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }