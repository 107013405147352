/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDepartmentPhoneNumberRequestBody } from '../models/AddDepartmentPhoneNumberRequestBody';
import type { CreateDepartmentRequestBody } from '../models/CreateDepartmentRequestBody';
import type { Department } from '../models/Department';
import type { DepartmentList } from '../models/DepartmentList';
import type { EmployeeListing } from '../models/EmployeeListing';
import { request as __request } from '../core/request';

export class DepartmentsService {

    /**
     * A list of all departments at a company
     * @returns DepartmentList A list of departments
     * @throws ApiError
     */
    public static async listDepartments({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<DepartmentList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments`,
        });
        return result.body;
    }

    /**
     * Add a department to a company
     * @returns Department The created department
     * @throws ApiError
     */
    public static async createDepartment({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: CreateDepartmentRequestBody,
}): Promise<Department> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments`,
            body: requestBody,
            errors: {
                403: `The authenticated user is not allowed to perform this operation. They may not belong to the company in the request context or do not have sufficient permissions to perform the operation`,
            },
        });
        return result.body;
    }

    /**
     * Get a deparment
     * @returns Department The department
     * @throws ApiError
     */
    public static async getDepartment({
companyId,
departmentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
}): Promise<Department> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}`,
            errors: {
                404: `Department not found`,
            },
        });
        return result.body;
    }

    /**
     * Update a department. This endpoint may also be used to restore an archived facility by setting the Department's isArchived property to "false". Note that this endpoint does archive a facility. To archive a facility, you must call DELETE on the department resource
     * @returns any The updated department
     * @throws ApiError
     */
    public static async updateDepartment({
companyId,
departmentId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
requestBody: Department,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}`,
            body: requestBody,
            errors: {
                404: `Department not found`,
            },
        });
        return result.body;
    }

    /**
     * Archive a department
     * @returns any Successfully archived the department
     * @throws ApiError
     */
    public static async archiveDepartment({
companyId,
departmentId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/${departmentId}`,
            errors: {
                404: `The requested resource was not found`,
            },
        });
        return result.body;
    }

    /**
     * Add phonenumbers and extension to department
     * @returns any Phonenumbers or extensions are added successfully to the department
     * @throws ApiError
     */
    public static async addDepartmentPhonenumbers({
companyId,
departmentId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
requestBody: AddDepartmentPhoneNumberRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/phonenumbers`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete a phone number or an extension of a department
     * @returns any The phone number or extension are deleted successfully
     * @throws ApiError
     */
    public static async deleteDepartmentPhonenumber({
companyId,
departmentId,
phonenumberId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** The phonenumber ID **/
phonenumberId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/${departmentId}/phonenumbers/${phonenumberId}`,
        });
        return result.body;
    }

    /**
     * List all archived departments at a company
     * @returns DepartmentList A list of archived departments
     * @throws ApiError
     */
    public static async listArchivedDepartments({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<DepartmentList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/archive`,
        });
        return result.body;
    }

    /**
     * Permanantly delete an archived department
     * @returns any The archived department is now gone. So long!
     * @throws ApiError
     */
    public static async deleteArchivedDepartment({
companyId,
departmentId,
}: {
/** The companyId **/
companyId: number,
/** The archived departmentId **/
departmentId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/archive/${departmentId}`,
            errors: {
                404: `The requested resource was not found`,
            },
        });
        return result.body;
    }

    /**
     * Add an admin to this department. If the given employee does not belong to this department, they will be added.
     * @returns any Successfully added the admin
     * @throws ApiError
     */
    public static async addAdminToDepartment({
companyId,
departmentId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/admins`,
            query: {
                'employeeId': employeeId,
            },
            errors: {
                404: `The requested resource was not found`,
            },
        });
        return result.body;
    }

    /**
     * Add a lead to this department.
     * @returns any Successfully added lead to department
     * @throws ApiError
     */
    public static async addLeadToDepartment({
companyId,
departmentId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}/lead`,
            query: {
                'employeeId': employeeId,
            },
            errors: {
                404: `The requested resource was not found`,
            },
        });
        return result.body;
    }

    /**
     * Remove lead to this department.
     * @returns any Successfully removed lead from department
     * @throws ApiError
     */
    public static async removeLeadToDepartment({
companyId,
departmentId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/departments/${departmentId}/lead/remove`,
            query: {
                'employeeId': employeeId,
            },
            errors: {
                404: `The requested resource was not found`,
            },
        });
        return result.body;
    }

    /**
     * remove the department admin. This does not remove the employee from the department.
     * @returns any Successfully removed this department admin
     * @throws ApiError
     */
    public static async removeDepartmentAdmin({
companyId,
departmentId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** The admin employee ID **/
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/${departmentId}/admins/${employeeId}`,
            errors: {
                404: `The requested resource was not found`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of archived employees in a department
     * @returns EmployeeListing List of employees in this department
     * @throws ApiError
     */
    public static async listDepartmentArchivedEmployees({
companyId,
departmentId,
employeeName,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** employee name filter **/
employeeName?: string,
}): Promise<EmployeeListing> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/archived-employees`,
            query: {
                'employeeName': employeeName,
            },
        });
        return result.body;
    }

    /**
     * Get a list of employees in a department
     * @returns EmployeeListing List of employees in this department
     * @throws ApiError
     */
    public static async listDepartmentEmployees({
companyId,
departmentId,
employeeName,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
/** employee name filter **/
employeeName?: string,
}): Promise<EmployeeListing> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/employees`,
            query: {
                'employeeName': employeeName,
            },
        });
        return result.body;
    }

    /**
     * Add an employee to this department
     * @returns any Successfully added the employee
     * @throws ApiError
     */
    public static async addEmployeeToDepartment({
companyId,
departmentId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The departmentId **/
departmentId: number,
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/employees`,
            query: {
                'employeeId': employeeId,
            },
            errors: {
                404: `Employee or department not found`,
            },
        });
        return result.body;
    }

    /**
     * Remove an employee from this department. This can only be done if this is not he employee's primary department AND the emplyee has more than one assigned department
     * @returns any Successfully removed this employee from the department
     * @throws ApiError
     */
    public static async removeEmployeeFromDepartment({
companyId,
departmentId,
employeeId,
}: {
companyId: number,
departmentId: number,
employeeId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/${departmentId}/employees/${employeeId}`,
            errors: {
                404: `Employee or department not found`,
            },
        });
        return result.body;
    }

}