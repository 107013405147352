/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyGuardianAngel = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        floor: {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
},
        room: {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
    isRequired: true,
},
        bed: {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
    isRequired: true,
},
        employeeId: {
    type: 'number',
    isRequired: true,
},
        primaryGuardian: {
    type: 'number',
    isRequired: true,
},
        employeeName: {
    type: 'string',
    isRequired: true,
},
    },
};