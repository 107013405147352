import store from "@/store";

const fetchDocumentURL = async (documentId: number): Promise<string> => {
  const res = await store.dispatch(
    "employeeDocuments/fetchDocumentUrl",
    documentId,
  );
  if (!res) return "";
  return res.url;
};

const downloadFromUrl = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const _url = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = _url;
  link.download = url.split("/").pop() || "Downlaoded File";
  link.click();
  URL.revokeObjectURL(link.href);
};

export { fetchDocumentURL, downloadFromUrl };
