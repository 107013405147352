/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Expense = {
    properties: {
        id: {
    type: 'number',
},
        catId: {
    type: 'number',
    isRequired: true,
},
        vendor: {
    type: 'string',
    isRequired: true,
},
        description: {
    type: 'string',
},
        amount: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        invoice: {
    type: 'string',
    isRequired: true,
},
        receipt: {
    properties: {
    },
},
        orderDate: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
        postedBy: {
    type: 'number',
    isRequired: true,
},
        apVerified: {
    type: 'number',
},
        createdAt: {
    type: 'string',
    format: 'date-time',
},
        updatedBy: {
    type: 'number',
},
        receivedAt: {
    type: 'string',
    format: 'date-time',
},
        updatedAt: {
    type: 'string',
    format: 'date-time',
},
        archivedAt: {
    type: 'string',
    format: 'date-time',
},
    },
};