/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CronJobResponse = {
    properties: {
        msg: {
    type: 'string',
    isRequired: true,
},
    },
};