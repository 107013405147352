


















































import { Vue, Component } from "vue-property-decorator";
import InspectionForm from "@/views/guardian_angel/inspection_form/InspectionForm.vue";
import FiveStarForm from "@/components/five_star/FiveStarForm.vue";

@Component({
  components: {
    InspectionForm,
    FiveStarForm,
  },
})
export default class StartInspection extends Vue {
  counter = 0;
  dialog = false;
  formInspectionDialog = false;

  recognize() {
    this.formInspectionDialog = !this.formInspectionDialog;
    this.dialog = !this.dialog;
  }
}
