

















































import { CreateCustomEventMetricRequestBody } from "@/api";
import { VForm } from "@/common/vuetify_types";
import { Vue, Component, Ref } from "vue-property-decorator";
import * as validation from "@/tool/validation";

@Component
export default class EditCustomEvent extends Vue {
  @Ref("form") form!: VForm;
  name = "";
  description = "";
  leadboard: boolean | null = null;
  loading = false;
  rules = {
    required: validation.requiredString,
    space: validation.noSpace,
  };

  close() {
    this.form.reset();
    this.$emit("close");
  }

  async save() {
    const isValid = this.form.validate();
    if (isValid) {
      try {
        this.loading = true;
        this.$store.dispatch("EventMetricAdmin/addCustomEvent", {
          name: this.name.trim(),
          description: this.description.trim(),
          isLeaderOrderDescending: this.leadboard,
        } as CreateCustomEventMetricRequestBody);
        this.form.reset();
        this.loading = false;
        this.$emit("save");
      } catch (e) {
        console.log(e);
      }
    }
  }
}
