















import { Component, Prop, Vue } from "vue-property-decorator";
import { selfData } from "@/common/role_utils";
import { Message } from "@/api";
import moment from "moment";
import PreviewFile from "@/views/chat/PreviewFile.vue";

@Component({
  components: {
    PreviewFile,
  },
})
export default class ChatText extends Vue {
  @Prop() readonly message!: Message;

  userId = selfData().id;

  getDate(date: string) {
    return moment(date).format("MMM DD, hh:mm a");
  }
}
