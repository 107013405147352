/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Inspection = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        formId: {
    type: 'number',
    isRequired: true,
},
        roomId: {
    type: 'number',
    isRequired: true,
},
        inspectedBy: {
    type: 'number',
    isRequired: true,
},
    },
};