












import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Loading extends Vue {
  @Prop(Boolean) loading: boolean = this.$props.loading;
  @Prop(Number) size: boolean = this.$props.size;
  @Prop(Number) width: boolean = this.$props.width;
}
