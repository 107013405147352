const formStatus = [
  {
    status: "All Form",
    value: -1,
  },
  {
    status: "Draft",
    value: 1,
  },
  {
    status: "In-active",
    value: 2,
  },
  {
    status: "Current",
    value: 3,
  },
];

const getStatus = (val: number) => {
  let statusName;
  formStatus.forEach(item => {
    if (item.value === val) {
      statusName = item.status;
    }
  });
  return statusName;
};

export { formStatus, getStatus };
