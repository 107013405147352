import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { CompanyList } from "@/api/models/CompanyList";
import { AdminService } from "@/api/services/AdminService";
import {
  CompaniesService,
  Company,
  EmployeeListing,
  EmployeesService,
  ProvisionCompanyRequestBody,
} from "@/api";

export type AdminState = {
  comapnyList: CompanyList | null;
  selectedCompany: Company | null;
  inactiveEmployees: EmployeeListing | null;
};

const state: AdminState = {
  comapnyList: null,
  selectedCompany: null,
  inactiveEmployees: null,
};

const getters: GetterTree<AdminState, RootState> = {
  companyList: state => state.comapnyList,
  selectedCompany: state => state.selectedCompany,
  inactiveEmployees: state => state.inactiveEmployees,
};

const mutations: MutationTree<AdminState> = {
  SET_COMPANY_LIST: (state, comapnyList) => {
    state.comapnyList = comapnyList;
  },
  SET_SELECTED_COMPANY: (state, company) => {
    state.selectedCompany = company;
  },
  SET_INACTIVE_EMPLOYEES: (state, employeeList) => {
    state.inactiveEmployees = employeeList;
  },
  CLEAR_INACTIVE_EMPLOYEES: state => {
    state.inactiveEmployees = null;
  },
};

const actions: ActionTree<AdminState, RootState> = {
  fetchCompanyList: async ({ commit }) => {
    try {
      const list = await AdminService.listAllCompanies();
      commit("SET_COMPANY_LIST", list.companies);
    } catch (error) {
      console.log(error);
    }
  },
  createCompany: async (
    { dispatch, commit },
    requestBody: ProvisionCompanyRequestBody,
  ) => {
    try {
      const response = await AdminService.provisionCompany({ requestBody });
      dispatch("fetchCompanyList");
      console.log(response);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e);
        commit("alert/SET_SHOW_ERROR", e.message, { root: true });
      }
    }
  },
  selectCompany: async ({ commit }, companyId: number) => {
    try {
      const res = await CompaniesService.getCompany({ companyId });
      commit("SET_SELECTED_COMPANY", res);
    } catch (error) {
      console.log(error);
    }
  },
  deleteCompany: async ({ dispatch, commit }, companyId: number) => {
    try {
      await CompaniesService.deleteCompany({ companyId });
      dispatch("fetchCompanyList");
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },
  updateCompany: async ({ dispatch, commit }, requestBody: Company) => {
    try {
      const res = await CompaniesService.updateCompany({
        companyId: requestBody.id,
        requestBody,
      });
      commit("SET_SELECTED_COMPANY", res);
      dispatch("fetchCompanyList");
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error, { root: true });
      }
    }
  },
  fetchInactiveList: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    try {
      if (companyId) {
        const res = await EmployeesService.getActivationPendingEmployees({
          companyId,
        });
        commit("SET_INACTIVE_EMPLOYEES", res);
      }
    } catch (error) {
      console.log(error);
    }
  },

  clearInactiveList: ({ commit }) => {
    commit("CLEAR_INACTIVE_EMPLOYEES");
  },
};

export const superAdmin: Module<AdminState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
