import store from "@/store";
import { auth as fbAuth } from "../firebase";
import firebase from "firebase/app";
import { AuthService, HelloService, OpenAPI } from "@/api";
import User = firebase.User;

type OnAuthStateChanged = (value: User | null) => void;

class Auth {
  user?: User | null;
  authStateCallbacks: OnAuthStateChanged[] = [];

  constructor() {
    fbAuth.onAuthStateChanged(user => {
      this.setUser(user);
    });
  }

  addOnAuthStateChangedListener(callback: OnAuthStateChanged) {
    this.authStateCallbacks.push(callback);
    if (this.user) {
      callback(this.user);
    }
  }

  async isAuthenticated(): Promise<boolean> {
    const currentUser = fbAuth.currentUser;
    return !!currentUser;
  }

  async setUser(user: User | null) {
    this.user = user;
    if (user) {
      OpenAPI.TOKEN = () => user.getIdToken();
    } else {
      OpenAPI.TOKEN = undefined;
    }
    await store.dispatch("onLoginChanged", user);
    for (const callback of this.authStateCallbacks) {
      callback(user);
    }
  }

  loginWithEmailAndPassword(email: string, password: string) {
    return fbAuth.signInWithEmailAndPassword(email, password);
  }

  async loginWithEmployeeID(
    companyId: number,
    externalEmployeeId: string,
    password: string,
  ) {
    await HelloService.sayHello();
    const emailAddress = await AuthService.getEmployeeEmail({
      companyId: companyId,
      externalEmployeeId: externalEmployeeId,
    });
    return this.loginWithEmailAndPassword(emailAddress, password);
  }

  logout() {
    this.user = null;
    return fbAuth.signOut();
  }
}

const auth = new Auth();

export default auth;
