/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetTarfRequestsListResponseBody = {
    properties: {
        years: {
    type: 'array',
    contains: {
        type: 'TarfRequestsGroupedYear',
    },
},
    },
};