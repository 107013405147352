





















import { Vue, Component } from "vue-property-decorator";

@Component
export default class RoomSetupLayout extends Vue {
  isInPath(check: string) {
    if (check.toLowerCase() === "room") {
      if (this.$route.params.roomId) return true;
    } else if (check.toLowerCase() === "floor") {
      if (this.$route.params.floorId) return true;
    } else false;
  }
}
