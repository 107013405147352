import { RouteConfig } from "vue-router";
import CompanyList from "@/views/super_admin/company/CompanyList.vue";
import CompanyDetail from "@/views/super_admin/company/CompanyDetail.vue";
import AddCompany from "@/views/super_admin/company/AddCompany.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    components: {
      default: CompanyList,
      companyList: CompanyList,
      companyDetail: CompanyDetail,
      companyCreate: AddCompany,
    },
    meta: {
      title: "Company List",
      requiresAuth: true,
    },
  },
  {
    path: "create",
    components: {
      default: AddCompany,
      companyList: CompanyList,
      companyCreate: AddCompany,
    },
    meta: {
      title: "Create Company",
      requiresAuth: true,
    },
  },
  {
    path: ":companyid",
    components: {
      default: CompanyDetail,
      companyList: CompanyList,
      companyDetail: CompanyDetail,
    },
    meta: {
      title: "Company Detail",
      requiresAuth: true,
    },
  },
];

export default routes;
