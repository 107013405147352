/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LogEventRequestBody = {
    properties: {
        eventMetricId: {
    type: 'number',
    isRequired: true,
},
        employeeIds: {
    type: 'array',
    contains: {
    type: 'number',
},
    isRequired: true,
},
        departmentId: {
    type: 'number',
},
        timestamp: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
        note: {
    type: 'string',
},
        giver: {
    type: 'string',
},
        approved: {
    type: 'number',
},
        document: {
    type: 'string',
    format: 'binary',
},
    },
};