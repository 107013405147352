











































































import { Component, Vue } from "vue-property-decorator";
import { dateFormat } from "@/common/date_format";

@Component
export default class DateFilter extends Vue {
  showStartDate = false;
  showEndDate = false;
  formattedStartDate = "";
  formattedEndDate = "";
  startDate = "";
  endDate = "";
  formErrors = "";

  formatDate(date: string, isStartDate: boolean) {
    if (isStartDate) this.formattedStartDate = dateFormat(date);
    else this.formattedEndDate = dateFormat(date);
  }

  async selectedDate() {
    if (this.startDate && this.endDate) {
      if (this.startDate < this.endDate) {
        this.formErrors = "";
        this.$emit("dateUpdate", this.startDate, this.endDate);
      } else {
        this.formErrors = "start date should not be greater than end date";
      }
    } else {
      this.formErrors = "require both dates";
    }
  }

  async clearFilter() {
    this.startDate = "";
    this.endDate = "";
    this.formattedStartDate = "";
    this.formattedEndDate = "";
    this.formErrors = "";
    // emit clearFilter event here
    this.$emit("clearFilter");
  }
}
