
































import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import ScoreboardOverview from "@/views/scoreboard/ScoreboardOverview.vue";
import DepartmentScoreboard from "@/views/scoreboard/DepartmentScoreboard.vue";
import ScoreboardOptions from "@/views/scoreboard/ScoreboardOptions.vue";
import { ScoreboardSummary } from "@/api";

@Component({
  components: {
    ScoreboardOptions,
    DepartmentScoreboard,
    ScoreboardOverview,
  },
})
export default class ScoreboardDetail extends Vue {
  isDataReady = false;
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    this.departmentId = (newVal.query?.departmentId as string) ?? null;
  }

  width = this.$vuetify.breakpoint.smAndDown ? 100 + "%" : 250 + "px";

  editOption = false;

  option() {
    this.editOption = true;
  }

  get scoreboards(): ScoreboardSummary[] {
    return this.$store.getters["scoreboard/scoreboards"];
  }

  departmentId: string | null = null;
}
