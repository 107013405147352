/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyGuardianAngels } from '../models/CompanyGuardianAngels';
import type { GuardianAngel } from '../models/GuardianAngel';
import type { GuardianAngels } from '../models/GuardianAngels';
import { request as __request } from '../core/request';

export class GuardianAngelService {

    /**
     * Get a company floor room guardian angels
     * @returns GuardianAngels Company Floor Rooms Guardian Angels
     * @throws ApiError
     */
    public static async getRoomGuardianAngels({
companyId,
floorId,
roomId,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
}): Promise<GuardianAngels> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/guardian`,
        });
        return result.body;
    }

    /**
     * Add a company floor room guardian angel
     * @returns GuardianAngel added Company Floor Room a Guardian Angel
     * @throws ApiError
     */
    public static async addRoomGuardianAngel({
companyId,
floorId,
roomId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
requestBody: GuardianAngel,
}): Promise<GuardianAngel> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/guardian`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update a company floor room bed
     * @returns GuardianAngel Company Floor Room Guardian Angel updated
     * @throws ApiError
     */
    public static async updateRoomGuardianAngel({
companyId,
floorId,
roomId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
requestBody: GuardianAngel,
}): Promise<GuardianAngel> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/guardian`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete a company floor room Guardian Angel
     * @returns any Company Floor Room Guardian deleted successfully
     * @throws ApiError
     */
    public static async deleteRoomGuardianAngel({
companyId,
floorId,
roomId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The Floor Id **/
floorId: number,
/** The Room Id **/
roomId: number,
requestBody: GuardianAngel,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/floor/${floorId}/room/${roomId}/guardian`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get a company guardian angels
     * @returns CompanyGuardianAngels Company Guardian Angels
     * @throws ApiError
     */
    public static async getCompanyGuardianAngels({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<CompanyGuardianAngels> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/guardian`,
        });
        return result.body;
    }

}