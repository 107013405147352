import { Route } from "vue-router";

export type payload = {
  departmentId: number | undefined;
  eventMetricId: number | undefined;
  employeeId: number | undefined;
  postedByEmployeeId: number | undefined;
  postedByEmployeeDepartmentId: number | undefined;
  limit: number | undefined;
  offset: number | undefined;
  beginDate: string | null;
  endDate: string | null;
};

const checkTypeNumber = (
  value: string | (string | null)[],
): number | undefined => {
  if (typeof value === "string") return parseInt(value);
  return undefined;
};

export const payloadInitializer = (route: Route): payload => {
  const reqPayload: payload = {
    departmentId: checkTypeNumber(route.query.deptId),
    eventMetricId: checkTypeNumber(route.params.id),
    employeeId: checkTypeNumber(route.query.empId),
    postedByEmployeeId: undefined,
    postedByEmployeeDepartmentId: undefined,
    limit: checkTypeNumber(route.query.limit),
    offset: checkTypeNumber(route.query.offset),
    beginDate: null,
    endDate: null,
  };

  if (route.query.isPoster === "true") {
    reqPayload.departmentId = undefined;
    reqPayload.employeeId = undefined;
    reqPayload.postedByEmployeeDepartmentId = checkTypeNumber(
      route.query.deptId,
    );
    reqPayload.postedByEmployeeId = checkTypeNumber(route.query.empId);
  }

  return reqPayload;
};
