






















































































import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";
import { Department } from "@/api";
import AddDepartmentModal from "@/views/admin/departments/AddDepartmentModal.vue";
import loading from "@/components/loader/loading.vue";
@Component({
  components: { AddDepartmentModal, loading },
})
export default class DepartmentList extends Vue {
  search = "";
  dialog = false;
  open = false;
  count = 0;
  isDataLoading = false;
  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("company/loadDepartments");
    await this.$store.dispatch("departmentAdmin/loadArchiveDepartment");
    this.isDataLoading = false;
  }

  get departments(): Department[] {
    const search = !this.search ? "" : this.search;
    const departments = this.$store.getters["departmentAdmin/departments"];
    const list = _.sortBy(
      _.filter(departments, department =>
        _.includes(`${department.name.toLowerCase()}`, search.toLowerCase()),
      ),
      [(department: Department) => `${department.name.trim().toUpperCase()}`],
    );
    return Object.values(list);
  }

  get archivedDepartments(): Department[] {
    return this.$store.getters["departmentAdmin/archivedDepartments"];
  }

  selectDepartment(department: Department) {
    this.$router.push({
      path: `/admin/department/${department.id}`,
    });
  }
  clearSearch() {
    this.search = "";
  }

  get departmentId(): number {
    const departmentIdParam = this.$route.params.id as string;
    if (departmentIdParam) {
      try {
        return parseInt(departmentIdParam);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  back() {
    this.$router.back();
  }
}
