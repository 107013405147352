














































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TarfConfirmModal extends Vue {
  @Prop() readonly text!: string;
  @Prop() readonly employee!: boolean;

  close() {
    this.$emit("close");
  }

  submitAnother() {
    this.$emit("another");
  }
}
