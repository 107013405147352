import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { CompanyPhysicians, PhysicianService } from "@/api";

type PhysicianState = {
  physicians: CompanyPhysicians | null;
};

const state: PhysicianState = {
  physicians: null,
};

const getters: GetterTree<PhysicianState, RootState> = {
  physicians: state => state.physicians,
};

const mutations: MutationTree<PhysicianState> = {
  SET_PHYSICIANS: (state, physicians) => {
    state.physicians = physicians;
  },
};

const actions: ActionTree<PhysicianState, RootState> = {
  fetchPhysicians: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const physicians = await PhysicianService.getCompanyPhysicians({
        companyId,
      });
      commit("SET_PHYSICIANS", physicians);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
};

export const physician: Module<PhysicianState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
