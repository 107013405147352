





































import { Component, Ref, Vue } from "vue-property-decorator";
import auth from "@/auth";
import { VForm } from "@/common/vuetify_types";
import * as validation from "@/tool/validation";

@Component
export default class SuperAdminLogin extends Vue {
  @Ref("form") form!: VForm;
  email = "";
  password = "";
  snackbar = false;
  errorText = "";
  loading = false;
  isPasswordVisible = false;
  rules = {
    required: validation.requiredString,
    emailValid: validation.emailValid,
  };

  async signIn() {
    const isValid = this.form.validate();
    if (isValid) {
      try {
        this.loading = true;
        await auth.loginWithEmailAndPassword(this.email, this.password);
        await this.$store.dispatch("getMe");
        const isSuperAdmin = this.$store.getters.isSuperAdmin;
        localStorage.setItem("isSuperAdmin", isSuperAdmin);
        if (isSuperAdmin) {
          await this.$router.replace({ path: "/company-list" });
        } else {
          this.$store.dispatch("clearState");
          await auth.logout();
          await this.$router.replace({ path: "/login" });
          this.$store.dispatch(
            "alert/error",
            "User cannot login as Super Admin",
          );
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        if (e instanceof Error) {
          if (e.message.includes("user")) this.showError("Invalid user Email");
          if (e.message.includes("password"))
            this.showError("Passowrd didn't match.");
        }
      }
    }
  }

  showError(text: string) {
    this.errorText = text;
    this.snackbar = true;
  }
}
