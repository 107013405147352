/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarCount } from '../models/CalendarCount';
import type { CalendarEvent } from '../models/CalendarEvent';
import type { CalendarEventList } from '../models/CalendarEventList';
import { request as __request } from '../core/request';

export class CalendarService {

    /**
     * Get company calendar events
     * @returns CalendarEventList Company Calendar Events
     * @throws ApiError
     */
    public static async getCompanyCalendarEvents({
companyId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<CalendarEventList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/calendar/event`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * add a calendar event
     * @returns CalendarEvent event type updated
     * @throws ApiError
     */
    public static async addEvent({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: CalendarEvent,
}): Promise<CalendarEvent> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/calendar/event`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update a calendar event
     * @returns CalendarEvent event type updated
     * @throws ApiError
     */
    public static async updateEvent({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: CalendarEvent,
}): Promise<CalendarEvent> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/calendar/event`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete a calendar event
     * @returns CalendarEvent event type deleted
     * @throws ApiError
     */
    public static async deleteEvent({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: CalendarEvent,
}): Promise<CalendarEvent> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/calendar/event`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get company calendar events count of the day
     * @returns CalendarCount Company Calendar Events
     * @throws ApiError
     */
    public static async getCalendarEventCount({
companyId,
date,
}: {
/** The companyId **/
companyId: number,
date: string,
}): Promise<CalendarCount> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/calendar/event/count`,
            query: {
                'date': date,
            },
        });
        return result.body;
    }

}