
















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import _ from "lodash";
import { VForm } from "@/common/vuetify_types";
import { CompanyGuardianAngel, Employee, GuardianAngel } from "@/api";
import { requiredSelectWithZero } from "@/tool/validation";

@Component
export default class EditAssignments extends Vue {
  @Ref("form") form!: VForm;
  @Prop() guardianAngel!: CompanyGuardianAngel;

  employeeId = this.guardianAngel.employeeId;
  primaryGuardian = true;
  loader = false;
  ruleSelect = requiredSelectWithZero;
  errorMessage = "";

  async created() {
    await this.$store.dispatch("directory/loadAllEmployees");
  }
  get employees(): Employee[] {
    const companyEmployees = this.$store.state.directory.employees || [];
    return _.orderBy(
      companyEmployees,
      ["firstName", "lastname"],
      ["asc", "asc"],
    );
  }

  async submit() {
    this.errorMessage = "";
    if (this.employeeId == this.guardianAngel.employeeId) {
      this.errorMessage = "Change Guardian Angel in order to update";
      return;
    }
    if (this.form.validate()) {
      this.loader = true;
      const payload = {
        id: this.guardianAngel?.id,
        roomId: this.guardianAngel.room.id,
        employeeId: this.employeeId,
        primaryGuardian: 1,
      } as GuardianAngel;

      await this.$store.dispatch("guardian/addRoomGuardian", {
        // eslint-disable-next-line
        floorId: this.guardianAngel.floor!.id,
        roomId: this.guardianAngel.room.id,
        requestBody: payload,
      });
      await this.$store.dispatch("guardian/fetchCompanyGuardianAngels");
      this.loader = false;
      this.close();
    }
  }

  close() {
    this.$emit("close");
  }
}
