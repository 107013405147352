


































































import { Vue, Component } from "vue-property-decorator";
import loading from "@/components/loader/loading.vue";

@Component({
  components: {
    loading,
  },
})
export default class ArchiveEmployeeList extends Vue {
  isDataLoading = false;
  async created() {
    this.isDataLoading = true;
    await this.$store.dispatch("directory/loadAllArchivedEmployees");
    this.isDataLoading = false;
  }

  get archivedEmployees() {
    const list = this.$store.getters["directory/archivedEmployees"];
    if (list) {
      return list;
    } else {
      setTimeout(() => {
        this.isDataLoading = false;
      }, 1000);
      return false;
    }
  }

  restore(id: number) {
    this.$store.dispatch("directory/restoreArchiveEmployee", id);
  }

  back() {
    this.$router.back();
  }

  destroyed() {
    this.$store.dispatch("directory/destroyArchivedEmployees");
  }
}
