/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { Employee } from '../models/Employee';
import type { EmployeeList } from '../models/EmployeeList';
import type { MeEventScoresResponse } from '../models/MeEventScoresResponse';
import type { MeRankingResponse } from '../models/MeRankingResponse';
import type { RegisterAccountRequestBody } from '../models/RegisterAccountRequestBody';
import type { SendPasswordResetLinkResponse } from '../models/SendPasswordResetLinkResponse';
import type { SendResetPasswordLinkBySelfRequest } from '../models/SendResetPasswordLinkBySelfRequest';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * When new accounts are created, users receive a OTP registration token. They exchange this token for a chance to set their password
     * @returns any successfully registered
     * @throws ApiError
     */
    public static async registerAccount({
requestBody,
}: {
requestBody: RegisterAccountRequestBody,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/register-account`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * get the internal, synthesized employee email address associated with this employee account. This does not work for user accounts with an actual, self-managed email address
     * @returns string an email address for the client to use when authenticating
     * @throws ApiError
     */
    public static async getEmployeeEmail({
companyId,
externalEmployeeId,
}: {
companyId: number,
externalEmployeeId: string,
}): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/get-employee-email`,
            query: {
                'companyId': companyId,
                'externalEmployeeId': externalEmployeeId,
            },
        });
        return result.body;
    }

    /**
     * get information about the authenticated employee
     * @returns EmployeeList The authenticated user info
     * @throws ApiError
     */
    public static async getMe(): Promise<EmployeeList> {
        const result = await __request({
            method: 'GET',
            path: `/me`,
        });
        return result.body;
    }

    /**
     * get 5 star moment and performance score and leaderboard rankings for the authenticated user
     * @returns MeRankingResponse The ranking info
     * @throws ApiError
     */
    public static async getMeRankings({
beginAt,
endAt,
}: {
beginAt?: string,
endAt?: string,
}): Promise<MeRankingResponse> {
        const result = await __request({
            method: 'GET',
            path: `/me/rankings`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * get event scores grouped by event metric
     * @returns MeEventScoresResponse The event scores
     * @throws ApiError
     */
    public static async getMeEventScores({
beginAt,
endAt,
}: {
beginAt?: string,
endAt?: string,
}): Promise<MeEventScoresResponse> {
        const result = await __request({
            method: 'GET',
            path: `/me/event-scores`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * find company by company code
     * @returns Company The Company
     * @throws ApiError
     */
    public static async lookupCompany({
companyCode,
}: {
/** The company's short code **/
companyCode: string,
}): Promise<Company> {
        const result = await __request({
            method: 'GET',
            path: `/company-lookup`,
            query: {
                'companyCode': companyCode,
            },
        });
        return result.body;
    }

    /**
     * Send a password reset link to the given email
     * @returns any Password reset link has been sent to the given email if email exist
     * @throws ApiError
     */
    public static async sendResetPasswordLinkBySelf({
requestBody,
}: {
requestBody: SendResetPasswordLinkBySelfRequest,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/password-reset-link`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Send Company Code to the given email
     * @returns any Company Code has been sent to the given email.
     * @throws ApiError
     */
    public static async sendCompanyCode({
email,
}: {
/** Email of the user **/
email?: string,
}): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/forget-company-code`,
            query: {
                'email': email,
            },
        });
        return result.body;
    }

    /**
     * Send a password reset link for the employee
     * @returns SendPasswordResetLinkResponse Password reset link details
     * @throws ApiError
     */
    public static async sendPasswordResetLink({
companyId,
employeeId,
}: {
/** The companyId **/
companyId: number,
/** The employee ID for which the password reset link should be generated **/
employeeId: number,
}): Promise<SendPasswordResetLinkResponse> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/employees/${employeeId}/password-reset-link`,
        });
        return result.body;
    }

    /**
     * set the employee's login email (company admins only)
     * @returns Employee Login email successfully updated
     * @throws ApiError
     */
    public static async updateEmployeeLoginEmail({
companyId,
employeeId,
email,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The new login email to use for the employee **/
email?: string,
}): Promise<Employee> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/email`,
            query: {
                'email': email,
            },
        });
        return result.body;
    }

    /**
     * set the employee's login employee ID (company admins only)
     * @returns Employee Login Employee ID successfully updated
     * @throws ApiError
     */
    public static async updateEmployeeLoginEmployeeId({
companyId,
employeeId,
externalEmployeeId,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
/** The new login employee ID to use for the employee **/
externalEmployeeId: string,
}): Promise<Employee> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/employees/${employeeId}/employee-id`,
            query: {
                'externalEmployeeId': externalEmployeeId,
            },
        });
        return result.body;
    }

}