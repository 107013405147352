import _ from "lodash";
import statusId from "@/tool/tarfStatus";

const orderStatus = [
  {
    name: "New",
    routename: "new",
    id: 0,
    orderby: 0,
  },
  {
    name: "Approved Excused",
    routename: "excused",
    id: 1,
    orderby: 3,
  },
  {
    name: "Approved Unexcused",
    routename: "unexcused",
    id: 2,
    orderby: 4,
  },
  {
    name: "Processed",
    routename: "processed",
    id: 3,
    orderby: 5,
  },
  {
    name: "Denied",
    routename: "denied",
    id: 4,
    orderby: 1,
  },
  {
    name: "Corrected/Resubmitted",
    routename: "resubmit",
    id: 5,
    orderby: 2,
  },
];

export const orderStatusList = _.orderBy(orderStatus, ["orderby"], "asc");

export const getStatusAndProcessed = (path: string) => {
  if (path.toLowerCase() === "new") {
    return { status: statusId.new, processed: 0 };
  } else if (path.toLowerCase() === "excused") {
    return { status: statusId.excused, processed: 0 };
  } else if (path.toLowerCase() === "processed") {
    return { status: statusId.processed, processed: statusId.processed };
  } else if (path.toLowerCase() === "unexcused") {
    return { status: statusId.unexcused, processed: 0 };
  } else if (path.toLowerCase() === "denied") {
    return { status: statusId.deny, processed: 0 };
  } else if (path.toLowerCase() === "resubmitted") {
    return { status: statusId.resubmit, processed: 0 };
  }
};

export interface OrderStatus {
  name: string;
  id: number;
  orderby: number;
}
