/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomEventMetricRequestBody } from '../models/CreateCustomEventMetricRequestBody';
import type { EventMetric } from '../models/EventMetric';
import type { EventMetricList } from '../models/EventMetricList';
import type { TrackedEventMetricsByDepartment } from '../models/TrackedEventMetricsByDepartment';
import { request as __request } from '../core/request';

export class EventMetricsService {

    /**
     * List all event metrics tracked by this company, ascending by ID
     * @returns EventMetricList Custom event metrics for this company
     * @throws ApiError
     */
    public static async listEventMetricsTrackedAtCompany({
companyId,
includeDepartmentEventMetrics,
}: {
companyId: number,
/** Normally, this endpoint only lists event metrics tha are tracked at the company level such as Five Star Moments. However, in some cases you will want to know the complete list of all event metrics tracked at the company adn department levels. If this is the case, set this valud to true **/
includeDepartmentEventMetrics?: boolean,
}): Promise<EventMetricList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/tracked-event-metrics`,
            query: {
                'includeDepartmentEventMetrics': includeDepartmentEventMetrics,
            },
        });
        return result.body;
    }

    /**
     * Track an event metric at the company level
     * @returns any Successfully added the event metric to the company
     * @throws ApiError
     */
    public static async startTrackingEventMetricAtCompany({
companyId,
eventMetricId,
}: {
companyId: number,
eventMetricId: number,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/tracked-event-metrics`,
            query: {
                'eventMetricId': eventMetricId,
            },
        });
        return result.body;
    }

    /**
     * Stop tracking this EventMetric at this company
     * @returns any Successfully stoped tracking this EventMetric at this company
     * @throws ApiError
     */
    public static async stopTrackingEventMetricAtCompany({
companyId,
eventMetricId,
}: {
companyId: number,
eventMetricId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/tracked-event-metrics/${eventMetricId}`,
        });
        return result.body;
    }

    /**
     * List of event metrics tracked by this department
     * @returns EventMetricList Standard event metrics
     * @throws ApiError
     */
    public static async listEventMetricsTrackedAtDepartment({
companyId,
departmentId,
}: {
companyId: number,
departmentId: number,
}): Promise<EventMetricList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/departments/${departmentId}/tracked-event-metrics`,
        });
        return result.body;
    }

    /**
     * Add a new event metric to be tracked for this department
     * @returns any Successfully added the event metric to the department
     * @throws ApiError
     */
    public static async startTrackingEventMetricAtDepartment({
companyId,
departmentId,
eventMetricId,
}: {
companyId: number,
departmentId: number,
eventMetricId: number,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/departments/${departmentId}/tracked-event-metrics`,
            query: {
                'eventMetricId': eventMetricId,
            },
        });
        return result.body;
    }

    /**
     * Stop tracking this event in this department
     * @returns any Successfully stopped tracking this event at this department
     * @throws ApiError
     */
    public static async stopTrackingEventMetricAtDepartment({
companyId,
departmentId,
eventMetricId,
}: {
companyId: number,
departmentId: number,
eventMetricId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/departments/${departmentId}/tracked-event-metrics/${eventMetricId}`,
        });
        return result.body;
    }

    /**
     * List available event metrics for a company
     * @returns EventMetricList Standard event metrics
     * @throws ApiError
     */
    public static async listAvailableCompanyEventMetrics({
companyId,
}: {
companyId: number,
}): Promise<EventMetricList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/event-metrics`,
        });
        return result.body;
    }

    /**
     * Create new custom event metric that can be tracked at the company
     * @returns EventMetric The new custom event metric
     * @throws ApiError
     */
    public static async createCustomEventMetric({
companyId,
requestBody,
}: {
companyId: number,
requestBody: CreateCustomEventMetricRequestBody,
}): Promise<EventMetric> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/event-metrics`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * List all tracked event metrics grouped by department
     * @returns TrackedEventMetricsByDepartment All tracked event metrics grouped by department
     * @throws ApiError
     */
    public static async listAllTrackedEventMetricsByDepartment({
companyId,
}: {
companyId: number,
}): Promise<TrackedEventMetricsByDepartment> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/event-metrics/tracked-event-metrics`,
        });
        return result.body;
    }

    /**
     * Get a specific event metric. This can either be a standard event metric or a custom one belonging to this company.
     * @returns EventMetric The EventMetric
     * @throws ApiError
     */
    public static async getCustomEventMetric({
companyId,
eventMetricId,
}: {
companyId: number,
eventMetricId: number,
}): Promise<EventMetric> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/event-metrics/${eventMetricId}`,
            errors: {
                404: `The requested resource was not found`,
            },
        });
        return result.body;
    }

    /**
     * Update custom event metrics. The event metric must be owned by the given company. This can also be used to restore a previously archived custom metric.
     * @returns EventMetric The updated custom event metric
     * @throws ApiError
     */
    public static async updateCustomEventMetric({
companyId,
eventMetricId,
requestBody,
}: {
companyId: number,
eventMetricId: number,
requestBody: EventMetric,
}): Promise<EventMetric> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/event-metrics/${eventMetricId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Archive custom event metric. The event metric must be owned by the given company.
     * @returns any success, the custom event metric has been archived
     * @throws ApiError
     */
    public static async archiveCustomEventMetric({
companyId,
eventMetricId,
}: {
companyId: number,
eventMetricId: number,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/event-metrics/${eventMetricId}`,
        });
        return result.body;
    }

    /**
     * List archived custom event metrics
     * @returns EventMetricList list of archived custom event metrics
     * @throws ApiError
     */
    public static async listArchivedCustomEventMetrics({
companyId,
}: {
companyId: number,
}): Promise<EventMetricList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/event-metrics/archive`,
        });
        return result.body;
    }

    /**
     * List available event metrics for a company
     * @returns EventMetricList Standard event metrics
     * @throws ApiError
     */
    public static async listStandardEventMetrics(): Promise<EventMetricList> {
        const result = await __request({
            method: 'GET',
            path: `/event-metrics`,
        });
        return result.body;
    }

}