





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {
  @Prop() readonly progres!: number;
  @Prop() readonly query!: boolean;
}
