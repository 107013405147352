/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ResponseTask = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        departmentId: {
    type: 'number',
    isRequired: true,
},
        inspectionDataId: {
    type: 'number',
},
        room: {
    properties: {
        roomId: {
    type: 'number',
},
        roomName: {
    type: 'string',
},
    },
    isRequired: true,
},
        bed: {
    properties: {
        bedId: {
    type: 'number',
},
        bedName: {
    type: 'string',
},
    },
    isRequired: true,
},
        details: {
    type: 'string',
    isRequired: true,
},
        location: {
    type: 'string',
    isRequired: true,
},
        type: {
    type: 'string',
    isRequired: true,
},
        assignee: {
    properties: {
        employeeId: {
    type: 'number',
},
        employeeName: {
    type: 'string',
},
    },
    isRequired: true,
},
        status: {
    type: 'number',
},
        deadline: {
    type: 'string',
    isRequired: true,
},
        createdAt: {
    type: 'string',
},
    },
};