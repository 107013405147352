/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TestNotificationResponse = {
    properties: {
        employee_id: {
    type: 'number',
    isRequired: true,
},
        notification_type: {
    type: 'string',
    isRequired: true,
},
        notification_body: {
    type: 'string',
    isRequired: true,
},
        payload_name: {
    type: 'string',
    isRequired: true,
},
        payload_body: {
    type: 'string',
    isRequired: true,
},
    },
};