/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ToggleLoggedEventRequestBody = {
    properties: {
        employeeId: {
    type: 'number',
    isRequired: true,
},
        toggle: {
    type: 'boolean',
    isRequired: true,
},
    },
};