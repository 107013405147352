/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetDepartmentTitlesRequestBody = {
    properties: {
        titles: {
    type: 'array',
    contains: {
        type: 'DepartmentTitleRequestObject',
    },
},
    },
};