














































































import { EventMetric } from "@/api";
import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
import EventByDepartment from "@/views/admin/event_metrics/EventByDepartment.vue";
import EditTrackList from "@/views/admin/event_metrics/EditTrackList.vue";
import loading from "@/components/loader/loading.vue";
@Component({
  components: {
    EventByDepartment,
    EditTrackList,
    loading,
  },
})
export default class EventTrackList extends Vue {
  editCompany = false;
  isComapny = false;
  editDepartment = false;
  id = 0;
  width = this.$vuetify.breakpoint.smAndDown ? screen.width + "px" : 300 + "px";
  created() {
    this.$store.dispatch("EventMetricAdmin/trackCompanyEvent");
    this.$store.dispatch("EventMetricAdmin/loadAvailableEvents");
  }
  get companyTrackLists() {
    const companyTrackLists = this.$store.getters[
      "EventMetricAdmin/companyTrackEventList"
    ];
    return _.sortBy(companyTrackLists, companyTrackLists =>
      companyTrackLists.name.toUpperCase(),
    );
  }
  get ComapnyId() {
    return this.$store.getters["getSelectedCompany"];
  }
  edit(id: number) {
    this.id = id;
    this.isComapny = false;
    this.editDepartment = true;
    this.editCompany = false;
  }
  editCompanyTrack(companyId: number) {
    this.id = companyId;
    this.isComapny = true;
    this.editCompany = true;
    this.editDepartment = false;
  }
  get departmentEventList() {
    return this.$store.getters["EventMetricAdmin/departmentEventList"];
  }
  get departmentEvents() {
    const departmentEvents: EventMetric[] = this.departmentEventList;
    const events = departmentEvents?.map(item => item.id);
    return _.uniqBy(events, event => event);
  }
  get companyEvents() {
    const departmentEvents: EventMetric[] = this.companyTrackLists;
    const events = departmentEvents?.map(item => item.id);
    return _.uniqBy(events, event => event);
  }
  get availableEventList() {
    const availableEventList = this.$store.getters[
      "EventMetricAdmin/availableEventList"
    ];
    return _.sortBy(availableEventList, availableEventList =>
      availableEventList.name.toUpperCase(),
    );
  }

  back() {
    this.$router.back();
  }
}
