import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=75dab4d8&scoped=true"
import script from "./Chat.vue?vue&type=script&lang=ts"
export * from "./Chat.vue?vue&type=script&lang=ts"
import style0 from "./Chat.vue?vue&type=style&index=0&id=75dab4d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75dab4d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAppBarNavIcon,VBtn,VCol,VContainer,VDialog,VDivider,VFileInput,VIcon,VList,VNavigationDrawer,VRow,VSheet,VTextarea})
