import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import {
  AnniversaryListing,
  AuthService,
  EmployeesService,
  MeEventScoresResponse,
  MeRankingResponse,
} from "@/api";

export type DashboardState = {
  anniversaryList: AnniversaryListing | null;
  birthdayList: AnniversaryListing | null;
  socreAndRank: MeRankingResponse | null;
  eventScore: MeEventScoresResponse | null;
  newHiresList: AnniversaryListing | null;
};

const state: DashboardState = {
  anniversaryList: null,
  birthdayList: null,
  socreAndRank: null,
  eventScore: null,
  newHiresList: null,
};

const getters: GetterTree<DashboardState, RootState> = {
  anniversaryList: state => state.anniversaryList,
  birthdayList: state => state.birthdayList,
  scoreAndRank: state => state.socreAndRank,
  eventScore: state => state.eventScore,
  newHireList: state => state.newHiresList,
};
const mutations: MutationTree<DashboardState> = {
  SET_ANNIVERSARY_LIST: (state, anniversaries) => {
    state.anniversaryList = anniversaries;
  },
  SET_BIRTHDAY_LIST: (state, birthdays) => {
    state.birthdayList = birthdays;
  },
  SET_NEW_HIRE_LIST: (state, hires) => {
    state.newHiresList = hires;
  },
  SET_SCORE_RANK: (state, score) => {
    state.socreAndRank = score;
  },
  SET_EVENT_SCORE: (state, score) => {
    state.eventScore = score;
  },
};
const actions: ActionTree<DashboardState, RootState> = {
  fetchAnniversary: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    try {
      if (companyId) {
        const anniversaries = await EmployeesService.getUpcominAnniversary({
          companyId,
        });
        commit("SET_ANNIVERSARY_LIST", anniversaries);
      }
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
  fetchBirthday: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    try {
      if (companyId) {
        const birthdays = await EmployeesService.getUpcominAnniversary1({
          companyId,
        });
        commit("SET_BIRTHDAY_LIST", birthdays);
      }
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
  fetchNewHires: async ({ commit, rootState }) => {
    const companyId = rootState.appContext.company?.id;
    try {
      if (companyId) {
        const newHires = await EmployeesService.getRecentlyJoinedEmployees({
          companyId,
        });
        commit("SET_NEW_HIRE_LIST", newHires);
      }
    } catch (error) {
      commit("alert/SET_SHOW_ERROR", error, { root: true });
    }
  },
  fetchSelfScore: async (
    { commit },
    {
      beginDate,
      endDate,
    }: {
      beginDate?: string;
      endDate?: string;
    },
  ) => {
    try {
      const date = new Date();
      let beginAt;
      let endAt;

      if (!beginDate && !endDate) {
        beginAt = new Date(date.getFullYear(), date.getMonth(), 2)
          .toISOString()
          .substr(0, 10);
        endAt = new Date(date.getFullYear(), date.getMonth() + 1, 1)
          .toISOString()
          .substr(0, 10);
      } else {
        if (beginDate && endDate) {
          beginAt = new Date(beginDate).toISOString().substr(0, 10);
          endAt = new Date(endDate).toISOString().substr(0, 10);
        }
      }
      const score = await AuthService.getMeRankings({ beginAt, endAt });
      commit("SET_SCORE_RANK", score);
    } catch (error) {
      console.log(error);
    }
  },
  fetchSelfEvents: async (
    { commit },
    {
      beginDate,
      endDate,
    }: {
      beginDate?: string;
      endDate?: string;
    },
  ) => {
    try {
      const date = new Date();
      let beginAt;
      let endAt;

      if (!beginDate && !endDate) {
        beginAt = new Date(date.getFullYear(), date.getMonth(), 2)
          .toISOString()
          .substr(0, 10);
        endAt = new Date(date.getFullYear(), date.getMonth() + 1, 1)
          .toISOString()
          .substr(0, 10);
      } else {
        if (beginDate && endDate) {
          beginAt = new Date(beginDate).toISOString().substr(0, 10);
          endAt = new Date(endDate).toISOString().substr(0, 10);
        }
      }
      const score = await AuthService.getMeEventScores({ beginAt, endAt });
      commit("SET_EVENT_SCORE", score);
    } catch (error) {
      console.log(error);
    }
  },
};

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
