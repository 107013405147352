/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Department = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        isArchived: {
    type: 'boolean',
    isRequired: true,
},
        createdAt: {
    type: 'string',
    isRequired: true,
},
        phonenumbers: {
    type: 'array',
    contains: {
        type: 'DepartmentPhoneNumber',
    },
    isRequired: true,
},
    },
};