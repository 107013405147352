export const adminheader = [
  { text: "", width: 5, value: "document_path" },
  {
    text: "Employee Document",
    align: "start",
    sortable: false,
    value: "document_name",
    width: 100,
  },
  { text: "Employee Name", value: "firstName", width: 100 },
  { text: "Department", value: "primaryDepartmentName", width: 150 },
  { text: "Expiration Date", value: "expiration_date", width: 10 },
  { text: "Status", value: "status", width: 5, sortable: false },
  { text: "Approval", value: "approval", width: 5, sortable: false },
];
export const employeeheader = [
  { text: "", width: 5, value: "document_path" },
  {
    text: "Employee Document",
    align: "start",
    sortable: false,
    value: "document_name",
    width: 100,
  },
  { text: "Expiration Date", value: "expiration_date", width: 10 },
  { text: "Status", value: "status", width: 5, sortable: false },
  { text: "Approval", value: "approval", width: 5, sortable: false },
];
