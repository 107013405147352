














































import { MessageGroup, MessageGroupList } from "@/api";
import _ from "lodash";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class PersonalChat extends Vue {
  get search() {
    return this.$store.getters["chat/chatListSearch"];
  }

  get groupChatId() {
    if (this.$route.params.id) {
      return parseInt(this.$route.params.id);
    } else {
      return 0;
    }
  }

  get groups() {
    const groups = this.$store.getters["chat/groupList"] as MessageGroupList;
    const list = _.filter(groups ?? [], (group: MessageGroup) =>
      _.includes(group.name.toLowerCase(), this.search?.toLowerCase()),
    );

    if (this.groupChatId) {
      const selectedGroup = _.find(
        list,
        (grp: MessageGroup) => grp?.id == this.groupChatId,
      );
      if (selectedGroup) {
        this.$store.dispatch("chat/setSelectedGroup", {
          group: selectedGroup,
          force: false,
        });
      }
    }

    return Object.values(list);
  }

  selectGroup(group: MessageGroup) {
    this.$store.dispatch("chat/setSelectedGroup", { group, force: true });
    this.$router.push({
      path: `/chat/${group.id}`,
      query: {
        isGroup: group.name,
      },
    });
  }

  getGroupLetter(name: string) {
    return `${name[0]}`;
  }
}
