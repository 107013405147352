/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Datum = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        question_id: {
    type: 'number',
    isRequired: true,
},
        question: {
    type: 'string',
    isRequired: true,
},
        order: {
    type: 'number',
    isRequired: true,
},
        allow_rating: {
    type: 'boolean',
    isRequired: true,
},
        meet: {
    type: 'boolean',
    isRequired: true,
},
        comment: {
    type: 'string',
    isRequired: true,
},
        feedback: {
    type: 'string',
    isRequired: true,
},
        rating: {
    type: 'number',
    isRequired: true,
},
    },
};