/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SendResetPasswordLinkBySelfRequest = {
    properties: {
        email: {
    type: 'string',
    isRequired: true,
},
    },
};