





















































import { Component, Prop, Vue } from "vue-property-decorator";
import { selfData } from "@/common/role_utils";
import { Message } from "@/api";
import moment from "moment";
import PreviewFile from "@/views/chat/PreviewFile.vue";
import { downloadFromUrl } from "@/common/document_url";

@Component({
  components: {
    PreviewFile,
  },
})
export default class ChatMedia extends Vue {
  @Prop() readonly message!: Message;

  userId = selfData().id;
  filePreview = false;

  isImage() {
    const res = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(this.message.media);
    return res;
  }

  getDate(date: string) {
    return moment(date).format("MMM DD, hh:mm a");
  }

  async downloadFile(url: string) {
    await downloadFromUrl(url);
  }
}
