































































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import { Employee, LogEventRequestBody } from "@/api";
import _ from "lodash";
import { dateFormat, hourMinAdder } from "@/common/date_format";
import { checkSelfRole, selfData } from "@/common/role_utils";
import {
  noSpace,
  numberOnly,
  requireAutoSelectNumber,
  requiredString,
} from "@/tool/validation";

@Component
export default class LogNewEvent extends Vue {
  @Ref("form") form!: VForm;
  @Prop(String) title: string = this.$props.title;
  @Prop(Number) departmentId: number = this.$props.departmentId;
  @Prop(Number) eventId: number = this.$props.eventId;

  selectedEmployees: number[] = [];
  note = "";
  date = "";
  dateMenu = false;
  formattedDate = "";
  logging = false;
  review = null;
  count = "1";
  searchInput = null;
  numberRules = [requiredString, noSpace, numberOnly];

  employeesRules = [requireAutoSelectNumber];
  space = noSpace;

  requiredTextInput = [requiredString];

  created() {
    this.$store.dispatch("directory/loadAllEmployees");
  }
  formatDate(date: string) {
    this.formattedDate = dateFormat(date);
  }

  get maxDate() {
    const currentDate = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000,
    ).toISOString();
    const maxDate = currentDate;
    return maxDate;
  }

  get isOwner() {
    return checkSelfRole("owner");
  }

  get employees(): Employee[] {
    const self = selfData();
    return _.map(
      _.orderBy(
        _.filter(
          this.$store.state.directory.employees ?? [],
          employee => employee.id !== self.id,
        ),
        employee => employee?.firstName.toLowerCase(),
      ),
      employee => {
        return {
          ...employee,
          list: `${employee.firstName} ${employee.lastName} - ${employee.title} - ${employee.departmentAssignments[0].departmentName}`,
        };
      },
    );
  }

  remove(employee: Employee) {
    for (let i = 0; i < this.selectedEmployees.length; i++) {
      const selectedEmployee = this.selectedEmployees[i];
      if (selectedEmployee == employee.id) {
        this.selectedEmployees.splice(i, 1);
      }
    }
  }

  resetForm() {
    this.selectedEmployees = [];
    this.note = "";
    this.count = "1";
    this.date = "";
    this.review = null;
    this.form.resetValidation();
  }

  async submit() {
    const isValid = this.form.validate();
    if (isValid) {
      try {
        this.logging = true;
        const timeStamp = hourMinAdder(this.date);
        await this.$store.dispatch("EventMetricAdmin/logNewEvent", {
          requestBody: {
            eventMetricId: this.eventId,
            employeeIds: this.selectedEmployees,
            departmentId: this.departmentId,
            note: this.note?.trim(),
            timestamp: timeStamp,
            giver: "self",
            approved: this.eventId === -5 ? Number(this.review) : 0,
          } as LogEventRequestBody,
          count: this.count,
        });
        this.logging = false;
        this.resetForm();
        this.$emit("save");
      } catch (e) {
        this.$store.dispatch("alert/error", e);
        this.logging = false;
      }
    }
  }

  close() {
    this.resetForm();
    this.$emit("close");
  }

  get finalEmployees() {
    let employees = this.$store.state.directory.employees;
    employees = employees
      ? employees.filter((employee: Employee) => {
          if (this.selectedEmployees.includes(employee.id)) {
            return employee;
          }
        })
      : [];
    return employees.map((employee: Employee) => {
      return {
        ...employee,
        list: `${employee.firstName} ${employee.lastName} - ${employee.title} - ${employee.departmentAssignments[0].departmentName}`,
      };
    });
  }

  set finalEmployees(value) {
    if (!this.selectedEmployees.includes(value?.id)) {
      if (value?.id) {
        this.selectedEmployees.push(value.id);
      }
    }
  }
}
