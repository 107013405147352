

























































































import { Component, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/common/vuetify_types";
import {
  CreateDepartmentRequestBody,
  Department,
  DepartmentPhoneNumberRequestBody,
} from "@/api";
import * as validation from "@/tool/validation";

type ExtOrPhone = {
  name: string;
  optionSelected: string;
  isExtension: boolean;
  isPhone: boolean;
};

@Component
export default class AddDepartmentModal extends Vue {
  @Ref("form") form!: VForm;

  name = "";
  extOrPhn = ["Extension", "Phone number"];
  rules = {
    required: validation.requiredString,
    numberOnly: validation.intCheck,
    noSpace: validation.noSpace,
    shotLen: (v: string) => validation.maxLen(v, 4),
    longLen: (v: string) => validation.maxLenInt(v, 10),
  };

  ext: ExtOrPhone[] = [
    {
      name: "",
      optionSelected: "Extension",
      isExtension: true,
      isPhone: false,
    },
  ];
  loading = false;
  nameErrorMessage = "";

  close() {
    this.form.reset();
    this.$emit("close");
  }

  async save() {
    let departments = this.$store.state.departmentAdmin.departments;
    if (departments) {
      departments = departments.filter((department: Department) => {
        if (
          department.name?.toUpperCase().trim() ===
          this.name.toUpperCase().trim()
        ) {
          return department;
        }
      });
    }
    const isValid = this.form.validate();
    const extOrPhone = JSON.parse(JSON.stringify(this.ext)) as ExtOrPhone[];
    const phonenumbers = extOrPhone
      .map(ext => {
        if (ext.isExtension) {
          return {
            value: ext.name.trim(),
            type: 2,
          };
        } else {
          return {
            value: ext.name.trim(),
            type: 1,
          };
        }
      })
      .filter(fil => fil.value.trim() !== "");
    const isValidphone = phonenumbers.filter(
      (phone: DepartmentPhoneNumberRequestBody) => {
        if (phone?.type === 1) {
          if (
            phone.value.toString().trim().length !== 10 &&
            phone.value.toString() !== ""
          ) {
            return phone;
          }
        } else {
          if (phone?.value !== "") return phone;
        }
      },
    );
    if (isValid && departments.length === 0 && isValidphone.length === 0) {
      try {
        this.loading = true;
        await this.$store.dispatch("departmentAdmin/createDepartment", {
          name: this.name.trim(),
          phonenumbers: phonenumbers.length ? phonenumbers : undefined,
        } as CreateDepartmentRequestBody);
        this.loading = false;
        this.form.reset();
        this.$store.dispatch("company/loadDepartments");
        this.$emit("save");
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    } else {
      this.loading = false;
      if (isValidphone.length > 0) {
        this.$store.dispatch(
          "alert/warning",
          "Phone number must contain 10 digits",
        );
      } else if (isValid) {
        this.$store.dispatch("alert/warning", "Department already exists.");
      }
    }
  }
  optionSelect(index: number) {
    if (this.ext[index].optionSelected.toLowerCase().includes("extension")) {
      if (this.ext[index].isPhone) {
        this.ext[index].isPhone = false;
      }
      this.ext[index].isExtension = true;
    }
    if (this.ext[index].optionSelected.toLowerCase().includes("phone")) {
      if (this.ext[index].isExtension) {
        this.ext[index].isExtension = false;
      }
      this.ext[index].isPhone = true;
    }
    this.ext[index].name = "";
    this.form.resetValidation();
  }
  addExt() {
    this.ext.push({
      name: "",
      optionSelected: "Extension",
      isExtension: true,
      isPhone: false,
    });
  }

  removeExt(index: number) {
    this.ext.splice(index, 1);
  }
}
